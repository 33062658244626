import angular from 'angular';
import { compact, get, values, differenceBy } from 'lodash';
import { Promise } from 'es6-promise';
import '../services/line';
import '../services/area';
import '../services/user';
import '../services/schema';
import '../services/location';
import '../services/globals';
import '../services/device';
import '../services/resource-group';
import '../services/banner';

const module = angular.module('whyline.components.osom', [
  'whyline.services.line',
  'whyline.services.device',
  'whyline.services.organization-line',
  'whyline.services.user',
  'whyline.services.area',
  'whyline.services.schema',
  'whyline.services.location',
  'whyline.services.globals',
  'whyline.services.device',
  'whyline.services.resource-group',
  'whyline.services.banner',
]);

const osomComponent = {
  bindings: {
    models: '<',
    fields: '<',
    includeSupervisor: '<',
    alreadyShown: '<',
    onSelect: '&',
    enable: '<?',
  },
  templateUrl: '/templates/components/osom.html',
  controller(
    $scope,
    LineService,
    DeviceService,
    OrganizationLineService,
    UserService,
    AreaService,
    SchemaService,
    GlobalsService,
    ResourceGroupService,
    BannerService,
  ) {
    'ngInject';
    this.enable = this.enable || true;

    this.$onChanges = () => {
      const fieldIsEmpty = this.fields && !this.fields.length;
      const modelIsEmpty = this.models && !this.models.length;

      this.currentLanguage = GlobalsService.CurrentLanguage;

      if (!this.fields || fieldIsEmpty) {
        this.fields = ['type', 'label', 'name', 'realType', 'alias'];
      }

      if (!this.models || modelIsEmpty) {
        this.models = ['lines', 'areas', 'users', 'devices', 'linked-devices'];
      }
      this.results = [];

      const promiseToResolve = [];

      this.models.forEach(model => {
        switch (model) {
          case 'lines':
            promiseToResolve.push(
              LineService.GetAllAsPromise()
                .then(lines => {
                  lines = differenceBy(
                    values(lines),
                    values(this.alreadyShown),
                    '_id'
                  );
                  if (!this.includeSupervisor) {
                    lines = lines.filter(line => !line.supervisorLine);
                  }
                  lines = lines.filter(line => !line.isDeleted && line.open);
                  return lines.map(line => {
                    const item = {
                      placeId: line.placeId,
                      _id: line._id,
                      type: 'line',
                      realType: line.realType,
                      name: line.label,
                      waitingPeople: line.waitingPeople,
                      statistics: line.statistics,
                      supervisorLine: line.supervisorLine,
                      requestSource: line.requestSource,
                    };
                    return item;
                  });
                })
                .catch(console.log)
            );
            break;
          case 'devices':
            promiseToResolve.push(
              DeviceService.GetUnlinked()
                .then(devices =>
                  differenceBy(
                    values(devices),
                    values(this.alreadyShown),
                    '_id'
                  ).map(device =>
                    Object.assign({
                      _id: device._id,
                      type: 'device',
                      deviceId: device.deviceId,
                    })
                  )
                )
                .catch(console.log)
            );
            break;
          case 'linked-devices':
            promiseToResolve.push(
              DeviceService.GetLinkedAsPromise()
                .then(devices =>
                  differenceBy(
                    values(devices),
                    values(this.alreadyShown),
                    '_id'
                  ).map(device =>
                    Object.assign({
                      _id: device._id,
                      type: 'device',
                      deviceId: device.deviceId,
                      alias: device.alias,
                      placeId: device.placeId,
                    })
                  )
                )
                .catch(console.log)
            );
            break;
          case 'organization-lines':
            promiseToResolve.push(
              OrganizationLineService.GetAllAsPromise()
                .then(lines => {
                  lines = differenceBy(
                    values(lines),
                    values(this.alreadyShown),
                    '_id'
                  );

                  return lines.map(line => {
                    const item = {
                      _id: line._id,
                      type: 'organization-line',
                      name: line.label,
                    };
                    return item;
                  });
                })
                .catch(console.log)
            );
            break;
          case 'areas':
            promiseToResolve.push(
              AreaService.GetAllAsPromise()
                .then(areas => {
                  areas = values(areas).filter(area => !area.isDeleted && area.open);
                  return differenceBy(
                    values(areas),
                    values(this.alreadyShown),
                    '_id'
                  ).map(area =>
                    Object.assign({
                      placeId: area.placeId,
                      _id: area._id,
                      type: 'area',
                      realType: area.realType,
                      ordered: area.ordered,
                      name: area.label,
                      withoutAppointment: area.withoutAppointment,
                      isWithoutAppointment: area.isWithoutAppointment,
                    })
                  );
                })
                .catch(console.log)
            );
            break;
          case 'schemas':
            promiseToResolve.push(
              SchemaService.GetAllAsPromise()
                .then(schemas =>
                  differenceBy(
                    values(schemas),
                    values(this.alreadyShown),
                    '_id'
                  ).map(schema =>
                    Object.assign({
                      placeId: schema.placeId,
                      _id: schema._id,
                      type: 'sector',
                      realType: schema.realType,
                      name: schema.name,
                      root: schema.root,
                    })
                  )
                )
                .catch(console.log)
            );
            break;
          case 'users':
            promiseToResolve.push(
              UserService.GetAllAsPromise()
                .then(users =>
                  differenceBy(
                    values(users),
                    values(this.alreadyShown),
                    '_id'
                  ).map(user =>
                    Object.assign({
                      placeId: user.placeId,
                      _id: user._id,
                      type: 'user',
                      name: user.local.email,
                    })
                  )
                )
                .catch(console.log)
            );
            break;
          case 'resource-groups':
            promiseToResolve.push(
              ResourceGroupService.GetAllAsPromiseFromServer()
                .then(resources => {
                  resources = differenceBy(
                    values(resources),
                    values(this.alreadyShown),
                    '_id'
                  );
                  if (!this.includeSupervisor) {
                    resources = resources.filter(
                      resource => resource.realType !== 'supervisor'
                    );
                  }
                  resources = resources.filter(s => s.realType !== 'place');
                  return resources.map(resource => Object.assign({
                      _id: resource._id,
                      placeId: resource.placeId,
                      name: resource.name,
                      organizationId: resource.organizationId,
                      resources: resource.resources,
                      type: resource.type ? resource.type : 'resource-group',
                      realType: resource.realType,
                      parent: resource.parent,
                    }));
                })
                .catch(console.log)
            );
            break;
          case 'banners':
            promiseToResolve.push(
              BannerService.GetAllAsPromise()
                .then(banners => {
                  banners = differenceBy(
                    values(banners),
                    values(this.alreadyShown),
                    '_id'
                  );
                  banners = banners.filter(line => !line.isDeleted);
                  return banners.map(banner => {
                    const item = {
                      type: 'banner',
                      _id: banner._id,
                      name: banner.name,
                      description: banner.description,
                      placeId: banner.placeId,
                    };
                    return item;
                  });
                })
                .catch(console.log)
            );
            break;
        }
      });

      Promise.all(promiseToResolve)
        .then(allModels => {
          this.searchSource = compact(allModels).reduce(
            (prev, current) => prev.concat(current),
            []
          );
        })
        .catch(console.log);
    };

    this.search = searchTerm => {
      if (searchTerm.trim()) {
        this.results = this.searchSource.filter(obj => {
          const result = [];
          this.fields.forEach(field => {
            let regEx = new RegExp(searchTerm, 'gi');
            if (
              obj[field]
              && obj[field].match(regEx)
              && obj[field].match(regEx).length > 0
              && get(this.alreadyShown, `${obj._id}`, null) === null
            ) {
              return result.push(obj);
            }
          });
          return result.length > 0;
        });
      } else {
        this.results = [];
      }
    };
    this.select = selection => {
      this.searchStr = '';
      this.results = [];
      this.onSelect({ selection });
    };

    this.focusIndex = 0;
    this.key = function ($event) {
      let element = angular.element(
        document.querySelector('.results li.highlight')
      );
      let divElem = document.querySelector('.results');
      let chElem = document.querySelector('.results li.highlight');

      if ($event.keyCode == 38) {
        //console.log('up arrow');
        this.focusIndex--;
      } else if ($event.keyCode == 40) {
        //console.log('down arrow');
        this.focusIndex++;
      } else if ($event.keyCode == 13) {
        //console.log('enter');
        if (this.results[this.focusIndex]) {
          this.select(this.results[this.focusIndex]);
        }
      }
      if (this.focusIndex < 0) {
        this.focusIndex = this.focusIndex = 0;
      } else if (this.focusIndex >= this.results.length) {
        this.focusIndex = this.results.length - 1;
      }

      if (element) {
        //console.log('scroll to element');
        if (chElem) {
          let topPos = chElem.offsetTop;
          //console.log(topPos, chElem.offsetTop);
          let difference = chElem.offsetTop - divElem.scrollTop;
          if ($event.keyCode == 38) {
            divElem.scrollTop = divElem.scrollTop + difference - 47;
          } else if ($event.keyCode == 40) {
            divElem.scrollTop = divElem.scrollTop + difference;
          }
        }
      }
    };
  },
};

module.exports = module.component('osom', osomComponent);
