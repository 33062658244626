
import angular from 'angular';
// import { includes } from 'lodash';
import '../../services/resource-group';

const module = angular.module('whyline.modals.resource-groups', [
  'whyline.components.status',
  'whyline.services.resource-group',
]);

const ResourceGroupModalController = ($scope, $timeout, $uibModalInstance, $translate, NotificationService, ResourceGroupService, resourceGroupId) => {
  'ngInject';

  let validate = () => {
    const name = $scope.resourceGroup.name;

    if(!name) {
      NotificationService.Warning('El nombre del resourceGroup es requerido.');
      return false;
    }

    if(name.length < 2) {
      NotificationService.Warning('El nombre debe tener al menos 2 caracteres.');
      return false;
    }
    if(!$scope.resourceGroup.lines.length && !$scope.resourceGroup.areas.length && !$scope.resourceGroup.schemas.length) {
      NotificationService.Warning('Debes seleccionar al menos, una fila, un área o un esquema.');
      return false;
    }
    return true;
  };

  const dismissOnError = err => {
    if([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
  };

  $scope.resourceGroup = {
    _id: '',
    name: '',
    placeId: '',
    organizationId: '',
    resources: [{
      resourceId: '',
      resourceType: '',
    }],
  };

  if(resourceGroupId) {
   console.log('not-implemented-yet')
  }

  $scope.save = () => {
    if(validate()) {
      if(resourceGroupId) {
        return ResourceGroupService.Update($scope.resourceGroup)
        .then(() => {
          NotificationService.Success('El resourceGroup fue actualizado exitosamente.');
          $uibModalInstance.close('cancel');
        })
        .catch(err => dismissOnError(err));
      }

      return ResourceGroupService.Create($scope.resourceGroup)
      .then(() => {
        NotificationService.Success('El resourceGroup fue creado exitosamente.');
        $uibModalInstance.close('cancel');
      })
      .catch(err => dismissOnError(err));
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  // $scope.selectLine = selection => {
  //   // console.log(selection);
  //   const id = selection._id;

  //   switch (selection.type) {
  //     case 'line':
  //       if(!includes($scope.resourceGroup.lines, selection._id)) {
  //         $scope.resourceGroup.lines.push(id);
  //         $scope.resourceGroup.linesPopulated[id] = selection;
  //         populateAlreadyShown();
  //       }
  //       break;
  //   }
  // };

//  $scope.remove = id => {
//     const schemaIndex = $scope.resourceGroup.schemas.indexOf(id);
//     if(schemaIndex >= 0) {
//       Reflect.deleteProperty($scope.resourceGroup.schemasPopulated, id);
//       populateAlreadyShown();
//     }
//   };
};

module.exports = module.controller('ResourceGroupModalController', ResourceGroupModalController);
