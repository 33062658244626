import angular from 'angular';
import '../factories/socket.io';
import '../resources/resource-group';
import '../services/resource-group';
import '../services/errors';
import '../services/socket';

const module = angular.module('whyline.sockets.resource-group', [
  'whyline.factories.socket',
  'whyline.resources.resource-group',
  'whyline.services.resource-group',
  'whyline.services.errors',
  'whyline.services.socket',
]);

const ResourceGroupSocket = (Socket, ResourceGroup, ResourceGroupService, ErrorService, SocketService, $timeout, NotificationService, $rootScope, $translate) => {
  $rootScope.$on('socketReady', function() {
    SocketService.GetInstance().then(function(socket) {
      // CREATED
      socket.on('resourceGroup:created', resourceGroup => {
        ResourceGroup.UpdateCache(resourceGroup._id, resourceGroup);
      });

      // UPDATED
      socket.on('resourceGroup:updated', resourceGroup => {
        ResourceGroup.UpdateCache(resourceGroup._id, resourceGroup);
      });

      // DELETED
      socket.on('resourceGroup:deleted', resourceGroup => {
        ResourceGroup.RemoveFromCache(resourceGroup._id, 'resource-group');
      });
    });
  });
};

module.exports = module.factory('ResourceGroupSocket', (Socket, ResourceGroup, ResourceGroupService, ErrorService, SocketService, $timeout, NotificationService, $rootScope, $translate) => new ResourceGroupSocket(Socket, ResourceGroup, ResourceGroupService, ErrorService, SocketService, $timeout, NotificationService, $rootScope, $translate));
