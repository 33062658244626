import angular from 'angular';
import '../../services/box';
import '../../services/reception';
import '../../services/user';

const module = angular.module('whyline.controllers.reception-welcome', [
  'whyline.services.box',
  'whyline.services.reception',
  'whyline.services.user',
  'whyline.controllers.box-select',
]);

const ReceptionWelcomeController = (ReceptionService, $scope, currentPlace, $state, $uibModal, UserService, BoxService) => {
  /*@ngInject*/

  const currentUser = UserService.GetCurrent();
  $scope.currentPlaceId = $state.params.placeId;

  if ($state.params.selectBox === null) { // To know whether the page has been refreshed or not
    if (currentUser) {
      const adminGeneral = currentUser.permissions.filter((permission) => permission.role.name === 'Admin General' && permission.role.placeId === $scope.currentPlaceId);
      if (adminGeneral === 0) {
        const operatorOrReceptionist = currentUser.permissions.filter((permission) => {
          return (permission.role.name === 'Base Receptionist' || permission.role.name === 'operator') && (permission.role.placeId === $scope.currentPlaceId);
        });
        if(operatorOrReceptionist.length > 0) {
          BoxService.GetAllEnabledByPlaceId(currentUser._id, false)
          .then(res => {
            if (res.length > 0) {
              $uibModal.open({
                templateUrl: '/templates/components/modals/box-select-modal.html',
                size: 'md',
                controller: 'BoxSelectController',
              });
            }
          });
        }
      }
    }
  } else if ($state.params.selectBox) {
    BoxService.GetAllEnabledByPlaceId(currentUser._id, false)
    .then(res => {
      if (res.length > 0) {
        $uibModal.open({
          templateUrl: '/templates/components/modals/box-select-modal.html',
          size: 'md',
          controller: 'BoxSelectController',
        });
      }
    });
  }

  $scope.isWhylineEnterprise = currentPlace.enterpriseType === 'whyline';

  ReceptionService.SetButtonStatus('both', false);
  ReceptionService.SetButtonStatus('status-text', false);
};

module.exports = module.controller('ReceptionWelcomeController', ReceptionWelcomeController);
