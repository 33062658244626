import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.place-messages', [
  'whyline.resources.resource',
]);

const PlaceMessagesFactory = (Resource, $http, $state, API_URL) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'messages?placeId';

  const _schema = {
    _id: String,
    configuration: [
      {
        _id: String,
        enabled: Boolean,
        readOnly: Boolean,
        type: String,
      }
    ],
    language: String,
    message: String,
    organizationId: String,
    placeId: String,
    slug: String,
    title: String,
  };

  const PlaceMessages = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  PlaceMessages.prototype = new Resource(_type, _schema, PlaceMessages);

  Resource.Extend(_type, PlaceMessages);

  //  ad-infinitum
  const validityTime = 24 * 60 * 60 * 1000;
  PlaceMessages.InitCache(validityTime);

  PlaceMessages.GetAllMessages = placeId => {
    const url = `${API_URL}place/${placeId}/messages`;
    return $http.get(url).then(response => response.data);
  };

  PlaceMessages.UpdateAllMessages = (messages, placeId) => {
    const url = `${API_URL}place/${placeId}/messages`;
    return $http.post(url, {
      messages
    }).then(response => response.data);
  };

  return PlaceMessages;
};

module.exports = module.factory('PlaceMessages', PlaceMessagesFactory);
