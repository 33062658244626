import angular from 'angular';
import { filter, find } from 'lodash';

const module = angular.module('whyline.filters.getSubscriptions', []);

const subscriptionsFilter = () => (obj, props) => {
  if(props) {
    if(props.status === 'done') {
      return filter(obj, elem => elem.status !== 'waiting' && elem.status !== 'processing' && elem.reason !== 'queue-clear');
    } else if(props.status === 'waiting') {
      // Now that I already filtered, return what the user is expecting (waiting or processing)
      return filter(obj, props);
    } else if(props.status === 'processing') {
      return find(obj, props);
    }
    return {};
  }
};

module.exports = module.filter('getSubscriptions', subscriptionsFilter);
