import { get } from 'lodash';
import { localStorage } from '../utils';

/**
 * Sets http interceptors for request and response errors
 * @see https://docs.angularjs.org/api/ng/service/$http#interceptors
 */
const configInterceptors = ['$q', '$injector', 'GlobalsService', ($q, $injector, GlobalsService) => ({
  request: config => {
    const $state = $injector.get('$state');
    const urlPattern = /api/;
    const place = GlobalsService.CurrentPlace;
    const organization = GlobalsService.CurrentOrganization;
    const attempToGlobalGroup = /GLOBAL_GROUP/;

    if(attempToGlobalGroup.test(config.url)) {
      return $q.reject(config);
    }

    if(urlPattern.test(config.url)) {
      if(config.url.indexOf('?placeId') > -1) {
        config.url = config.url.replace('?placeId', '');
        if(place && place._id) {
          // Chequea si ya se agrego un query param para esta consulta
          // Ejemplo, caso de user.save({ query: `role='coordinator'` })
          let queryStringPrepend = '?';
          if(config.url.search('\\?') !== -1) {
            queryStringPrepend = '&';
          }
          config.url = `${config.url}${queryStringPrepend}placeId=${place._id}`;
        }
      }
    }
    if(urlPattern.test(config.url)) {
      //Se agrega en el type de resource para que se tenga en cuenta
      if(config.url.indexOf('?organizationId') > -1) {
        config.url = config.url.replace('?organizationId', '');
        if(organization && organization._id) {
          let queryStringPrepend = '?';
          if(config.url.search('\\?') !== -1) {
            queryStringPrepend = '&';
          }
          config.url = `${config.url}${queryStringPrepend}organizationId=${organization._id}`;
        }
      }
    }

    if(config.url.indexOf(':organizationId') > -1) {
      const organization = GlobalsService.CurrentOrganization;
      const organizationId = organization ? organization._id : $state.params.organizationId;
      config.url = config.url.replace(':organizationId', organizationId);
    }

    return config || $q.when(config);
  },
  responseError: response => {
    const errors = [401, 403, 404];
    const $state = $injector.get('$state');
    const $timeout = $injector.get('$timeout');
    const CacheService = $injector.get('CacheService');
    const previousError = errors.indexOf(GlobalsService.HttpError) >= 0;
    const currentError = errors.indexOf(response.status) >= 0;
    const url = get(response, 'config.url');
    const shouldProcess = !previousError && currentError && !$state.current.abstract;
    // if(shouldProcess && url && url.indexOf('api/v1') >= 0) {
    //   $timeout(() => {
    //     GlobalsService.SetHttpError(response.status);
    //     CacheService.Restart();
    //     $state.transitionTo($state.current, $state.params, {
    //       reload: true, inherit: false, notify: true
    //     });
    //   }, 2000);
    // }
    return $q.reject(response);
  }
})];

module.exports = ($httpProvider, jwtOptionsProvider, SAFE_STATES) => {
  jwtOptionsProvider.config({
    whiteListedDomains: ['google.com', /whyline.com/, 'localhost'],
    tokenGetter: () => localStorage.get('token'),
    unauthenticatedRedirectPath: '/sign-in',
    unauthenticatedRedirector: ['$state', '$injector', ($state, $injector) => {
      const AuthService = $injector.get('AuthService');
      AuthService.SignOut();
      $state.go(SAFE_STATES.signIn);
    }]
  });

  $httpProvider.interceptors.push(configInterceptors);
  $httpProvider.interceptors.push('jwtInterceptor');
};
