import angular from 'angular';
import moment from 'moment-timezone';
import { sortBy, toArray, filter, extend, cloneDeep, remove, get, map } from 'lodash';
import {
  schedule
} from '../../utils';

const module = angular.module('whyline.modals.holiday', [
  'whyline.services.date-handler',
  'whyline.services.area',
  'whyline.services.holiday'
]);

const HolidayController = ($scope, $translate, $uibModalInstance, holiday, PlaceService, HolidayService, DateHandlerService, AreaService, NotificationService, ErrorService) => {
  'ngInject';

  const holidayId = get(holiday, '_id', null);
  $scope.place = PlaceService.GetCurrent();
  $scope.placeUTCOffset = moment.tz($scope.place.timezone).format('Z').toString();
  $scope.format = $translate.use() === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
  $scope.selectedAreas = [];
  $scope.modalTitle = holidayId ? 'edit' : 'new';
  $scope.blockSaveButton = false;

  $scope.hours = schedule.getHours();
  $scope.time = {
    from: holidayId ? moment.tz(holiday.date.from, $scope.place.timezone).format('HH:mm') : $scope.hours[0].displayHour,
    to: holidayId ? moment.tz(holiday.date.to, $scope.place.timezone).format('HH:mm') : $scope.hours[$scope.hours.length - 1].displayHour,
  };

  $scope.areasSettings = {
    template: '<translate>{{option.label}}</translate>',
    externalIdProp: '',
    displayProp: 'label',
    idProp: '_id',
    searchField: 'label',
    enableSearch: true,
    keyboardControls: true,
    selectedToTop: true,
    scrollable: true,
    showCheckAll: false,
    showUncheckAll: false,
    groupByTextProvider: groupValue => {
      if (groupValue) {
        return '';
      } else {
        return $translate.instant('service');
      }
    },
    groupBy: 'applyToAll',
  };

  $scope.multiselectTranslations = {
    checkAll: $translate.instant('all'),
    uncheckAll: $translate.instant('multiselect_ninguno'),
    dynamicButtonTextSuffix: $translate.instant('multiselect_seleccionados'),
    buttonDefaultText: $translate.instant('select_one_or_more_services'),
    searchPlaceholder: $translate.instant('search_service'),
  };

  $scope.changeSelectedArea = {
    onItemSelect: item => {
      if (item.applyToAll) {
        $scope.selectedAreas = [defaultAllOption];
        $scope.holiday.areas = [];
        $scope.holiday.applyToAll = true;
      } else {
        $scope.selectedAreas = filter($scope.selectedAreas, area => !area.applyToAll);
        $scope.holiday.areas.push(item);
        $scope.holiday.applyToAll = false;
      }
    },
    onItemDeselect: item => {
      remove($scope.holiday.areas, area => get(area, '_id', area) === item._id);
      remove($scope.selectedAreas, area => get(area, '_id', area) === item._id);
      if (!$scope.selectedAreas.length && !$scope.holiday.areas.length) {
        $scope.selectedAreas = [defaultAllOption];
        $scope.holiday.areas = [];
        $scope.holiday.applyToAll = true;
      }
    }
  };

  const defaultAllOption = { applyToAll: true, label: $translate.instant('all_services') };

  const init = () => {
    const holidayPromise = holidayId ? getHoliday(holidayId) : setDefaultHoliday();
    holidayPromise.then(() => {
      setAreasOptions();
      setCalendarOptions();
    });
  };

  const getHoliday = id => {
    return HolidayService.GetOneAsPromise(id).then(holiday => {
      $scope.holiday = cloneDeep(holiday);
    });
  };

  const setAreasOptions = () => {
    AreaService.GetAllAsPromise().then(areas => {
      const areasSorted = sortBy(toArray(filter(areas, area => area.appointmentsConfiguration.enabled)), ['label']);
      $scope.areasList = areasSorted.map(area => extend({ applyToAll: false }, area));
      $scope.areasList.push(defaultAllOption);

      setSelectedAreas();
    });
  };

  const setCalendarOptions = () => {
    $scope.dateOptionsFrom = {
      formatYear: 'yy',
      minDate: setMinDate(),
    };
    $scope.dateOptionsTo = {
      formatYear: 'yy',
      minDate: setMinDate(),
    };
    $scope.calendarDates = {
      from: DateHandlerService.getNeutralDate($scope.holiday.date.from),
      to: DateHandlerService.getNeutralDate($scope.holiday.date.to ? $scope.holiday.date.to : moment().startOf('day').add(1, 'day').format()),
    };
  };

  const setMinDate = () => {
    const todayPlace = moment().tz($scope.place.timezone);
    return todayPlace.startOf('day').toDate();
  };

  const setSelectedAreas = () => {
    if ($scope.holiday.applyToAll) {
      $scope.selectedAreas = [defaultAllOption];
    } else if ($scope.holiday.areas.length) {
      $scope.holiday.areas.forEach(area => {
        const selectedArea = $scope.areasList.find(areaList => areaList._id === area._id);
        $scope.selectedAreas.push(selectedArea);
      });
    }
  };

  const setDefaultHoliday = () => {
    return new Promise((resolve, reject) => {
      $scope.holiday = {
        applyToAll: true,
        areas: [],
        date: {
          from: null,
        },
        description: '',
        isPeriod: false,
      };
      resolve();
    });
  };

  const validate = () => {
    if (!$scope.holiday.description) {
      NotificationService.Warning($translate.instant('mandatory_description'));
      return false;
    }

    if (!$scope.holiday.areas.length && !$scope.holiday.applyToAll) {
      NotificationService.Warning($translate.instant('areas_mandatory'));
      return false;
    }

    if (moment($scope.holiday.date.from).isBefore(moment().utc().toDate())) {
      ErrorService.handler({ data: 'holiday.invalid-from-date.can-not-be-in-the-past' });
      return false;
    }

    if (moment($scope.holiday.date.to).isBefore(moment($scope.holiday.date.from))) {
      const messageKey = moment($scope.holiday.date.to).day() === moment($scope.holiday.date.from).day() ?
        'holiday.invalid-period.to-hour-must-be-greater-or-equal-than-from-hour' : 'holiday.invalid-period.to-must-be-greater-or-equal-than-from';
      ErrorService.handler({ data: messageKey });
      return false;
    }
    return true;
  };

  // Close modal
  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.save = () => {
    const isEndOfDay = $scope.time.to === '23:59';

    $scope.holiday.date.from = moment.tz(`${moment($scope.calendarDates.from).format('YYYY-MM-DD')} ${$scope.time.from}`, $scope.place.timezone).toISOString();
    const dateTo = $scope.holiday.isPeriod ? moment($scope.calendarDates.to).format('YYYY-MM-DD') : moment($scope.calendarDates.from).format('YYYY-MM-DD');
    
    if (isEndOfDay) {
      $scope.holiday.date.to = moment.tz(dateTo, $scope.place.timezone).endOf('day').toISOString();
    } else {
      $scope.holiday.date.to = moment.tz(`${dateTo} ${$scope.time.to}`, $scope.place.timezone).toISOString();
    }


    if (validate()) {
      $scope.blockSaveButton = true;
      $scope.holiday.areas = map($scope.holiday.areas, area => get(area, '_id', area));
      if (holidayId) {
        return HolidayService.Update($scope.holiday)
          .then(holidayUpdated => {
            holiday = holidayUpdated;
            NotificationService.Success($translate.instant('holiday_update_succ'));
            $uibModalInstance.close('cancel');
          })
          .catch(error => {
            ErrorService.handler(error);
            $scope.blockSaveButton = false;
          });
      } else {
        return HolidayService.Create($scope.holiday)
          .then(() => {
            NotificationService.Success($translate.instant('holiday_create_succ'));
            $uibModalInstance.close('cancel');
          })
          .catch(error => {
            ErrorService.handler(error);
            $scope.blockSaveButton = false;
          });
      }
    }
  };

  $scope.dateChange = () => {
    if ($scope.holiday.isPeriod && (!$scope.calendarDates.to || $scope.calendarDates.to < $scope.calendarDates.from)) {
      $scope.calendarDates.to = DateHandlerService.getNeutralDate(moment($scope.calendarDates.from).add(1, 'day').format());
    }
    $scope.dateOptionsTo.minDate = DateHandlerService.getNeutralDate(moment($scope.calendarDates.from).add(1, 'day').format());
  };

  init();
};

module.exports = module.controller('HolidayController', HolidayController);
