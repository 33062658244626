import angular from 'angular';
import '../services/displaytv';
import '../services/errors';
import '../services/display';
import '../services/globals';
import '../services/line';
import '../services/area';
import '../services/redirect-to-new-version';
import '../components/operator';
import '../components/status';
import { differenceBy, get, cloneDeep, map } from 'lodash';
import { localStorage } from '../utils';
import '../services/language';

const module = angular.module('whyline.controllers.display-tv', [
  'whyline.services.displaytv',
  'whyline.services.errors',
  'whyline.services.display',
  'whyline.services.globals',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.redirect-to-new-version',
  'whyline.components.operator',
  'whyline.components.status',
  'whyline.services.language',
  'whyline.services.organization',
]);

const DisplayTvController = (
  $scope,
  $rootScope,
  ErrorService,
  DisplayTvService,
  DisplayService,
  $interval,
  PlaceService,
  $state,
  $timeout,
  $log,
  Fullscreen,
  $window,
  RedirectToNewVersionService,
  LanguageService,
  OrganizationService,
) => {
  /*@ngInject*/

  let wsDisplay;

  $scope.place = {};
  $scope.currentVideo = null;
  PlaceService.GetOneAsPromiseFromServer($state.params.placeId).then((place) => {
    $scope.place = place;
    $scope.backgroundColor = get($scope, 'place.configuration.display.background', '');
    $scope.fontColor = get($scope, 'place.configuration.display.color', '');
    if ($scope.place.revertMigration) {
      let user;
      localStorage.get('user').then(user => {
        RedirectToNewVersionService.Redirect(
          'display',
          place._id,
          user._id,
          $state.params.displayId,
        );
      });

    }
    $scope.ttsEnabledConfiguration = get($scope, 'place.configuration.display.ttsEnabled', {});
    if ($scope.ttsEnabledConfiguration.enabled) {
      speechSynthesis.addEventListener('voiceschanged', () => {
        $scope.voices = speechSynthesis.getVoices();
      });
    }
  });
  OrganizationService.GetOneAsPromiseFromServer($state.params.organizationId).then((org) => {
    $scope.useScenarios = get(org, 'configuration.queuer.useScenarios', false);
  });
  $scope.fontSize = $state.params.fontSize;
  $scope.data = [];
  $scope.toCall = [];
  $scope.listToShow = [];
  $scope.nextCalls = [];
  $scope.identify = false;
  $scope.socketIsDown = true;
  $scope.lastCalled = null;
  $scope.qtyToShow = 7;
  $scope.bannerData = '';
  $scope.marqueeDuration = 30;
  $scope.hasBanner = false;
  let counter = 0;
  const sound = new Audio('../../img/dingdong.mp3');
  sound.volume = 0.5;

  const setRowsQty = () => {
    if (window.screen.height <= 720) {
      if ($scope.fontSize === 'normal') {
        $scope.qtyToShow = 5;
      } else if ($scope.fontSize === 'medium') {
        $scope.qtyToShow = 4;
      } else if ($scope.fontSize === 'big') {
        $scope.qtyToShow = 4;
      }
    } else if (window.screen.height > 720 && window.screen.height <= 1080) {
      if ($scope.fontSize === 'normal') {
        $scope.qtyToShow = 8;
      } else if ($scope.fontSize === 'medium') {
        $scope.qtyToShow = 7;
      } else if ($scope.fontSize === 'big') {
        $scope.qtyToShow = 6;
      }
    }
  };

  setRowsQty();

  $rootScope.$on('socket:display-connected', (event, socket) => {
    wsDisplay = socket;

    $scope.socketIsDown = false;

    socket.emit('display:opened', $state.params.displayId);
  });

  const removeIfExists = (subscription, subscriptions) => {
    for (let index = subscriptions.length - 1; index >= 0; index--) {
      const item = subscriptions[index];
      const itemId = item._id || item.subscriptionId;
      if (itemId && (itemId === subscription._id || itemId === subscription.subscriptionId)) {
        subscriptions.splice(index, 1);
      }
    }
  };

  const updateNextCalls = () => {
    DisplayTvService.GetDisplayTvData(
      $state.params.displayId,
      $scope.currentDisplay.isCallNext,
    ).then((nextCalls) => {
      $timeout(() => {
        const oldHiddenNextCalls = cloneDeep($scope.nextCalls);
        const oldShownNextCalls = oldHiddenNextCalls.splice(0, 10);
        const nextCallsFiltered = differenceBy(nextCalls, oldShownNextCalls, '_id');
        $scope.nextCalls = oldShownNextCalls.concat(nextCallsFiltered);
      });
    });
  };

  $rootScope.$on('display:recall-subscription', (event, data) => {
    removeIfExists(data, $scope.toCall);
    $scope.toCall.unshift(data);
  });

  $rootScope.$on('display:call-subscription', (event, data) => {
    removeIfExists(data, $scope.toCall);
    if ($scope.currentDisplay.isCallNext) {
      removeIfExists(data, $scope.nextCalls);
    }

    $scope.toCall.push(data);
  });

  /*$rootScope.$on('display:update-next-calls', (event, data) => {
    if (!data) {
      updateNextCalls();
    } else {
      removeIfExists(data, $scope.nextCalls);
    }
  }); */

  $rootScope.$on('display:ping', () => {
    try {
      if (wsDisplay) {
        wsDisplay.emit('display:pong', $state.params.displayId);
      }
    } catch (e) {
      console.error('display:ping', e);
    }
  });

  $rootScope.$on('display:identify', () => {
    $scope.identify = true;
    $interval(() => ($scope.identify = false), 5000);
  });

  $rootScope.$on('display:refresh', () => {
    $window.location.reload();
  });

  $rootScope.$on('display:minmax', () => {});

  $rootScope.$on('display:reconnected', () => {
    $scope.socketIsDown = false;
  });

  $rootScope.$on('display:disconnected', () => {
    $scope.socketIsDown = true;
  });

  $rootScope.$emit('connect-display-socket');

  // Do this when display load first time
  DisplayService.GetOneAsPromise($state.params.displayId)
    .then((currentDisplay) => {
      $timeout(() => {
        // Putting the current display available on scope
        $scope.currentDisplay = currentDisplay;

        // Force isCallNext to false. This option is disabled for improve the performance.
        $scope.currentDisplay.isCallNext = false;
        applyMarquee();

        // Get subscriptions called
        DisplayTvService.GetDisplayTvData(
          $state.params.displayId,
          $scope.currentDisplay.isCallNext,
        ).then((displayTvData) => {
          $timeout(() => {
            $scope.data = displayTvData;
            if ($scope.currentDisplay.isCallNext) {
              $scope.nextCalls = cloneDeep($scope.data);
            } else {
              $scope.lastCalled = $scope.data.shift();
              $scope.listToShow = $scope.data.slice(0, 10);
            }
          });
        });
      });
    })
    .catch(ErrorService.handler);

  const getNewData = () => {
    try {
      // Get subscriptions called
      DisplayTvService.GetDisplayTvData(
        $state.params.displayId,
        $scope.currentDisplay.isCallNext,
      ).then((displayTvData) => {
        $timeout(() => {
          $scope.toCall = differenceBy(displayTvData, $scope.data, 'subscriptionId');

          try {
            if ($scope.currentDisplay.isCallNext && $scope.toCall.length > 0) {
              this.updateNextCalls(displayTvData);
            }
            if ($scope.lastCalled) {
              removeIfExists($scope.lastCalled, $scope.toCall);
            }
          } catch (e) {
            console.error('getNewData - removeIfExists', e);
          }
        });
      });
    } catch (e) {
      console.error('getNewData', e);
    }
  };

  $scope.capitalizeFirstLetter = (str) => {
    if (str && str.length) {
      str = str.toLowerCase();
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return '';
  };

  $scope.capitalizeWordWithPoint = (str) => {
    if (str && str.length) {
      if (str.includes('.')) {
        let strSplits = str.toLowerCase().trim().split('.');
        let strCapitalize = strSplits.map((word) => $scope.capitalizeFirstLetter(word.trim()));
        return strCapitalize.join('. ');
      } else {
        return $scope.capitalizeFirstLetter(str.toLowerCase());
      }
    }
    return '';
  };

  $scope.doAnimation = undefined;

  const callNext = () => {
    if ($scope.toCall.length) {
      const nextCall = $scope.toCall[0];
      if ($scope.lastCalled && nextCall.subscriptionId !== $scope.lastCalled.subscriptionId) {
        removeIfExists($scope.lastCalled, $scope.data);
        removeIfExists(nextCall, $scope.data);
        $scope.data.unshift($scope.lastCalled);
        $scope.listToShow = $scope.data.slice(0, 10);
      }

      $scope.doAnimation = new Date().getTime();
      $scope.lastCalled = $scope.toCall.shift();

      const displayTts = get($scope.currentDisplay, 'textToSpeech', true);
      if (
        $scope.ttsEnabledConfiguration.enabled &&
        displayTts &&
        ($scope.ttsEnabledConfiguration.enabledFor === 'all' ||
          ($scope.ttsEnabledConfiguration.enabledFor === 'priority' &&
            $scope.lastCalled.person.priority &&
            $scope.lastCalled.person.priority.category === 'disabled'))
      ) {
        const message = new SpeechSynthesisUtterance();
        const lang = LanguageService.GetBrowserLanguage();
        message.voice = $scope.voices.find((v) =>
          lang === 'es' ? v.lang === 'es-US' : v.lang.includes(lang),
        );
        message.volume = 1;
        message.rate = 1;
        const alias = $scope.lastCalled.alias[1]
          ? `${$scope.lastCalled.alias[0]} ${$scope.lastCalled.alias[1]}`
          : $scope.lastCalled.alias[0];

        const text = `${alias.toLowerCase()} ${$scope.lastCalled.line.label}`;
        message.text =
          $scope.lastCalled.called.box && $scope.lastCalled.called.box !== ''
            ? `${text} ${$scope.lastCalled.called.box}`
            : text;
        message.pitch = 1;
        speechSynthesis.speak(message);
      } else {
        sound.play().then().catch();
      }
    }
  };

  $scope.pressForFullScreen = () => {
    if (counter < 3) {
      counter += 1;
    }
    if (counter === 3) {
      counter = 0;
      if (Fullscreen.isEnabled()) {
        Fullscreen.cancel();
      } else {
        Fullscreen.all();
      }
    }
  };

  // Get data if socket is down
  const stopGettingNewData = $interval(() => {
    if ($scope.socketIsDown) {
      getNewData();
    }
  }, 5000); // 5 secs

  // Refresh subscriptions every [given time]
  const checkIfHaveToCall = $interval(() => {
    callNext();
  }, 4000); // 4 secs

  $scope.$on('$destroy', () => {
    if (angular.isDefined(stopGettingNewData)) {
      $interval.cancel(stopGettingNewData);
    }
    if (angular.isDefined(checkIfHaveToCall)) {
      $interval.cancel(checkIfHaveToCall);
    }
  });

  const applyMarquee = () => {
    const marqueeElement = document.querySelector(
      '#display-container .display-tv .banner-marquee-container',
    );
    if ($scope.currentDisplay.banners.length) {
      $scope.hasBanner = true;
      const bannerText = map($scope.currentDisplay.bannersData, (banner) =>
        $scope.capitalizeWordWithPoint(banner.description),
      );
      $scope.bannerData = bannerText.join(' | ');
      const marqueeText = marqueeElement.querySelector('.banner-marquee-content');
      if (marqueeText) {
        let timeMiliseconds;
        let transformFrom;
        let transformTo;
        let widthElement = 'fit-content';

        if ($scope.bannerData.length <= 100) {
          transformFrom = 'translateX(100%)';
          transformTo = 'translateX(-100%)';
          timeMiliseconds = 25000;
          widthElement = '100%';
        } else if ($scope.bannerData.length > 100 && $scope.bannerData.length <= 200) {
          transformFrom = 'translateX(70%)';
          transformTo = 'translateX(-100%)';
          timeMiliseconds = 30000;
        } else if ($scope.bannerData.length > 200 && $scope.bannerData.length <= 300) {
          transformFrom = 'translateX(50%)';
          transformTo = 'translateX(-100%)';
          timeMiliseconds = 40000;
        } else {
          transformFrom = 'translateX(30%)';
          transformTo = 'translateX(-100%)';
          timeMiliseconds = 50000;
        }

        marqueeText.animate(
          [
            // keyframes
            { transform: transformFrom },
            { transform: transformTo },
          ],
          {
            // timing options
            duration: timeMiliseconds,
            iterations: Infinity,
          },
        );

        marqueeText.style.width = widthElement;
      }
    } else {
      marqueeElement.remove();
      return;
    }
  };

  $scope.getFillItems = () => {
    let itemsToAdd = 7;
    const itemsToFill = [];
    const nextCallsLength = $scope.nextCalls.length;
    const listToShowLength = $scope.listToShow.length;

    if (nextCallsLength > 0) {
      itemsToAdd = $scope.qtyToShow - nextCallsLength;
    }

    if (listToShowLength > 0) {
      itemsToAdd = $scope.qtyToShow - listToShowLength;
    }

    if (itemsToAdd > 0) {
      for (let i = 1; i <= itemsToAdd; i++) {
        itemsToFill.push(i);
      }
    }

    return itemsToFill;
  };

  $scope.getFillItemsClass = (index) => {
    let itemsQty = 0;

    if ($scope.nextCalls.length > 0) {
      itemsQty = $scope.nextCalls.length;
    }

    if ($scope.listToShow.length > 0) {
      itemsQty = $scope.listToShow.length;
    }

    const cssClass = (itemsQty % 2 === 0) ?
    { 'bluish-gray-background': index % 2 === 0, 'gray-background': index % 2 !== 0, 'seven-rows': $scope.hasBanner, 'seven-rows-no-banner': !$scope.hasBanner } :
    { 'bluish-gray-background': index % 2 !== 0, 'gray-background': index % 2 === 0, 'seven-rows': $scope.hasBanner, 'seven-rows-no-banner': !$scope.hasBanner };

    return cssClass;
  };

  $scope.getFirstName = (name) => {
    const firstName = name ? name.split(' ')[0] : '';
    return firstName;
  }

};

module.exports = module.controller('DisplayTvController', DisplayTvController);
