import angular from 'angular';
import { schedule } from '../../utils';
import { forEach, get, includes, isNil } from 'lodash';
import moment from 'moment';
import 'angular-ui-tree';
import '../../services/area';
import '../../services/organization-area';
import '../../services/place';
import '../../services/errors';
import '../../components/osom';
import { AreaCalendarType } from '../../utils/areaCalendarType';

const module = angular.module('whyline.modals.area', [
  'whyline.services.area',
  'whyline.services.organization-area',
  'whyline.services.place',
  'whyline.services.errors',
  'whyline.components.osom',
  'whyline.services.user',
]);

const AreaModalController = (
  $scope,
  $timeout,
  $state,
  $uibModalInstance,
  $translate,
  GlobalsService,
  NotificationService,
  AreaService,
  modalType,
  areaId,
  ErrorService,
  PlaceService,
  dialog,
  UserService,
) => {
  'ngInject';

  //#region Initialize Variables
  $scope.items = [];
  $scope.supervisors = [];
  $scope.hours = schedule.getHours();
  $scope.enterpriseType = GlobalsService.EnterpriseType;
  let currentUser;
  UserService.GetCurrentAsPromise().then(user => {
    currentUser = user;
    $scope.superAdmin = currentUser && currentUser.sudo;
    if (currentUser) {
      const organizationId = $state.params.organizationId;
      $scope.isOrganizationAdmin = currentUser.permissions.find(perm => perm.role.name === 'Organization Admin' && perm.role.organizationId === organizationId);
    }
  });
  $scope.showQuotas = false;
  $scope.quotaButtonTitle = 'quotas_show';

  $scope.area = {
    sla: 0,
    schedule: schedule.format(schedule.reOrder(schedule.create()), true),
    maxNumbers: 0,
    isWithAppointment: false,
    withoutAppointment: {
      withPriority: true,
      withoutPriority: true,
      requestSource: {
        mobileApp: true,
        reception: true,
        totem: true,
      },
    },
    appointmentsConfiguration: {
      enabled: false,
      enabledForAgendaUsers: true,
      slots: 1,
      duration: 30,
      timeSlotsQuantity: 1,
      areaCalendarType: AreaCalendarType.ForADynamicPeriod,
      dynamicPeriod: {
        since: false,
        sinceNextDays: 0,
        until: true,
        untilNextDays: 90,
      },
      fixedPeriod: {
        from: null,
        to: null,
      },
      personalData: {
        email: false,
        phone: false,
      },
      notes: {
        required: false,
        label: '',
        placeholder: '',
      },
      penalty: {
        canceledAppointments: 0,
        enabled: false,
        since: 0,
        time: 0,
      },
      alertMessage: {
        text: '',
        show: false,
      },
      validateAppointmentQuantityByPerson: {
        quantity: 0,
        required: false,
      },
    },
    configuration: {
      showSubscriptionsBy: {
        dailySubscriptions: true,
        lastHoursSubscriptions: false,
      },
    },
  };

  $scope.showSubscriptionsConfig = {
    showSubscriptionsBy: ['dailySubscriptions', 'lastHoursSubscriptions'],
    selectedShowSubscriptionsBy: 'dailySubscriptions',
  };

  function initSubscriptionsByChange() {
    const configs = Object.keys($scope.area.configuration.showSubscriptionsBy);
    if (configs.length) {
      configs.forEach((key) => {
        if ($scope.area.configuration.showSubscriptionsBy[key] == true) {
          $scope.showSubscriptionsConfig.selectedShowSubscriptionsBy = key;
        }
      });
    }
  }

  $scope.fromToDates = {
    from: moment().startOf('day').toDate(),
    to: moment().startOf('day').toDate(),
  };

  // Date picker
  $scope.dateOptionsFrom = {
    minDate: moment().startOf('day').toDate(),
    startingDay: 1,
  };
  $scope.dateOptionsTo = {
    minDate: moment().startOf('day').toDate(),
    startingDay: 1,
  };
  $scope.datesIsOpen = {
    from: false,
    to: false,
  };
  // End of Date picker

  if (modalType === 'group') {
    $scope.title = 'groups';
    $scope.isDraggable = false;
  } else {
    $scope.title = 'processes';
    $scope.isDraggable = true;
  }

  //#endregion

  //#region Initialize Functions
  $scope.dateChange = () => {
    $scope.dateOptionsTo.minDate = $scope.fromToDates.from;
    if ($scope.fromToDates.to < $scope.fromToDates.from) {
      $scope.fromToDates.to = $scope.fromToDates.from;
    }
  };

  $scope.onShowSubscriptionsByChange = () => {
    const configs = Object.keys($scope.area.configuration.showSubscriptionsBy);

    if (configs.length) {
      configs.forEach((key) => {
        if (key == $scope.showSubscriptionsConfig.selectedShowSubscriptionsBy) {
          $scope.area.configuration.showSubscriptionsBy[key] = true;
        } else {
          $scope.area.configuration.showSubscriptionsBy[key] = false;
        }
      });
    }
  };

  $scope.requestReasonAndMotiveChange = (value) => {
    if (!value) {
      $scope.area.configuration.reasonRequired = false;
    }
  };

  // Dynamic Period
  $scope.sinceCheck = () => {
    if ($scope.area.appointmentsConfiguration.dynamicPeriod.since) {
      $scope.area.appointmentsConfiguration.dynamicPeriod.sinceNextDays = 1;
    } else {
      $scope.area.appointmentsConfiguration.dynamicPeriod.sinceNextDays = 0;
    }
  };

  $scope.untilCheck = () => {
    if ($scope.area.appointmentsConfiguration.dynamicPeriod.until) {
      $scope.area.appointmentsConfiguration.dynamicPeriod.untilNextDays = 1;
    } else {
      $scope.area.appointmentsConfiguration.dynamicPeriod.untilNextDays = 0;
    }
  };
  // End of Dynamic Period

  $scope.quantityCheck = () => {
    if ($scope.area.appointmentsConfiguration.validateAppointmentQuantityByPerson.required) {
      $scope.area.appointmentsConfiguration.validateAppointmentQuantityByPerson.quantity = 1;
    } else {
      $scope.area.appointmentsConfiguration.validateAppointmentQuantityByPerson.quantity = 0;
    }
  };

  $scope.penaltyCheck = () => {
    if ($scope.area.appointmentsConfiguration.penalty.enabled) {
      $scope.area.appointmentsConfiguration.penalty.canceledAppointments = 1;
      $scope.area.appointmentsConfiguration.penalty.since = 30;
      $scope.area.appointmentsConfiguration.penalty.time = 7;
    } else {
      $scope.area.appointmentsConfiguration.penalty.canceledAppointments = 0;
      $scope.area.appointmentsConfiguration.penalty.since = 0;
      $scope.area.appointmentsConfiguration.penalty.time = 0;
    }
  };

  // OSOM line selector
  $scope.selectLine = (selection) => {
    const itemSelected = {
      label: selection.name,
      _id: selection._id,
      type: selection.type,
      realType: selection.realType,
      statistics: selection.statistics,
      waitingPeople: selection.waitingPeople,
    };
    if (
      !includes(
        $scope.items.map((item) => item._id),
        selection._id,
      )
    ) {
      $scope.items.push(itemSelected);
    }
    populateAlreadyShown();
  };

  $scope.removeLine = (lineId) => {
    $scope.items = $scope.items.filter((item) => item._id !== lineId);
    populateAlreadyShown();
  };

  // OSOM supervisor selector
  $scope.selectSupervisor = (selection) => {
    const userSelected = { label: selection.name, _id: selection._id };
    if (
      !includes(
        $scope.supervisors.map((supervisor) => supervisor._id),
        selection._id,
      )
    ) {
      $scope.supervisors.push(userSelected);
    }
    populateAlreadyShown();
  };

  $scope.removeSupervisor = (userId) => {
    $scope.supervisors = $scope.supervisors.filter((supervisor) => supervisor._id !== userId);
    populateAlreadyShown();
  };

  $scope.setHasSecondPeriod = (day, hasSecondPeriod) => {
    day.hasSecondPeriod = hasSecondPeriod;
  };

  $scope.applyToAll = (day) => {
    schedule.applyToAll(day, $scope.area.schedule);
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.validateNumber = () => {
    let number = parseInt($scope.area.maxNumbers);
    number = isNaN(number) ? 0 : number;
    $scope.area.maxNumbers = number;
  };

  $scope.maxNumberKeyPress = (e) => {
    if (e.keyCode === 101 || e.keyCode === 69) {
      e.preventDefault();
    }
  };

  $scope.save = () => {
    if (validate()) {
      $scope.area.lines = $scope.items.filter((item) => item.enabled).map((item) => item._id);
      $scope.area.supervisors = $scope.supervisors.map((supervisor) => supervisor._id);

      if (!$scope.area.appointmentsConfiguration.duration) {
        Reflect.deleteProperty($scope.area.appointmentsConfiguration, 'duration');
      }

      if (!$scope.area.appointmentsConfiguration.slots) {
        Reflect.deleteProperty($scope.area.appointmentsConfiguration, 'slots');
      }

      if (!$scope.place.branchId) {
        Reflect.deleteProperty($scope.area, 'serviceId');
      }

      Reflect.deleteProperty($scope.area, 'linesDisabled');

      $scope.area.appointmentsConfiguration.fixedPeriod.from = moment(
        new Date($scope.fromToDates.from),
      ).format('YYYY-MM-DD');
      $scope.area.appointmentsConfiguration.fixedPeriod.to = moment(
        new Date($scope.fromToDates.to),
      ).format('YYYY-MM-DD');
      $scope.area.appointmentsConfiguration.dynamicPeriod.sinceNextDays = get(
        $scope.area.appointmentsConfiguration.dynamicPeriod,
        'sinceNextDays',
        0,
      );
      $scope.area.appointmentsConfiguration.dynamicPeriod.untilNextDays = get(
        $scope.area.appointmentsConfiguration.dynamicPeriod,
        'untilNextDays',
        0,
      );

      $scope.area.schedule = schedule.format($scope.area.schedule, false);

      if (areaId) {
        if (
          $scope.area.appointmentsConfiguration.penalty.time !== $scope.oldPenaltyTime &&
          $scope.oldPenaltyEnabled
        ) {
          return updateAreaWithDialog($scope.area.appointmentsConfiguration.penalty.time);
        } else {
          return updateArea();
        }
      }

      if (modalType === 'group') {
        $scope.area.ordered = false;
        $scope.area.realType = 'group';
      } else {
        $scope.area.ordered = true;
        $scope.area.realType = 'process';
      }

      return AreaService.Create($scope.area)
        .then(() => {
          NotificationService.Success($translate.instant('area_create_succ'));
          $uibModalInstance.close('cancel');
        })
        .catch(ErrorService.handler);
    }
  };

  const updateAreaWithDialog = (penalty) => {
    const message = penalty ? 'area.penalty_time_update_sure' : 'area.penalty_time_delete_sure';
    dialog
      .confirm($translate.instant(message))
      .then(() => updateArea())
      .catch(() => {
        $scope.area.appointmentsConfiguration.penalty.enabled = $scope.oldPenaltyEnabled;
        $scope.area.appointmentsConfiguration.penalty.canceledAppointments =
          $scope.oldPenaltyCanceledAppointments;
        $scope.area.appointmentsConfiguration.penalty.since = $scope.oldPenaltySince;
        $scope.area.appointmentsConfiguration.penalty.time = $scope.oldPenaltyTime;
      });
  };

  const updateArea = () =>
    
    AreaService.Update($scope.area)
      .then(() => {
        NotificationService.Success($translate.instant('area_update_succ'));
        $uibModalInstance.close('cancel');
      })
      .catch((err) => {
        err.context = get(err, 'data.context', {});
        ErrorService.handler(err);
      });

  $scope.showHideQuotas = () => {
    $scope.showQuotas = !$scope.showQuotas;
    $scope.quotaButtonTitle = $scope.showQuotas ? 'quotas_hide': 'quotas_show';
  }

  const validateQuotas = () => {
    let valid = true;
    for (let i = 0; i < $scope.area.schedule.length; i++) {
      const day = $scope.area.schedule[i];
      let quota = 0;

      const quotaNumber = parseInt(day.firstPeriod.quota);
      $scope.area.schedule[i].firstPeriod.quota = isNaN(quotaNumber) ? 0 : quotaNumber;

      if (day.firstPeriod.quota) {
        quota += day.firstPeriod.quota;
      }

      if (day.hasSecondPeriod) {
        const quotaNumber = parseInt(day.secondPeriod.quota);
        $scope.area.schedule[i].secondPeriod.quota = isNaN(quotaNumber) ? 0 : quotaNumber;

        if (day.secondPeriod.quota) {
          quota += day.secondPeriod.quota;
        }
      }

      if (quota > 0 && $scope.area.maxNumbers > 0) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const validate = () => {
    const name = $scope.area.label;
    const postMessage = get($scope, 'area.configuration.postMessage', '');
    const sla = get($scope, 'area.sla');

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_area_name'));
      return false;
    }
    if (name.length > 50) {
      NotificationService.Warning($translate.instant('max_length_name'));
      return false;
    }
    if (postMessage.length > 59) {
      NotificationService.Warning($translate.instant('max_length_post_check_in_message'));
      return false;
    }
    if (
      !/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(
        name,
      )
    ) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }

    if ($scope.items.length <= 0) {
      NotificationService.Warning($translate.instant('min_fila_assign'));
      return false;
    }

    if (isNil(sla) || sla < 0 || sla > 1000) {
      NotificationService.Warning($translate.instant('sla_process_invalid'));
      return false;
    }

    if (!validateRanges($scope.area.schedule)) {
      return false;
    }

    const requestTypes = {
      isWithAppointment: $scope.area.isWithAppointment,
      isWithoutAppointment: $scope.area.isWithoutAppointment,
    };
    if (!validateRequestType(requestTypes)) {
      return false;
    }

    if (
      $scope.area.isWithoutAppointment &&
      !validateWithoutAppointment($scope.area.withoutAppointment)
    ) {
      return false;
    }

    if (!validateAtLeastOneLineIsEnabled($scope.items)) {
      NotificationService.Warning($translate.instant('at_least_one_line_enabled'));
      return false;
    }

    if ($scope.area.appointmentsConfiguration.enabled) {
      if (!validateAppointmentsConfiguration()) {
        return false;
      }
    }

    if (!validateQuotas()) {
      NotificationService.Warning($translate.instant('quotas_error'));
      return false;
    };

    return true;
  };

  //#region Validate Ranges
  const validateRanges = (schedule) => {
    var valid = true;
    const opened = schedule.filter((s) => s.open);
    for (let i = 0; i < opened.length; i++) {
      const day = opened[i];
      if (day.firstPeriod.opening >= day.firstPeriod.closing) {
        NotificationService.Warning(
          $translate
            .instant('opening_hours_first_period_msg_validator')
            .replace('{dayname}', $translate.instant(day.name)),
        );
        valid = false;
        break;
      }
      if (day.hasSecondPeriod) {
        if (day.secondPeriod.opening <= day.firstPeriod.closing) {
          NotificationService.Warning(
            $translate
              .instant('opening_hours_second_must_be_later_first_msg_validator')
              .replace('{dayname}', $translate.instant(day.name)),
          );
          valid = false;
          break;
        }

        if (day.secondPeriod.opening >= day.secondPeriod.closing) {
          NotificationService.Warning(
            $translate
              .instant('opening_hours_second_period_msg_validator')
              .replace('{dayname}', $translate.instant(day.name)),
          );
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  const updateTotalDuration = () => {
    $scope.totalDuration =
      $scope.area.appointmentsConfiguration.duration *
      $scope.area.appointmentsConfiguration.timeSlotsQuantity;
  };
  //#endregion

  //#region Validate AppointmentsConfiguration
  const validateAppointmentsConfiguration = () => {
    const {
      alertMessage,
      areaCalendarType,
      duration,
      notes,
      penalty,
      slots,
      timeSlotsQuantity,
      validateAppointmentQuantityByPerson,
    } = $scope.area.appointmentsConfiguration;

    if (!timeSlotsQuantity || timeSlotsQuantity < 1) {
      NotificationService.Warning($translate.instant('appointments_min_time_slots_quantity'));
      return false;
    }

    if (!slots || !duration) {
      NotificationService.Warning($translate.instant('mandatory_appointments_duration_slots'));
      return false;
    }

    if (slots > 100) {
      NotificationService.Warning($translate.instant('appointments_max_slots'));
      return false;
    }

    if (!validatePeriodConfiguration(areaCalendarType)) {
      return false;
    }

    if (notes.required && (!notes.hasOwnProperty('label') || !notes.label.length)) {
      NotificationService.Warning($translate.instant('appointments_label_required'));
      return false;
    }

    if (alertMessage.show) {
      if (!alertMessage.text) {
        NotificationService.Warning($translate.instant('appointments_alert_message_required'));
        return false;
      }
      if (alertMessage.text.length > 300) {
        NotificationService.Warning($translate.instant('appointments_alert_message_too_long'));
        return false;
      }
    }

    if (validateAppointmentQuantityByPerson && validateAppointmentQuantityByPerson.required) {
      if (!validateAppointmentQuantityByPerson.quantity) {
        NotificationService.Warning(
          $translate.instant('appointment_limit_by_person_message_required'),
        );
        return false;
      } else if (
        validateAppointmentQuantityByPerson.quantity &&
        (validateAppointmentQuantityByPerson.quantity < 1 ||
          validateAppointmentQuantityByPerson.quantity > 1000)
      ) {
        NotificationService.Warning($translate.instant('appointments_limit_by_person_value'));
        return false;
      }
    }

    if (penalty && penalty.enabled) {
      if (!penalty.canceledAppointments) {
        NotificationService.Warning(
          $translate.instant('area.canceled_appointments_admitted_message_required'),
        );
        return false;
      } else if (
        penalty.canceledAppointments &&
        (penalty.canceledAppointments < 1 || penalty.canceledAppointments > 10)
      ) {
        NotificationService.Warning(
          $translate.instant('area.canceled_appointments_admitted_value'),
        );
        return false;
      }

      if (!penalty.since) {
        NotificationService.Warning($translate.instant('area.penalty_since_message_required'));
        return false;
      } else if (penalty.since && (penalty.since < 1 || penalty.since > 30)) {
        NotificationService.Warning($translate.instant('area.penalty_since_value'));
        return false;
      }

      if (!penalty.time) {
        NotificationService.Warning($translate.instant('area.penalty_time_message_required'));
        return false;
      } else if (penalty.time && (penalty.time < 1 || penalty.time > 365)) {
        NotificationService.Warning($translate.instant('area.penalty_time_value'));
        return false;
      }
    }

    return true;
  };
  //#endregion

  //#region Validate Period Configuration
  const validatePeriodConfiguration = (areaCalendarType) => {
    const { since, sinceNextDays, until, untilNextDays } =
      $scope.area.appointmentsConfiguration.dynamicPeriod;
    const fromMoment = moment($scope.fromToDates.from).startOf('day');
    const toMoment = moment($scope.fromToDates.to).startOf('day');

    if (areaCalendarType == AreaCalendarType.ForADynamicPeriod) {
      if (since && (sinceNextDays < 1 || sinceNextDays > 365 || !sinceNextDays)) {
        NotificationService.Warning($translate.instant('appointments_invalid_since_next_days'));
        return false;
      }
      if (until && (untilNextDays < 1 || untilNextDays > 365 || !untilNextDays)) {
        NotificationService.Warning($translate.instant('appointments_invalid_until_next_days'));
        return false;
      }
    } else if (areaCalendarType == AreaCalendarType.ForAFixedPeriod) {
      if (!$scope.fromToDates.from || !$scope.fromToDates.to) {
        NotificationService.Warning($translate.instant('appointments_date_required'));
        return false;
      }

      if (
        fromMoment.isBefore(moment().startOf('day')) ||
        toMoment.isBefore(moment().startOf('day'))
      ) {
        NotificationService.Warning($translate.instant('appointments_old_date'));
        return false;
      }

      if (fromMoment.isAfter(toMoment)) {
        NotificationService.Warning(
          $translate.instant('appointments_from_date_greater_than_to_date'),
        );
        return false;
      }
    }
    return true;
  };
  //#endregion

  //#region Validate With or WithoutAppointment
  const validateRequestType = (values) => {
    if (!values.isWithAppointment && !values.isWithoutAppointment) {
      NotificationService.Warning($translate.instant('min_one_request_type'));
      return false;
    }
    return true;
  };

  const validateWithoutAppointment = (withoutAppointment) => {
    if (!withoutAppointment.withPriority && !withoutAppointment.withoutPriority) {
      NotificationService.Warning($translate.instant('min_one_priority_type'));
      return false;
    }

    if (
      !withoutAppointment.requestSource.reception &&
      !withoutAppointment.requestSource.totem &&
      !withoutAppointment.requestSource.mobileApp
    ) {
      NotificationService.Warning($translate.instant('min_one_request_type'));
      return false;
    }

    return true;
  };
  //#endregion

  //#region Validate Lines enabled
  const validateAtLeastOneLineIsEnabled = (lines) => {
    for (const line of lines) {
      if (line.enabled) {
        return true;
      }
    }
    return false;
  };

  //#endregion

  const populateAlreadyShown = () => {
    $scope.itemsPopulated = {};
    forEach($scope.items, (line) => {
      $scope.itemsPopulated[line._id] = line;
    });
    forEach($scope.supervisors, (supervisor) => {
      $scope.itemsPopulated[supervisor._id] = supervisor;
    });
  };

  //#endregion

  //#region Init
  const init = () => {
    $scope.$watch('area.appointmentsConfiguration.timeSlotsQuantity', updateTotalDuration);
    $scope.$watch('area.appointmentsConfiguration.duration', updateTotalDuration);

    PlaceService.GetOneAsPromise($state.params.placeId).then((place) => {
      $timeout(() => {
        $scope.place = place;
      });
    });

    //#region Update Area
    if (areaId) {
      AreaService.GetCurrentWithLines(areaId)
        .then((res) => {
          $timeout(() => {
            $scope.area = AreaService.Copy(res);
            $scope.area.schedule = schedule.format($scope.area.schedule, true);

            const fromDate = get(
              $scope.area,
              'appointmentsConfiguration.fixedPeriod.from',
              undefined,
            );
            const toDate = get($scope.area, 'appointmentsConfiguration.fixedPeriod.to', undefined);
            const sinceDays = get(
              $scope.area,
              'appointmentsConfiguration.dynamicPeriod.sinceNextDays',
              0,
            );
            const untilDays = get(
              $scope.area,
              'appointmentsConfiguration.dynamicPeriod.untilNextDays',
              90,
            );
            const calendarType = get(
              $scope.area,
              'appointmentsConfiguration.areaCalendarType',
              'forADynamicPeriod',
            );

            $scope.area.appointmentsConfiguration.personalData.email = $scope.place.requirements
              .virtualine.email
              ? true
              : $scope.area.appointmentsConfiguration.personalData.email;

            $scope.area.appointmentsConfiguration.personalData.phone = $scope.place.requirements
              .virtualine.phone
              ? true
              : $scope.area.appointmentsConfiguration.personalData.phone;

            $scope.area.appointmentsConfiguration.validateAppointmentQuantityByPerson.required =
              get(
                $scope.area,
                'appointmentsConfiguration.validateAppointmentQuantityByPerson.required',
                false,
              );
            $scope.area.appointmentsConfiguration.validateAppointmentQuantityByPerson.quantity =
              get(
                $scope.area,
                'appointmentsConfiguration.validateAppointmentQuantityByPerson.quantity',
                0,
              );

            $scope.area.appointmentsConfiguration.penalty.enabled = get(
              $scope.area,
              'appointmentsConfiguration.penalty.enabled',
              false,
            );
            $scope.area.appointmentsConfiguration.penalty.canceledAppointments = get(
              $scope.area,
              'appointmentsConfiguration.penalty.canceledAppointments',
              0,
            );
            $scope.area.appointmentsConfiguration.penalty.since = get(
              $scope.area,
              'appointmentsConfiguration.penalty.since',
              0,
            );
            $scope.area.appointmentsConfiguration.penalty.time = get(
              $scope.area,
              'appointmentsConfiguration.penalty.time',
              0,
            );
            $scope.oldPenaltyEnabled = get(
              $scope.area,
              'appointmentsConfiguration.penalty.enabled',
              false,
            );
            $scope.oldPenaltyCanceledAppointments = get(
              $scope.area,
              'appointmentsConfiguration.penalty.canceledAppointments',
              0,
            );
            $scope.oldPenaltySince = get($scope.area, 'appointmentsConfiguration.penalty.since', 0);
            $scope.oldPenaltyTime = get($scope.area, 'appointmentsConfiguration.penalty.time', 0);

            $scope.fromToDates.from = moment(fromDate).startOf('day').toDate();
            $scope.fromToDates.to = moment(toDate).startOf('day').toDate();
            $scope.area.appointmentsConfiguration.dynamicPeriod.sinceNextDays = sinceDays;
            $scope.area.appointmentsConfiguration.dynamicPeriod.untilNextDays = untilDays;
            $scope.area.appointmentsConfiguration.dynamicPeriod.since = get(
              $scope.area,
              'appointmentsConfiguration.dynamicPeriod.since',
              false,
            );
            $scope.area.appointmentsConfiguration.dynamicPeriod.until = get(
              $scope.area,
              'appointmentsConfiguration.dynamicPeriod.until',
              true,
            );
            $scope.area.appointmentsConfiguration.areaCalendarType =
              calendarType !== 'forADynamicPeriod' && calendarType !== 'forAFixedPeriod'
                ? 'forADynamicPeriod'
                : calendarType;

            $scope.linesPopulated = res.linesPopulated;
            $scope.supervisorsPopulated = res.supervisorsPopulated;

            // Lleno los items de Lineas
            for (let key in res.linesPopulated) {
              const item = {
                label: res.linesPopulated[key].label,
                _id: key,
                type: res.linesPopulated[key].type,
                realType: res.linesPopulated[key].realType,
                statistics: res.linesPopulated[key].statistics,
                waitingPeople: res.linesPopulated[key].waitingPeople,
                enabled: true,
              };
              $scope.items.push(item);
            }

            for (let key in res.linesDisabledPopulated) {
              const item = {
                label: res.linesDisabledPopulated[key].label,
                _id: key,
                type: res.linesDisabledPopulated[key].type,
                realType: res.linesDisabledPopulated[key].realType,
                statistics: res.linesDisabledPopulated[key].statistics,
                waitingPeople: res.linesDisabledPopulated[key].waitingPeople,
                enabled: false,
              };
              $scope.items.push(item);
            }

            // Lleno los items de Supervisores
            for (let user in res.supervisorsPopulated) {
              const supervisor = {
                label: res.supervisorsPopulated[user].local.email,
                _id: user,
              };
              $scope.supervisors.push(supervisor);
            }
            populateAlreadyShown();
            initSubscriptionsByChange();
          });
        })
        .catch(ErrorService.handler);
    }
    //#endregion
  };
  //#endregion

  init();
};

module.exports = module.controller('AreaModalController', AreaModalController);
