import angular from 'angular';
import { values, sortBy } from 'lodash';
import './modals/plans';
import '../services/plan';
import '../services/errors';

const module = angular.module('whyline.controllers.plans', [
  'whyline.modals.plans',
  'whyline.services.plan',
  'whyline.services.errors',
]);

const PlansController = ($scope, $timeout, $uibModal, PlanService, NotificationService, $translate, ErrorService, dialog) => {
  /*@ngInject*/
  $scope.title = 'plans';
  $scope.quantityOfItems = 8;

  $scope.plans = [];

  PlanService.GetAllAsPromise().then(data => {
    $scope.plans = data;
  });

  $scope.switch = PlanService.Switch;

  $scope.remove = (planId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('plan_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        PlanService.Remove(planId)
          .then(() => {
            let n = name || '';
            NotificationService.Success($translate.instant('plan_{name}_remove_succ').replace('{name}', n));
          })
          .catch(ErrorService.handler);
      });
  };

  // Modal
  $scope.open = planId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/plans-modal.html',
      size: 'lg',
      controller: 'PlansModalController',
      resolve: {
        planId: () => planId
      }
    });
  };
};

module.exports = module.controller('PlansController', PlansController);
