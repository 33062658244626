import angular from 'angular';
import {
  isEmpty
} from 'lodash';
import './resource';

const module = angular.module('whyline.resources.appointment', [
  'ngFileUpload',
  'whyline.resources.resource',
]);

const AppointmentFactory = (Resource, $timeout, $http, API_URL, Upload) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'Appointment?placeId';
  const _schema = {
    _id: {
      type: String
    },
    person: {
      firstName: {
        type: String,
        lowercase: true
      },
      lastName: {
        type: String,
        lowercase: true
      },
      priority: {
        type: Object,
      },
      colorPriority: Number,
      phone: {
        countryCode: {
          type: String,

        },
        full: {
          type: String,
        }
      },
      idType: String,
      id: String,
      identifications: {
        dni: {
          value: String,
          type: {
            type: String,
          },
        },
        lc: {
          value: String,
          type: {
            type: String,
          },
        },
        le: {
          value: String,
          type: {
            type: String,
          },
        },
        ci: {
          value: String,
          type: {
            type: String,
          },
        },
        dni_ext: {
          value: String,
          type: {
            type: String,
          },
        },
        pasaporte: {
          value: String,
          type: {
            type: String,
          },
        },
      },
    },
    appointment: {
      time: String,
      date: String,
      ISODate: String,
    },
    updatedFrom: String,
    sede: String,
    service: String,
    statusInfo: {
      type: Object,
      displayName: {
        type: String
      },
      reason: {
        type: String
      },
      status: {
        type: String
      }
    },
    notes: {
      label: {
        type: String,
      },
      text: {
        type: String,
      }
    }
  };

  const Appointment = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    if (this.hasOwnProperty('person') && this.person) {
      this.fullName = `${this.person.firstName} ${this.person.lastName}`;
    }
  };

  Appointment.CheckIn = appointmentId => {
    const url = `${API_URL}appointment/${appointmentId}/checkin?placeId`;
    return $http.post(url);
  };

  Appointment.prototype = new Resource(_type, _schema, Appointment);

  Resource.Extend(_type, Appointment);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Appointment.InitCache(validityTime);

  Appointment.Search = (fields = {}, options, blockUI = true) => {
    const {
      firstName,
      lastName,
      id,
      idType,
      nroCita,
    } = fields;
    const forceUpdate = options && options.force;
    const cacheUrl = `${API_URL}appointment/search?placeId`;
    const list = Appointment.GetCacheSearch(cacheUrl);
    if (forceUpdate || isEmpty(list)) {
      return $http({
          method: 'GET',
          url: `${API_URL}appointment/search?placeId`,
          params: {
            firstName,
            lastName,
            idType,
            id,
            nroCita,
          }
        })
        .then(response => {
          if (response.data) {
            Appointment.UpdateCacheSearch(cacheUrl, response.data, {});
          }
          return response;
        });
    }
    return list;
  };

  Appointment.SearchWithSubscriptions = (fields = {}, placeId, searchFrom) => {
    const {
      firstName,
      lastName,
      idType,
      id,
      nroCita,
      email,
      phone
    } = fields;

    return $http({
        method: 'GET',
        url: `${API_URL}appointment/appointmentsAndSubscriptions?searchFrom=${searchFrom}`,
        params: {
          firstName,
          lastName,
          idType,
          id,
          nroCita,
          email,
          countryCode: phone && phone.countryCode ? phone.countryCode : '',
          phoneNumber: phone && phone.full ? phone.full : '',
          placeId
        }
      });
  };

  Appointment.Upload = (rows, createdFrom) => {
    const configuration = {
      url: `${API_URL}appointment/upload?createdFrom=${createdFrom}`,
      method: 'POST',
      file: Upload.jsonBlob(rows),
    };

    return Upload.upload(configuration);
  };

  Appointment.Forward = appointmentId => {
    let url = `${API_URL}appointment/${appointmentId}/forward?placeId`;
    return $http.post(url);
  };

  Appointment.Requeue = appointment => {
    let url = `${API_URL}appointment/${appointment._id}/requeue?lineId=${appointment.lineId}`;
    return $http.post(url).then(
      response => {
        $timeout(() => {
          Appointment.UpdateCache(response.data._id, response.data);
        });
        return response;
      }
    );
  };

  Appointment.GetSchedule = (date, dayCount) => {
    return $http({
      method: 'GET',
      url: `${API_URL}appointment/schedule`,
      params: { date, dayCount }
    })
    .then(response => {
      return response;
    });
  };

  Appointment.GetAppointmentsByHour = params => {
    const { date, areaIds, placeId } = params;
    let url = `${API_URL}appointment/appointments-by-slot?blockui=false`;
    return $http({
      method: 'GET',
      url,
      params: { areaIds, date, placeId }
    })
      .then(response => response.data);
  };

  Appointment.Process = appointmentId => {
    let url = `${API_URL}appointment/${appointmentId}/process?placeId`;
    return $http.put(url);
  };

  Appointment.ExportReservations = configuration => {
    const url = `${API_URL}appointment/appointments-reservations-csv`;
    return $http.post(url, configuration);
  };

  return Appointment;
};

module.exports = module.factory('Appointment', AppointmentFactory);
