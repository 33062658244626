import angular from 'angular';
import '../services/user';
import { get, forEach, some } from 'lodash';
const module = angular.module('whyline.components.operator', [
  'whyline.services.user'
]);

const operatorComponent = {
  bindings: {
    userId: '<',
    placeId: '<',
    lineId: '<',
    dash: '<',
  },
  templateUrl: '/templates/components/operator.html',
  controller($scope, UserService, $timeout) {
    'ngInject';

    let userId;
    let lineId;

    this.$onInit = () => {
      this.operatorLabel = '';
      this.dash = false;
    };

    this.$onChanges = changes => {
      if(changes.dash && changes.dash.currentValue) {
        this.dash = changes.dash.currentValue;
      }

      // Watch changes in userId...
      let userIdChanged = changes.userId && changes.userId.currentValue;
      if(userIdChanged) {
        userId = changes.userId.currentValue;
      } else {
        userId = this.userId;
      }

      // ...and also in lineId, to be sure we're doing it right
      let lineIdChanged = changes.lineId && changes.lineId.currentValue;
      if(lineIdChanged) {
        lineId = changes.lineId.currentValue;
      } else {
        lineId = this.lineId;
      }

      if(userIdChanged || lineIdChanged) {
        UserService.GetOneAsPromise(userId)
        .then(user => {
          $timeout(() => {
            let found = false;
            forEach(user.permissions, permission => {
              if(some(permission.resourceGroup.resources, { type: 'line', resourceId: lineId }) || some(permission.resourceGroup.resources, { type: 'line', _id: lineId })) {
                this.operatorLabel = permission.boxLabel;
                found = true;
              }
            });

            if(!found) {
              this.operatorLabel = '';
            }

            // @TODO refactor this, maybe?
            if(changes.dash && changes.dash.currentValue && this.operatorLabel !== '') {
              this.operatorLabel = ` - ${this.operatorLabel}`;
            }
          });
        })
        .catch(() => {
          console.log('Operator User not found in database.');
        });
      }
    };
  },
};

module.exports = module.component('operator', operatorComponent);
