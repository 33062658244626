import angular from 'angular';
import {
  toArray
} from 'lodash';
import {
  formatAwt
} from '../../utils';
import '../../app.constants';
import '../../services/totem';
import '../../services/globals';
import '../../services/line';
import '../../services/area';
import '../../services/totem-tv';
import '../../services/subscription';
import '../../components/operator';
import '../../components/status';

const module = angular.module('whyline.controllers.totem-tv-status', [
  'whyline.constants',
  'whyline.services.totem',
  'whyline.services.globals',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.totem-tv',
  'whyline.services.subscription',
  'whyline.components.operator',
  'whyline.components.status',
]);

const TotemTvStatusController = (SAFE_STATES, $scope, $state, $timeout, $interval, TotemService, LineService, AreaService, GlobalsService, TotemTvService, SubscriptionService) => {
  /*@ngInject*/

  // Init values
  $scope.quantityOfItems = 8;
  $scope.subscriptions = {};
  $scope.subscriptionsAmount = {};
  $scope.queueStatus = {};
  $scope.showStatusView = false;
  let enqueueIn = {};

  // Set buttons statuses
  TotemTvService.SetButtonStatus('both', true);
  TotemTvService.SetButtonStatus('status-text', true);
  TotemTvService.SetButtonStatus('browserBack', true);

  // Get full path
  const path = TotemTvService.GetPath();

  // Check if path existis, else return to first step
  if (!path.length) {
    return $state.go(SAFE_STATES.totemTvWelcome);
  }

  // Get the last queue selected
  const selectedQueue = path[path.length - 1];
  const previousQueue = path.length > 1 && selectedQueue.realType === 'line' ? path[path.length - 2] : undefined;

  // Enqueue with this data
  if (selectedQueue.realType === 'process') {
    enqueueIn = {
      where: selectedQueue.realType,
      lineId: undefined,
      areaId: selectedQueue._id,
    };
  } else if (selectedQueue.realType === 'line' && !previousQueue || selectedQueue.realType === 'line' && previousQueue && previousQueue.realType !== 'group') {
    enqueueIn = {
      where: selectedQueue.realType,
      lineId: selectedQueue._id,
      areaId: undefined,
    };
  } else if (previousQueue.realType === 'group') {
    enqueueIn = {
      where: previousQueue.realType,
      lineId: selectedQueue._id,
      areaId: previousQueue._id,
    };
  }

  switch (enqueueIn.where) {
    case 'line':
      LineService.GetOneAsPromiseFromServer(enqueueIn.lineId)
        .then(line => {
          $timeout(() => {
            $scope.queueStatus = line;
            $scope.showStatusView = true;
            TotemTvService.SetEnqueueIn(enqueueIn);
            TotemTvService.SetPostMessage(line.configuration.messages.postCheckIn);
            TotemTvService.SetSelectedService(line);
          });
        });
      break;
    case 'process':
      AreaService.GetOneAsPromise(enqueueIn.areaId)
        .then(process => {
          LineService.GetOneAsPromiseFromServer(process.lines[0])
            .then(lineForProcess => {
              $timeout(() => {
                $scope.queueStatus = AreaService.Copy(process);
                $scope.queueStatus.waitingPeople = lineForProcess.waitingPeople;
                $scope.queueStatus.statistics = lineForProcess.statistics;
                $scope.showStatusView = true;
                TotemTvService.SetEnqueueIn(enqueueIn);
                TotemTvService.SetPostMessage(process.configuration.postMessage);
                TotemTvService.SetSelectedService($scope.queueStatus);
              });
            });
        });
      break;
    case 'group':
      const getArea = AreaService.GetOneAsPromise(enqueueIn.areaId);
      const getLine = LineService.GetOneAsPromise(enqueueIn.lineId);
      Promise.all([getArea, getLine])
        .then(queues => {
          $timeout(() => {
            const group = queues[0];
            const line = queues[1];
            $scope.queueStatus = group;
            $scope.queueLine = line;
            $scope.showStatusView = true;
            TotemTvService.SetEnqueueIn(enqueueIn);
          });
        });
      break;
  }

  $scope.$watch('lineForProcess', newVal => {
    if ($scope.queueStatus.realType === 'process') {
      $scope.queueStatus.waitingPeople = newVal.waitingPeople;
    }
  },
    true);
};

module.exports = module.controller('TotemTvStatusController', TotemTvStatusController);
