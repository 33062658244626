import angular from 'angular';
import './resource';
import './line';
import './subscription';
import './user';
import '../app.constants';
import { localStorage, schedule } from '../utils';
import {
  isEmpty,
  values,
} from 'lodash';

const module = angular.module('whyline.resources.area', [
  'whyline.resources.resource',
  'whyline.resources.line',
  'whyline.resources.subscription',
  'whyline.resources.user',
  'whyline.constants',
]);

const AreaFactory = (API_URL, $http, Resource, Line, User, Subscription, $timeout) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'area?placeId';

  const _schema = {
    _id: {
      type: String
    },
    appointmentsConfiguration: {
      alertMessage: {
        text: {
          type: String,
          required: false
        },
        show: Boolean
      },
      areaCalendarType: String,
      dynamicPeriod: {
        since: Boolean,
        sinceNextDays: Number,
        until: Boolean,
        untilNextDays: Number,
      },
      fixedPeriod: {
        from: String,
        to: String,
      },
      duration: Number,
      enabled: Boolean,
      notes: {
        label: {
          type: String,
          required: false
        },
        required: {
          type: Boolean,
          required: false
        },
        placeholder: {
          type: String,
          required: false
        }
      },
      penalty: {
        canceledAppointments: {
          default: 0,
          type: Number,
        },
        enabled: {
          default: false,
          type: Boolean,
        },
        since: {
          default: 0,
          type: Number,
        },
        time: {
          default: 0,
          type: Number,
        },
      },
      slots: Number,
      timeSlotsQuantity: Number,
      validateAppointmentQuantityByPerson: {
        quantity: {
          required: false,
          type: Number,
        },
        required: {
          required: true,
          type: Boolean,
        }
      },
      required: true,
    },
    placeId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    ordered: {
      type: Boolean,
      required: true
    },
    lines: {
      type: [Line],
      required: true,
      default: []
    },
    linesDisabled: {
      type: [Line],
      required: false,
      default: [],
    },
    supervisors: {
      type: [User],
      required: true,
      default: []
    },
    createdAt: {
      type: Date,
      required: true
    },
    modifiedAt: {
      type: Date,
      required: true
    },
    averageWaitingByNumber: {
      type: Number,
      required: true
    },
    lastNumberCalled: {
      type: Number,
      required: true
    },
    lastNumberGiven: {
      type: Number,
      required: true
    },
    lastCallTime: {
      type: Date,
      required: true
    },
    type: {
      type: String,
      required: false,
    },
    realType: {
      type: String,
      required: false,
    },
    schedule: {
      type: Object,
      required: true,
      default: schedule.create()
    },
    maxNumbers: {
      type: Number,
      required: true,
      default: 0
    },
    open: {
      type: Boolean,
      required: true,
    },
    serviceId: {
      type: String,
      required: false,
      default: '',
    },
    configuration: {
      postMessage: String,
      reasonRequired: Boolean,
      reasons: [],
      requestReasonAndMotive: Boolean,
      showSubscriptionsBy: {
        dailySubscriptions: {
          type: Boolean,
          default: true,
        },
        lastHoursSubscriptions: {
          type: Boolean,
          default: false,
        },
      },
    },
    isDeleted: {
      type: Boolean,
    },
    isWithAppointment: Boolean,
    isWithoutAppointment: Boolean,
    sla: {
      type: Number,
      default: 0,
    },
    withoutAppointment: {
      withPriority: Boolean,
      withoutPriority: Boolean,
      requestSource: {
        reception: Boolean,
        totem: Boolean,
        mobileApp: Boolean,
      },
    },
  };

  const Area = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.enable = enabled => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            // Cuando halla respuesta, modificar el cache
            Area.UpdateCache(item._id, item, null);
          });
          // Devuelve la respuesta desde el cache.
          return Area.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  Area.CallNext = areaId => {
    const selectedBox = localStorage.get('selectedBox', null);
    let boxName = selectedBox ? selectedBox.name : selectedBox;
    const url = `${API_URL}area/${areaId}/call-next`;
    return $http.post(url, {
      selectedBox: boxName,
    })
      .then(response => {
        const item = response.data;
        Subscription.UpdateCache(item._id, item);
        return item;
      });
  };

  Area.CallTo = (subscriptionId, queueId) => {
    const selectedBox = localStorage.get('selectedBox', null);
    let boxName = selectedBox ? selectedBox.name : selectedBox;
    const url = `${API_URL}subscription/${subscriptionId}/call-to?areaId=${queueId}`;
    return $http.post(url, {
      selectedBox: boxName,
    })
      .then(response => {
        const item = response.data;
        Subscription.UpdateCache(item._id, item);
        return item;
      });
  };

  Area.GetMyAreas = () => {
    const selectedBox = localStorage.get('selectedBox');
    const url = selectedBox ? `${API_URL}${_type}?boxId=${selectedBox._id}` : `${API_URL}${_type}`;
    const cacheUrl = selectedBox ? `${API_URL}area/allowed?boxId${selectedBox._id}` : `${API_URL}area/allowed`;
    const cacheExists = Area.SearchCacheExists(cacheUrl);
    const list = Area.GetCacheSearch(cacheUrl);
    if ((isEmpty(list) && !cacheExists)) {
      return $http.get(url)
      .then(response => {
        if (response.data) {
          Area.UpdateCacheSearch(cacheUrl, values(response.data), {});
        }
        return Area.GetCacheSearch(cacheUrl);
      });
    }
    return list;
  };

  Area.GetLines = areaId => {
    const url = `${API_URL}area/${areaId}/lines?placeId`;
    return $http.get(url)
      .then(response => {
        const lines = response.data;
        return lines;
      });
  };

  Area.GetSlotsByHour = params => {
    const url = `${API_URL}area/schedule?blockui=false`;
    return $http({
      method: 'GET',
      url,
      params
    })
      .then(response => response.data);
  };


  Area.prototype = new Resource(_type, _schema, Area);

  Resource.Extend(_type, Area);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Area.InitCache(validityTime);

  return Area;
};

module.exports = module.factory('Area', AreaFactory);
