import angular from 'angular';
import '../app.constants';
import './resource';

const module = angular.module('whyline.resources.ticket-template', [
  'whyline.constants',
  'whyline.resources.resource',
]);

const TicketTemplateFactory = (API_URL, $http, Resource) => {
    /*@ngInject*/
    //API REST Resource Name
    const _type = 'ticket-template?organizationId';
    const _schema = {
        name: {
            type: String,
            required: true,
        },
        organizationId: {
            type: String,
            required: true,
        },
        template: {
            type: String,
            required: true,
        },    
    };

    const TicketTemplate = function(data) {
        angular.forEach(_schema, (prop, key) => {
        this[key] = prop.default;
        });

        angular.forEach(data, (prop, key) => {
        this[key] = prop;
        });
    };

    TicketTemplate.prototype = new Resource(_type, _schema, TicketTemplate);

    Resource.Extend(_type, TicketTemplate);

    TicketTemplate.GetAllByOrganizationId = (organizationId) => {
        const url = `${API_URL}${_type}`;
        return $http.get(url).then(ticketTemplates => ticketTemplates.data);
    };

    const validityTime = 5 * 60 * 1000;
    TicketTemplate.InitCache(validityTime);

    return TicketTemplate;
}

module.exports = module.factory('TicketTemplate', TicketTemplateFactory);
