import angular from 'angular';
import './resource';
import '../app.constants';

const module = angular.module('whyline.resources.organizationuser', [
  'whyline.constants',
  'whyline.resources.resource'
]);

const OrganizationUserFactory = ($http, $timeout, API_URL, USER_CACHE_VALIDITY, Resource) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'organization/:organizationId/users';

  const _schema = {
    _id: {
      type: String
    },
    local: {
      type: Object,
      required: true
    },
    profile: {
      type: Object,
      required: true
    },
    permissions: {
      type: Array,
      required: true
    },
    provider: {
      type: String
    },
    sudo: {
      type: Boolean,
    }
  };

  const _saveInCache = user => {
    const isInstanceOfUser = user instanceof OrganizationUser;
    if (!isInstanceOfUser) {
      user = new OrganizationUser(user);
    }
    OrganizationUser.UpdateCache(user._id, user);
    OrganizationUser.InvalidateCache();
    return OrganizationUser.GetByIdFromCache(user._id);
  };

  const OrganizationUser = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.enableForOrganization = enabled => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            OrganizationUser.UpdateCache(item._id, item);
          });
          return OrganizationUser.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  OrganizationUser.deleteUserForOrganization = (userId, organizationId, deleteUserForOrganizationAndPlaces) => {
    const url = `${API_URL}${_type}/${userId}/delete-user-for`;
    return $http.put(url, {
      deleteUserForOrganizationAndPlaces,
    });
  };

  OrganizationUser.prototype = new Resource(_type, _schema, OrganizationUser);

  Resource.Extend(_type, OrganizationUser);

  OrganizationUser.InitCache(USER_CACHE_VALIDITY);

  return OrganizationUser;
};

module.exports = module.factory('OrganizationUser', OrganizationUserFactory);
