import angular from 'angular';
import { validatePersonId } from '../../utils';
import '../../app.constants';
import { get, isString, isEmpty, forEach, toArray, toUpper } from 'lodash';
import '../../services/totem';
import '../../services/globals';
import '../../services/line';
import '../../services/totem-tv';
import '../../services/validation';
import '../../services/errors';
import '../../components/operator';
import '../../components/status';
import '../../services/place';
import '../../services/third-party-subscription-info';
import '../../directives/numbers-only';
import { countriesWithCodes } from '../../utils/countries-and-codes';

const module = angular.module('whyline.controllers.totem-tv-user', [
  'whyline.constants',
  'whyline.services.totem',
  'whyline.services.globals',
  'whyline.services.line',
  'whyline.services.totem-tv',
  'whyline.services.validation',
  'whyline.services.errors',
  'whyline.services.place',
  'whyline.components.operator',
  'whyline.components.status',
  'whyline.services.third-party-subscription-info',
  'whyline.directives.numbersOnly',
]);

const TotemTvUserController = (SAFE_STATES, $scope, TotemService, LineService, $interval, GlobalsService, $state, TotemTvService, $timeout, NotificationService, ValidationService, $translate, ErrorService, PlaceService, ThirdPartySubscriptionInfoService, $window) => {
  /*@ngInject*/

  TotemTvService.SetButtonStatus('both', true);
  TotemTvService.SetButtonStatus('status-text', false);
  TotemTvService.SetButtonStatus('browserBack', false);

  const place = PlaceService.GetCurrent();

  $scope.updateType = place.enterpriseType !== 'ba' ? 'blur' : 'default';

  const conf = place.configuration;
  const thirdPartyApi = get(conf, 'thirdPartyInfo.getSubscriptionInfo', false);
  $scope.countries = countriesWithCodes;

  $scope.validatePersonApi = (id, idType, organizationId) => {
    ThirdPartySubscriptionInfoService.ValidatePerson(idType, id, organizationId)
      .then(re => {
        if (!isEmpty(re)) {
          $scope.person.id = re.id.toString();
          $scope.person.firstName = re.firstName.toString();
          $scope.person.lastName = re.lastName.toString();
          $scope.person.email = re.email.toString();
        } else {
          $scope.person.id = id;
          $scope.person.firstName = '';
          $scope.person.lastName = '';
          $scope.person.phone = {
            countryCode: toUpper(place.location.countryCode),
            full: ''
          };
        }
      })
      .catch(ErrorService.handler);
    $scope.apiSearch = true;
  };

  let currentRequiredFields = PlaceService.GetCurrent().requirements;
  $scope.emailRequired = currentRequiredFields && currentRequiredFields.virtualine && currentRequiredFields.virtualine.email;
  $scope.idRequired = currentRequiredFields && currentRequiredFields.id;

  const path = TotemTvService.GetPath();
  if (!path.length) {
    TotemTvService.ResetStack();
    $state.go(SAFE_STATES.totemTvWelcome);
  }

  switch ($state.params.step) {
    case 'identification':
      if (!$scope.idRequired && place.enterpriseType !== 'ba') {
        $state.go(SAFE_STATES.totemTvUser, { step: 'info' });
      }
      $scope.showUserNumberView = true;
      $scope.showUserInfoView = false;
      break;
    case 'info':
      if (thirdPartyApi) {
        $scope.validatePersonApi($scope.person.id, $scope.person.idType);
      }
      $scope.showUserNumberView = false;
      $scope.showUserInfoView = true;
      break;
  }

  $scope.validationErrors = {};

  const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

  const selected = identifications.find(id => id.key == $scope.person.idType) || identifications.find(id => id.default) || identifications.length && identifications[0];

  // Options
  $scope.idTypesList = identifications;

  const totem = TotemTvService.totem;

  $scope.showPersonFirstName = true;
  $scope.showPersonLastName = true;
  $scope.showPersonPhone = true;

  if (place.enterpriseType !== 'ba' && typeof totem.fields !== 'undefined' && Object.keys(totem.fields).length > 0) {
    $scope.showPersonFirstName = totem.fields.firstName;
    $scope.showPersonLastName = totem.fields.lastName;
    $scope.showPersonPhone = totem.fields.phone;
  }

  $scope.selectedIdType = {
    value: selected
  };

  $scope.person.phone = {
    countryCode: toUpper(place.location.countryCode),
    full: '',
  };

  $scope.validCharacters = /[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+/;
  $scope.validEmail = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,5})$/;
  $scope.idMandatoryError = "";

  // Update selected
  $scope.updateIdType = () => {
    $scope.person.idType = get($scope.selectedIdType, 'value.key', undefined);
    $scope.idMandatoryError = $translate.instant('invalid_person_{id}').replace('{id}', get($scope.selectedIdType, 'value.name', undefined));
  };

  $scope.updateIdType();

  let validateIdentification = person => {
    const idType = person.idType;
    const idNumber = person.id;
    const identification = $scope.selectedIdType.value;

    if ($scope.idRequired && !idNumber) {
      $scope.userData.personId.$dirty = true;
      $scope.userData.personId.$invalid = true;
      return false;
    }
    if ($scope.idRequired && !idType) {
      NotificationService.Warning($translate.instant('mandatory_person_number'), TotemTvService.TOASTER_OPTIONS);
      return false;
    }
    if (idNumber && !validatePersonId(idNumber, idType, $window, identification)) {
      $scope.userData.personId.$dirty = true;
      $scope.userData.personId.$error.badFormat = true;
      return false;
    }

    return true;
  };

  let validatePerson = person => {
    const { firstName, lastName } = person;

    if ($scope.showPersonFirstName && !firstName) {
      $scope.userData.firstName.$dirty = true;
      return false;
    }

    if ($scope.showPersonLastName && !lastName) {
      $scope.userData.lastName.$dirty = true;
      return false;
    }

    if (currentRequiredFields && currentRequiredFields.virtualine && currentRequiredFields.virtualine.email && !$scope.userData.email.$viewValue) {
      $scope.userData.email.$dirty = true;
      return false;
    }

    if ($scope.showPersonPhone && !ValidationService.validatePerson($scope.person, 'phone')) {
      $scope.phoneNumberIsInvalid = true;
      $scope.phoneNumberError = '';

      let someValue = false;
      forEach($scope.person.phone, value => {
        // if ANY phone field is present, require all phone fields
        if (value && value.length) {
          someValue = true;
        }
      });

      if (currentRequiredFields && currentRequiredFields.phoneNumber && !someValue) {
        $scope.phoneNumberError = 'phone_required';
      } else if (someValue) {
        $scope.phoneNumberError = 'invalid_tel';
      }

      return false;
    }

    /**
     * If email is set, test whether is valid or not.
     */
    if ($scope.userData.email && $scope.userData.email.$viewValue && $scope.userData.email.$invalid) {
      return false;
    }

    return true;
  };

  if ($state.params.step == 'identification') {
    TotemTvService.Validate(validateIdentification, $scope.person);
  } else {
    $timeout(() => {
      if (!$scope.showPersonLastName) {
        $scope.person.lastName = '  ';
      }
      if (!$scope.showPersonFirstName) {
        $scope.person.firstName = '  ';
      }
      TotemTvService.Validate(validatePerson, $scope.person);
    });
  }

  // Keyboard
  $scope.keyboard = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 'clear'];

  $scope.keyboardAction = key => {
    if (key === 'clear') {
      if (place.enterpriseType === 'ba') {
        $scope.person.id = '';
      } else {
        $scope.person.id = $scope.person.id.substring(0, $scope.person.id.length - 1);
      }
      $scope.userData.personId.$invalid = false;
    } else {
      if (!isString($scope.person.id)) {
        $scope.person.id = '';
      }
      $scope.person.id = $scope.person.id + key;
    }
    $scope.userData.personId.$dirty = false;
    $scope.userData.personId.$error.badFormat = false;
    if (place.enterpriseType !== 'ba') {
      $window.document.getElementById('personIdField').focus();
    }
  };
};

module.exports = module.controller('TotemTvUserController', TotemTvUserController);
