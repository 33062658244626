/* eslint-disable no-undef */
import angular from 'angular';
import { get, find, head } from 'lodash';
import moment from 'moment-timezone';
import '../../components/status';
import '../../services/subscription';
import '../../services/place';
import '../../services/line';
import '../../services/area';
import '../../services/organization';
import * as utils from '../../utils';
import '../../services/user';

const module = angular.module('whyline.modals.preference', [
  'whyline.components.status',
  'whyline.services.subscription',
  'whyline.services.place',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.user',
]);

const PreferenceController = (
  $scope,
  $timeout,
  $uibModalInstance,
  SubscriptionService,
  AreaService,
  subscriptionId,
  currentPlaceId,
  $translate,
  preference,
) => {
  'ngInject';

  $scope.preference = preference;
  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.assignPreference = (preference) => {
    $scope.preference = preference;
    SubscriptionService.ChangePreference(subscriptionId, preference).then(() =>
      $uibModalInstance.close('close'),
    );
  };
};

module.exports = module.controller('PreferenceController', PreferenceController);
