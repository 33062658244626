import angular from 'angular';
import '../services/display';
import '../services/errors';
import '../services/notifications';
import '../services/place';
import './modals/display';
import '../services/globals';

const module = angular.module('whyline.controllers.displays', [
  'whyline.services.display',
  'whyline.services.errors',
  'whyline.services.notifications',
  'whyline.services.place',
  'whyline.modals.display',
  'whyline.services.globals',
]);

const DisplaysController = function (
  $scope,
  $uibModal,
  $timeout,
  $state,
  $translate,
  dialog,
  GlobalsService,
  $window,
  DisplayService,
  NotificationService,
  PlaceService,
  ErrorService,
  currentPlace,
) {
  /*@ngInject*/
  $scope.title = 'Displays';
  $scope.quantityOfItems = 8;
  $scope.organizationId = $state.params.organizationId;

  $scope.plan = {};
  $scope.plan.maxDisplays =
    currentPlace.plan && currentPlace.plan.maxDisplays ? currentPlace.plan.maxDisplays : 1;
  $scope.displays = DisplayService.GetAll();

  $scope.switch = DisplayService.Switch;

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';
  PlaceService.GetOneAsPromise($state.params.placeId)
    .then((place) => {
      $timeout(() => {
        $scope.place = place;
      });
    })
    .catch(ErrorService.handler);

  $scope.delete = (displayId, name) => {
    let n = name || '';
    dialog
      .confirm($translate.instant('display_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        DisplayService.Remove(displayId)
          .then(() => {
            let name = name || '';
            NotificationService.Success(
              $translate.instant('display_{name}_remove_succ').replace('{name}', name),
            );
          })
          .catch(ErrorService.handler);
      });
  };

  // Modal
  $scope.open = (displayId) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/displays-modal.html',
      size: 'lg',
      controller: 'DisplayModalController',
      resolve: {
        displayId: () => displayId,
        currentPlace: [
          'PlaceService',
          function (PlaceService) {
            return PlaceService.GetCurrentAsPromise();
          },
        ],
      },
    });
  };

  $scope.identify = (displayId) => {
    DisplayService.Identify(displayId)
      .then(() => {
        let name = name || '';
        NotificationService.Success(
          $translate.instant('display_{name}_identified_succ').replace('{name}', name),
        );
      })
      .catch(ErrorService.handler);
  };

  $scope.minmax = (displayId) => {
    DisplayService.MinMax(displayId)
      .then(() => {
        let name = name || '';
        NotificationService.Success(
          $translate.instant('display_{name}_minmax_succ').replace('{name}', name),
        );
      })
      .catch(ErrorService.handler);
  };

  $scope.refresh = (displayId) => {
    DisplayService.Refresh(displayId)
      .then(() => {
        let name = name || '';
        NotificationService.Success(
          $translate.instant('display_{name}_refresh_succ').replace('{name}', name),
        );
      })
      .catch(ErrorService.handler);
  };
};

module.exports = module.controller('DisplaysController', DisplaysController);
