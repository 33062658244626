import angular from 'angular';
import '../resources/third-party-subscription-info';
import '../services/errors';
import './globals';

const module = angular.module('whyline.services.third-party-subscription-info', [
  'whyline.resources.third-party-subscription-info'
]);

let ThirdPartySubscriptionInfo;

class ThirdPartySubscriptionInfoService {
  static $inject = ['ThirdPartySubscriptionInfo'];

  constructor(injectedThirdPartySubscriptionInfo) {
    /*@ngInject*/
    ThirdPartySubscriptionInfo = injectedThirdPartySubscriptionInfo;
  }

  ValidatePerson(identificatorType, identificator, organizationId) {
    return ThirdPartySubscriptionInfo.ValidatePerson(identificatorType, identificator, organizationId);
  }
}

module.exports = module.service('ThirdPartySubscriptionInfoService', ThirdPartySubscriptionInfoService);
