import angular from 'angular';
import moment from 'moment-timezone';
import '../app.constants';
import '../factories/socket.io';
import '../resources/subscription';
import '../services/subscription';
import '../services/area';
import '../services/line';
import '../services/place';
import '../services/errors';
import '../services/socket';
import '../services/notifications';
import { get } from 'lodash';

const module = angular.module('whyline.sockets.subscription', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.subscription',
  'whyline.services.subscription',
  'whyline.services.area',
  'whyline.services.place',
  'whyline.services.line',
  'whyline.services.errors',
  'whyline.services.socket',
  'whyline.services.notifications',
]);

const SubscriptionSocket = (Socket, Subscription, SubscriptionService, AreaService, LineService, ErrorService, $timeout, NotificationService, $rootScope, SocketService, PlaceService, $state, SAFE_STATES, $translate) => {
  $rootScope.$on('socketReady', function() {
    SocketService.GetInstance().then(function(socket) {
      // CREATED
      socket.on('subscription:created', data => {
        const {
          queue,
          subscription
        } = data;
        const place = PlaceService.GetCurrent();
        const minCheckIn = get(place, 'configuration.checkIn.min', 15);
        const checkinTime = moment().utc().add(minCheckIn, 'm');
        if (moment(subscription.subscriptionTime).isSameOrBefore(checkinTime)) {
          Subscription.UpdateCache(subscription._id, subscription);
          SubscriptionService.UpdateSubscriptionsCacheSearch(queue, subscription, false);
        }

        // Desktop notification
        NotificationService.ShowDesktop({
          title: $translate.instant('new_subscription'),
          body: $translate.instant('click_to_go_to') + queue.label,
          onclick: () => {
            // Params for state execution
            const stateParams = {
              placeId: queue.placeId,
              lineId: queue.type === 'line' ? queue._id : queue.lineId,
            };

            // Go to this queue
            $state.go(SAFE_STATES.nowServingQueue, stateParams)
              .then(() => {
                $rootScope.$broadcast('update-active-queue');
              });
          },
        });
      });
      // UPDATED
      socket.on('subscription:updated', subscription => {
        subscription.fullName = `${subscription.person.firstName} ${subscription.person.lastName}`;
        if(subscription.areaId) {
          AreaService.GetOneAsPromise(subscription.areaId).then(area => {
            $timeout(() => {
              SubscriptionService.UpdateSubscriptionsCacheSearch(area, subscription, false, true);
              Subscription.UpdateCache(subscription._id, subscription);
            });
          });
        } else {
          Subscription.UpdateCache(subscription._id, subscription);
        }
      });

      // DELETED
      socket.on('subscription:deleted', subscription => {
        subscription.fullName = `${subscription.person.firstName} ${subscription.person.lastName}`;
        Subscription.UpdateCache(subscription._id, subscription);
      });

      // CALLED
      socket.on('subscription:called', subscription => {
        subscription.fullName = `${subscription.person.firstName} ${subscription.person.lastName}`;
        Subscription.UpdateCache(subscription._id, subscription);
      });

      // FORWARDED
      socket.on('subscription:forwarded', data => {
        const {
          originalSubscription,
          newSubscription
        } = data;
        Subscription.UpdateCache(originalSubscription._id, originalSubscription);
        Subscription.UpdateCache(newSubscription._id, newSubscription);
        // To supervisor action
        if(!newSubscription.areaId) {
          LineService.GetOneAsPromise(newSubscription.lineId)
            .then(line => {
              $timeout(() => {
                SubscriptionService.UpdateSubscriptionsCacheSearch(line, newSubscription, false);
                let text = $translate.instant('person_added_to_line');
                NotificationService.Information(`${text}: ${line.name || line.label}`, 'Info');
              });
            });
        }
        // From supervisor action
        if(newSubscription.areaId) {
          AreaService.GetOneAsPromise(newSubscription.areaId)
            .then(area => {
              $timeout(() => {
                SubscriptionService.UpdateSubscriptionsCacheSearch(area, newSubscription, false);
              });
            });
        }
      });
    });
  });
};

module.exports = module.factory('SubscriptionSocket', (Socket, Subscription, SubscriptionService, AreaService, LineService, ErrorService, $timeout, NotificationService, $rootScope, SocketService, PlaceService, $state, SAFE_STATES, $translate) => new SubscriptionSocket(Socket, Subscription, SubscriptionService, AreaService, LineService, ErrorService, $timeout, NotificationService, $rootScope, SocketService, PlaceService, $state, SAFE_STATES, $translate));
