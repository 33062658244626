import angular from 'angular';

const module = angular.module('whyline.directives.time-mask', []);

const timeMask = () => {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.on('input', function() {
          var value = element.val();
          value = value.replace(/[^0-9]/g, '');
          
          if (value.length >= 2) {
            value = value.substring(0, 2) + ':' + value.substring(2, 4);
          }

          if (value.length > 5) {
            value = value.substring(0, 5);
          }

          var parts = value.split(':');
          if (parts[0]) {
            var hours = parseInt(parts[0], 10);
            if (hours > 23) { 
              hours = 23;
            } else {
              hours = parts[0];
            }
            parts[0] = hours.toString();
          }
          if (parts[1]) {
            var minutes = parseInt(parts[1], 10);
            if (minutes > 59) {
              minutes = 59;
            } else {
              minutes = parts[1];  
            }
            parts[1] = minutes.toString();
          }
          value = parts.join(':');

          element.val(value);
          scope.$applyAsync();
        });
      }
    };
  };


  module.exports = module.directive('timeMask', timeMask);