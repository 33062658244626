import angular from 'angular';
import { localStorage } from '../../utils';
import '../../app.constants';
import '../../services/globals';
import '../../services/organization';
import '../../services/place';
import '../../services/user';
import '../../services/redirect-to-new-version';
import '../../services/errors';
import '../../resources/resource';
import '../modals/places';

const module = angular.module('whyline.controllers.places.list', [
  'whyline.constants',
  'whyline.services.globals',
  'whyline.services.organization',
  'whyline.services.place',
  'whyline.services.user',
  'whyline.services.errors',
  'whyline.services.redirect-to-new-version',
  'whyline.resources.resource',
  'whyline.modals.place',
]);

const PlacesListController = (SAFE_STATES, $translate, $scope, $state, $timeout, $uibModal, PlaceService, UserService, GlobalsService, ErrorService, OrganizationService, RedirectToNewVersionService) => {
  /*@ngInject*/

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';
  GlobalsService.SetCurrentPlace(null);

  localStorage.get('user').then(user => {
    $scope.currentUser = user;
    $scope.superAdmin = user && user.sudo;
  });

  const placesPromise = $scope.superAdmin ? PlaceService.GetAllEnterpriseAsPromise() : PlaceService.GetAllAllowedByUserAsPromise();
  const organizationPromise = OrganizationService.GetCurrentAsPromise();
  Promise.all([placesPromise, organizationPromise]).then(([places, organization]) => {
    $timeout(() => {
      $scope.places = places;
      $scope.organization = organization;
      $scope.limits = {
        maxPlaces: organization.configuration.plan.maxPlaces,
        unlimited: organization.configuration.plan.maxPlaces === -1
      };
      $timeout(() => {
        $scope.$apply();
      });
    });
  })
  .catch(ErrorService.handler);

  $scope.selectPlace = place => {
    if ($scope.disabledForPlace(place._id)) {
      return;
    }
    if (place.revertMigration) {
      RedirectToNewVersionService.Redirect('now-serving', place._id, $scope.currentUser._id, '').then();
    }
    UserService.GetCurrentAsPromise()
      .then(currentUser => {
        $timeout(() => {
          PlaceService.SetCurrent(place);
          const currentRole = UserService.GetCurrentRoleFor(currentUser);
          switch (currentRole.name) {
            case 'operator':
              return $state.go(SAFE_STATES.nowServing, { placeId: place._id, organizationId: place.organizationId, selectBox: true });
            case 'Admin General':
            case 'Coordinator':
              return $state.go(SAFE_STATES.nowServing, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
            case 'Receptionist':
            case 'Base Receptionist':
              return $state.go(SAFE_STATES.receptionWelcome, { placeId: place._id, organizationId: place.organizationId, selectBox: true });
            case 'Statistical':
              return $state.go(SAFE_STATES.stats, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
            default: {
              const otherRoles = ['SiGeCi', 'Whyline - SuperUser', 'Supervisor', 'Organization Admin'];
              if (otherRoles.indexOf(currentRole.name) < 0 && typeof currentRole === 'object') {
                const sections = currentRole.actions.find(a => a.includes('section'));
                switch (sections) {
                  case 'section.displays':
                    return $state.go(SAFE_STATES.displays, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
                  case 'section.areas':
                    return $state.go(SAFE_STATES.areas, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
                  case 'section.totems':
                    return $state.go(SAFE_STATES.totems, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
                  case 'section.boxes':
                    return $state.go(SAFE_STATES.boxes, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
                  case 'section.lines':
                    return $state.go(SAFE_STATES.lines, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
                  case 'section.users':
                    return $state.go(SAFE_STATES.users, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
                  }
              } else {
                return $state.go(SAFE_STATES.nowServing, { placeId: place._id, organizationId: place.organizationId, selectBox: false });
              }
            }
          }
        });
      });
  };

  $scope.edit = placeId => $state.go(SAFE_STATES.placesEdit, { id: placeId });


  $scope.open = placeId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/places-modal.html',
      size: 'lg',
      controller: 'PlaceModalController',
      resolve: {
        placeId: () => placeId,
        organization: () => $scope.organization,
      }
    });
  };

  $scope.disabledForPlace = placeId => UserService.DisabledForPlace(placeId);
};

module.exports = module.controller('PlacesListController', PlacesListController);
