import angular from 'angular';
import 'angular-wizard';
import 'angular-sanitize';
import {
  debounce,
  cloneDeep,
  includes,
  values,
} from 'lodash';
import {
  schedule,
} from '../utils/index';
import '../services/globals';
import '../services/auth';
import '../services/user';
import '../services/place';
import '../services/organization';
import '../services/line';
import '../services/location';
import '../services/errors';
import '../services/notifications';
import '../services/timezone';
import '../services/category';
import './modals/merchant/lines';

const module = angular.module('whyline.controllers.sign-up', [
  'uiCropper',
  'mgo-angular-wizard',
  'ngSanitize',
  'whyline.services.globals',
  'whyline.services.auth',
  'whyline.services.user',
  'whyline.services.place',
  'whyline.services.organization',
  'whyline.services.line',
  'whyline.services.location',
  'whyline.services.notifications',
  'whyline.services.category',
  'whyline.services.errors',
  'whyline.services.timezone',
  'whyline.services.language',
  'whyline.modals.merchant.lines'
]);

const SignUpController = (SAFE_STATES, $scope, Slug, $window, dialog, $state, $timeout, $translate, TimezoneService, UserService, LocationService, AuthService, ErrorService, GlobalsService, PlaceService, OrganizationService, NotificationService, CategoryService, WizardHandler, LanguageService, LineService, $uibModal, $location, $rootScope) => {
  /*@ngInject*/

  const emptyPlace = {
    name: '',
    language: LanguageService.GetBrowserLanguage(),
    info: {
      website: '',
      phones: [],
      address: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      street: '',
      streetNumber: '',
    },
    location: {
      lat: '',
      lng: '',
    },
    openingDetails: {
      periods: [],
      weekday_text: [],
    },
    images: {},
    checkInCode: '',
    categories: [],
  };

  $scope.checkbox = {
    noPlace: false
  };

  $scope.place = cloneDeep(emptyPlace);

  $scope.completeItems = length => {
    if (5 - length >= 0) {
      return new Array(5 - length);
    } else {
      return new Array(0);
    }
  };
  // INIT
  const validCharacters = '[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]';
  $scope.namePattern = new RegExp(`^${validCharacters}+(${validCharacters}{1,} {0,1}${validCharacters}{1,}){0,}${validCharacters}+$`, 'u');
  $scope.urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  $scope.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  $scope.currentLanguage = $translate.use();
  $scope.currentStep;

  $scope.data = {
    confirmPassword: '',
    phone: '',
    locationAsString: '',
    address: '',
    virtualineImage: '',
    mobileAppImage: '',
    results: [],
    addressResults: [],
    selectedCategory: '',
    selectedPlace: '',
    timezones: TimezoneService.List,
    addressSearch: '',
    selectedAddress: ''
  };

  CategoryService.GetAllAsPromise().then(categories => {
    $scope.data.categories = categories;
  });

  // Data for angular wizard


  //
  //
  // User step
  // Empty user
  $scope.user = {
    profile: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      language: $translate.use(),
    },
    local: {
      email: '',
      password: '',
    },
  };

  $scope.checkboxTerms = false;
  // Create user
  // $scope.createUser = () => {
  //   const user = cloneDeep($scope.user);
  //   if ($scope.place.name) {
  //     $scope.place.checkInCode = `${$scope.place.name}_check_in`;
  //   }

  //   if ($scope.user.local.password && $scope.user.local.password !== $scope.data.confirmPassword) {
  //     if($scope.currentLanguage === 'en') {
  //       NotificationService.Warning('Passwords must match.');
  //     } else {
  //       NotificationService.Warning('Las contraseñas deben coincidir antes de continuar.');
  //     }
  //     return false;
  //   }

  //   user.local.password = AuthService.Encrypt(user.local.password);

  //   AuthService.SignUp(user)
  //     .then(user => {
  //       if($scope.currentLanguage === 'en') {
  //         NotificationService.Success('User was succesfully created.');
  //       } else {
  //         NotificationService.Success('El usuario fue creado exitosamente.');
  //       }
  //       AuthService.SignIn($scope.user.local.email, $scope.user.local.password).then(() => {
  //         WizardHandler.wizard().next();
  //       });
  //     })
  //     .catch(ErrorService.handler);
  // };
  $scope.disabledButton = false;

  //
  //
  // Place selection (google api)
  // $scope.selectPlace = place => {
  //   const placeId = place.place_id;
  //   if(placeId) {
  //     // Set description on search input
  //     if(place.description) {
  //       $scope.data.address = place.description;
  //     }

  //     // Get the details of the place selected by user
  //     LocationService.GetPlaceDetails(placeId)
  //       .then(placeDetails => {
  //         $scope.data.selectedPlace = placeDetails;
  //         $timeout(fillPlaceDatailsData);
  //       });
  //   }
  //   $scope.data.results = [];
  // };

  var fillPlaceDatailsData = () => {

    let placeDetails = $scope.data.selectedPlace;
    if (!placeDetails) return;

    $scope.data.locationAsString = [placeDetails.geometry.location.lat(), placeDetails.geometry.location.lng()];
    $scope.place.location = {
      lat: placeDetails.geometry.location.lat(),
      lng: placeDetails.geometry.location.lng(),
    };
    autocompleteFields(placeDetails);
    $scope.data.results = [];
  }

  $scope.selectAddress = place => {
    const placeId = place.place_id;
    if (placeId) {
      // Set description on search input
      if (place.description) {
        $scope.data.addressSearch = place.description;
      }

      // Get the details of the place selected by user
      LocationService.GetPlaceDetails(placeId)
        .then(placeDetails => {
          $timeout(() => {
            $scope.data.selectedAddress = placeDetails;
            $scope.place.location = {
              lat: placeDetails.geometry.location.lat(),
              lng: placeDetails.geometry.location.lng(),
            };
            autocompleteFields(placeDetails, true);
          });
        });
    }
    $scope.data.addressResults = [];
  };

  $scope.editLocation = (form) => {
    form.$setUntouched();
    form.$setPristine();
    WizardHandler.wizard().previous();
    return false;
  };

  $scope.$watch('data.address', debounce(findByStablishment, 500));

  function findByStablishment() {
    if ($scope.data.address && $scope.data.address.length >= 3 && !$scope.data.selectedPlace) {
      return LocationService.FindStablishments($scope.data.address)
        .then(result => {
          $timeout(() => {
            $scope.data.results = result;
          });
        });
    }
  }

  $scope.$watch('data.addressSearch', debounce(findByAddress, 500));

  function findByAddress() {
    let search = $scope.data.addressSearch;
    if (search && search.length >= 3 && !$scope.data.selectedAddress) {
      return LocationService.FindAddresses(search)
        .then(result => {
          $timeout(() => {
            $scope.data.addressResults = result;
          });
        });
    }
  }

  $scope.onPlaceSelectedContinue = (form) => {

    if ($scope.checkbox.noPlace) {
      if (!$scope.data.selectedAddress) {
        restartFullSelectedAdderss();
      }
      WizardHandler.wizard().next();

    } else {

      if (form.$submitted && !$scope.data.selectedPlace) {
        NotificationService.Warning($translate.instant('must_select_place'));
      } else {
        if (form.$valid && $scope.data.selectedPlace) {
          fillPlaceDatailsData();
          $scope.data.selectedAddress = '';
          WizardHandler.wizard().next();
        }
      }
    }
  }


  $scope.onCheckboxNoPlaceChange = (checked) => {
    if (checked) {
      $scope.data.results = [];
      if (!$scope.data.selectedPlace) $scope.data.address = '';
    }
  }

  $scope.restartSelectedPlace = (form) => {
    $scope.data.results = [];
    $scope.data.selectedPlace = '';
    $scope.data.address = '';
    $scope.data.locationAsString = '';
    $scope.place = cloneDeep(emptyPlace);
    form.$setPristine();
    form.$setUntouched();
  };

  var restartFullSelectedAdderss = () => {

    $scope.data.addressResults = [];
    $scope.data.selectedAddress = '';
    $scope.data.addressSearch = '';
    $scope.data.phone = '';
    $scope.place = cloneDeep(emptyPlace);
  }

  $scope.restartSelectedAddress = (form) => {

    $scope.data.addressResults = [];
    $scope.data.selectedAddress = '';
    $scope.data.addressSearch = '';

    let name = $scope.place.name;
    let empty = Object.assign({}, emptyPlace, { name, info: { website: $scope.place.info.website } });
    $scope.place = cloneDeep(empty);
    if (form) form.$setPristine();
    if (form) form.$setUntouched();
  };

  const autocompleteFields = (placeDetails, excludeName) => {
    const { name, website, formatted_phone_number, formatted_address, place_id } = placeDetails;

    if (name && !excludeName) {
      $scope.place.name = name;
    }

    if (website) {
      $scope.place.info.website = website;
    }

    if (formatted_phone_number) {
      $scope.data.phone = formatted_phone_number;
    }
    if (formatted_address) {
      $scope.place.info.address = formatted_address;
    }

    if (placeDetails.opening_hours) {
      $scope.place.openingDetails.periods = placeDetails.opening_hours.periods;
      $scope.place.openingDetails.weekday_text = placeDetails.opening_hours.weekday_text;
    }

    // Google place id
    $scope.place.googlePlaceId = place_id;

    // Check in code
    $scope.place.checkInCode = `${Slug.slugify(placeDetails.name)}_check_in`;

    // Address components
    Object.assign($scope.place.info, LocationService.GetLocationInfo(placeDetails));
  };

  const validateRanges = schedule => {
    var valid = true;
    const opened = schedule.filter(s => s.open);
    for (let i = 0; i < opened.length; i++) {
      const day = opened[i];
      if (day.firstPeriod.opening >= day.firstPeriod.closing) {
        NotificationService.Warning($translate.instant('opening_hours_first_period_msg_validator').replace('{dayname}', $translate.instant(day.name)));
        valid = false;
        break;
      }
      if (day.hasSecondPeriod) {
        if (day.secondPeriod.opening <= day.firstPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_must_be_later_first_msg_validator').replace('{dayname}', $translate.instant(day.name)));
          valid = false;
          break;
        }
        if (day.secondPeriod.opening >= day.secondPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_period_msg_validator').replace('{dayname}', $translate.instant(day.name)))
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  $scope.validateSchedule = () => {
    if (validateRanges($scope.schedule)) {
      WizardHandler.wizard().next();
    }
  };

  $scope.selectPlace = place => {
    const placeId = place.place_id;
    if (placeId) {
      // Set description on search input
      if (place.description) {
        $scope.data.address = place.description;
      }

      // Get the details of the place selected by user
      LocationService.GetPlaceDetails(placeId)
        .then(placeDetails => {
          $timeout(() => {
            $scope.data.selectedPlace = placeDetails;
            $scope.data.locationAsString = [placeDetails.geometry.location.lat(), placeDetails.geometry.location.lng()];
            $scope.place.location = {
              lat: placeDetails.geometry.location.lat(),
              lng: placeDetails.geometry.location.lng(),
            };
            autocompleteFields(placeDetails);
          });
        });
    }
    $scope.data.results = [];
  };

  // Crop
  $scope.setImage = (file, type) => {
    if (type === 'virtualine') {
      $scope.data.virtualineImage = file;
    } else if (type === 'mobile') {
      $scope.data.mobileAppImage = file;
    }
  };


  $scope.onUserFormSubmit = (form) => {
    if (form.$submitted && form.$invalid) {
      NotificationService.Warning($translate.instant('errors_in_form'));
    } else {
      if (form.$valid) createUser();
    }
  }

  // Creators
  function createUser() {
    const user = cloneDeep($scope.user);
    if ($scope.place.name) {
      $scope.place.checkInCode = `${$scope.place.name}_check_in`;
    }

    if ($scope.user.local.password && $scope.user.local.password !== $scope.data.confirmPassword) {
      NotificationService.Warning($translate.instant('pw_must_match'));
      return false;
    }

    user.local.password = AuthService.Encrypt(user.local.password);

    AuthService.SignUp(user)
      .then(user => {
        NotificationService.Success($translate.instant('user_create_succ'));
        AuthService.SignIn($scope.user.local.email, $scope.user.local.password)
          .then(response => {
            if (response.user) {
              $rootScope.$broadcast('authenticated', response.user);
            }
            WizardHandler.wizard().next();
          });
      })
      .catch(ErrorService.handler);
  };


  $scope.placeCreation = (form) => {
    if ($scope.checkbox.noPlace && !$scope.data.selectedAddress) {
      $scope.data.addressSearch = '';
      form.$setSubmitted();
      return NotificationService.Warning($translate.instant('must_select_addr'));
    }

    form.$setSubmitted();
    if (form.$submitted && form.$invalid) {
      NotificationService.Warning($translate.instant('errors_in_form'));
    } else {
      if (form.$valid) createPlace();
    }
  }

  $scope.selectedAddress = '';
  $scope.addressSearch = '';

  function createPlace() {
    dialog.confirm($translate.instant('have you checked dialog'))
      .then(() => {
        if ($scope.data.phone) {
          if (!includes($scope.place.info.phones, $scope.data.phone)) {
            if (!$scope.place.info.phones) $scope.place.info.phones = [];
            $scope.place.info.phones.push($scope.data.phone);
          }
        }
        if ($scope.data.selectedCategory) {
          if (!includes($scope.place.categories, $scope.data.selectedCategory)) {
            $scope.place.categories.push($scope.data.selectedCategory);
          }
        }
        PlaceService.Merchant($scope.place)
          .then(place => {
            $timeout(() => {
              OrganizationService.GetOneAsPromiseFromServer(place.organizationId).then(organization => {
                NotificationService.Success($translate.instant('stablish_succ'));

                GlobalsService.SetCurrentOrganization(organization);
                GlobalsService.SetCurrentPlace(place);

                $state.current.reloadOnSearch = false;
                $location.search('organizationId', organization._id);
                $location.search('placeId', place._id);

                UserService.SyncCurrent().then(() => {
                  $timeout(() => {
                    $state.current.reloadOnSearch = undefined;
                    WizardHandler.wizard().next();
                  });
                });
              });
            });
          })
          .catch(ErrorService.handler);
      });
  };

  //
  //
  // Opening hours
  $scope.hours = schedule.getHours();
  $scope.schedule = schedule.reOrder(schedule.create());
  $scope.setHasSecondPeriod = (day, hasSecondPeriod) => {
    day.hasSecondPeriod = hasSecondPeriod;
  };

  $scope.applyToAll = day => {
    schedule.applyToAll(day, $scope.schedule);
  };

  // Lines step
  $scope.lines = [];

  $scope.finish = () => {
    if ($scope.lines.length) {
      $state.go(SAFE_STATES.nowServing, { placeId: $state.params.placeId, organizationId: $state.params.organizationId });
    } else {
      NotificationService.Warning('Debes crear, al menos, una fila en tu establecimiento para finalizar.');
    }
  };

  $scope.completeItems = length => {
    if (5 - length >= 0) {
      return new Array(5 - length);
    } else {
      return new Array(0);
    }
  };

  // Modal
  $scope.open = lineId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/merchant/lines.html',
      size: 'lg',
      controller: 'MerchantLineModalController',
      resolve: {
        lineId: () => lineId,
        lines: () => $scope.lines,
        placeId: () => $scope.place._id,
        schedule: () => $scope.schedule,
      }
    });
  };

  $scope.remove = (lineId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('line_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        LineService.Remove(lineId)
          .then(() => {
            NotificationService.Success($translate.instant('line_remove_succ'));
            $scope.lines = values(LineService.GetAll());
          })
          .catch(ErrorService.handler);
      });
  };

  $scope.$on('wizard:stepChanged', function (event, args) {
    $scope.currentStep = args.index + 1;
    if ($scope.currentStep === 5) {
      $scope.slidesToShow = slides.secondBlock;
      $scope.showCarousel = true;
    }
  });

  // Carousel
  // Init
  // Slides
  const slides = {
    firstBlock: [
      {
        step: 1,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/1.jpg'
      },
      {
        step: 2,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/2.jpg'
      },
      {
        step: 3,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/3.jpg'
      },
      {
        step: 4,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/4.jpg'
      },
      {
        step: 5,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/5.jpg'
      },
      {
        step: 6,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/6.jpg'
      },
      {
        step: 7,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-1/7.jpg'
      }
    ],
    secondBlock: [
      {
        step: 1,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-2/1.jpg'
      },
      {
        step: 2,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-2/2.jpg'
      },
      {
        step: 3,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-2/3.jpg'
      },
      {
        step: 4,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-2/4.jpg'
      },
      {
        step: 5,
        alt: '',
        src: 'http://whyline.com/on-boarding_merchant/block-2/5.jpg'
      }
    ],
  };

  $scope.showCarousel = true;
  $scope.showCloseCarousel = false;
  $scope.slidesToShow = slides.firstBlock;

  // Actions
  $scope.onCarouselAfterChange = currentSlide => {
    $scope.showCloseCarousel = false;
    if (currentSlide === $scope.slidesToShow.length - 1) {
      $scope.showCloseCarousel = true;
    }
  };

  $scope.closeCarousel = () => {
    $scope.showCarousel = false;
    $scope.showCloseCarousel = false;
  };
};

module.exports = module.controller('SignUpController', SignUpController);
