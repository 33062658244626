import angular from 'angular';
import '../services/area';
import '../services/errors';
import './modals/areas';
import '../components/status';
import '../services/globals';

const module = angular.module('whyline.controllers.areas', [
  'whyline.components.status',
  'whyline.modals.area',
  'whyline.services.area',
  'whyline.services.errors',
  'whyline.services.globals',
]);

const AreasController = ($scope, $uibModal, $window, $translate, AreaService, NotificationService, GlobalsService, ErrorService, dialog) => {
  /*@ngInject*/

  $scope.areas = AreaService.GetAll();
  $scope.switch = AreaService.Switch;
  $scope.quantityOfItems = 8;
  $scope.selectedType = 'process';

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';

  // Toggle selected type function
  $scope.toggleSelectedType = () => {
    if ($scope.selectedType === 'process') {
      $scope.selectedType = 'group';
    } else {
      $scope.selectedType = 'process';
    }
  };

  $scope.delete = (areaId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('area_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        AreaService.Remove(areaId)
          .then(() => {
            NotificationService.Success($translate.instant('area_remove_succ'));
          })
          .catch(ErrorService.handler);
      });
  };

  $scope.update = AreaService.Update;

  $scope.open = (modalType, areaId) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/areas-modal.html',
      size: 'lg',
      controller: 'AreaModalController',
      resolve: {
        modalType: () => modalType,
        areaId: () => areaId
      }
    });
  };
};

module.exports = module.controller('AreasController', AreasController);
