import angular from 'angular';
import '../services/connection';
import './notifications';
import '../app.constants';
import { get } from 'lodash';

const module = angular.module('whyline.services.errors', ['whyline.services.notifications', 'whyline.services.connection']);

const ErrorService = function (NotificationService, $translate) {
  'ngInject';

  const KnowErrors = [
    'queue.subscribe.line-not-found-in-area',
    'queue.call-to.somebody-is-processing',
    'queue.call-to.not-found',
    'queue.call-next.somebody-is-processing',
    'queue.call-next.not-found',
    'queue.call-next.operator-no-subscriptions',
    'queue.subscribe.queue-close',
    'queue.subscribe.daily-limit-reached',
    'queue.forward.area-closed',
    'appointment.too-late',
    'appointment.too-early',
    'appointment.already-canceled',
    'appointment.already-checked-in',
    'appointment.cannot-checked-in',
    'appointment.csv-file-not-found',
    'appointment.incorrect-number-of-headers',
    'appointment.cant-map-headers',
    'appointment-cant-map-data',
    'appointment.no-area-found',
    'appointment.cant-save',
    'appointment.not-found',
    'appointment.cron-service-error',
    'appointment.all-appointments-already-saved',
    'appointment.missing-required-value',
    'appointment.create.slot-reserved',
    'appointment.max-appointments-by-person',
    'appointment.just-canceled-by-cron',
    'area.cant-change-configuration-because-appointments-exists',
    'area.cant-decrease-slots-configuration-because-appointments-exists',
    'area.duplicated-serviceId-in-place',
    'area.invalid-date',
    'banner.active-in-displays',
    'banner.duplicated-name',
    'box.not-found',
    'box.duplicated-name',
    'line.remove.line-exists-in-areas',
    'login.user-disabled',
    'call-logic.cannot_delete_default_logic',
    'call-logic.cannot_enable_default_logic',
    'call-logic.cannot_disable_default_logic',
    'call-logic.duplicated_name',
    'call-logic.not_found',
    'conditions_active_in_scenarios',
    'condition_slug_already_exists',
    'scenario_active_in_place',
    'scenario_area_in_use',
    'scenario_area_is_not_open',
    'scenario_condition_in_use',
    'scenario_name_already_exists',
    'user.login.incorrect-password',
    'user.not-found',
    'users.sign-up.email-already-registered',
    'users.sign-in.invalid-email',
    'place.create.google-place-id-already-taken',
    'place.name-already-in-use',
    'video.already-saved-name',
    'video.already-saved-src',
    'video.empty-data',
    'organization.duplicated_reasons',
    'organization.externalId-not-unique',
    'organization.slug-not-unique',
    'organization-user.already-saved-user-email',
    'organization-area.active-in-displays',
    'organization-area.active-in-places',
    'organization-area.active-in-totems',
    'organization-area.duplicated_reasons',
    'organization-area.serviceId-duplicated',
    'organization-area.supervisor-active-in-displays',
    'organization-area.supervisor-active-in-totems',
    'organization-line.active-in-displays',
    'organization-line.active-in-places',
    'organization-line.active-in-totems',
    'organization-line.used-in-areas',
    'plan.limit-reached',
    'holiday.invalid-date.exists-appointments-in-range-of-days',
    'holiday.invalid-period.to-must-be-greater-or-equal-than-from',
    'holiday.invalid-period.to-hour-must-be-greater-or-equal-than-from-hour',
    'holiday.invalid-from-date.can-not-be-in-the-past',
    'organization-custom-role.in-use',
    'organizationArea.line-in-use',
    'user.external-id.exists',
    'totem.friendly-id.exists',
    'subscription.exceeded-quota',
    'subscription.undo-forward-to-in-progress',
    'subscription.undo-forward-to-other-in-progress'
  ];

  function generic(error) {
    let algo_mal = $translate.instant('default_case');
    return NotificationService.Error(algo_mal + ` \n ${error}`);
  }

  function message(msj) {
    return function (error) {
      return NotificationService.Error(`${msj}
        ${error}`,
        'Error');
    };
  }

  function handler(error) {
    if (get(error.data, 'data', false)) {
      error.extraData = error.data.data;
    }
    if (get(error.data, 'message', false)) {
      error.data = error.data.message;
    }

    switch (error.data) {
      case 'queue.call-to.somebody-is-processing':
      case 'queue.call-next.somebody-is-processing':
        return NotificationService.Error($translate.instant('queue.call-next.somebody-is-processing'));
      case 'queue.subscribe.queue-close':
        return NotificationService.Error($translate.instant('queue.subscribe.queue-close'));
      case 'queue.subscribe.daily-limit-reached':
        return NotificationService.Error($translate.instant('queue.subscribe.daily-limit-reached'));
      case 'queue.subscribe.line-not-found-in-area':
        return NotificationService.Error($translate.instant('queue.subscribe.line-not-found-in-area'));
      case 'queue.forward.area-closed':
        return NotificationService.Error($translate.instant('queue.forward.area-closed'));
      case 'queue.call-next.not-found':
        return NotificationService.Error($translate.instant('queue.call-next.not-found'));
      case 'queue.call-next.operator-no-subscriptions':
        return NotificationService.Error($translate.instant('queue.call-next.operator-no-subscriptions'));
      case 'queue.call-to.not-checked-in':
        return NotificationService.Error($translate.instant('queue.call-to.not-checked-in'));
      case 'queue.operator.not-ready':
        return NotificationService.Error($translate.instant('queue.operator.not-ready'));
      case 'queue.call-next.not-found':
        return NotificationService.Error($translate.instant('queue.call-next.not-found'));
      case 'appointment.already-canceled':
        return NotificationService.Error($translate.instant('appointment.already-canceled'));
      case 'appointment.create.slot-reserved':
        return NotificationService.Error($translate.instant('appointment.create.slot-reserved'));
      case 'appointment.too-late':
        return NotificationService.Error($translate.instant('appointment.too-late'), { timeOut: 10000 });
      case 'appointment.too-early':
        let translated = $translate.instant('appointment.too-early', { minutes: error.checkInMin }),
          come_back_at = $translate.instant('appointment.too-early-comeback');

        if (error.comeBackAt) {
          return NotificationService.Error(`${translated} ${come_back_at} ${error.comeBackAt}.`, { timeOut: 10000 });
        } else {
          return NotificationService.Error(translated, { timeOut: 10000 });
        }
      case 'appointment.cannot-checked-in':
      case 'appointment.already-checked-in':
        return NotificationService.Error($translate.instant('appointment.already-checked-in'));
      case 'appointment.csv-file-not-found':
        return NotificationService.Error($translate.instant('appointment.csv-file-not-found'));
      case 'appointment.incorrect-number-of-headers':
        return NotificationService.Error($translate.instant('appointment.incorrect-number-of-headers'));
      case 'appointment.cant-map-headers':
        return NotificationService.Error($translate.instant('appointment.cant-map-headers'));
      case 'appointment-cant-map-data':
        return NotificationService.Error($translate.instant('appointment-cant-map-data'));
      case 'appointment.no-area-found':
        return NotificationService.Error($translate.instant('appointment.no-area-found'));
      case 'appointment.cant-save':
        return NotificationService.Error($translate.instant('appointment.cant-save'));
      case 'appointment.not-found':
        return NotificationService.Error($translate.instant('appointment.not-found'));
      case 'appointment.cron-service-error':
        return NotificationService.Warning($translate.instant('appointment.cron-service-error'));
      case 'appointment.all-appointments-already-saved':
        return NotificationService.Warning($translate.instant('appointment.all-appointments-already-saved'));
      case 'appointment.cancel.appointment-already-processed':
        return NotificationService.Warning($translate.instant('appointment.cancel.appointment-already-processed'));
      case 'appointment.max-appointments-by-person':
        return NotificationService.Warning($translate.instant('appointment.max-appointments-by-person'));
      case 'appointment.just-canceled-by-cron':
        return NotificationService.Warning($translate.instant('appointment.already-canceled'));
      case 'area.cant-change-configuration-because-appointments-exists':
        return NotificationService.Error($translate.instant('area.cant-change-configuration-because-appointments-exists'));
      case 'area.cant-decrease-slots-configuration-because-appointments-exists':
        return NotificationService.Error($translate.instant('area.cant-decrease-slots-configuration-because-appointments-exists',
          { currentSlotConfiguration: error.context.currentSlotConfiguration }));
      case 'area.duplicated-serviceId-in-place':
        return NotificationService.Error($translate.instant('area.duplicated-serviceId-in-place'));
      case 'area.invalid-date':
        return NotificationService.Error($translate.instant('appointments_old_date'));
      case 'banner.active-in-displays':
        return NotificationService.Error($translate.instant('banner.active-in-displays', { displays: error.extraData.join(', ').toUpperCase() }));
      case 'banner.duplicated-name':
        return NotificationService.Error($translate.instant('banner.duplicated-name'));
      case 'box.not-found':
        return NotificationService.Error($translate.instant('box_not_found'));
      case 'box.duplicated-name':
        return NotificationService.Error($translate.instant('box_duplicated_name'));
      case 'line.remove.line-exists-in-areas':
        return NotificationService.Error($translate.instant('line.remove.line-exists-in-areas'));
      case 'login.user-disabled':
        return NotificationService.Error($translate.instant('login_user_disabled'));
      case 'call-logic.cannot_delete_default_logic':
        return NotificationService.Error($translate.instant('call-logic.cannot_delete_default_logic'));
      case 'call-logic.cannot_enable_default_logic':
        return NotificationService.Error($translate.instant('call-logic.cannot_enable_default_logic'));
      case 'call-logic.cannot_disable_default_logic':
        return NotificationService.Error($translate.instant('call-logic.cannot_disable_default_logic'));
      case 'call-logic.duplicated_name':
        return NotificationService.Error($translate.instant('call_logic_name_duplicated'));
      case 'call-logic.not_found':
        return NotificationService.Error($translate.instant('call-logic.not_found'));
      case 'conditions_active_in_scenarios':
        return NotificationService.Error($translate.instant('conditions_active_in_scenarios'));
      case 'condition_slug_already_exists':
        return NotificationService.Error($translate.instant('condition_slug_already_exists'));
      case 'scenario_active_in_place':
        return NotificationService.Error($translate.instant('scenario_active_in_place'), { places: error.extraData.placesNames.join(', ').toUpperCase() });
      case 'scenario_area_in_use':
        return NotificationService.Error($translate.instant('scenario_area_in_use').replace('{name}', error.extraData.toUpperCase()));
      case 'scenario_area_is_not_open':
        return NotificationService.Error($translate.instant('scenario_area_is_not_open').replace('{name}', error.extraData.processName.toUpperCase()));
      case 'scenario_condition_in_use':
        return NotificationService.Error($translate.instant('scenario_condition_in_use').replace('{name}', error.extraData.toUpperCase()));
      case 'scenario_name_already_exists':
        return NotificationService.Error($translate.instant('scenario_name_already_exists'));
      case 'scenario_default_already_exists':
        return NotificationService.Error($translate.instant('scenario_default_already_exists').replace('{name}', error.extraData));
      case 'users.sign-up.email-already-registered':
      case 'user.login.incorrect-password':
        return NotificationService.Error($translate.instant('user.login.incorrect-password'));
      case 'user.not-found':
        return NotificationService.Error($translate.instant('user.not-found'));
      case 'place.create.google-place-id-already-taken':
        return NotificationService.Error($translate.instant('place.create.google-place-id-already-taken'));
      case 'place.create.branch-id-already-taken':
        return NotificationService.Error($translate.instant('place.create.branch-id-already-taken'));
      case 'place.name-already-in-use':
        return NotificationService.Error($translate.instant('place.name-already-in-use'));
      case 'video.already-saved-src':
        return NotificationService.Error($translate.instant('video.already-saved-src'));
      case 'video.already-saved-name':
        return NotificationService.Error($translate.instant('video.already-saved-name'));
      case 'video.empty-data':
        return NotificationService.Error($translate.instant('video.empty-data'));
      case 'subscription.no-requeue-status-allowed':
        return NotificationService.Error($translate.instant('subscription.no-requeue-status-allowed'));
      case 'organization.duplicated-reasons':
        return NotificationService.Error($translate.instant('duplicated-reasons'));
      case 'organization.externalId-not-unique':
        return NotificationService.Error($translate.instant('organization.externalId-not-unique'));
      case 'organization.slug-not-unique':
        return NotificationService.Error($translate.instant('organization.slug-not-unique'));
      case 'not-authorized':
        return NotificationService.Error($translate.instant('not-authorized'));
      case 'organization-user.already-saved-user-email':
        return NotificationService.Error($translate.instant('organization-user.already-saved-user-email'));
      case 'organization-area.active-in-displays':
        return NotificationService.Error($translate.instant('organization-area.active-in-displays',
        { displays: error.extraData.displaysNames.join(', ').toUpperCase(), places: error.extraData.placesNames.join(', ').toUpperCase()}));
      case 'organization-area.active-in-places':
        return NotificationService.Error($translate.instant('organization-area.active-in-places',
        { places: error.extraData.join(', ').toUpperCase() }));
      case 'organization-area.active-in-totems':
        return NotificationService.Error($translate.instant('organization-area.active-in-totems',
        { totems: error.extraData.totemsNames.join(', ').toUpperCase(), places: error.extraData.placesNames.join(', ').toUpperCase()}));
      case 'organization-area.duplicated-reasons':
        return NotificationService.Error($translate.instant('duplicated-reasons'));
      case 'organization-area.serviceId-duplicated':
        return NotificationService.Error($translate.instant('organization-area.serviceId-duplicated'));
      case 'organization-area.supervisor-active-in-displays':
        return NotificationService.Error($translate.instant('organization-area.supervisor-active-in-displays',
        { displays: error.extraData.displaysNames.join(', ').toUpperCase(), places: error.extraData.placesNames.join(', ').toUpperCase()}));
      case 'organization-area.supervisor-active-in-totems':
        return NotificationService.Error($translate.instant('organization-area.supervisor-active-in-totems',
        { totems: error.extraData.totemsNames.join(', ').toUpperCase(), places: error.extraData.placesNames.join(', ').toUpperCase()}));
      case 'organization-line.active-in-displays':
        return NotificationService.Error($translate.instant('organization-line.active-in-displays', { displays: error.extraData.join(', ').toUpperCase() }));
      case 'organization-line.active-in-places':
        return NotificationService.Error($translate.instant('organization-line.active-in-places', { places: error.extraData.join(', ').toUpperCase() }));
      case 'organization-line.active-in-totems':
        return NotificationService.Error($translate.instant('organization-line.active-in-totems', { totems: error.extraData.join(', ').toUpperCase() }));
      case 'organization-line.used-in-areas':
        return NotificationService.Error($translate.instant('organization-line.used-in-areas', { areas: error.extraData.join(', ').toUpperCase() }));
      case 'users.sign-in.invalid-email':
        return NotificationService.Error($translate.instant('users.sign-in.invalid-email'));
      case 'plan.limit-reached':
        return NotificationService.Error($translate.instant('plan.limit-reached'));
      case 'holiday.invalid-date.exists-appointments-in-range-of-days':
        return NotificationService.Error($translate.instant('holiday.invalid-date.exists-appointments-in-range-of-days'));
      case 'holiday.invalid-from-date.can-not-be-in-the-past':
        return NotificationService.Error($translate.instant('holiday.invalid-from-date.can-not-be-in-the-past'));
      case 'holiday.invalid-period.to-must-be-greater-or-equal-than-from':
        return NotificationService.Error($translate.instant('second_period_must_be_greater_than_first_one'));
      case 'holiday.invalid-period.to-hour-must-be-greater-or-equal-than-from-hour':
        return NotificationService.Error($translate.instant('second_period_hour_must_be_greater_than_first_one'));
      case 'organization-custom-role.in-use':
        return NotificationService.Error($translate.instant('role_already_in_use'));
      case 'organizationArea.line-in-use': 
        return NotificationService.Error($translate.instant('organizationArea.line-in-use', { place: error.extraData.place, line: error.extraData.line }));
      case 'user.external-id.exists':
        return NotificationService.Error($translate.instant('user.external-id.exists', {externalId: error.extraData.externalId, email: error.extraData.email}));
      case 'totem.friendly-id.exists':
        return NotificationService.Error($translate.instant('totem.friendly-id.exists', {friendlyId: error.extraData.friendlyId}));
      case 'subscription.exceeded-quota':
        return NotificationService.Error($translate.instant('subscription.exceeded-quota'));
      case 'subscription.undo-forward-to-in-progress':
        return NotificationService.Error($translate.instant('subscription.undo-forward-to-in-progress'));
      case 'subscription.undo-forward-to-other-in-progress':
        return NotificationService.Error($translate.instant('subscription.undo-forward-to-other-in-progress'));
      default:
        return NotificationService.Error($translate.instant('default_case'));
    }
  }

  return {
    handler,
    generic,
    message,
    KnowErrors
  };
};

module.exports = module.service('ErrorService', ErrorService);
