import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/banner';

const module = angular.module('whyline.services.banner', [
  'whyline.resources.banner',
]);

let Banner;

class BannerService {
  static $inject = ['Banner'];

  constructor(injectedBanner) {
    Banner = injectedBanner;
  }

  GetAll() {
    return Banner.FetchAll();
  }

  GetAllAsPromise() {
    return Banner.FetchAll({ promise: true});
  }

  GetAllAsPromiseFromServer() {
    return Banner.FetchAll({ promise: true, force: true});
  }

  GetOne(displayId) {
    return Banner.FindById(displayId);
  }

  GetOneAsPromise(displayId) {
    return Banner.FindById(displayId, {promise: true});
  }

  Create(data) {
    const newBanner = new Banner(data);
    return Banner.Save(newBanner);
  }

  Copy(banner) {
    const copy = cloneDeep(banner);
    return new Banner(copy);
  }

  Update(banner) {
    return Banner.Save(banner);
  }

  Remove(bannerId) {
    const banner = Banner.FindById(bannerId);
    return Banner.Remove(banner);
  }

  Switch() {
    //console.log('TODO: implement banner switch');
  }
}

module.exports = module.service('BannerService', BannerService);
