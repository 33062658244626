import angular from 'angular';
import 'angular-jwt';
import { localStorage } from '../utils';
import '../app.constants';
import '../services/auth';
import '../services/user';
import '../services/globals';
import '../services/errors';
import 'angular-recaptcha';
import { get } from 'lodash';
import '../controllers/modals/account/changePassword';

const module = angular.module('whyline.controllers.sign-in', [
  'angular-jwt',
  'whyline.constants',
  'whyline.services.auth',
  'whyline.services.user',
  'whyline.services.globals',
  'whyline.services.errors',
  'vcRecaptcha',
  'whyline.controllers.modals.account.changePassword',
]);

const SignInController = (
  SAFE_STATES,
  $scope,
  $state,
  jwtHelper,
  AuthService,
  UserService,
  NotificationService,
  GlobalsService,
  ErrorService,
  $translate,
  vcRecaptchaService,
  PUBLIC_KEY,
  $uibModal,
) => {
  /*@ngInject*/

  // Enterprise type for branding
  $scope.enterpriseType = GlobalsService.EnterpriseType;
  $scope.currentYear = new Date().getFullYear();
  $scope.currentLanguage = $translate.use();

  // Init
  $scope.signInData = {
    email: null,
    password: null,
  };

  $scope.recap = {
    publicKey: PUBLIC_KEY,
  };

  $scope.showPassword = false;
  $scope.passwordType = 'password';

  const token = localStorage.get('token');
  if (token && !jwtHelper.isTokenExpired(token)) {
    $state.go(SAFE_STATES.organizationsList);
  }

  $scope.signIn = () => {
    const { email, password } = $scope.signInData;

    if (!email || email.lenght === 0) {
      return NotificationService.Error($translate.instant('mandatory_email'));
    } else if (!password || password.lenght === 0) {
      return NotificationService.Error($translate.instant('mandatory_password'));
    } else {
      grecaptcha.ready(() => {
        grecaptcha.execute(PUBLIC_KEY, { action: 'submit' }).then((captchaResponse) => {
          AuthService.SignIn(email, password, captchaResponse)
            .then((data) => {
              const sigeci = data.user.sigeci;
              if (get(data, 'user.local.requireChangePassword', false)) {
                const modal = $uibModal.open({
                  templateUrl: '/templates/components/modals/account/change-password.html',
                  size: 'md',
                  controller: 'changePasswordModalController',
                  resolve: {
                    currentPassword: () => password,
                  },
                });
                modal.result.then( status => {
                  if( !status ){
                    AuthService.CloseSessions(email, password).then(()=>{
                      AuthService.SignOut();                    
                    });
                  }else{
                    goToSafeState(sigeci);
                  }
                });
              } else {
                goToSafeState(sigeci);
              }
            })
            .catch(ErrorService.handler);
        });
      });
    }
  };

  function goToSafeState(sigeci) {
    if (sigeci) {
      return $state.go(SAFE_STATES.appointments);
    }
    $state.go(SAFE_STATES.organizationsList);
  }

  $scope.toggleShowPassword = () => {
    $scope.showPassword = !$scope.showPassword;
    if ($scope.showPassword) {
      $scope.passwordType = 'text';
    } else {
      $scope.passwordType = 'password';
    }
  };
};

module.exports = module.controller('SignInController', SignInController);
