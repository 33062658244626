import angular from 'angular';
import '../../../services/user';
import '../../../services/errors';

const module = angular.module('whyline.controllers.modals.account.changePassword', [
  'whyline.services.user',
  'whyline.services.errors',
]);

const changePasswordModalController = (
  $scope,
  currentPassword,
  $uibModalInstance, 
  UserService, 
  ErrorService,
  $translate,
) => {
  'ngInject';

  // Init
  $scope.passwordTypeInput = {
    'showPassword': false,
    'type': 'password',
  };

  const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])\S{8,}$/);
  $scope.newPasswordHelpTooltip = $translate.instant('new_password_help');
  $scope.me = UserService.CopyCurrent();
  $scope.inputPasswordError = false;

  $scope.togglePassword = () => {
    $scope.passwordTypeInput.showPassword = !$scope.passwordTypeInput.showPassword;
    $scope.passwordTypeInput.type = $scope.passwordTypeInput.type === 'password' ? 'text' : 'password';
  }

  const validate = () => {
    const newPassword = $scope.me.newPassword;
    const isValid = newPassword && regexPassword.test(newPassword)
    $scope.inputPasswordError = !isValid;
    return isValid;
  };

  $scope.togglePasswordValidate = () => {
    $scope.inputPasswordError = false;
  }

  $scope.save = () => {
    if( validate() ) {
      $scope.me.currentPassword = currentPassword;
      $scope.me.confirmPassword = $scope.me.newPassword;
      UserService.UpdateCurrent($scope.me)
        .then(() => $scope.cancel() )
        .catch(ErrorService.handler);
        $uibModalInstance.close(true);
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close(false);
  };
};

module.exports = module.controller('changePasswordModalController', changePasswordModalController);