import angular from 'angular';
import '../../../services/line';

const module = angular.module('whyline.modals.merchant.lines', [
  'whyline.services.line',
]);

const MerchantLineModalController = ($scope, $uibModalInstance, $timeout, NotificationService, LineService, lineId, lines, schedule) => {
  'ngInject';

  $scope.line = {
    schedule,
    maxNumbers: 0,
    requestSource: {
      mobileApp: true,
      reception: true,
      totem: true,
    },
    sla: 0
  };

  const validate = () => {
    const name = $scope.line.label;

    if (!name) {
      NotificationService.Warning('El nombre de la fila es requerido.');
      return false;
    }
    if (name.length > 50) {
      NotificationService.Warning('El nombre solamente puede contener 50 caracteres.');
      return false;
    }
    if (name.length < 2) {
      NotificationService.Warning('El nombre debe tener al menos 2 caracteres.');
      return false;
    }
    if (!/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(name)) {
      NotificationService.Warning('El nombre contiene al menos, un caracter no permitido.');
      return false;
    }
    return true;
  };

  const dismissOnError = err => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
  };

  if (lineId) {
    LineService.GetOneAsPromiseFromServer(lineId)
      .then(line => {
        $timeout(() => {
          $scope.line = LineService.Copy(line);
        });
      })
      .catch(err => dismissOnError(err));
  }

  $scope.save = () => {
    if (validate()) {
      if (lineId) {
        return LineService.Update($scope.line)
          .then(() => {
            NotificationService.Success('queue_update_succ');
            $uibModalInstance.close('cancel');
          })
          .catch(err => dismissOnError(err));
      }

      return LineService.Create($scope.line)
        .then(line => {
          lines.push(line);
          console.info(line);
          NotificationService.Success('queue_create_succ');
          $uibModalInstance.close('cancel');
        })
        .catch(err => dismissOnError(err));
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller('MerchantLineModalController', MerchantLineModalController);
