import angular from 'angular';
import {
  get
} from 'lodash';
import moment from 'moment-timezone';
import '../../../app.constants';
import '../../../services/appointment';
import '../../../services/area';
import '../../../services/reception';
import '../../../services/totem-tv';
import '../../../services/errors';
import '../../../services/notifications';
import '../../modals/appointment';
import '../../modals/appointment-info';
import '../../../services/place';
import appointment from '../../../services/appointment';

const module = angular.module('whyline.controllers.reception.appointment-confirm', [
  'whyline.constants',
  'whyline.services.appointment',
  'whyline.services.area',
  'whyline.services.reception',
  'whyline.services.totem-tv',
  'whyline.services.errors',
  'whyline.services.notifications',
  'whyline.modals.edit-appointment',
  'whyline.modals.appointment-info',
  'whyline.services.place',
  'whyline.services.queue'
]);

const AppointmentConfirmController = ($uibModal, $scope, $state, $timeout, ErrorService, AppointmentService, AreaService, SubscriptionService, ReceptionService, NotificationService, $translate, PlaceService, QueueService) => {
  /*@ngInject*/

  $scope.currentPlaceId = $state.params.placeId;
  $scope.id = $state.params.id;
  $scope.type = $state.params.type;
  $scope.appointment = {};
  $scope.subscription = {};
  $scope.showEditAppointments = false;

  // Controls
  ReceptionService.SetButtonStatus('back', false);
  ReceptionService.SetButtonStatus('forward', true);
  ReceptionService.SetButtonStatus('status-text', true);

  const CHECK_IN_MIN = 15;

  const placePromise = PlaceService.GetCurrentAsPromise()
    .then(place => {
      $scope.place = place;
      $scope.showEditAppointments = place.configuration.canEditAppointments;
      $scope.placeUTCOffset = moment.tz($scope.place.timezone).format('Z').toString();
      return place;
    });

  $scope.confirmAppointment = () => {
    placePromise.then(place => {
      const checkInMin = get(place, 'configuration.checkIn.min', CHECK_IN_MIN);
      AppointmentService.CheckIn($scope.appointment).then(response => {
        NotificationService.Success($translate.instant('appointment_confirmed_succ'));
      }).catch(err => {
        err.checkInMin = checkInMin;
        const subtractTime = moment.duration(checkInMin, 'm');
        const appointmentTime = moment($scope.appointment.appointment.ISODate).subtract(subtractTime);
        err.comeBackAt = appointmentTime.format('h:mm a');
        ErrorService.handler(err);
      });
    });
  };

  // Modal to enqueue/add user to line
  $scope.editAppointment = () => {
    AreaService.GetOneAsPromise($scope.appointment.areaId).then(area => {
      $uibModal.open({
        templateUrl: '/templates/components/modals/appointment.html',
        size: 'md',
        controller: 'EditAppointmentController',
        resolve: {
          appointment: () => AppointmentService.Copy($scope.appointment),
          area: () => area,
        }
      });
    });
  };

  // Cancel appointment
  $scope.cancelAppointment = () => {
    AppointmentService.Remove($scope.appointment._id).then(() => {
      // Get the appointment to update status.
      $scope.appointment = AppointmentService.GetOne($state.params.id);
      NotificationService.Success($translate.instant('appointment_canceled_succ'));
    }).catch(ErrorService.handler);
  };

  $scope.openForwardModal = appointmentId => {
    SubscriptionService.GetOneByAppointmentId(appointmentId).then(subscription => {
      $uibModal.open({
        templateUrl: '/templates/components/modals/subscription-forward.html',
        size: 'md',
        controller: 'ForwardController',
        resolve: {
          subscription: () => subscription,
          line: () => QueueService.currentQueue,
        }
      }).result.then(()=> {
        $scope.appointment = AppointmentService.GetOneFromServer(appointmentId);
      });
    }).catch(ErrorService.handler)

  };

  $scope.forwardAppointment = (appointmentId, name) => {
    AppointmentService.Forward(appointmentId)
      .then(() => {
        $timeout(() => {
          let name = name || '';
          NotificationService.Success($translate.instant('user_{name}_was_derived_succ').replace('{name}', name));
        });
      })
      .catch(ErrorService.handler);
  };

  $scope.requeueAppointment = appointment => {
    AppointmentService.Requeue(appointment)
      .then(() => {
        NotificationService.Success($translate.instant('requeue_success'), $translate.instant('success'));
      })
      .catch(ErrorService.handler);
  };

  $scope.processAppointment = () => {
    placePromise.then(place => {
      const checkInMin = get(place, 'configuration.checkIn.min', CHECK_IN_MIN);
      AppointmentService.Process($scope.appointment._id).then(response => {
        NotificationService.Success($translate.instant('appointment_confirmed_succ'));
      }).catch(err => {
        err.checkInMin = checkInMin;
        const subtractTime = moment.duration(checkInMin, 'm');
        const appointmentTime = moment($scope.appointment.appointment.ISODate).subtract(subtractTime);
        err.comeBackAt = appointmentTime.format('h:mm a');
        ErrorService.handler(err);
      });
    });
  };

  $scope.requeueSubscription = subscription => {
    SubscriptionService.Requeue(subscription)
      .then(() => {
        NotificationService.Success($translate.instant('requeue_success'), $translate.instant('success'));
      })
      .catch(ErrorService.handler);
  };

  $scope.confirmSubscription = subscription => {
    SubscriptionService.Confirm(subscription)
      .then(() => {
        NotificationService.Success($translate.instant('appointment_confirmed_succ'), $translate.instant('success'));
      })
      .catch(ErrorService.handler);
  };

  const isToday = date => {
    const today = moment().tz($scope.place.timezone).startOf('day').format('YYYY-MM-DD');
    let day = moment(date, 'YYYY-MM-DD', true);
    if (!day.isValid()) {
      day = moment(date, 'DD/MM/YYYY');
    }
    return day.format('YYYY-MM-DD') === today;
  };

  const init = () => {
    $scope.appointment = $state.params.type === 'appointment' ? AppointmentService.GetOneFromServer($state.params.id) : {};
    $scope.subscription = $state.params.type === 'subscription' ? SubscriptionService.GetOneFromServer($state.params.id) : {};
    const date = $state.params.type === 'appointment' ? get($scope, 'appointment.appointment.date', null) : get($scope, 'subscription.subscriptionTime', null);
    $scope.isToday = date ? isToday(date) : true;
  };
  init();

  $scope.seeObservationNotes = appointment => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/appointment-info.html',
      size: 'md',
      controller: 'AppointmentInfoController',
      resolve: {
        appointment: () => appointment,
      }
    });
  };
};

module.exports = module.controller('ReceptionAppointmentConfirmController', AppointmentConfirmController);
