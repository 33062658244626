import angular from 'angular';
import CryptoJS from 'crypto-js';
import 'angular-jwt';
import {
  get
} from 'lodash';
import {
  localStorage
} from '../utils';
import './user';
import '../services/socket';
import '../resources/user';
import '../resources/resource';
import '../app.constants';

const module = angular.module('whyline.services.auth', [
  'angular-jwt',
  'whyline.services.user',
  'whyline.services.socket',
  'whyline.resources.user',
  'whyline.constants',
  'whyline.resources.resource'
]);

let jwtHelper;
let UserService;
let GlobalsService;
let SocketService;
let User;
let Resource;
let $state;
let $http;
let Socket;
let $timeout;
let USER_CACHE_VALIDITY;

class AuthService {

  static $inject = ['jwtHelper', 'UserService', 'GlobalsService', 'User', 'Resource', '$state', '$http', 'Socket', '$timeout', 'SocketService', 'USER_CACHE_VALIDITY'];

  constructor(injectedJwtHelper, injectedUserService, injectedGlobalsService, injectedUser, innjectedResource, injected$state, injected$http, injectedSocket, injected$timeout, injectedSocketService, injectedUSER_CACHE_VALIDITY) {
    jwtHelper = injectedJwtHelper;
    UserService = injectedUserService;
    GlobalsService = injectedGlobalsService;
    User = injectedUser;
    Resource = innjectedResource;
    $state = injected$state;
    $http = injected$http;
    Socket = injectedSocket;
    $timeout = injected$timeout;
    SocketService = injectedSocketService;
    USER_CACHE_VALIDITY = injectedUSER_CACHE_VALIDITY;
  }

  Encrypt(password) {
    return CryptoJS.SHA256(password).toString();
  }

  SignIn(email, password, captchaResponse) {
    return User.SignIn({
      email,
      password: this.Encrypt(password),
      captcha: captchaResponse,
    })
      .then(data => {
        if (data.token && data.token.length) {
          localStorage.set('token', data.token);
        }
        if (data.user && data.user._id) {
          UserService.SetCurrent(data.user);

          localStorage.set('user', data.user);
          localStorage.set('lastUpdate', Date.now());
        }
        return data;
      });
  }

  SignUp(user) {
    return User.SignUp(user);
  }

  SignOut() {
    const user = UserService.GetCurrent();
    if( user ){
      SocketService.GetInstance().then(socket => socket.emit('user:signOut', user));
    }
    UserService.RemoveCurrent();
    localStorage.remove('user');
    localStorage.remove('token');
    $http.pendingRequests.forEach(function (request) {
      if (request.cancel) {
        request.cancel.resolve();
      }
    });
    Resource.RestartCache();
    GlobalsService.SetCurrentPlace();
    return Promise.resolve('unauthenticated');
  }

  IsAuthenticated() {
    const token = localStorage.get('token');
    const user = UserService.GetCurrent();
    return token && !jwtHelper.isTokenExpired(token) && user && user._id;
  }

  IsCoordinator() {
    const placeId = $state.params.placeId;
    let isCoordinator = false;
    if (GlobalsService.CurrentPlace && GlobalsService.CurrentPlace._id) {
      let user = UserService.GetCurrent();
      if (!user) {
        user = this.GetUserFromLocalStorage();
      }

      const placeId = GlobalsService.CurrentPlace._id;
      const roles = get(user, `roles['${placeId}'].roles`, []);
      isCoordinator = roles.indexOf('coordinator') >= 0;
    }
    return isCoordinator;
  }

  CanUseStats() {
    return UserService.CanUseStats();
  }

  GetUserFromLocalStorage() {
    localStorage.get('user').then(user => {
      if (!user || !user._id) { return null; }
      const lastUpdate = localStorage.get('lastUpdate');
      const isValid = Date.now() - lastUpdate < USER_CACHE_VALIDITY;
      if (isValid) {
        UserService.SyncCurrent();
        return user;
      } else {
        return null;
      }
    });

  }

  VerifyAccount(password, token) {
    return User.Verify(
      this.Encrypt(password), token
    ).then(response => {
      const user = response.data;
      if (user && user.local && user.local.email) {
        return this.SignIn(user.local.email, password);
      }
      return false;
    });
  }

  RecoverPassword(email) {
    return User.RecoverPassword(email).then(
      response => response.data
    );
  }

  ResetPassword(password, token, captchaResponse) {
    return User.ResetPassword(this.Encrypt(password), token)
      .then(response => {
        const user = response.data;
        if (user && user.local && user.local.email) {
          return this.SignIn(user.local.email, password, captchaResponse);
        }
        return false;
      });
  }

  ResetPasswordMobil(password, token) {
    return User.ResetPassword(this.Encrypt(password), token)
      .then(response => {
        const user = response.data;
        if (user && user.local && user.local.email) {
          return true;
        }
        return false;
      });
  }

  ChangePassword(oldPassword, newPassword) {
    return User.ChangePassword(
      this.Encrypt(oldPassword), this.Encrypt(newPassword)
    ).then(response => response.data);
  }

  GetToken() {
    return localStorage.get('token');
  }

}

module.exports = module.service('AuthService', AuthService);
