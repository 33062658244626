import angular from 'angular';

const module = angular.module('whyline.modals.appointment-info', [

]);

 const AppointmentInfoController = ($scope, $uibModalInstance, appointment) => {
  'ngInject';

  $scope.appointment = appointment;

   // Close modal
  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller('AppointmentInfoController', AppointmentInfoController);
