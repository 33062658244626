import angular from 'angular';
import moment from 'moment-timezone';
import './resource';
import './line';
import './area';
import './banner';

const module = angular.module('whyline.resources.display', [
  'whyline.resources.resource',
  'whyline.resources.line',
  'whyline.resources.area',
  'whyline.resources.banner',
]);

const DisplayFactory = (Resource, Line, Area, Banner, API_URL, $http) => {
  /*@ngInject*/

  //API REST Resource Name
  const _type = 'display?placeId';

  const _schema = {
    _id: {
      type: String
    },
    placeId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    username: {
      type: String
    },
    password: {
      type: String
    },
    lines: {
      type: [Line],
      required: true,
      default: []
    },
    areas: {
      type: [Area],
      default: []
    },
    banners: {
      type: [Banner],
      default: []
    },
    showAreaName: Boolean,
    hasMultimedia: {
      default: true,
      type: Boolean,
    },
    isCallNext: {
      default: false,
      type: Boolean,
    },
    textToSpeech: {
      default: false,
      type: Boolean
    }
  };

  const Display = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Display.GetDisplayTvData = (displayId, isCallNext) => {
    const startOfDay = moment.utc().startOf('day').format();
    const url = `${API_URL}display/${displayId}/data?placeId`;
    return $http({
      url,
      params: {
        isCallNext,
        from: startOfDay
      },
    })
      .then(response => response.data);
  };

  Display.Identify = displayId => {
    const url = `${API_URL}display/${displayId}/identify?placeId`;
    return $http({
      url,
    })
      .then(response => response.data);
  };

  Display.MinMax = displayId => {
    const url = `${API_URL}display/${displayId}/minmax?placeId`;
    return $http({
      url,
    })
      .then(response => response.data);
  };

  Display.Refresh = displayId => {
    const url = `${API_URL}display/${displayId}/refresh?placeId`;
    return $http({
      url,
    })
      .then(response => response.data);
  };

  Display.prototype = new Resource(_type, _schema, Display);

  Resource.Extend(_type, Display);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Display.InitCache(validityTime);

  return Display;
};

module.exports = module.factory('Display', DisplayFactory);
