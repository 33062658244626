import angular from 'angular';
import { cloneDeep } from 'lodash';
import { Promise } from 'es6-promise';
import '../resources/plan';

const module = angular.module('whyline.services.plan', [
  'whyline.resources.plan',
]);


let Plan;

class PlanService {

  static $inject = ['Plan'];

  constructor(injectedPlan) {
    /*@ngInject*/
    Plan = injectedPlan;
  }

  Create(data) {
    const newPlan = new Plan(data);
    return Plan.Save(newPlan);
  }

  Copy(plan) {
    const copy = cloneDeep(plan);
    return new Plan(copy);
  }

  Switch(plan) {
    return plan.setEnable(!plan.active);
  }

  Update(plan) {
    return Plan.Save(plan);
  }

  Remove(planId) {
    const planToRemove = Plan.FindById(planId);
    return Plan.Remove(planToRemove);
  }

  GetOne(planId) {
    return Plan.FindById(planId);
  }

  GetOneAsPromise(planId) {
    return Plan.FindById(planId, { promise: true });
  }

  GetOneAsPromiseFromServer(planId) {
    return Plan.FindById(planId, { promise: true, force: true });
  }

  GetAllActive() {
    return Plan.FetchAll({ promise: true, force: true, params: { onlyActive: true } });
  }

  GetAll() {
    return Plan.FetchAll();
  }

  GetAllAsPromise() {
    return Plan.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return Plan.FetchAll({ promise: true, force: true });
  }
}

module.exports = module.service('PlanService', PlanService);
