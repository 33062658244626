import angular from 'angular';
import '../services/access-control';
import { svg } from '../utils';

const module = angular.module('whyline.directives.access-control', [
  'whyline.services.access-control',
]);

function getBlockNodes(nodes) {
  // TODO(perf): update `nodes` instead of creating a new object?
  var node = nodes[0];
  var endNode = nodes[nodes.length - 1];
  var blockNodes;

  for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
    if (blockNodes || nodes[i] !== node) {
      if (!blockNodes) {
        blockNodes = window.jqLite(slice.call(nodes, 0, i));
      }
      blockNodes.push(node);
    }
  }

  return blockNodes || nodes;
}

const accessControl = (AccessControlService, $timeout, $parse, $compile, $animate) => {
  return {
    multiElement: true,
    transclude: 'element',
    priority: 1000,
    terminal: true,
    restrict: 'A',
    $$tlb: true,
    link: ($scope, $element, $attr, ctrl, $transclude) => {
      var block;
      var childScope;
      var previousElements;

      if ($attr.accessControlLoader) {
        $element.parent().append(`<div class="pull-right">${svg.sticksLoader}</div>`);
        $compile($element[0].children)($scope);
        $compile($element)($scope);
      }

      // Watch if resourceId changed
      $scope.$watch($attr.resourceid, resourceId => {
        // Ask if the user can do an action
        AccessControlService.CanPerformAction([$attr.accessControl], resourceId)
          .then(response => {
            if ($attr.accessControlLoader && $element[0].nextElementSibling.id !== 'new') {
              $element[0].nextElementSibling.remove();
            }

            if (response) {
              if (!childScope) {
                $transclude(function (clone, newScope) {
                  childScope = newScope;
                  clone[clone.length++] = $compile.$$createComment('end ngIf', $attr.accessControl);
                  // Note: We only need the first/last node of the cloned nodes.
                  // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                  // by a directive with templateUrl when its template arrives.
                  block = {
                    clone
                  };
                  $animate.enter(clone, $element.parent(), $element);
                });
              }
            } else {
              if (previousElements) {
                previousElements.remove();
                previousElements = null;
              }
              if (childScope) {
                childScope.$destroy();
                childScope = null;
              }
              if (block) {
                previousElements = getBlockNodes(block.clone);
                $animate.leave(previousElements).done(function (response) {
                  if (response !== false) previousElements = null;
                });
                block = null;
              }
            }
          });
      });
    }
  };
};

module.exports = module.directive('accessControl', accessControl);
