import angular from 'angular';

const module = angular.module('whyline.constants.api_url', []);

let url;
let apiUrl;
let v1serverUrl;
let v1clientUrl;
let analyticsUrl;
let urlTotemBA;
let adminUrl;
let env;
let attentionUrl = 'https://atencion.filacero.whyline.com/';
let receptionUrl = 'https://recepcion.filacero.whyline.com/';

const urlSplited = window.location.origin.split('.');
urlSplited[0] = `${window.location.protocol}//api-admin`;
url = urlSplited.join('.').replace('.whyline', '.filacero.whyline');
apiUrl = `${url}/api/v1/`;
urlSplited[0] = `${window.location.protocol}//api-virtualine`;
v1serverUrl = `${urlSplited.join('.')}/`;
urlSplited[0] = `${window.location.protocol}//virtualine`;
v1clientUrl = `${urlSplited.join('.')}/`;
urlTotemBA = `https://kiosk-filacero.${urlSplited[1]}.whyline.com/`;


url = 'api-admin.filacero.whyline.com';
analyticsUrl = 'http://analytics.whyline.com';
urlTotemBA = `https://kiosk-filacero.whyline.com/`;
adminUrl = 'https://monitor.filacero.whyline.com/';
env = 'production';






module.constant('API_URL', apiUrl);
module.constant('SOCKET_URL', url);
module.constant('OLD_VERSION_SERVER_URL', v1serverUrl);
module.constant('OLD_VERSION_CLIENT_URL', v1clientUrl);
module.constant('ANALYTICS_CLIENT_URL', analyticsUrl);
module.constant('URL_TOTEM_BA', urlTotemBA);
module.constant('ADMIN_CLIENT_URL', adminUrl);
module.constant('ENV_NAME', env);
module.constant('ATTENTION_URL', attentionUrl);
module.constant('RECEPTION_URL', receptionUrl);

module.exports = module;
