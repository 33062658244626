import angular from 'angular';
import { forEach, includes } from 'lodash';
import '../services/user';
import '../services/globals';
import '../services/role';
import '../services/errors';
import '../services/resource-group';
import './modals/users';

const module = angular.module('whyline.controllers.users', [
  'whyline.services.user',
  'whyline.services.globals',
  'whyline.services.resource-group',
  'whyline.services.role',
  'whyline.services.errors',
  'whyline.modals.user',
]);

const UsersController = (
  $scope,
  $uibModal,
  $window,
  $translate,
  NotificationService,
  UserService,
  RoleService,
  GlobalsService,
  ResourceGroupService,
  ErrorService,
  dialog,
  $state,
  $timeout
) => {
  'ngInject';
  $scope.title = 'Users';
  $scope.quantityOfItems = 8;
  $scope.currentUser = UserService.GetCurrent();
  $scope.placeId = $state.params.placeId;
  $scope.placeholderSearch = GlobalsService.IsMobile()
    ? $translate.instant('search')
    : '';

  UserService.GetAllAsPromise().then(users => {
    forEach(users, user => {
      user.enabled = !includes(user.disabledForPlaces, $scope.placeId);
    });
    $scope.users = users;
  });

  $scope.remove = (userId, user) => {
    let text = $translate
      .instant('user_{name}_remove_sure')
      .replace('{name}', user);
    dialog.confirm(text).then(() => {
      UserService.Remove(userId)
        .then(() => {
          let n = user;
          let text = $translate
            .instant('user_{name}_remove_succ')
            .replace('{name}', n);
          NotificationService.Success(text);
        })
        .catch(ErrorService.handler);
    });
  };

  $scope.switch = function (user) {
    UserService.Switch(user);
  };

  // Modal
  $scope.open = function (userId) {
    RoleService.GetAllAsPromise().then(roles => {
      $timeout(() => {
        $uibModal.open({
          templateUrl: '/templates/components/modals/users-modal.html',
          size: 'lg',
          controller: 'UserModalController',
          controllerAs: 'modal',
          resolve: {
            userId: () => userId,
            placeId: () => GlobalsService.CurrentPlace._id,
            roles: () => roles,
          },
        });
      });
    });
  };
};

module.exports = module.controller('UsersController', UsersController);
