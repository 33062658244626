const PriorityCategories = {
  Disabled: 'disabled',
  Pregnant: 'pregnant',
  Elderly: 'elderly',
  Retained: 'retained',
  Forwarded: 'forwarded',
  Courtesy: 'courtesy',
  Authorized: 'authorized',
  Programmed: 'programmed',
  ProgrammedExpired: 'programmed-expired',
}

module.exports = {
  PriorityCategories
}