import angular from 'angular';
import { forEach } from 'lodash';
import '../../services/schema';
import '../../services/errors';

const module = angular.module('whyline.modals.box', [
  'whyline.services.box',
  'whyline.services.schema',
  'whyline.services.errors',
]);

const BoxModalController = (
  $scope,
  $uibModalInstance,
  $timeout,
  $translate,
  NotificationService,
  BoxService,
  ErrorService,
  boxId,
  placeId,
  dialog,
  SchemaService,
) => {
  'ngInject';

  $scope.sectorsSelected = [];

  if (boxId) {
    BoxService.GetOneAsPromiseFromServer(boxId)
      .then((box) => {
        $timeout(() => {
          $scope.box = BoxService.Copy(box);
          SchemaService.GetAllTopLevelAsPromiseFromServer().then((sectors) => {
            populateSectors($scope.box.sectors, sectors);
            generateAlreadyShown();
          });
        });
      })
      .catch(ErrorService.handler);
  } else {
    $scope.box = {
      name: '',
      display: '',
      enabled: true,
      placeId,
      sectors: [],
    };
  }

  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.save = () => {
    if (validate()) {
      if (boxId) {
        dialog
          .confirm($translate.instant('box_edit_warning'))
          .then(() =>
            BoxService.Update($scope.box).then(() => {
              $timeout(() => {
                NotificationService.Success($translate.instant('box_edit_succ'));
                $uibModalInstance.close('cancel');
              });
            }),
          )
          .catch(ErrorService.handler)
          .catch(() => 'canceled');
      } else {
        return BoxService.Create($scope.box)
          .then(() => {
            $timeout(() => {
              NotificationService.Success($translate.instant('box_create_succ'));
              $uibModalInstance.close('cancel');
            });
          })
          .catch(ErrorService.handler);
      }
    }
  };

  const validate = () => {
    const name = $scope.box.name;

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_box_name'));
      return false;
    }

    if (name.length < 1 || name.length > 56) {
      NotificationService.Warning($translate.instant('invalid_box_name'));
      return false;
    }

    if (
      !/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(
        name,
      )
    ) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }

    return true;
  };

  $scope.selectSector = (sector) => {
    $scope.sectorsSelected.push(sector);
    $scope.box.sectors.push(sector._id);
    generateAlreadyShown();
  };

  $scope.remove = (id) => {
    const sectorIndex = $scope.box.sectors.indexOf(id);
    if (sectorIndex >= 0) {
      $scope.box.sectors.splice(sectorIndex, 1);
      $scope.sectorsSelected.splice(sectorIndex, 1);
      generateAlreadyShown();
    }
  };

  const generateAlreadyShown = () => {
    $scope.alreadyShown = {};
    forEach($scope.sectorsSelected, (sector) => {
      if (sector) {
        $scope.alreadyShown[sector._id] = sector;
      }
    });
  };

  const populateSectors = (boxSectors, placeSectors) => {
    forEach(boxSectors, (sector) => {
      Object.values(placeSectors).forEach((ps) => {
        const isAlreadyExists = $scope.sectorsSelected.find((sc) => sc && sc._id === sector);
        let box = null;
        if (!isAlreadyExists) {
          if (ps._id === sector) {
            box = ps;
          } else {
            box = ps.tree.find((tree) => tree._id === sector);
          }
          if (!box) {
            return;
          }
          $scope.sectorsSelected.push(box);
        }
      });
    });
  };
};

module.exports = module.controller('BoxModalController', BoxModalController);
