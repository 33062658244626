import angular from 'angular';
import './modals/lines';
import '../components/status';
import '../services/line';
import '../services/errors';
import '../services/notifications';
import '../services/globals';

const module = angular.module('whyline.controllers.lines', [
  'whyline.components.status',
  'whyline.services.line',
  'whyline.services.errors',
  'whyline.services.notifications',
  'whyline.modals.line',
  'whyline.services.globals',
]);

const LinesController = ($scope, $window, $timeout, $translate, $uibModal, GlobalsService, NotificationService, LineService, ErrorService, dialog, currentPlace) => {
  /*@ngInject*/
  $scope.title = 'Lines';
  $scope.quantityOfItems = 8;
  $scope.lines = LineService.GetAll();
  $scope.switch = LineService.Switch;
  //$scope.clear = LineService.Clear;
  $scope.showDisplayWarning = currentPlace.enterpriseType !== 'ba';
  $scope.plan = {};
  $scope.plan.maxLines = currentPlace.plan && currentPlace.plan.maxLines ? currentPlace.plan.maxLines : 5;
  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';  
  $scope.clear = line => {
    let n = line.label || '';
    let clean_sure_text = $translate.instant('line_{name}_clean_sure').replace('{name}', n);
    let total_waiting_text = $translate.instant('total_waiting_{number}_text').replace('{number}', line.waitingPeople);

    dialog.confirm(`${clean_sure_text} ${total_waiting_text}`)
      .then(() => {
        LineService.Clear(line)
          .catch(err => {
            ErrorService.handler(err);
          });
      });
  };

  $scope.remove = (lineId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('line_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        LineService.Remove(lineId)
          .then(() => {
            NotificationService.Success($translate.instant('line_remove_succ'));
            if($scope.showDisplayWarning){
              NotificationService.Warning($translate.instant('Please remember to refresh your display for your changes to take effect'));
            }

            $scope.lines = LineService.GetAll();
          })
          .catch(err => {
            $scope.lines = LineService.GetAllFromServer();
            ErrorService.handler(err);
          });
      });
  };

  // Modal
  $scope.open = lineId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/lines-modal.html',
      size: 'lg',
      controller: 'LineModalController',
      resolve: {
        lineId: () => lineId,
        currentPlace,
      }
    }).result.then(()=>{
      if ($scope.showDisplayWarning) {
        NotificationService.Warning($translate.instant('Please remember to refresh your display for your changes to take effect'));
      }
    });
  };
};

module.exports = module.controller('LinesController', LinesController);
