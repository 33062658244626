import angular from 'angular';
import {
  toArray,
  forEach,
  countBy
} from 'lodash';
import {
  formatAwt
} from '../../utils';
import '../modals/reception-enqueue';
import '../modals/info';
import '../../services/line';
import '../../app.constants';
import '../../services/area';
import '../../services/reception';
import '../../services/subscription';
import '../../services/globals';
import '../../services/errors';
import '../../services/queue';
import '../../services/notifications';
import '../../components/operator';
import '../../components/status';

const module = angular.module('whyline.controllers.reception-status', [
  'whyline.constants',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.reception',
  'whyline.services.subscription',
  'whyline.services.globals',
  'whyline.services.errors',
  'whyline.services.queue',
  'whyline.services.notifications',
  'whyline.modals.reception-enqueue',
  'whyline.modals.info',
  'whyline.components.operator',
  'whyline.components.status',
]);

const ReceptionStatusController = (SAFE_STATES, $scope, $state, $timeout, $interval, $uibModal, LineService, AreaService, ReceptionService, SubscriptionService, GlobalsService, ErrorService, dialog, NotificationService, QueueService, $translate, PlaceService, OrganizationService) => {
  /*@ngInject*/

  // Init values
  $scope.quantityOfItems = 8;
  $scope.subscriptions = {};
  $scope.subscriptionsAmount = {};
  $scope.currentQueue = {};
  $scope.permissions = {
    add: true,
  };
  let enqueueIn = {};

  // Remove filters
  $scope.filterData = {
    showFilters: false,
    selectedFilter: 'total',
  };

  // Set buttons statuses
  if(!$state.params.rejoining) {
    ReceptionService.SetButtonStatus('both', true);
    ReceptionService.SetButtonStatus('status-text', true);
  } else {
    ReceptionService.SetButtonStatus('forward', true);
    ReceptionService.SetButtonStatus('back', false);
    ReceptionService.SetButtonStatus('status-text', true);
  }

  // Get full path
  const path = ReceptionService.GetPath();

  // Check if path existis, else return to first step
  if (!path.length) {
    return $state.go(SAFE_STATES.receptionStep, {
      n: 0
    });
  }

  // Get the last queue selected
  const selectedQueue = path[path.length - 1];
  const previousQueue = path.length > 1 && selectedQueue.realType === 'line' ? path[path.length - 2] : undefined;

  // Enqueue with this data
  if (selectedQueue.realType === 'process') {
    enqueueIn = {
      where: selectedQueue.realType,
      lineId: undefined,
      areaId: selectedQueue._id,
    };
  } else if (selectedQueue.realType === 'line' && !previousQueue || selectedQueue.realType === 'line' && previousQueue && previousQueue.realType !== 'group') {
    enqueueIn = {
      where: selectedQueue.realType,
      lineId: selectedQueue._id,
      areaId: undefined,
    };
  } else if (previousQueue && previousQueue.realType === 'group') {
    enqueueIn = {
      where: previousQueue.realType,
      lineId: selectedQueue._id,
      areaId: previousQueue._id,
    };
  } else if (selectedQueue.realType === 'supervisor') {
    enqueueIn = {
      where: 'line',
      lineId: selectedQueue._id,
      areaId: undefined,
    };
  }

  switch (enqueueIn.where) {
    case 'line':
      LineService.GetOneAsPromise(enqueueIn.lineId)
        .then(line => {
          $timeout(() => {
            // $scope.queueStatus = line;
            $scope.currentQueue = line;
            $scope.subscriptions = SubscriptionService.GetAllByQueue(line);
            $scope.showStatusView = true;
            ReceptionService.SetEnqueueIn(enqueueIn);
          });
        });
      break;
    case 'process':
      AreaService.GetOneAsPromise(enqueueIn.areaId)
        .then(process => {
          LineService.GetOneAsPromise(process.lines[0])
            .then(line => {
              $timeout(() => {
                // $scope.queueStatus = process;
                $scope.currentQueue = AreaService.Copy(process);
                $scope.currentQueue.waitingPeople = line.waitingPeople;
                $scope.queueLine = line;
                $scope.subscriptions = SubscriptionService.GetAllByQueue(line);
                $scope.showStatusView = true;
                ReceptionService.SetEnqueueIn(enqueueIn);
              });
            });
        });
      break;
    case 'group':
      const getArea = AreaService.GetOneAsPromise(enqueueIn.areaId);
      const getLine = LineService.GetOneAsPromise(enqueueIn.lineId);
      Promise.all([getArea, getLine])
        .then(queues => {
          $timeout(() => {
            const group = queues[0];
            const line = queues[1];
            // $scope.queueStatus = group;
            $scope.currentQueue = group;
            $scope.queueLine = line;
            $scope.subscriptions = SubscriptionService.GetAllByQueue(group);
            $scope.showStatusView = true;
            ReceptionService.SetEnqueueIn(enqueueIn);
          });
        });
      break;
  }

  /**
   * Modals
   */
  // Modal to show user (subscription) info
  $scope.info = subscriptionId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/subscription-modal.html',
      size: 'md',
      controller: 'InfoController',
      resolve: {
        // don't replace with 'short-hand'!
        subscriptionId: () => subscriptionId,
        currentPlaceId: () => GlobalsService.CurrentPlace._id
      }
    });
  };

  // Modal to enqueue/add user to line
  $scope.openEnqueueModal = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/reception-enqueue.html',
      size: 'md',
      controller: 'ReceptionEnqueueController',
      resolve: {
        enqueueIn: () => enqueueIn,
        path: () => path,
        rejoining: () => $state.params.rejoining,
        currentPlace: () => PlaceService.GetOneAsPromise(GlobalsService.CurrentPlace._id),
        currentOrganization: () => OrganizationService.GetCurrent(),
      }
    });
  };

  // REFACTORED// Remove subscription
  $scope.removeSubscription = (subscriptionId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('list_remove_{name}_sure').replace('{name}', n))
      .then(() => {
        SubscriptionService.Remove(subscriptionId, selectedQueue)
          .then(() => {
            NotificationService.Success($translate.instant('list_{name}_remove_succ').replace('{name}', n));
          })
          .catch(ErrorService.handler);
      })
      .catch(res => res);
  };

  $scope.$watch('queueLine', newVal => {
      if ($scope.currentQueue.realType === 'process') {
        $scope.currentQueue.waitingPeople = newVal.waitingPeople;
      }
    },
    true);

  // Create watcher for subscriptions
  $scope.$watch('subscriptions', newVal => {
      getSubscriptionsAmount(newVal);
    },
    true);

  const getSubscriptionsAmount = subscriptions => {
    forEach(subscriptions, (value, key) => {
      if (value.status === 'canceled' && value.reason === 'queue-clear') {
        Reflect.deleteProperty(subscriptions, key);
      }
    });
    const amount = countBy(subscriptions, 'status');
    $scope.subscriptionsAmount.waiting = amount.waiting || 0;
    $scope.subscriptionsAmount.done = (amount.completed || 0) + (amount.canceled || 0);
  };
};

module.exports = module.controller('ReceptionStatusController', ReceptionStatusController);
