import angular from 'angular';
import { includes, get } from 'lodash';
import '../../services/device';
import '../../services/place';
import '../../resources/device';

const module = angular.module('whyline.modals.devices', [
  'whyline.components.status',
  'whyline.services.device',
  'whyline.services.place',
  'whyline.resources.device'
]);


const DeviceModalController = ($scope, $timeout, $uibModalInstance, $translate, NotificationService, DeviceService, PlaceService, deviceId, Device) => {
    'ngInject';
    $scope.devices = {
      name: '',
      alias: '',
      id: 0
    }
    $scope.device = {
        name: '',
        alias: ''
    };
    $scope.enableCombo = deviceId ? false : true;
    if(deviceId){
        DeviceService.GetById(deviceId).then(data => {
            $scope.devices = data;
            $scope.device.alias = data.alias;
        })
    }
    const place = PlaceService.GetCurrent();
    const dismissOnError = err => {
        if ([401, 403, 404].indexOf(err.status) >= 0) {
          $uibModalInstance.close('cancel');
        }
      };
      const errHandler = err => {
        if(err.status === 404){
          NotificationService.Error($translate.instant('device_not_found'));
        } else if(err.status === 409){
          if(err.data.message === 'device.already-linked'){
            NotificationService.Error($translate.instant('device_repeated'));
          } else if(err.data.message === 'device.alias-already-exists'){
            NotificationService.Error($translate.instant('alias_repeated'));
          }
          
        } else if(err.status === 400){
         if(err.data.message.includes('["alias" is not allowed to be empty]')){
          NotificationService.Error($translate.instant('empty_alias'));
         } else if(err.data.message.includes('["deviceId" is not allowed to be empty]')){
          NotificationService.Error($translate.instant('empty_serial'));
         }
        }
      };

    $scope.cancel = () => {
        $uibModalInstance.close('cancel');
      };

    $scope.save = () => {
        if(deviceId){
          $scope.devices.alias = $scope.device.alias;
            return DeviceService.Update($scope.devices)
            .then(() => {
              NotificationService.Success($translate.instant('device_rename_succes'));
              $uibModalInstance.close('cancel');
            })
            .catch(err => errHandler(err));
        } else {
            return DeviceService.LinkToPlace($scope.device)
            .then(() => {
              NotificationService.Success($translate.instant('device_linked_succes'));
              $uibModalInstance.close('cancel');
            })
            .catch(err => errHandler(err));
        }
        
    }

    

      
};

module.exports = module.controller('DeviceModalController', DeviceModalController);
