import angular from 'angular';

const module = angular.module('whyline.directives.preventScroll', []);

const preventScroll = () => {
  return {
    restrict: 'A',
    link: (scope, element) => {
      element.bind('mousewheel DOMMouseScroll', function(e) {
        var e0 = e;//.originalEvent,
        var delta = e0.wheelDelta || -e0.detail;

        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      });
    }
  };
};
module.exports = module.directive('preventScroll', preventScroll);
