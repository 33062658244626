import angular from 'angular';
import '../factories/socket.io';
import '../resources/organization-user';
import '../resources/line';
import '../resources/area';
import '../services/notifications';
import '../services/user';
import '../services/socket';
import '../services/organization';
import '../app.constants';
import { includes } from 'lodash';

const module = angular.module('whyline.sockets.organizationuser', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.organizationuser',
  'whyline.resources.line',
  'whyline.resources.area',
  'whyline.services.notifications',
  'whyline.services.user',
  'whyline.services.socket',
  'whyline.services.organization',
]);

const OrganizationUserSocket = (Socket, OrganizationUser, blockUI, NotificationService, SAFE_STATES, $state, UserService, $rootScope, SocketService, Line, Area, OrganizationService, $translate) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('organizationUser:created', user => {
        user.enabled = !includes(user.disabledForOrganizations, OrganizationService.CurrentOrganization ? OrganizationService.CurrentOrganization._id : null);
        OrganizationUser.UpdateCache(user._id, user);
      });
      socket.on('organizationUser:updated', user => {
        OrganizationUser.UpdateCache(user._id, user);
      });
      socket.on('organizationUser:deleted', user => {
        OrganizationUser.RemoveFromCache(user._id, user._type);
      });
    });
  });
};

module.exports = module.factory('OrganizationUserSocket', (Socket, OrganizationUser, blockUI, NotificationService, SAFE_STATES, $state, UserService, $rootScope, SocketService, Line, Area, OrganizationService, $translate) => new OrganizationUserSocket(Socket, OrganizationUser, blockUI, NotificationService, SAFE_STATES, $state, UserService, $rootScope, SocketService, Line, Area, OrganizationService, $translate));
