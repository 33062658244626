import angular from 'angular';
import {
  get
} from 'lodash';
import moment from 'moment-timezone';
import '../../../app.constants';
import '../../../services/appointment';
import '../../../services/totem-tv';
import '../../../services/place';
import '../../../services/area';
import '../../../services/line';

const module = angular.module('whyline.controllers.totem-tv.appointment-confirm', [
  'whyline.constants',
  'whyline.services.appointment',
  'whyline.services.totem-tv',
  'whyline.services.place'
]);

const AppointmentConfirmController = (SAFE_STATES, $scope, $state, $timeout, ErrorService, AppointmentService, TotemTvService, PlaceService, LineService, AreaService) => {
  /*@ngInject*/

  // Controls
  TotemTvService.SetButtonStatus('both', false);
  TotemTvService.SetButtonStatus('browserBack', false);
  TotemTvService.SetButtonStatus('status-text', true);

  $scope.appointment = AppointmentService.GetOne($state.params.appointmentId);

  const CHECK_IN_MIN = 15;
  const placePromise = PlaceService.GetCurrentAsPromise();
  $scope.placeUTCOffset = moment.tz($scope.place.timezone).format('Z').toString();

  const setPostMessage = () => {
    const confirmType = {
      type: get($scope.appointment, 'areaId') ? 'process' : 'line',
      id: get($scope.appointment, 'areaId') || get($scope.appointment, 'lineId')
    };
    switch (confirmType.type) {
      case 'line':
        LineService.GetOneAsPromiseFromServer(confirmType.id)
          .then(line => {
            $timeout(() => {
              TotemTvService.SetPostMessage(line.configuration.messages.postCheckIn);
              $state.go(SAFE_STATES.totemTvThanks, {
                name: $scope.appointment.person.firstName
              });
            });
          });
      break;
      case 'process':
        AreaService.GetOneAsPromise(confirmType.id)
          .then(process => {
            $timeout(() => {
              TotemTvService.SetPostMessage(process.configuration.postMessage);
              $state.go(SAFE_STATES.totemTvThanks, {
                name: $scope.appointment.person.firstName
              });
            });
          });
      break;
      }
  }

  $scope.confirmAppointment = () => {
    placePromise.then(place => {
      const checkInMin = get(place, 'configuration.checkIn.min', CHECK_IN_MIN);
      AppointmentService.CheckIn($scope.appointment).then(response => {
        setPostMessage();
      }).catch(err => {
        err.checkInMin = checkInMin;
        const subtractTime = moment.duration(checkInMin, 'm');
        const appointmentTime = moment($scope.appointment.appointment.ISODate).subtract(subtractTime);
        err.comeBackAt = appointmentTime.format('h:mm a');
        ErrorService.handler(err);
        $timeout(() => {
          $state.go(SAFE_STATES.totemTvWelcome);
        }, 10000);
      });
    });
  };
};

module.exports = module.controller('TotemTvAppointmentConfirmController', AppointmentConfirmController);
