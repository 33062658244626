import angular from 'angular';
import { sortBy, toArray, filter, forEach, extend } from 'lodash';
import moment from 'moment-timezone';
import '../../services/area';
import '../../services/notifications';
import '../../services/place';
import '../../services/errors';
import '../../services/date-handler';
import '../modals/holiday';
import '../modals/holiday-info';

const module = angular.module('whyline.controllers.schedule-holidays', [

  'whyline.services.area',
  'whyline.services.holiday',
  'whyline.modals.holiday-info',
  'whyline.modals.holiday',
  'whyline.services.notifications',
  'whyline.services.errors',
  'whyline.services.place',
  'whyline.services.date-handler',
]);

const ScheduleHolidaysController = ($scope, $uibModal, $translate, $timeout, AreaService, HolidayService, ErrorService, PlaceService, NotificationService, dialog) => {
  /*@ngInject*/

  $scope.place = PlaceService.GetCurrent();
  $scope.showHolidaysLoader = false;
  $scope.showNoHolidaysCircle = false;
  $scope.holidays = [];
  $scope.format = $translate.use() === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

  // Filters
  $scope.filters = {
    filterType: 'all',
    areas: [],
    selected: {
      id: null,
      label: null
    }
  };

  $scope.areasSettings = {
    template: '<translate>{{option.label}}</translate>',
    externalIdProp: '',
    displayProp: 'label',
    idProp: '_id',
    searchField: 'label',
    enableSearch: true,
    showCheckAll: false,
    showUncheckAll: false,
    keyboardControls: true,
    selectedToTop: true,
    scrollable: true,
    selectionLimit: 1,
    groupByTextProvider: groupValue => {
      if (groupValue) {
        return $translate.instant('filters');
      } else {
        return $translate.instant('service');
      }
    },
    groupBy: 'group',
  };

  $scope.changeSelectedArea = {
    onItemSelect: item => {
      applyFilters(item);
    }
  };

  const init = () => {
    const areasPromise = AreaService.GetAllAsPromise();
    const placePromise = PlaceService.GetCurrentAsPromise();
    const holidayPromise = HolidayService.GetAll();

    Promise.all([areasPromise, placePromise, holidayPromise])
      .then(data => {
          $timeout(() => {
            const areasList = sortBy(toArray(filter(data[0], area => area.appointmentsConfiguration.enabled)), ['label']);
            $scope.place = data[1];
            $scope.placeUTCOffset = moment.tz(data[1].timezone).format('Z').toString();
            setAreasOptions(areasList);
            $scope.holidays = data[2];
            $scope.showNoHolidaysCircle = !data[2].length;
          });
      })
      .catch(ErrorService.handler);
  };

  const setAreasOptions = areas => {
    $scope.areasList = areas.map(area => extend({filterType: 'service', group: false }, area));
    $scope.areasList.push({ filterType: 'onlyShared', label: $translate.instant('general_holidays'), group: true });
    $scope.areasList.push({ filterType: 'all', label: $translate.instant('all_services'), group: true });
  };

  const applyFilters = item => {
    $scope.filters.filterType = $scope.filters.areas[0].filterType;
    if ($scope.filters.areas[0].filterType === 'service') {
      $scope.filters.selected.id = $scope.filters.areas[0]._id;
      $scope.filters.selected.label = $translate.instant('included_{service}').replace('{service}', $scope.filters.areas[0].label);
    } else {
      $scope.filters.selected.id = null;
      $scope.filters.selected.label = null;
    }
  };

  $scope.filterHolidays = item => {
    if (item) {
      if ($scope.filters.filterType === 'all') {
        return true;
      } else if ($scope.filters.filterType === 'onlyShared') {
        return item.applyToAll;
      } else {
        return item.applyToAll || item.areas.find(area => area._id === $scope.filters.selected.id);
      }
    }
  };

  $scope.seeHolidayInfo = holiday => {
    $uibModal.open({
      templateUrl: '../../../templates/components/modals/holiday-info.html',
      size: 'md',
      controller: 'HolidayInfoController',
      resolve: {
        holiday: () => holiday,
      }
    });
  };

  $scope.deleteHoliday = holiday => {
    let description = holiday.description || '';
    dialog.confirm($translate.instant('holiday_{description}_remove_sure').replace('{description}', description))
      .then(() => {
        HolidayService.Remove(holiday._id)
          .then(() => {
            description = `<span class="bold">${description || ''}</span>`;
            NotificationService.Success($translate.instant('holiday_{description}_remove_succ').replace('{description}', description));
          })
          .catch(ErrorService.handler);
      });
  };

  $scope.editHoliday = holiday => {
    $uibModal.open({
      templateUrl: '../../../templates/components/modals/holiday.html',
      size: 'md',
      controller: 'HolidayController',
      resolve: {
        holiday: () => holiday
      }
    });
  };

  init();
};

module.exports = module.controller('ScheduleHolidaysController', ScheduleHolidaysController);
