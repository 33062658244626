import angular from 'angular';
import '../resources/appointment-reservation';

const module = angular.module('whyline.services.appointment-reservation', [
  'whyline.services.globals',
  'whyline.resources.appointment-reservation',
]);

let API_URL;
let AppointmentReservation;

class AppointmentReservationService {

  static $inject = ['API_URL', 'AppointmentReservation'];

  constructor(injAPI, injectedAppointmentReservation) {
    API_URL = injAPI;
    AppointmentReservation = injectedAppointmentReservation;
  }

  createAppointmentReservation(placeId, areaId, person, day, slot, notes, createdFrom) {
    return AppointmentReservation.createAppointmentReservation(placeId, areaId, person, day, slot, notes, createdFrom);
  }
}

export default module.service('AppointmentReservationService', AppointmentReservationService);
