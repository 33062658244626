import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.plan', [
  'whyline.resources.resource',
]);

const PlanFactory = ($timeout, $http, API_URL, Resource) => {
  /*@ngInject*/
  // API REST Resource Name
  const _type = 'plan?placeId';
  const apiEndPoint = `${API_URL}${_type}`;

  const _schema = {
    planId: {
      type: String
    },
    hasLimits: Boolean,
    maxAreas: {
      type: Number,
      required: true
    },
    maxDisplays: {
      type: Number,
      required: true
    },
    maxLines: {
      type: Number,
      required: true
    },
    maxSectors: {
      type: Number,
      required: true
    },
    maxTotems: {
      type: Number,
      required: true
    },
    maxVideos: {
      type: Number,
      required: true
    },
    maxPlaces: {
      type: Number,
      required: true
    },
    maxUsers: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    reception: {
      type: Boolean,
      default: true
    },
    type: String
  };

  const Plan = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.setEnable = enabled => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });

      promise.then(
        response => {
          const item = response.data;

          $timeout(() => {
            // Cuando halla respuesta, modificar el cache
            Plan.UpdateCache(item._id, item, null);
          });

          // Devuelve la respuesta desde el cache.
          return Plan.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  Plan.prototype = new Resource(_type, _schema, Plan);

  Resource.Extend(_type, Plan);

  //one hour
  const validityTime = 60 * 60 * 5000;
  Plan.InitCache(validityTime);

  return Plan;
};

module.exports = module.factory('Plan', PlanFactory);
