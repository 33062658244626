/*eslint-disable */
module.exports = ($provide, $translateProvider) => {

  var lang = $translateProvider
              .resolveClientLocale()
              .substr(0, 2);

  if(lang === 'es') {
    $provide.value("$locale", {
      "DATETIME_FORMATS": {
        "AMPMS": [
          "a. m.",
          "p. m."
        ],
        "DAY": [
          "domingo",
          "lunes",
          "martes",
          "mi\u00e9rcoles",
          "jueves",
          "viernes",
          "s\u00e1bado"
        ],
        "ERANAMES": [
          "antes de Cristo",
          "despu\u00e9s de Cristo"
        ],
        "ERAS": [
          "a. C.",
          "d. C."
        ],
        "FIRSTDAYOFWEEK": 6,
        "MONTH": [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre"
        ],
        "SHORTDAY": [
          "dom.",
          "lun.",
          "mar.",
          "mi\u00e9.",
          "jue.",
          "vie.",
          "s\u00e1b."
        ],
        "SHORTMONTH": [
          "ene.",
          "feb.",
          "mar.",
          "abr.",
          "may.",
          "jun.",
          "jul.",
          "ago.",
          "sep.",
          "oct.",
          "nov.",
          "dic."
        ],
        "STANDALONEMONTH": [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre"
        ],
        "WEEKENDRANGE": [
          5,
          6
        ],
        "fullDate": "EEEE, d 'de' MMMM 'de' y",
        "longDate": "d 'de' MMMM 'de' y",
        "medium": "d MMM y HH:mm:ss",
        "mediumDate": "d MMM y",
        "mediumTime": "HH:mm:ss",
        "short": "d/M/yy HH:mm",
        "shortDate": "d/M/yy",
        "shortTime": "HH:mm"
      },
      "NUMBER_FORMATS": {
        "CURRENCY_SYM": "$",
        "DECIMAL_SEP": ",",
        "GROUP_SEP": ".",
        "PATTERNS": [
          {
            "gSize": 3,
            "lgSize": 3,
            "maxFrac": 3,
            "minFrac": 0,
            "minInt": 1,
            "negPre": "-",
            "negSuf": "",
            "posPre": "",
            "posSuf": ""
          },
          {
            "gSize": 3,
            "lgSize": 3,
            "maxFrac": 2,
            "minFrac": 2,
            "minInt": 1,
            "negPre": "-\u00a4\u00a0",
            "negSuf": "",
            "posPre": "\u00a4\u00a0",
            "posSuf": ""
          }
        ]
      },
      "id": "es-ar",
      "localeID": "es_AR",
      "pluralCat": function(n, opt_precision) {  if (n == 1) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
    })
  }

  if(lang === 'en') {
    $provide.value("$locale", {
      "DATETIME_FORMATS": {
        "AMPMS": [
          "AM",
          "PM"
        ],
        "DAY": [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        "ERANAMES": [
          "Before Christ",
          "Anno Domini"
        ],
        "ERAS": [
          "BC",
          "AD"
        ],
        "FIRSTDAYOFWEEK": 6,
        "MONTH": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        "SHORTDAY": [
          "Sun",
          "Mon",
          "Tue",
          "Wed",
          "Thu",
          "Fri",
          "Sat"
        ],
        "SHORTMONTH": [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        "STANDALONEMONTH": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        "WEEKENDRANGE": [
          5,
          6
        ],
        "fullDate": "EEEE, MMMM d, y",
        "longDate": "MMMM d, y",
        "medium": "MMM d, y h:mm:ss a",
        "mediumDate": "MMM d, y",
        "mediumTime": "h:mm:ss a",
        "short": "M/d/yy h:mm a",
        "shortDate": "M/d/yy",
        "shortTime": "h:mm a"
      },
      "NUMBER_FORMATS": {
        "CURRENCY_SYM": "$",
        "DECIMAL_SEP": ".",
        "GROUP_SEP": ",",
        "PATTERNS": [
          {
            "gSize": 3,
            "lgSize": 3,
            "maxFrac": 3,
            "minFrac": 0,
            "minInt": 1,
            "negPre": "-",
            "negSuf": "",
            "posPre": "",
            "posSuf": ""
          },
          {
            "gSize": 3,
            "lgSize": 3,
            "maxFrac": 2,
            "minFrac": 2,
            "minInt": 1,
            "negPre": "-\u00a4",
            "negSuf": "",
            "posPre": "\u00a4",
            "posSuf": ""
          }
        ]
      },
      "id": "en-us",
      "localeID": "en_US",
      "pluralCat": function(n, opt_precision) {  var i = n | 0;  var vf = getVF(n, opt_precision);  if (i == 1 && vf.v == 0) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
    });
  }

  if(lang === 'pt') {
    var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
    $provide.value("$locale", {
      "DATETIME_FORMATS": {
        "AMPMS": [
          "AM",
          "PM"
        ],
        "DAY": [
          "domingo",
          "segunda-feira",
          "ter\u00e7a-feira",
          "quarta-feira",
          "quinta-feira",
          "sexta-feira",
          "s\u00e1bado"
        ],
        "ERANAMES": [
          "Antes de Cristo",
          "Ano do Senhor"
        ],
        "ERAS": [
          "a.C.",
          "d.C."
        ],
        "FIRSTDAYOFWEEK": 6,
        "MONTH": [
          "janeiro",
          "fevereiro",
          "mar\u00e7o",
          "abril",
          "maio",
          "junho",
          "julho",
          "agosto",
          "setembro",
          "outubro",
          "novembro",
          "dezembro"
        ],
        "SHORTDAY": [
          "dom",
          "seg",
          "ter",
          "qua",
          "qui",
          "sex",
          "s\u00e1b"
        ],
        "SHORTMONTH": [
          "jan",
          "fev",
          "mar",
          "abr",
          "mai",
          "jun",
          "jul",
          "ago",
          "set",
          "out",
          "nov",
          "dez"
        ],
        "STANDALONEMONTH": [
          "janeiro",
          "fevereiro",
          "mar\u00e7o",
          "abril",
          "maio",
          "junho",
          "julho",
          "agosto",
          "setembro",
          "outubro",
          "novembro",
          "dezembro"
        ],
        "WEEKENDRANGE": [
          5,
          6
        ],
        "fullDate": "EEEE, d 'de' MMMM 'de' y",
        "longDate": "d 'de' MMMM 'de' y",
        "medium": "d 'de' MMM 'de' y HH:mm:ss",
        "mediumDate": "d 'de' MMM 'de' y",
        "mediumTime": "HH:mm:ss",
        "short": "dd/MM/yy HH:mm",
        "shortDate": "dd/MM/yy",
        "shortTime": "HH:mm"
      },
      "NUMBER_FORMATS": {
        "CURRENCY_SYM": "R$",
        "DECIMAL_SEP": ",",
        "GROUP_SEP": ".",
        "PATTERNS": [
          {
            "gSize": 3,
            "lgSize": 3,
            "maxFrac": 3,
            "minFrac": 0,
            "minInt": 1,
            "negPre": "-",
            "negSuf": "",
            "posPre": "",
            "posSuf": ""
          },
          {
            "gSize": 3,
            "lgSize": 3,
            "maxFrac": 2,
            "minFrac": 2,
            "minInt": 1,
            "negPre": "-\u00a4",
            "negSuf": "",
            "posPre": "\u00a4",
            "posSuf": ""
          }
        ]
      },
      "id": "pt-br",
      "localeID": "pt_BR",
      "pluralCat": function(n, opt_precision) {  if (n >= 0 && n <= 2 && n != 2) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
    });
  }
}