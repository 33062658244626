import angular from 'angular';

const module = angular.module('whyline.components.stats.line', []);

const lineComponent = {
    bindings: {
        line: '<',
        statuses:'<'
    },
    templateUrl: 'templates/views/stats/line.html',
    controller($scope) {
        'ngInject';
        $scope.showDetails = false;
    }
};

export default module.component('line', lineComponent);
