import angular from 'angular';
import '../resources/sector';

const module = angular.module('whyline.services.sector', [
  'whyline.resources.sector',
]);

let Sector;

class SectorService {
  static $inject = ['Sector'];

  constructor(injectedSector) {
    Sector = injectedSector;
  }

  GetAllAsPromise() {
    return Sector.GetAllPlaceSectors();
  }

  Update(placeSectors) {
    return Sector.SavePlaceSectors(placeSectors);
  }
}

module.exports = module.service('SectorService', SectorService);
