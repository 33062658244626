import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.category', ['whyline.resources.resource']);

const CategoryFactory = (Resource, $http, API_URL) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'category';

  const _schema = {
    _id: {
      type: String
    },
    name_es: {
      type: String
    },
    name_en: {
      type: String
    },
    name_pt: {
      type: String
    },
    key: {
      type: String
    }
  };

  const Category = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Category.prototype = new Resource(_type, _schema, Category);

  Resource.Extend(_type, Category);

  const validityTime = 10 * 60 * 1000;

  Category.InitCache(validityTime);

  Category.GetAllCategories = () => {
    const url = `${API_URL}category`;
    return $http.get(url).then(response => response.data);
  };

  return Category;
};

module.exports = module.factory('Category', CategoryFactory);
