const options = [
  {
    'key': 'organization',
    'val': 'only organization',
    onlyOrganizations: true,
  },
  {
    'key': 'place',
    'val': 'only place',
    onlyPlace: true,
  },
  {
    'key': 'placeAndLines',
    'val': 'place and lines',
    placeLines: true,
  },
  {
    'key': 'all',
    'val': 'all the places',
    all: true,
  },
  {
    'key': 'orgPlacesLines',
    'val': 'all the places and lines',
    orgPlacesLines: true,
  }
];

module.exports = {
  options
};
