import angular from 'angular';
import { cloneDeep, every, findIndex, get, map, orderBy, remove } from 'lodash';
import '../services/globals';
import '../services/sector';
import '../services/organization-sector';
import '../services/organization-area';
import '../services/organization-line';
import '../services/notifications';
import '../services/errors';

const module = angular.module('whyline.controllers.sectors', [
  'whyline.services.globals',
  'whyline.services.sector',
  'whyline.services.organization-sector',
  'whyline.services.organization-area',
  'whyline.services.organization-line',
  'whyline.services.notifications',
  'whyline.services.errors',
]);

const SectorsController = ($q, $scope, $state, $timeout, $translate, dialog, ErrorService, NotificationService, OrganizationAreaService, OrganizationLineService, OrganizationSectorService, SectorService) => {
  /*@ngInject*/

  $scope.placeId = $state.params.placeId ? $state.params.placeId : false;
  $scope.grabbing = false;
  $scope.toggleGrab = toggle => {
    $scope.grabbing = toggle;
  };
  // Data arrays
  $scope.areas = [];
  $scope.lines = [];
  // New Sector Model
  $scope.newRootSector = {
    show: false,
    name: '',
    loading: false,
  };
  // Handle enter key
  $scope.isCreatingNewSector = false;
  $scope.isChangingSectorName = false;
  // Used to set the next sector value to save
  $scope.nodeToUpdate = null;
  // Expand or collapse current node
  $scope.toggle = node => node.toggle();
  // Collapse all nodes
  $scope.collapseAll = () => {
    $scope.allCollapsed = true;
    $scope.$broadcast('angular-ui-tree:collapse-all');
  }
  // Expand all nodes
  $scope.expandAll = () => {
    $scope.allCollapsed = false;
    $scope.$broadcast('angular-ui-tree:expand-all')
  };
  // Open Sector form for creating a new subSector
  $scope.toggleSectorForm = node => {
    node.newSubSectorName = '';
    node.showSectorForm = !node.showSectorForm;
  };

  /**
   * Add to each sector the properties for adding a subsector if its root,
   * the future name to edit and if is the subSector form is open
   * @param {sector} sector instance
   */
  const mapAndSetSector = sector => {
    let alreadyExisted = false;

    sector.editName = false;
    sector.newName = sector.name;
    sector.showSectorForm = false;
    sector.newSubSectorName = '';
    sector.tree.forEach(node => {
      node.rootSectorId = sector._id;
      if (node.type === 'organization-sector') {
        node.editName = false;
        node.newName = node.name;
      }
    });
    // If current sector already exists override with the new mapped sector
    $scope.sectors.forEach((currSector, idx) => {
      if (currSector._id === sector._id) {
        $scope.sectors[idx] = sector;
        alreadyExisted = true;
      }
    });

    if (!alreadyExisted) {
      $scope.sectors.push(sector);
    }
  };

  /**
   * Handle all responses by clearing the nodeToUpdate and mapping the response
   * @param {promise} promise object
   */
  const handlePromiseResponse = promise => {
    const defer = $q.defer();
    promise
      .then(sector => {
        orderPopulatedLines(sector);
        mapAndSetSector(sector);
        $scope.nodeToUpdate = null;
        $scope.isCreatingNewSector = false;
        $scope.isChangingSectorName = false;
        NotificationService.Success($translate.instant('organization_sector_update_succ'));
        defer.resolve(sector);
      }, err => {
        $scope.nodeToUpdate = null;
        $scope.isCreatingNewSector = false;
        $scope.isChangingSectorName = false;
        defer.reject();
        ErrorService.handler(err);
      });

    return defer.promise;
  }

  /**
   * Validates that the current sector name doesn't already exist in the given tree and if is more than 2 chars long
   * @param {tree} array to search into for validation
   * @param {name} string to validate
   */
  const validateSectorName = (tree, name) => {
    let isNameValid = true;
    tree.forEach(node => {
      if (node.name === name) {
        isNameValid = false;
        NotificationService.Warning($translate.instant('organization_subsector_already_exist_on_sector'));
      }
    });
    if (name.length < 2) {
      isNameValid = false;
      NotificationService.Warning($translate.instant('organization_sector_name_length'));
    }
    if (!/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(name)) {
      isNameValid = false;
      NotificationService.Warning($translate.instant('char_forbidden_name'));
    }
    return isNameValid;
  };

  /**
   * Change a sector name by updating the current sector root or not only if the name has changed
   * @param {nodeToUpdate} a sector object
   * @param {nodeScope} the node current scope
   */
  $scope.changeSectorName = (nodeToUpdate, nodeScope) => {
    if (nodeToUpdate.newName && nodeToUpdate.newName !== nodeToUpdate.name && !$scope.isChangingSectorName) {
      const tree = nodeToUpdate.root ? $scope.sectors : nodeScope.$parent.$parent.$modelValue
      if (validateSectorName(tree, nodeToUpdate.newName)) {
        $scope.isChangingSectorName = true;

        handlePromiseResponse(OrganizationSectorService.Update(nodeToUpdate).then(sector => {
          regenerateTree();

          return sector;
        }));
      }
    } else {
      nodeToUpdate.editName = false;
    }
  };

  /**
   * Handle Sector tree events.
   * accept: Set the dropped line or process for saving only if it wasn't already added.
   */
  $scope.sectorsTreeOptions = {
    accept: (sourceNodeScope, destNodesScope) => {
      if (get(destNodesScope, '$nodeScope.$modelValue', false)) {
        const index = findIndex(destNodesScope.$nodeScope.$modelValue.tree, { _id: sourceNodeScope.$parent.node._id });
        if (index >= 0) {
          // Already exists
          $scope.nodeToUpdate = null;
          return false;
        } else {
          // Doesn't exist. Allow node to be dropped.
          $scope.nodeToUpdate = cloneDeep(destNodesScope.$nodeScope.$modelValue);
          return true;
        }
      }
      return false;
    }
  };

  /**
   * Handle Sector tree events.
   * accept: Don't allow the queues tree to be modified by drag and drop
   * dropped: When an element of this tree is dropped check if it was set on $scope.nodeToUpdate
   * by the $scope.sectorsTreeOptions.accept() method. If it was add element.
   */
  $scope.queuesTreeOptions = {
    accept: () => {
      $scope.nodeToUpdate = null;
      return false;
    },
    dropped: event => {
      if ($scope.nodeToUpdate) {
        $scope.nodeToUpdate.tree.splice(event.dest.index, 0, event.source.nodeScope.$parent.node);
        handlePromiseResponse(OrganizationSectorService.Update($scope.nodeToUpdate).then(sector => {
          regenerateTree();

          return sector;
        }));
      }
    }
  };

  /**
   * Removes a line, sector or area
   * @param {nodeToRemove} current node to remove (line, sector or area)
   * @param {nodeScope} angular scope of the node
   */
  $scope.removeSectorNode = (nodeToRemove, nodeScope) => {
    dialog
      .confirm($translate.instant('item_{name}_remove_sure').replace('{name}', nodeToRemove.label || nodeToRemove.name))
      .then(() => removeNode());

    const removeNode = () => {
      if (nodeToRemove.type === 'organization-sector' && nodeToRemove.root) {
        OrganizationSectorService.Delete(nodeToRemove._id).then(() => {
          NotificationService.Success($translate.instant('organization_sector_update_succ'));
          $scope.sectors = $scope.sectors.filter(sector => sector._id !== nodeToRemove._id);
        });
        return;
      }

      const parentSectorBackUp = cloneDeep(nodeScope.$parentNodeScope.$modelValue);
      remove(nodeScope.$parentNodeScope.$modelValue.tree, { _id: nodeToRemove._id });

      if (nodeToRemove.type === 'organization-sector') {
        OrganizationSectorService.DeleteSubSector(nodeToRemove._id)
          .then(
            () => NotificationService.Success($translate.instant('organization_sector_update_succ')),
            () => nodeScope.$parentNodeScope.$modelValue = parentSectorBackUp
          );
      } else {
        handlePromiseResponse(OrganizationSectorService.Update(nodeScope.$parentNodeScope.$modelValue, nodeToRemove._id))
          .then(null, () => nodeScope.$parentNodeScope.$modelValue = parentSectorBackUp);
      }
    }

    regenerateTree();
  };

  /**
   * Creates a subSector for a given sector
   * @param {node} sector where the subsector is going to be created
   * @param {nodeScope} angular scope of the node
   */
  $scope.createSubSector = (node, nodeScope) => {
    if (node.newSubSectorName && !$scope.isCreatingNewSector) {
      const newSector = {
        name: node.newSubSectorName,
        root: false,
        tree: [],
        type: 'organization-sector'
      };
      const rootSectorId = nodeScope.$parent.node._id;

      if (validateSectorName(nodeScope.$parent.node.tree, node.newSubSectorName)) {
        $scope.isCreatingNewSector = true;

        handlePromiseResponse(OrganizationSectorService.Create(newSector, rootSectorId)).then(sector => {
          regenerateTree();

          nodeScope.$parent.$parent.$modelValue = sector;
        });
      }
    } else {
      node.showSectorForm = false;
      node.newSubSectorName = '';
    }
  };

  /**
   * Creates a root level sector
   */
  $scope.createRootSector = () => {
    if ($scope.newRootSector.name && !$scope.isCreatingNewSector) {
      const newSector = {
        name: $scope.newRootSector.name,
        root: true,
        tree: [],
        type: 'organization-sector'
      };
      $scope.newRootSector.loading = true;
      if (validateSectorName($scope.sectors, $scope.newRootSector.name)) {
        $scope.isCreatingNewSector = true;

        handlePromiseResponse(OrganizationSectorService.Create(newSector)).then(() => {
          $scope.newRootSector.show = false;
          $scope.newRootSector.name = '';
          $scope.newRootSector.loading = false;

          regenerateTree();
        });
      }
    } else {
      $scope.newRootSector.show = false;
      $scope.newRootSector.name = '';
    }
  };

  /**
   * Save active/inactive sectors for a place
   */
  $scope.savePlaceSectors = () => {
    const placeSectors = cloneDeep($scope.sectors);
    const placeSectorsFiltered = placeSectors.filter((sector) => sector.isUpdate);
    placeSectorsFiltered.forEach(sector => sector.tree.forEach(nodeSector => {
      Reflect.deleteProperty(nodeSector, 'rootSectorId');
      if (nodeSector.tree) {
        nodeSector.tree.forEach(sub => {
          Reflect.deleteProperty(nodeSector, 'rootSectorId');
          Reflect.deleteProperty(sub, 'subSectorId');
        });
      }
    }));

    SectorService.Update(placeSectorsFiltered)
      .then(() => {
        NotificationService.Success($translate.instant('organization_sector_update_succ'));
        $scope.sectors.map((sector) => Reflect.deleteProperty(sector, 'isUpdate'));
      })
      .catch(ErrorService.handler);
  };

  $scope.placeSectorsActions = node => {
    switch (node.type) {
      // Sector & Subsector actions
      case 'organization-sector':
        // Sector actions
        if (node.root) {
          const sector = $scope.sectors.find(sector => node._id == sector._id);
          sector.isUpdate = true;
          sector.tree.forEach(nodeSector => {
            nodeSector.enableInPlace = node.enableInPlace;

            if (nodeSector.type == 'organization-sector') {
              nodeSector.tree.forEach(nodeSector => {
                nodeSector.enableInPlace = node.enableInPlace;
              });
            }
          });
        } else {
          // Subsector actions
          const sector = $scope.sectors.find(sector => node.rootSectorId == sector._id);
          const subSector = sector.tree.find(subSector => node._id == subSector._id);
          subSector.tree.forEach(nodeSector => {
            nodeSector.enableInPlace = node.enableInPlace;
          });
          validateSectorStates(node.rootSectorId);
        }
        break;
      // Area & Lines actions
      case 'organization-area':
      case 'organization-line':
        // Sector areas & lines
        if (!node.subSectorId) {
          validateSectorStates(node.rootSectorId);
        } else {
          // Subsector areas & lines
          validateSubSectorStates(node.rootSectorId, node.subSectorId);
        }
        break;
    }
  };

  const validateSectorStates = rootSectorId => {
    const sector = $scope.sectors.find(sector => rootSectorId == sector._id);
    sector.isUpdate = true;
    const allFalse = every(sector.tree, ['enableInPlace', false]);
    if (allFalse) {
      sector.enableInPlace = false;
    } else {
      sector.enableInPlace = true;
    }
  };

  const validateSubSectorStates = (rootSectorId, subSectorId) => {
    const sector = $scope.sectors.find(sector => rootSectorId == sector._id);
    const subsector = sector.tree.find(subSector => subSectorId == subSector._id);

    const allFalse = every(subsector.tree, ['enableInPlace', false]);
    if (allFalse) {
      subsector.enableInPlace = false;
    } else {
      subsector.enableInPlace = true;
    }
    validateSectorStates(rootSectorId);
  };

  const initSectorsForPlace = () => {
    $scope.sectors.forEach(sector => {
      sector.tree.forEach(node => {
        node.rootSectorId = sector._id;
        if (node.tree) {
          node.tree.forEach(nodeSector => {
            nodeSector.subSectorId = node._id;
            nodeSector.rootSectorId = sector._id;
          });
        }
      });
    });

    regenerateTree();
  };

  const orderPopulatedLines = sector => {
    sector.tree.map(t => {
      const sectorsOrdered = [];
      if (t.organizationLines) {
        for (let i = 0; i < t.organizationLines.length; i++) {
          for (let j = 0; j < t.organizationLinesPopulated.length; j++) {
            if (t.organizationLines[i] === t.organizationLinesPopulated[j]._id) {
              sectorsOrdered.push(t.organizationLinesPopulated[j]);
            }
          }
        }
        t.organizationLinesPopulated = cloneDeep(sectorsOrdered);
      }
    });
  };

  const orderSectorProperty = 'tree';
  const orderSectorsBy = ['name', 'label'];

  const regenerateTree = () => {
    let sectors = $scope.sectors;

    sectors = sortSectors(sectors);
    sectors.map(sector => orderSectors(sector));

    $scope.sectors = sectors;
  };

  const sortSectors = function (sectors) {
    sectors = orderBy(sectors, orderSectorsBy);

    return sectors;
  };

  const orderSectors = leafToOrder => {
    if (get(leafToOrder, orderSectorProperty, false) && leafToOrder[orderSectorProperty].length > 0) {
      leafToOrder[orderSectorProperty] = sortSectors(leafToOrder[orderSectorProperty]);
      leafToOrder[orderSectorProperty].map(leaf => orderSectors(leaf));
    }
  };

  /**
   * Init controller by populating the Areas, Lines and Sectors
   */
  const init = () => {
    if (!$scope.placeId) {
      OrganizationSectorService.GetAllAsPromise()
        .then(sectors => {
          $timeout(() => {
            $scope.sectors = [];
            Object.values(sectors).map((sector) => orderPopulatedLines(sector));
            map(sectors, sector => mapAndSetSector(sector));
            regenerateTree();
          });
        })
        .catch(ErrorService.handler);

      OrganizationAreaService.GetAllAsPromise()
        .then(areas => {
          $timeout(() => {
            map(areas, area => $scope.areas.push({
              _id: area._id,
              label: area.label,
              type: area.type
            }));
          });
        })
        .catch(ErrorService.handler);

      OrganizationLineService.GetAllAsPromise()
        .then(lines => {
          $timeout(() => {
            map(lines, line => $scope.lines.push({
              _id: line._id,
              label: line.label,
              type: line.type
            }));
          });
        })
        .catch(ErrorService.handler);
    } else {
      SectorService.GetAllAsPromise()
        .then(sectors => {
          $timeout(() => {
            Object.values(sectors).map((sector) => orderPopulatedLines(sector));
            $scope.sectors = sectors;
            initSectorsForPlace();
          });
        });
    }
  };
  init();
};

module.exports = module.controller('SectorsController', SectorsController);
