import angular from 'angular';
import '../services/place';

const module = angular.module('whyline.directives.plan-click', [
  'whyline.services.place',
]);


const planClick = (PlaceService, $compile) => {

  return {
    restrict: 'A',
    terminal: true,
    scope: {
      planClick: '=',
      stopProp: '<',
    },
    compile: function compile(element, attrs) {
        return {
            pre: function(scope, iElement, iAttrs, controller) { },
            post: function postLink(scope, iElement, iAttrs, controller) {
              if(!iAttrs.planClickStatic) {
                scope.$watch('planClick', function(disable) {
                  if(disable) {
                    iElement.addClass('disabled');
                    iElement.addClass('allow-pointer-events');
                  } else {
                    iElement.removeClass('disabled');
                    iElement.removeClass('allow-pointer-events');
                  }
                });
              }
              iElement.on('click', function (event) {
                let disable = scope.planClick;

                if(scope.stopProp) {
                  event.stopPropagation();
                }
                if(disable) {
                  event.preventDefault();
                }
              });
            }
        };
     }
  };
};

module.exports = module.directive('planClick', planClick);
