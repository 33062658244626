module.exports={
  "name": "joi",
  "description": "Object schema validation",
  "version": "13.7.0",
  "homepage": "https://github.com/hapijs/joi",
  "repository": "git://github.com/hapijs/joi",
  "main": "lib/index.js",
  "keywords": [
    "hapi",
    "schema",
    "validation"
  ],
  "engines": {
    "node": ">=8.9.0"
  },
  "dependencies": {
    "hoek": "5.x.x",
    "isemail": "3.x.x",
    "topo": "3.x.x"
  },
  "devDependencies": {
    "code": "5.x.x",
    "hapitoc": "1.x.x",
    "lab": "15.x.x"
  },
  "scripts": {
    "test": "lab -t 100 -a code -L",
    "test-debug": "lab -a code",
    "test-cov-html": "lab -r html -o coverage.html -a code",
    "toc": "hapitoc",
    "version": "npm run toc && git add API.md README.md"
  },
  "license": "BSD-3-Clause"
}
