import angular from 'angular';
import './modals/box';
import '../services/box';
import '../services/globals';

const module = angular.module('whyline.controllers.box', [
  'whyline.components.status',
  'whyline.services.box',
  'whyline.modals.box',
  'whyline.services.globals',
]);

const BoxesController = ($scope, BoxService, $uibModal, dialog, $translate, GlobalsService, ErrorService, NotificationService, $state) => {
  /*@ngInject*/
  $scope.placeId = $state.params.placeId ? $state.params.placeId : false;
  $scope.quantityOfItems = 8;

  // View init
  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';

  BoxService.GetAllAsPromise()
    .then((res) => {
      $scope.boxes = res;
    });

  $scope.remove = (boxId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('box_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        BoxService.Remove(boxId)
          .then(() => {
            NotificationService.Success($translate.instant('box_{name}_remove_succ').replace('{name}', name));
          })
          .catch(err => {
            ErrorService.handler(err);
          });
      });
  };

  $scope.switch = (box) => {
    let n = box.name || '';
    let enabled = !box.enabled;
    const confirmMessage = enabled ? 'box_{name}_enable_sure' : 'box_{name}_disable_sure';
    dialog.confirm($translate.instant(confirmMessage).replace('{name}', n))
      .then(() => {
        const successMessage = enabled ? 'box_{name}_enable_succ' : 'box_{name}_disable_succ';
        BoxService.Enable(box._id)
        .then(() => {
          NotificationService.Success($translate.instant(successMessage).replace('{name}', n));
        })
        .catch(err => {
          box.enabled = !box.enabled;
          ErrorService.handler(err);
        });
      })
      .catch(() => {
        box.enabled = !box.enabled;
      });
  };

  // Modal
  $scope.open = boxId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/box-modal.html',
      size: 'lg',
      controller: 'BoxModalController',
      resolve: {
        placeId: () => $scope.placeId,
        boxId: () => boxId,
      }
    });
  };
};

module.exports = module.controller('BoxesController', BoxesController);
