import angular from 'angular';
import { Promise } from 'es6-promise';
import { findIndex, forEach } from 'lodash';
import '../resources/line';
import '../resources/area';
import '../resources/schema';

const module = angular.module('whyline.services.schema', [
  'whyline.resources.line',
  'whyline.resources.area',
  'whyline.resources.schema'
]);

let $q;
let Schema;
let Line;
let Area;

const removeElement = (parent, elementToRemove) => {
  let i;
  for (i = parent.tree.length - 1; i >= 0; i--) {
    const element = parent.tree[i];
    if (element._id === elementToRemove._id) {
      return parent.tree.splice(findIndex(parent.tree, element), 1);
    }
  }
  return parent;
};

const clean = element => {
  const { _id, type, name, root, tree } = element;
  const item = { _id, name, type };
  if (element.type === 'sector') {
    item.root = root;
    item.tree = tree.map(child => ({
      _id: child._id,
      type: child.type
    }));
  }
  return item;
};

const populateItem = item => {
  if (item.type === 'line' && item._id) {
    return Object.assign(item, Line.FindById(item._id));
  } else if (item.type === 'area' && item._id) {
    return Object.assign(item, Area.FindById(item._id, { populate: ['lines'] }));
  } else {
    console.error('unable to populate item that is not line or area', item);
    return item;
  }
};

// @TODO: improve
const populateSchema = schemas => {
  forEach(schemas, schema => {
    if (schema.tree && schema.tree.length) {
      forEach(schema.tree, (item, i) => {
        if (Object.keys(item).length === 0) {
          return;
        }
        if (item.type === 'sector') {
          if (item.tree && item.tree.length) {
            forEach(item.tree, (subitem, j) => {
              item.tree[j] = populateItem(subitem);
            });
          }
        } else {
          schema.tree[i] = populateItem(item);
        }
      });
    }
  });
};

class SchemaService {

  static $inject = ['$q', 'Schema', 'Area', 'Line'];

  constructor(injected$q, injectedSchema, injectedArea, injectedLine) {
    /*@ngInject*/
    $q = injected$q;
    Line = injectedLine;
    Area = injectedArea;
    Schema = injectedSchema;
  }

  GetAllAsPromise() {
    return Schema.FetchAll({ promise: true });
  }

  //top level
  GetAllTopLevelAsPromiseFromServer() {
    return Schema.FindBy('root', 'true', { promise: true, force: true })
  }

  ClearCacheAndGetAllTopLevelAsPromiseFromServer() {
    Schema.TruncateCache();
    return this.GetAllTopLevelAsPromiseFromServer();
  }

  GetAllRootAsPromise() {
    return Line.FetchAll({ promise: true, force: true })
      .then(() => Area.FetchAll({ promise: true, force: true })) // .then(lines =>
      .then(() => Schema.FindBy('root', 'true', { promise: true, force: true })) // .then(areas =>
      .then(schemas => {
        populateSchema(schemas);
        return schemas;
      });
  }

  GetAllRootPopulatedAsPromise() {
    Schema.TruncateCache();
    return Line.FetchAll({ promise: true, force: false })
      .then(() => Area.FetchAll({ promise: true, force: false, populate: ['lines'] }))
      .then(() => Schema.FindBy('root', 'true', { promise: true, force: false }))
      .then(schemas => {
        const promisesAll = [];
        forEach(schemas, (value, id) => {
          promisesAll.push(Schema.FindById(id, { promise: true, populate: ['lines', 'areas', 'schemas'] }));
        });
        return $q.all(promisesAll);
      });
  }

  Create(data) {
    const newSchema = new Schema(data);
    return newSchema;
  }

  Save(schema) {
    const newSchema = new Schema(clean(schema));
    return Schema.Save(newSchema);
  }

  Remove(parent, element) {
    const promises = [];
    removeElement(parent, element);
    if (parent._id) {
      const newParent = new Schema(clean(parent));
      promises.push(Schema.Save(newParent));
    }
    if (element.type === 'sector') {
      const newElement = new Schema(clean(element));
      promises.push(Schema.Remove(newElement));
    }
    Schema.TruncateCache();
    return Promise.all(promises);
  }

  AddChildSchema(parentSchema, childData) {
    const newChildSchema = new Schema(childData);
    return Schema.Save(newChildSchema)
      .then(childSchema => {
        parentSchema.tree.push({
          type: 'sector',
          _id: childSchema._id
        });
        return this.Update(parentSchema);
      });
  }

  GetAllPopulated(placeId) {
    return Schema.GetAllPopulated(placeId);
  }
}

module.exports = module.service('SchemaService', SchemaService);
