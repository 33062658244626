import angular from 'angular';
import '../resources/organization-messages';
import { map, cloneDeep } from 'lodash';

const module = angular.module('whyline.services.organization-messages', [
  'whyline.resources.organization-messages',
]);

let OrganizationMessage;
let ErrorService;
let $timeout;

class OrganizationMessagesService {
  static $inject = ['$timeout', 'OrganizationMessages', 'ErrorService'];

  constructor(injected$timeout, injectedOrganizationMessage, injectedErrorService) {
    $timeout = injected$timeout;
    OrganizationMessage = injectedOrganizationMessage;
    ErrorService = injectedErrorService;
  }

  GetAll(organizationId) {
    return OrganizationMessage.GetCurrent(organizationId);
  }

  GetDefaults() {
    const defaultMessageConfiguration = [
      {
        enabled: true,
        readOnly: false,
        type: 'push',
      },
      {
        enabled: true,
        readOnly: false,
        type: 'message',
      },
    ];

    return map(
      [
        {
          configuration: [
            {
              enabled: true,
              readOnly: false,
              type: 'push',
            },
            {
              enabled: false,
              readOnly: false,
              type: 'message',
            },
          ],
          messages: [
            {
              content:
                '{{{personName}}} you are being called by the box {{{operatorLabel}}} in line {{{queueName}}}.',
              language: 'en',
              title: "It's your turn!",
            },
            {
              content:
                '{{{personName}}} estás siendo llamado por el puesto {{{operatorLabel}}} en la fila {{{queueName}}}.',
              language: 'es',
              title: '¡Es tu turno!',
            },
            {
              content:
                '{{{personName}}} você está sendo chamado pelo posto {{{operatorLabel}}} na fila {{{queueName}}}.',
              language: 'pt',
              title: 'É sua vez!',
            },
          ],
          slug: 'its_your_turn',
        },
        {
          messages: [
            {
              content: '{{{personName}}} you are next in the line {{{queueName}}}',
              language: 'en',
              title: 'You are next!',
            },
            {
              content: '{{{personName}}} sos el próximo en la fila {{{queueName}}}',
              language: 'es',
              title: '¡Sos el próximo!',
            },
            {
              content: '{{{personName}}} você é o seguinte na fila {{{queueName}}}',
              language: 'pt',
              title: 'Você é o seguinte!',
            },
          ],
          slug: 'you_are_next',
        },
        {
          messages: [
            {
              content:
                '{{{personName}}} you have {{peopleAhead}} {{personOrPeople}} ahead in line, you will be called approximately in {{waitingTime}}',
              language: 'en',
              title: 'Attention!',
            },
            {
              content:
                '{{{personName}}} tenés {{peopleAhead}} {{personOrPeople}} adelante, serás atendido aproximadamente en {{waitingTime}}',
              language: 'es',
              title: '¡Atención!',
            },
            {
              content: '{{{personName}}} você tem {{peopleAhead}} {{personOrPeople}} em frente',
              language: 'pt',
              title: 'Atenção!',
            },
          ],
          slug: 'people_ahead',
        },
        {
          messages: [
            {
              content:
                '{{{personName}}} you will be called in less than 20 minutes in {{{queueName}}}',
              language: 'en',
              title: 'Soon',
            },
            {
              content: '{{{personName}}} te atenderemos en menos de 20 minutos en {{{queueName}}}',
              language: 'es',
              title: 'En breve',
            },
            {
              content:
                '{{{personName}}} você será atendido em menos de 20 minutos em {{{queueName}}}',
              language: 'pt',
              title: 'Em seguida',
            },
          ],
          slug: 'check_in_early',
        },
        {
          messages: [
            {
              content: '{{{personName}}} you will be called in {{waitingTime}}.',
              language: 'en',
              title: 'Take advantage of your time!',
            },
            {
              content: '{{{personName}}} te atenderemos en {{waitingTime}}',
              language: 'es',
              title: '¡Aprovechá tu tiempo!',
            },
            {
              content: '{{{personName}}} você será atendido em {{waitingTime}}',
              language: 'pt',
              title: 'Aproveite melhor o seu tempo!',
            },
          ],
          slug: 'check_in',
        },
        {
          configuration: [
            {
              enabled: true,
              readOnly: false,
              type: 'push',
            },
            {
              enabled: false,
              readOnly: false,
              type: 'message',
            },
          ],
          messages: [
            {
              content:
                "Hello! Today you have an appointment at {{{placeName}}}. If you don't attend, cancel it from the Abandon option APP. Thank you!",
              language: 'en',
              title: 'You have an Appointment',
            },
            {
              content:
                '¡Hola! Hoy tienes una cita en {{{placeName}}}. De no asistir cancélalo desde la APP opción Abandonar. ¡Muchas gracias!',
              language: 'es',
              title: 'Tienes una Cita',
            },
            {
              content:
                'Olá! Hoje você tem um agendamento em {{{placeName}}}. Se você não comparecer, cancele-o na opção Abandonar APP. Muito obrigado!',
              language: 'pt',
              title: 'Você tem um Agendamento',
            },
          ],
          slug: 'appointment_reminder',
        },
        {
          configuration: [
            {
              enabled: false,
              readOnly: true,
              type: 'push',
            },
            {
              enabled: true,
              readOnly: false,
              type: 'message',
            },
            {
              enabled: true,
              readOnly: false,
              type: 'external',
            },
          ],
          messages: [
            {
              content:
                'Hello {{personName}}! To know the waiting time from your mobile phone, download the Whyline App (www.whyline.com)',
              language: 'en',
              title: 'Download Whyline',
            },
            {
              content:
                '¡Hola {{personName}}! Para conocer el tiempo de espera desde tu telefono, descarga la App Whyline (www.whyline.com)',
              language: 'es',
              title: 'Descarga Whyline',
            },
            {
              content:
                'Olá, {{personName}}! Para saber o tempo de espera através do celular, baixe o App da Whyline (www.whyline.com)',
              language: 'pt',
              title: 'Descarrega Whyline',
            },
          ],
          slug: 'download_application',
        },
      ],
      (message) => {
        message.configuration = message.configuration || cloneDeep(defaultMessageConfiguration);

        return message;
      },
    );
  }

  GetAllAsPromise(organizationId) {
    return OrganizationMessage.GetAllMessages(organizationId)
      .then((messages) => $timeout(() => Promise.resolve(messages || [])))
      .catch(ErrorService.handler);
  }

  UpdateAllMessages(messages, organizationId, forAllPlaces) {
    return OrganizationMessage.UpdateAllMessages(messages, organizationId, forAllPlaces)
      .then((messagesFromServer) => $timeout(() => Promise.resolve(messagesFromServer || [])))
      .catch(ErrorService.handler);
  }
}

module.exports = module.service('OrganizationMessagesService', OrganizationMessagesService);
