
import angular from 'angular';
import '../../services/organization-condition';
import '../../services/errors';
import { cloneDeep, get } from 'lodash';

const module = angular.module('whyline.modals.organization-condition', [
  'whyline.services.organization-condition',
  'whyline.services.errors',
]);

const OrganizationConditionModalController = (
  $scope,
  $timeout,
  $uibModalInstance,
  $translate,
  OrganizationConditionService,
  NotificationService,
  ErrorService,
  conditionId,
  currentOrganization
) => {
  'ngInject';

  $scope.organizationCondition = {
    name: '',
    description: '',
    condition: '',
    weight: ''
  };

  $scope.conditionAsString = '';

  if (conditionId) {
    OrganizationConditionService.GetOneAsPromise(conditionId).then(
      orgCondition => {
        $timeout(() => {
          $scope.organizationCondition = cloneDeep(orgCondition);
          $scope.conditionAsString = JSON.stringify(orgCondition.condition);
        });
      },
    );
  }

  const validate = () => {
    const slug = get($scope.organizationCondition, 'slug', '');
    const slugMaxLength = 56;
    const validCharactersRegex = new RegExp('[a-zA-ZáéíóúñÁÉÍÓÚÑ ]');
    const validWeightRegex = /^\d+(\.\d{1,2})?$/;
    const conditionParsed = $scope.conditionAsString
        ? $scope.conditionAsString.replace(/'/g, '"')
        : '';
    const weight = $scope.organizationCondition.weight;

    //#region validate slug
    if (!slug) {
      NotificationService.Warning($translate.instant('mandatory_name'));
      return false;
    }

    if (slug.length > slugMaxLength) {
      NotificationService.Warning(
        $translate
          .instant('max_{maxNumber}_length_name')
          .replace('{maxNumber}', slugMaxLength)
      );
      return false;
    }

    if (!validCharactersRegex.test(slug)) {
      NotificationService.Warning($translate.instant('name_only_letters'));
      return false;
    }
    //#endregion

    //#region validate condition
    const validateEndOfStringToBeValidArray = new RegExp(/([^/]*)\]$/g);
    if (!validateEndOfStringToBeValidArray.test(conditionParsed.trimEnd())) {
      NotificationService.Warning($translate.instant('mandatory_condition_array'));
      return false;
    }
    const condition = JSON.parse(conditionParsed);
    const conditionMaxLength = 100;

    if (!condition) {
      NotificationService.Warning($translate.instant('mandatory_condition'));
      return false;
    }

    if (!condition.length) {
      NotificationService.Warning($translate.instant('condition_cannot_be_empty'));
      return false;
    }

    if (condition.length > conditionMaxLength) {
      NotificationService.Warning(
        $translate
          .instant('max_{maxNumber}_length_condition')
          .replace('{maxNumber}', conditionMaxLength)
      );
      return false;
    }

    if (!Array.isArray(condition)) {
      NotificationService.Warning($translate.instant('condition_must_be_array'));
      return false;
    }

    //#endregion

    if (weight && !validWeightRegex.test(weight)) {
      NotificationService.Warning($translate.instant('weight_validation'));
      return false;
    }

    return true;
  };

  const dismissOnError = err => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
    OrganizationConditionService.GetAllAsPromiseFromServer();
  };

  if (conditionId) {
    OrganizationConditionService.GetOneAsPromise(conditionId)
      .then(organizationCondition => {
        $timeout(() => {
          $scope.organizationCondition = OrganizationConditionService.Copy(organizationCondition);
        });
      })
      .catch(error => {
        dismissOnError(error);
        ErrorService.handler(error);
      });
  }

  $scope.save = () => {
    if (validate()) {
      $scope.organizationCondition.condition = JSON.parse($scope.conditionAsString);
      let message = '';
      let promise = null;
      if (conditionId) {
        message = $translate.instant('condition_update_succ');
        promise = OrganizationConditionService.Update($scope.organizationCondition);
      } else {
        message = $translate.instant('condition_create_succ');
        promise = OrganizationConditionService.Create($scope.organizationCondition);
      }

      promise
        .then(() => {
          NotificationService.Success(message);
          $uibModalInstance.close('cancel');
        })
        .catch(err => {
          ErrorService.handler(err);
        });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller(
  'OrganizationConditionModalController',
  OrganizationConditionModalController
);
