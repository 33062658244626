import angular from 'angular';

import {
  cloneDeep
} from 'lodash';

import '../resources/organization';


const module = angular.module('whyline.services.organization', [
  'whyline.resources.organization',
]);


let Organization;
let GlobalsService;

class OrganizationService {

  static $inject = ['Organization', 'GlobalsService'];

  constructor(injectedOrganization, injectedGlobalsService) {
    /*@ngInject*/
    Organization = injectedOrganization;
    GlobalsService = injectedGlobalsService;
  }

  Create(data) {
    const newPlace = new Organization(data);
    return Organization.Save(newPlace);
  }

  Copy(place) {
    const copy = cloneDeep(place);
    return new Organization(copy);
  }

  Update(place) {
    return Organization.Save(place);
  }

  UpdateGeneral(organization, organizationId) {
    return Organization.UpdateGeneral(organization, organizationId);
  }

  UpdateConfiguration(organization, organizationId, applyIn) {
    return Organization.UpdateConfiguration(organization, organizationId, applyIn);
  }

  GetOne(organizationId) {
    return Organization.FindById(organizationId);
  }

  GetOneAsPromise(organizationId) {
    return Organization.FindById(organizationId, { promise: true });
  }

  GetOneAsPromiseFromServer(organizationId) {
    return Organization.FindById(organizationId, {
      promise: true,
      force: true
    });
  }

  GetAll() {
    return Organization.FetchAll();
  }

  GetAllAsPromise() {
    return Organization.FetchAll({ promise: true });
  }

  GetByIds(ids) {
    return Organization.FindByIds(ids, { force: true, promise: true });
  }

  SetCurrent(organization) {
    GlobalsService.SetCurrentOrganization(organization);
    Organization.TruncateAll();
  }

  GetCurrent() {
    return Organization.FindById(GlobalsService.CurrentOrganization._id);
  }

  GetCurrentAsPromise() {
    if (!GlobalsService.CurrentOrganization) {
      return Promise.resolve(null);
    }
    return Organization.FindById(GlobalsService.CurrentOrganization._id, { promise: true });
  }

  GetAliasTypes() {
    return Organization.GetAliasTypes();
  }

  SwitchOrganizationEnable(organization) {
    return new Organization(organization).enableOrganization(!organization.enabled);
  }
}

module.exports = module.service('OrganizationService', OrganizationService);
