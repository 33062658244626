import angular from 'angular';
import '../factories/socket.io';
import '../resources/user';
import '../resources/line';
import '../resources/area';
import '../services/notifications';
import '../services/user';
import '../services/socket';
import '../services/place';
import '../app.constants';
import { localStorage } from '../utils';
import { includes } from 'lodash';

const module = angular.module('whyline.sockets.user', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.user',
  'whyline.resources.line',
  'whyline.resources.area',
  'whyline.services.notifications',
  'whyline.services.user',
  'whyline.services.socket',
  'whyline.services.place',
]);

const UserSocket = (Socket, User, blockUI, NotificationService, SAFE_STATES, $state, UserService, $rootScope, SocketService, Line, Area, PlaceService, $translate) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('user:close-session', () => {
        localStorage.get('user').then(user => {
          if (!user) {
            $state.go(SAFE_STATES.signIn);
          }
        });
      });
      socket.on('user:created', user => {
        user.enabled = !includes(user.disabledForPlaces, PlaceService.CurrentPlace ? PlaceService.CurrentPlace._id : null);
        User.UpdateCache(user._id, user);
      });
      socket.on('user:updated', user => {
        localStorage.get('user').then(currentUser => {
          User.UpdateCache(user._id, user);
          if (currentUser._id === user._id) {
            UserService.SyncCurrent().then(() => {
              Line.TruncateCache();
              Area.TruncateCache();
              $state.go($state.current, {selectBox: null}, { reload: true }).then(() => {
                NotificationService.Information($translate.instant('permiso_update_coordinator'));
              });
            });
          }
        });
      });
      socket.on('user:deleted', user => {
        User.RemoveFromCache(user._id, user._type);
      });
    });
  });
};

module.exports = module.factory('UserSocket', (Socket, User, blockUI, NotificationService, SAFE_STATES, $state, UserService, $rootScope, SocketService, Line, Area, PlaceService, $translate) => new UserSocket(Socket, User, blockUI, NotificationService, SAFE_STATES, $state, UserService, $rootScope, SocketService, Line, Area, PlaceService, $translate));
