module.exports = (blockUIConfig, showBlockUIAccesor) => {
  blockUIConfig.autoInjectBodyBlock = true;
  blockUIConfig.message = 'loading';
  blockUIConfig.templateUrl = '/templates/components/block-ui-overlay.html';
  blockUIConfig.requestFilter = function(config) {
    if(!showBlockUIAccesor()) {
      return false;
    }

    // If the request starts with '/api/quote' ...
    if(config.url.match(/blockui=false/) || config.url.match(/send-update-by-socket/) || config.url.match(/recount-people-ahead-by-line/) || config.url.match(/recalculate-waiting-people/) || config.url.match(/img/) || config.url.match(/html/)) {
      return false;
    }
  };
};