import angular from 'angular';
import { get, set, cloneDeep } from 'lodash';
import { options } from '../utils';

const module = angular.module('whyline.components.partial-change', [

]);

const PartialChangeComponent = {
  bindings: {
    type: '@',  // Input type: text, textarea, number, options.
    itemValue: '<',  // The Value/Object that is going to be changed.
    key: '@?',   // The Value/Object key to update the value.
    title: '@', // The title for the accordion.
    validateFn: '&?',
    disabledOptions: '@?',
    defaultOption: '@?',
    ba: '<',
    selectOptions: '<'
  },
  templateUrl: '/templates/components/partial-change.html',

  controller($scope) {
    'ngInject';

    const ctrl = this;
    ctrl.editingItem = false;

    ctrl.$onChanges = changesObj => {
      if (changesObj.itemValue && changesObj.itemValue.isFirstChange()) {
        //Order object keys
        if (ctrl.type === 'options') {
          const ordered = {};
          Object.keys(changesObj.itemValue.currentValue).sort().forEach(function(key) {
            ordered[key] = changesObj.itemValue.currentValue[key];
          });
          ctrl.item = cloneDeep(ordered);
          ctrl.originalValue = cloneDeep(ordered);
        } else {
          ctrl.item = cloneDeep(changesObj.itemValue.currentValue);
          ctrl.originalValue = cloneDeep(changesObj.itemValue.currentValue);
        }
      }
    };
    ctrl.cancel = () => {
      ctrl.item = ctrl.originalValue;
      ctrl.editingItem = false;
    };

    ctrl.$onInit = () => {
      ctrl.options = get(ctrl, 'selectOptions', options);
      ctrl.selectedOption = ctrl.defaultOption || ctrl.options[0].key;
    };

    ctrl.save = () => {
      const data = {
        updateValues: {},
        updateType: ctrl.selectedOption
      };
      set(ctrl.key ? data.updateValues : data, ctrl.key ? ctrl.key : 'updateValues', ctrl.item);
      if (!ctrl.validateFn || ctrl.validateFn({ value: ctrl.item })) {
        $scope.$parent.save(data).then(() => {
          ctrl.editingItem = false;
          ctrl.originalValue = ctrl.item;
        })
          .catch(e => {
            console.log(e);
          });
      }
    };
  }
};

module.exports = module.component('partialchange', PartialChangeComponent);
