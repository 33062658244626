import angular from 'angular';
const module = angular.module('whyline.components.status', []);

const statusComponent = {
  bindings: {
    item: '='
  },
  templateUrl: '/templates/components/status.html',
  controller($scope) {
    'ngInject';
    $scope.getStatus = () => {
      // No queue provided
      if(!this.item) {
        return '';
      }

      // Queue type Area
      if(this.item.realType === 'process') {
        return 'icon-process';
      } else if(this.item.realType === 'group') {
        return 'icon-group';
      } else if(this.item.realType === 'supervisor') {
        return 'icon-supervisor';
      } else if(this.item.realType === 'line') {
        const { velocity = 0 } = this.item.statistics;
        const { waitingPeople = 0 } = this.item;
        const waitingTime = velocity * waitingPeople;
        if(waitingTime > 20) {
          return 'red';
        } else if(waitingTime > 10) {
          return 'yellow';
        } else if(waitingTime >= 0) {
          return 'green';
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
  },
};

module.exports = module.component('status', statusComponent);
