import angular from 'angular';
import { isEmpty } from 'lodash';
import './resource';

const module = angular.module('whyline.resources.subscription', ['whyline.resources.resource']);

const SubscriptionFactory = (Resource, API_URL, $q, $http, $timeout) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'subscription?placeId';
  const _schema = {
    _id: {
      type: String,
    },
    enqueueIn: {
      type: String,
    },
    subscribedBy: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    lineId: {
      type: String,
      required: false,
    },
    placeId: {
      type: String,
      required: false,
    },
    person: {
      type: Object,
      required: true,
    },
    day: {
      type: Number,
      required: false,
    },
    myNumber: {
      type: Number,
      required: false,
    },
    peopleAhead: {
      type: Number,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    subscriptionTime: {
      type: Date,
      required: false,
    },
    subscriptionType: {
      type: String,
    },
    waitingTime: {
      type: Number,
      required: false,
    },
    hasPriority: {
      type: Boolean,
    },
    calledBy: {
      type: String,
    },
    calledTime: {
      type: Date,
    },
    calledData: {
      firstName: {
        type: String,
      },
      lastName: {
        type: String,
      },
    },
    preference: String,
    completedTime: {
      type: Date,
    },
    callHistory: {
      type: [Date],
    },
    path: [
      {
        type: {
          type: String,
        },
        realType: {
          type: String,
        },
        _id: {
          type: String,
        },
        name: {
          type: String,
        },
        ordered: {
          type: Boolean,
        },
      },
    ],
    checkIn: {
      type: Object,
    },
    additionalInfo: {
      reason: {
        type: String,
      },
      motive: {
        type: String,
      },
    },
    statusInfo: {
      type: Object,
      displayName: {
        type: String,
      },
      reason: {
        type: String,
      },
      status: {
        type: String,
      },
    },
    createdFrom: String,
    assignedUser: { 
      userId: { 
        type: String 
      }, 
      userName: { 
        type: String 
      },
      serviceId: { 
        type: String 
      },
      serviceType: { 
        type: String 
      } 
   }
  };

  const Subscription = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    if (this.hasOwnProperty('person') && this.person) {
      this.fullName = `${this.person.firstName} ${this.person.lastName}`;
    }
  };

  Subscription.prototype = new Resource(_type, _schema, Subscription);

  Resource.Extend(_type, Subscription);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Subscription.InitCache(validityTime);

  Subscription.EndSubscription = (subscription, status, reason) => {
    let url = `${API_URL}subscription/${subscription._id}?lineId=${subscription.lineId}?placeId`;
    return $http
      .patch(url, {
        status,
        reason,
      })
      .then((response) =>
        $timeout(() => {
          const subscriptionResponse = response.data.subscription;
          Subscription.UpdateCache(subscriptionResponse._id, subscriptionResponse);
          return new Promise((resolve) => resolve(response));
        }),
      );
  };

  Subscription.ChangePreference = (subscriptionId, preference) => {
    let url = `${API_URL}subscription/${subscriptionId}/preference/${preference}`;
    return $http.patch(url).then((response) =>
      $timeout(() => {
        Subscription.UpdateCache(subscriptionId, response.data.subscription);
        return new Promise((resolve) => resolve(response));
      }),
    );
  };

  Subscription.Remove = (subscriptionId, queue) => {
    let url = `${API_URL}subscription/${subscriptionId}?${
      queue.type === 'line' ? 'lineId' : 'areaId'
    }=${queue._id}?placeId`;
    return $http.delete(url).then((response) => {
      $timeout(() => {
        Subscription.UpdateCache(response.data._id, response.data);
      });
      return response;
    });
  };

  Subscription.Forward = (subscriptionId, queue, destination, areaId) => {
    // Get the queue type
    const queueType = queue.type === 'line' ? 'line' : 'area';
    // Do forward
    const destinationId = destination ? `lineIdDest=${destination._id}` : '';
    const areaQuery = areaId ? `&areaId=${areaId}` : '';
    let url = `${API_URL}subscription/${subscriptionId}/forward?${destinationId}${areaQuery}&${queueType}Id=${queue._id}?placeId`;
    return $http.post(url).then((response) => {
      const subscriptionResponse = response.data.originalSubscription;
      Subscription.UpdateCache(subscriptionResponse._id, subscriptionResponse);
      return response.data;
    });
  };

  Subscription.Requeue = (subscription) => {
    let url = `${API_URL}subscription/${subscription._id}/requeue?lineId=${subscription.lineId}`;
    return $http.post(url).then((response) => {
      $timeout(() => {
        Subscription.UpdateCache(response.data._id, response.data);
      });
      return response;
    });
  };

  Subscription.Retain = (subscriptionId, queueId, reason) => {
    let url = `${API_URL}subscription/${subscriptionId}/retain?lineId=${queueId}?placeId`;
    return $http.post(url, { reasonRetained: reason }).then((response) => {
      $timeout(() => {
        Subscription.UpdateCache(response.data._id, response.data);
      });
      return response;
    });
  };

  Subscription.Confirm = (subscription) => {
    let url = `${API_URL}subscription/${subscription._id}/confirm?placeId`;
    return $http.post(url).then((response) => {
      $timeout(() => {
        Subscription.UpdateCache(response.data._id, response.data);
      });
      return response;
    });
  };

  Subscription.GetOneByAppointmentId = (appointmentId) => {
    const url = `${API_URL}subscription/byAppoinmentId`;
    const data = {
      url,
      method: 'POST',
      data: {
        appointmentId,
      },
    };
    return $http(data).then((response) => response.data);
  };

  Subscription.ForwardTo = (
    subscriptionId,
    serviceId,
    serviceType,
    forwardFrom,
    forwardReasons,
    preference,
  ) => {
    const url = `${API_URL}subscription/${subscriptionId}/forward-to?placeId`;
    const data = {
      url,
      method: 'POST',
      data: {
        serviceId,
        serviceType,
        forwardFrom,
        forwardReasons,
        preference,
      },
    };
    return $http(data).then((response) => {
      const subscriptionResponse = response.data.originalSubscription;
      Subscription.UpdateCache(subscriptionResponse._id, subscriptionResponse);
      return response.data;
    });
  };

  Subscription.UndoForwardTo = (
    subscriptionId,
    newSubscriptionId,
  ) => {
    const url = `${API_URL}subscription/${subscriptionId}/undo-forward-to?placeId`;
    const data = {
      url,
      method: 'POST',
      data: {
        newSubscriptionId
      },
    };
    return $http(data).then((response) => {
      const subscriptionResponse = response.data.originalSubscription;
      Subscription.UpdateCache(subscriptionResponse._id, subscriptionResponse);
      return response.data;
    });
  };

  Subscription.GetAllByQueue = (queue, options, blockUI = true) => {
    const queueType =
      queue.type === 'area' && queue.ordered
        ? 'process'
        : queue.type === 'area' && !queue.ordered
        ? 'group'
        : 'line';
    const forceUpdate = options && options.force;
    const httpUrl = `${API_URL}subscription?enqueueIn=${queueType}&${queue.type}Id=${queue._id}&blockui=${blockUI}?placeId`;
    const cacheUrl = `${API_URL}subscription?enqueueIn=${queueType}&${queue.type}Id=${queue._id}`;
    const list = Subscription.GetCacheSearch(cacheUrl);
    if (forceUpdate || isEmpty(list)) {
      $http.get(httpUrl).then((response) => {
        if (response.data) {
          Subscription.UpdateCacheSearch(cacheUrl, response.data, {});
        }
        return response;
      });
    }
    return list;
  };

  Subscription.Stats = (placeId, linesIds, from, to, status, priorityOnly) =>
    $http({
      method: 'GET',
      url: `${API_URL}stats/stats?placeId`,
      params: {
        linesIds: angular.toJson(linesIds),
        from,
        to,
        status: angular.toJson(status),
        priorityOnly,
      },
    });

  Subscription.StatsProcess = (areas, from, to, priorityOnly) =>
    $http({
      method: 'GET',
      url: `${API_URL}stats/stats-process?placeId`,
      params: {
        areas: angular.toJson(areas),
        from,
        to,
        priorityOnly,
      },
    });

  Subscription.SchemaStats = (sectorId, from, to, priorityOnly) =>
    $http({
      method: 'GET',
      url: `${API_URL}stats/stats-sector?placeId`,
      params: {
        sectorId,
        from,
        to,
        priorityOnly,
      },
    });

  Subscription.SigehosSubscriptions = (personId, date, place, includeSubscriptionsPresents = false) => {
    const httpUrl = `${API_URL}sigehos/people/${personId}/appointments?dateTo=${date}&placeId=${place}&includeSubscriptionsPresents=${includeSubscriptionsPresents}`;
    return $http.get(httpUrl).then(response => response.data);
  }

  Subscription.CreateFromLoadSubscriptions = (payload, organizationId, placeId, totemId,includeSubscriptionsPresents = false) => {
    const url = `${API_URL}sigehos/subscriptions?totemId=${totemId}&organizationId=${organizationId}&placeId=${placeId}&includeSubscriptionsPresents=${includeSubscriptionsPresents}`;
    return $http.post(url.toString(), payload).then(response => response.data);
  }

  Subscription.SigehosPeople = (typeId, id, placeId, organizationId, totemId = '') => {
    const httpUrl = `${API_URL}sigehos/people?type=${typeId}&identification=${id}&placeId=${placeId}&organizationId=${organizationId}&totemId=${totemId}`;
    return $http.get(httpUrl).then(response => response.data);
  }

  return Subscription;
};

module.exports = module.factory('Subscription', SubscriptionFactory);
