import angular from 'angular';
import '../../services/area';
import '../../services/organization';
import '../../services/line-flow-type-organization';
import '../../components/osom';
import { schedule } from '../../utils';
import { get, isUndefined } from 'lodash';

const module = angular.module('whyline.modals.line', [
  'whyline.services.line',
  'whyline.components.osom',
  'whyline.services.line-flow-type-organization',
  'whyline.services.organization',
  'whyline.services.user',
]);

const LineModalController = ($scope, $uibModalInstance, $timeout, $translate, NotificationService, LineService, lineId, currentPlace, LineFlowTypeOrganizationService, OrganizationService, $state, UserService) => {
  'ngInject';

  $scope.showQuotas = false;
  $scope.quotaButtonTitle = 'quotas_show';

  const validateRanges = (schedule) => {
    var valid = true;
    const opened = schedule.filter(s => s.open);
    for (let i = 0; i < opened.length; i++) {
      const day = opened[i];
      if (day.firstPeriod.opening >= day.firstPeriod.closing) {
        NotificationService.Warning($translate.instant('opening_hours_first_period_msg_validator').replace('{dayname}', $translate.instant(day.name)));
        valid = false;
        break;
      }
      if (day.hasSecondPeriod) {
        if (day.secondPeriod.opening <= day.firstPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_must_be_later_first_msg_validator').replace('{dayname}', $translate.instant(day.name)));
          valid = false;
          break;
        }

        if (day.secondPeriod.opening >= day.secondPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_period_msg_validator').replace('{dayname}', $translate.instant(day.name)))
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  $scope.showHideQuotas = () => {
    $scope.showQuotas = !$scope.showQuotas;
    $scope.quotaButtonTitle = $scope.showQuotas ? 'quotas_hide': 'quotas_show';
  }

  const validateQuotas = () => {
    let valid = true;
    for (let i = 0; i < $scope.line.schedule.length; i++) {
      const day = $scope.line.schedule[i];
      let quota = 0;

      const quotaNumber = parseInt(day.firstPeriod.quota);
      $scope.line.schedule[i].firstPeriod.quota = isNaN(quotaNumber) ? 0 : quotaNumber;

      if (day.firstPeriod.quota) {
        quota += day.firstPeriod.quota;
      }

      if (day.hasSecondPeriod) {
        const quotaNumber = parseInt(day.secondPeriod.quota);
        $scope.line.schedule[i].secondPeriod.quota = isNaN(quotaNumber) ? 0 : quotaNumber;

        if (day.secondPeriod.quota) {
          quota += day.secondPeriod.quota;
        }
      }

      if (quota > 0 && $scope.line.maxNumbers > 0) {
        valid = false;
        break;
      }
    }
    return valid;
  };

  const validate = () => {
    const name = $scope.line.label;
    const preJoin = get($scope, 'line.configuration.messages.preJoin', '');
    const postJoin = get($scope, 'line.configuration.messages.postJoin', '');
    const postCheckIn = get($scope, 'line.configuration.messages.postCheckIn', '');
    const lineFlowTypeOrganization = $scope.line.defaultLineFlowTypeOrganization;
    const { sla } = $scope.line;
    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_queue_name'));
      return false;
    }
    if (name.length > 50) {
      NotificationService.Warning($translate.instant('max_length_name'));
      return false;
    }
    if (preJoin.length > 300) {
      NotificationService.Warning($translate.instant('max_length_pre_join_message'));
      return false;
    }
    if (postJoin.length > 300) {
      NotificationService.Warning($translate.instant('max_length_post_join_message'));
      return false;
    }
    if (postCheckIn.length > 59) {
      NotificationService.Warning($translate.instant('max_length_post_check_in_message'));
      return false;
    }

    if (sla < 0 || sla > 1000 || isUndefined(sla)) {
      NotificationService.Warning($translate.instant('sla_minutes_error'));
      return false;
    }

    if (!/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(name)) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }

    if (!validateRanges($scope.line.schedule)) {
      return false;
    };

    if (!lineFlowTypeOrganization) {
      NotificationService.Warning($translate.instant('mandatory_call_logic'));
      return false;
    }

    if (!validateQuotas()) {
      NotificationService.Warning($translate.instant('quotas_error'));
      return false;
    };

    return true;
  };

  const dismissOnError = err => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.dismiss('cancel');
    }
    LineService.GetAllAsPromise();
  };

  $scope.currentPlace = currentPlace;

  $scope.useScenarios = false;
  OrganizationService.GetOneAsPromiseFromServer($scope.currentPlace.organizationId)
    .then(organization => {
      $timeout(() => {
        $scope.useScenarios = organization.configuration.queuer.useScenarios;
      });
    })
    .catch(err => dismissOnError(err));

  let currentUser;
  UserService.GetCurrentAsPromise().then(user => {
    currentUser = user;
    $scope.superAdmin = currentUser && currentUser.sudo;
    if (currentUser) {
      const organizationId = $state.params.organizationId;
      $scope.isOrganizationAdmin = currentUser.permissions.find(perm => perm.role.name === 'Organization Admin' && perm.role.organizationId === organizationId);
    }
  });

  $scope.hours = schedule.getHours();

  $scope.line = {
    schedule: schedule.format(schedule.reOrder(schedule.create()), true),
    maxNumbers: 0,
    configuration: {
      showSubscriptionsBy: {
        dailySubscriptions: true,
        lastHoursSubscriptions: false,
      },
    },
    requestSource: {
      mobileApp: true,
      reception: true,
      totem: true,
    },
    sla: 0,
    checkAppointments: false
  };

  $scope.showSubscriptionsConfig = {
    showSubscriptionsBy: [
      'dailySubscriptions',
      'lastHoursSubscriptions',
    ],
    selectedShowSubscriptionsBy: 'dailySubscriptions'
  };

  $scope.onShowSubscriptionsByChange = () => {
    const configs = Object.keys($scope.line.configuration.showSubscriptionsBy);

    if (configs.length) {
      configs.forEach(key => {
        if (key == $scope.showSubscriptionsConfig.selectedShowSubscriptionsBy) {
          $scope.line.configuration.showSubscriptionsBy[key] = true;
        } else {
          $scope.line.configuration.showSubscriptionsBy[key] = false;
        }
      });
    }
    $scope.line.sla = $scope.currentPlace.configuration.sla;
  };

  $scope.lineFlowTypeOrganizationList = [];
  $scope.lineFlowTypeOrganizationSelected = {};
  $scope.line.defaultLineFlowTypeOrganization = $scope.currentPlace.defaultLineFlowTypeOrganization;

  $scope.setLFTOId = lftoSelected => {
    $scope.line.defaultLineFlowTypeOrganization = lftoSelected._id;
  };

  function initSubscriptionsByChange() {
    const configs = Object.keys($scope.line.configuration.showSubscriptionsBy);
    if (configs.length) {
      configs.forEach(key => {
        if ($scope.line.configuration.showSubscriptionsBy[key] == true) {
          $scope.showSubscriptionsConfig.selectedShowSubscriptionsBy = key;
        }
      });
    }
  }

  if (lineId) {
    LineService.GetOneAsPromiseFromServer(lineId)
      .then(line => {
        $timeout(() => {
          $scope.line = LineService.Copy(line);
          $scope.line.schedule = schedule.format($scope.line.schedule, true);
          initSubscriptionsByChange();
        });
        LineFlowTypeOrganizationService.GetAllEnabledByPlace(line.placeId, false)
          .then((lftoList) => {
            $scope.lineFlowTypeOrganizationList = lftoList;
            const found = $scope.lineFlowTypeOrganizationList.find((lfto) =>
              lfto._id == $scope.line.defaultLineFlowTypeOrganization);
            $scope.lineFlowTypeOrganizationSelected = found ? found : $scope.lineFlowTypeOrganizationList[0];
          });
      })
      .catch(err => dismissOnError(err));
  }

  $scope.save = () => {
    if (validate()) {
      $scope.line.schedule = schedule.format($scope.line.schedule, false);
      if (lineId) {
        return LineService.Update($scope.line)
          .then(() => {
            NotificationService.Success($translate.instant('queue_update_succ'));
            $uibModalInstance.close('cancel');
          })
          .catch(err => dismissOnError(err));
      }

      return LineService.Create($scope.line)
        .then(() => {
          NotificationService.Success($translate.instant('queue_create_succ'));
          $uibModalInstance.close('cancel');
        })
        .catch(err => dismissOnError(err));
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.setHasSecondPeriod = (day, hasSecondPeriod) => {
    day.hasSecondPeriod = hasSecondPeriod;
  };

  $scope.applyToAll = day => {
    schedule.applyToAll(day, $scope.line.schedule);
  };

  $scope.validateNumber = () => {
    let number = parseInt($scope.line.maxNumbers);
    number = isNaN(number) ? 0 : number;
    $scope.line.maxNumbers = number;
  };
};

module.exports = module.controller('LineModalController', LineModalController);
