import angular from 'angular';
import { filter, take } from 'lodash';
import '../app.constants';
import '../resources/totem';
import '../resources/area';
import '../resources/line';
import '../resources/schema';
import '../services/subscription';
import '../services/totem';
import { CreatedFromEnum } from '../utils/createdFromEnum';

const module = angular.module('whyline.services.totem-tv', [
  'whyline.constants',
  'whyline.resources.totem',
  'whyline.resources.area',
  'whyline.resources.line',
  'whyline.resources.schema',
  'whyline.services.subscription',
  'whyline.services.totem',
]);

let SAFE_STATES;
let Area;
let Subscription;
let SubscriptionService;
let Line;
let Schema;
let $state;
let ErrorService;
let $window;

let CurrentItem;
let Queue;
let enqueueIn;
let Stack = [];
let validate;
let toValidate;
let historyButtons = {
  back: false,
  forward: false,
  browserBack: false,
  'status-text': false,
};

const populateItem = item => {
  if (item.type === 'sector' && item._id) {
    item.linesPopulated = filter(item.tree, { type: 'line' });
    item.schemasPopulated = filter(item.tree, { type: 'sector' });
    item.areasPopulated = filter(item.tree, { type: 'area' });
    return item;
  } else if ((item.type === 'line' || item.type === 'area') && item._id) {
    return item;
  } else {
    console.error('unable to populate item that is not line, area or schema', item);
    return item;
  }
};

const getPath = () => {
  let path = [];
  Stack.forEach(item => {
    let toSave = {};
    toSave.name = item.label || item.name;
    toSave.type = item.type;
    toSave.realType = item.realType;
    toSave._id = item._id;
    if (item.type === 'area') {
      toSave.ordered = item.ordered;
    }
    path.push(toSave);
  });
  return path;
};

const resetStack = () => {
  Stack = [];
};

class TotemTvService {

  static $inject = ['Area', 'Line', 'Schema', '$state', 'Subscription', 'SubscriptionService', 'ErrorService', 'SAFE_STATES', '$window', 'PlaceService', 'TotemService'];

  selectedIdType = undefined;

  constructor(injectedArea, injectedLine, injectedSchema, injected$state, injectedSubscription, injectedSubscriptionService, injectedErrorService, injectedSAFE_STATES, injected$window, PlaceService, TotemService) {
    SAFE_STATES = injectedSAFE_STATES;
    Area = injectedArea;
    Line = injectedLine;
    Schema = injectedSchema;
    $state = injected$state;
    Subscription = injectedSubscription;
    SubscriptionService = injectedSubscriptionService;
    ErrorService = injectedErrorService;
    $window = injected$window;
    this.PostMessage = null;
    this.SelectedService = null;

    const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

    this.selectedIdType = identifications.find(id => id.default);
    this.totem = {};

    TotemService.GetOnePopulatedAsPromiseFromServer($state.params.totemId)
      .then(totem => {
        this.totem = totem;
      });
  }

  TOASTER_OPTIONS = { timeOut: 10000 };

  GetButtonStatus() {
    return historyButtons;
  }

  SetButtonStatus(which, value) {
    switch (which) {
      case 'both':
        historyButtons.back = value;
        historyButtons.forward = value;
        break;
      case 'back':
      case 'forward':
      case 'status-text':
      case 'browserBack':
        historyButtons[which] = value;
        break;
    }
  }

  EmptyPerson() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      idType: this.selectedIdType ? this.selectedIdType.key : undefined,
      id: '',
      phone: {
        countryCode: '',
        full: ''
      }
    };
  }

  SetQueue(queue) {
    Queue = queue;
  }

  SetEnqueueIn(data) {
    enqueueIn = data;
  }

  GetCurrent() {
    const currentStep = $state.params.n;
    if (currentStep > Stack.length) {
      Stack = [];
      $state.go(SAFE_STATES.totemTvStep, { n: 0 });
    } else if (currentStep != 0) {
      CurrentItem = Stack[currentStep - 1];
    } else if (currentStep != 0) {
      CurrentItem = Stack[currentStep - 1];
    }
    return CurrentItem;
  }

  GetTotem(totem) {
    this.totem = totem;
  }

  Select(item) {
    // Set next number step
    const currentStep = $state.params.n;
    // Populate select item
    CurrentItem = populateItem(item);
    if (Stack[currentStep]) {
      Stack[currentStep] = CurrentItem;
      Stack = take(Stack, currentStep + 1);
    } else {
      Stack.push(CurrentItem);
    }
    const nextStep = Stack.length;
    if (item.type === 'sector' || item.type === 'area' && item.ordered === false) {
      $state.go(SAFE_STATES.totemTvStep, { n: nextStep });
    }
  }

  GetPath = getPath;
  ResetStack = resetStack;

  Validate(evaluation, obj) {
    validate = evaluation;
    toValidate = obj;
  }

  GetLineToEnqueue() {
    return Stack[Stack.length - 1];
  }

  SetPostMessage(message) {
    this.PostMessage = message;
  }

  SetSelectedService(service) {
    this.SelectedService = service;
  }

  History = direction => {

    if (direction === 'browserBack') {
      $window.history.back();
    }

    // Going back
    if (direction === 'back') {
      if ($state.current.name === SAFE_STATES.totemTvStep && $state.params.n != 0) {
        $state.go(SAFE_STATES.totemTvStep, { n: $state.params.n - 1 });
      }
      if ($state.current.name === SAFE_STATES.totemTvStep && $state.params.n == 0) {
        resetStack();
        $state.go(SAFE_STATES.totemTvWelcome);
      }
      if ($state.current.name === SAFE_STATES.totemTvStatus) {
        resetStack();
        $state.go(SAFE_STATES.totemTvWelcome);
      }
      if ($state.current.name === SAFE_STATES.totemTvUser && $state.params.step === 'identification') {
        $window.history.back();
      }
      if ($state.current.name === SAFE_STATES.totemTvUser && $state.params.step === 'info') {
        $window.history.back();
      }

      // Appointment
      if ($state.current.name === SAFE_STATES.totemTvAppointmentSearch) {
        $state.go(SAFE_STATES.totemTvWelcome);
      }

      // Appointment
      if ($state.current.name === SAFE_STATES.totemTvAppointmentResults) {
        $state.go(SAFE_STATES.totemTvWelcome);
      }
    }
    // Goign forward
    if (direction === 'forward') {
      if ($state.current.name === SAFE_STATES.totemTvStatus) {
        $state.go(SAFE_STATES.totemTvUser, { step: 'identification' });
      }
      if ($state.current.name === SAFE_STATES.totemTvUser && $state.params.step == 'identification') {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.totemTvUser, { step: 'info' });
        }
      }
      if ($state.current.name === SAFE_STATES.totemTvUser && $state.params.step == 'info') {
        if (validate(toValidate)) {
          // toValidate in this case, is the person object inserted in the totem form
          const createdFrom = CreatedFromEnum.totemOperator;

          SubscriptionService.Create(toValidate, enqueueIn, createdFrom, getPath())
            .then(() => {
              $state.go(SAFE_STATES.totemTvThanks, { name: toValidate.firstName });
            })
            .catch(ErrorService.handler);
        }
      }

      // Appointment
      if ($state.current.name === SAFE_STATES.totemTvAppointmentSearch) {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.totemTvAppointmentResults, { fields: toValidate });
        }
      }

      // Appointment
      if ($state.current.name === SAFE_STATES.totemTvAppointmentResults) {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.totemTvAppointmentConfirm, { appointmentId: toValidate._id });
        }
      }
    }
  };

}

module.exports = module.service('TotemTvService', TotemTvService);
