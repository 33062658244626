import angular from 'angular';
import '../resources/category';

const module = angular.module('whyline.services.category', [
  'whyline.resources.category'
]);

let Category;

class CategoryService {

  static $inject = ['Category'];

  constructor(injectedCategory) {
    Category = injectedCategory;
  }

  GetAll() {
    return Category.GetAllCategories();
  }

  GetAllFromServer() {
    return Category.GetAllCategories({force: true});
  }

  GetAllAsPromise() {
    return Category.GetAllCategories({promise: true});
  }

}

module.exports = module.service('CategoryService', CategoryService);
