import angular from 'angular';

import { cloneDeep } from 'lodash';

import '../resources/organization-user';
import '../services/notifications';
import '../services/area';
import '../services/globals';
import '../app.constants';

const module = angular.module('whyline.services.organizationuser', [
  'whyline.resources.organizationuser',
  'whyline.services.notifications',
  'whyline.services.area',
  'whyline.services.globals',
  'whyline.constants'
]);

let User;
let GlobalsService;
let NotificationService;
let $translate;
let UserActions;

class OrganizationUserService {

  static $inject = ['OrganizationUser', 'GlobalsService', 'NotificationService', '$translate', 'UserActions'];
  static Me = undefined;
  static CurrentRole = undefined;

  constructor(injectedUser, injectedGlobalsService, injectedNotificationService, injected$translate, _UserActions) {
    /*@ngInject*/
    User = injectedUser;
    GlobalsService = injectedGlobalsService;
    NotificationService = injectedNotificationService;
    $translate = injected$translate;
    UserActions = _UserActions;
  }

  Create(data) {
    const newUser = new User(data);
    return User.Save(newUser);
  }

  Copy(user) {
    const copy = cloneDeep(user);
    return new User(copy);
  }

  Update(user) {
    return User.Save(user);
  }

  Remove(userId) {
    const user = User.FindById(userId);
    return User.Remove(user);
  }

  GetAll() {
    return User.FetchAll();
  }

  GetAllAsPromise() {
    return User.FetchAll({promise: true});
  }

  GetAllAsPromiseFromServer() {
    return User.FetchAll({promise: true, force: true});
  }

  GetOne(userId) {
    return User.FindById(userId);
  }

  GetOneAsPromise(userId) {
    return User.FindById(userId, {promise: true});
  }

  GetOneAsPromiseFromServer(userId) {
    return User.FindById(userId, {promise: true, force: true});
  }

  SwitchOrganizationEnable(user) {
    return new User(user).enableForOrganization(!user.enabled);
  }

  DeleteUserForOrganization(userId, organizationId, deleteUserForOrganizationAndPlaces) {
    return User.deleteUserForOrganization(userId, organizationId, deleteUserForOrganizationAndPlaces);
  }
}

module.exports = module.service('OrganizationUserService', OrganizationUserService);
