import angular from 'angular';

const module = angular.module('whyline.directives.numbersOnly', []);

const allowKeys = {
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  scape: 27,
  backspace: 8,
  tab: 9,
  enter: 13,
  delete: 46,
  0: 48,
  1: 49,
  2: 50,
  3: 51,
  4: 52,
  5: 53,
  6: 54,
  7: 55,
  8: 56,
  9: 57,
  numPad0: 96,
  numPad1: 97,
  numPad2: 98,
  numPad3: 99,
  numPad4: 100,
  numPad5: 101,
  numPad6: 102,
  numPad7: 103,
  numPad8: 104,
  numPad9: 105,
};

const inputTypeNumber = event => {
  const key = event.which ? event.which : event.keyCode;
  if (!Object.values(allowKeys).includes(key)) {
    event.preventDefault();
    return true;
  }
};

const numbersOnly = () => ({
  restrict: 'A',
  require: 'ngModel',
  link: (scope, element, attr, ngModelCtrl) => {
    if (attr.type === 'number') {
      element.on('keydown', function (event) {
        inputTypeNumber(event);
      });
    } else {
      const fromUser = text => {
        if (text) {
          const transformedInput = text.replace(/[^0-9]/g, '');

          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }
          return transformedInput;
        }
        return undefined;
      };
      ngModelCtrl.$parsers.push(fromUser);
    }
  },
});

module.exports = module.directive('numbersOnly', numbersOnly);
