import angular from 'angular';
import '../../services/globals';
import '../../resources/resource';

const module = angular.module('whyline.controllers.places', [
  'whyline.services.globals',
  'whyline.resources.resource',
]);

const PlacesController = ($scope, GlobalsService, Resource) => {
  /*@ngInject*/

  Resource.RestartCache();

  $scope.isPlaceSelected = () => {
    const isSelected = GlobalsService.CurrentPlace && GlobalsService.CurrentPlace._id;
    $scope.clickMessage = isSelected ? '' : 'PLEASE, SELECT A PLACE';
    return isSelected;
  };
};

module.exports = module.controller('PlacesController', PlacesController);
