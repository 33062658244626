import angular from 'angular';
import '../services/place';

const module = angular.module('whyline.directives.plan-control', [
  'whyline.services.place',
]);


const planControl = (PlaceService, $compile, $translate) => {

  const POPOVER_TITLE = $translate.instant('update_your_plan'),
        POPOVER_CONTENT = $translate.instant('plan_control_html_desc');

  return {
    restrict: 'A',
    terminal: true,
    priority: 50000, // high priority to compile this before directives of lower prio
    compile: function compile(element, attrs) {

      const planControl = attrs.planControl;

      element.removeAttr('plan-control'); // avoid indefinite loop
      element.removeAttr('data-plan-control');

        return {
            pre: function preLink(scope, iElement, iAttrs, controller) {  },
            post: function postLink(scope, iElement, iAttrs, controller) {
              PlaceService.GetCurrentAsPromise()
              .then(place => {

                /**
                  * place.plan  = {
                  *  hasLimits: true
                  *  maxAreas: 0
                  *  maxDisplays: 1
                  *  maxSector: 0
                  *  maxTotem: 1
                  *  name: "Merchant-free"
                  *  type: "free"
                  * }
                  */

                  if(place && place.enterpriseType === 'whyline') {

                    const isFree = place.plan && place.plan.type && place.plan.type === 'free';
                    const mustDisable = isFree && planControl === 'disabled';
                    const isExpression = isFree && planControl.trim() && planControl !== 'disabled' && planControl !== 'hide';
                    const mustHide = isFree && planControl === 'hide';
                    const originalNgClick = iElement[0].getAttribute('ng-click');
                    const stopProp = iElement[0].getAttribute('plan-stop-prop');

                    if(mustDisable || isExpression) {
                      iElement[0].setAttribute('popover-title', POPOVER_TITLE);
                      iElement[0].setAttribute('popover-trigger', "'outsideClick'");
                      iElement[0].setAttribute('uib-popover-html', `'${POPOVER_CONTENT}'`);
                      iElement[0].setAttribute('popover-placement', attrs.planControlPlacement || 'auto');
                      iElement[0].setAttribute('popover-class', 'whyline-popover');
                      if(!!stopProp && !!stopProp.trim()) iElement[0].setAttribute('stop-prop', stopProp);
                    }

                     if(mustDisable) {
                      iElement.addClass('disabled');
                      iElement.addClass('allow-pointer-events');
                      iElement[0].setAttribute('plan-click', true);
                      iElement[0].setAttribute('plan-click-static', 'true');
                      iElement[0].setAttribute('ng-click', `false && ${originalNgClick}`);
                     }

                     if(isExpression) {
                      iElement[0].setAttribute('ng-click', `!(${planControl}) && ${originalNgClick}`);
                      iElement[0].setAttribute('plan-click', planControl);
                      iElement.removeAttr('plan-clic-static');
                      iElement[0].setAttribute('popover-enable', planControl);
                     }

                     const originalNgIfExpression = attrs.ngIf ? `${attrs.ngIf} && ` : '';

                    iElement[0].setAttribute('ng-if', originalNgIfExpression + !mustHide);
                  }
                  $compile(iElement[0].children)(scope);
                  $compile(iElement)(scope);
              });
            }
        };
     }
  };
};

module.exports = module.directive('planControl', planControl);
