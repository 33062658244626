import angular from 'angular';
import { intersection } from 'lodash';
import './user';

const module = angular.module('whyline.services.access-control', [
  'whyline.services.user',
]);

let UserService;
const checkIfIndividualPermissionMeetRequiredActions = (permission, requestedUserAction, resourceTargetId) => {
  let authorized = false;
  permission.resourceGroup.resources.forEach(resource => {
    if (!authorized) {
      if (checkIfResourceAndRoleMeetRequestedActions(permission, resource, requestedUserAction, resourceTargetId, permission.resourceGroup.placeId)) {
        authorized = true;
      }
    }
  });

  return authorized;
};


const ActionScope = userAction => {
  let actionPart = userAction[0].split('.');
  let actionScope = actionPart[0];

  switch (userAction[0]) {
    case 'area.enabled' :
    case 'appointment.create' :
    case 'area.create' :
    case 'line.create' :
    case 'place.create' :
    case 'role.create' :
    case 'subscription.create' :
    case 'resourceGroup.create' :
    // Sections
    case 'section.areas' :
    case 'section.displays' :
    case 'section.lines' :
    case 'section.places' :
    case 'section.resource-groups' :
    case 'section.roles' :
    case 'section.sectors' :
    case 'section.totems' :
    case 'section.devices' :
    case 'section.users' :
    case 'section.now-serving' :
    case 'section.reception' :
    case 'section.sigeci-csv' :
    case 'section.statistics':
    case 'section.schedule':
    case 'section.banners':
    case 'section.organization-conditions':
    case 'section.organization-scenarios':
    case 'section.scenarios':
    // Sector
    case 'sector.create' :
    case 'sector.delete' :
    case 'sector.read' :
    case 'sector.update' :
    // Box
    case 'box.create' :
    case 'box.delete' :
    case 'box.update' :
    // Display
    case 'display.create' :
    case 'display.delete' :
    case 'display.update' :
    case 'display.read' :
    // Totem
    case 'totem.create' :
    case 'totem.delete' :
    case 'totem.update' :
    case 'totem.read' :
    // Device
    case 'device.create' :
    case 'device.delete' :
    case 'device.update' :
    case 'device.read' :
    // User
    case 'user.create' :
    case 'user.delete' :
    case 'user.read' :
    case 'user.update' :
      // Scope is PLACE
      return 'place';
    // Appointment
    case 'appointment.delete' :
    case 'appointment.forward' :
    case 'appointment.read' :
    case 'appointment.upload-csv' :
    case 'appointment.update' :
    case 'subscription.update' :
    // Area
    case 'area.call-next' :
    case 'area.call-to' :
    case 'area.clear' :
    case 'area.forward' :
    case 'area.join' :
    case 'area.read' :
    case 'area.remove-subscription' :
    case 'area.remove-subscription-not-checked-in' :
    case 'area.update' :
    case 'area.delete' :
    case 'line.call-next' :
    case 'line.call-to' :
    case 'line.clear' :
    case 'line.delete' :
    case 'line.forward' :
    case 'line.join' :
    case 'line.read' :
    case 'line.remove-subscription' :
    case 'line.remove-subscription-not-checked-in' :
    case 'line.update' :
    case 'place.create' :
    case 'place.delete' :
    case 'place.read' :
    case 'place.update' :
    case 'resourceGroup.delete' :
    case 'resourceGroup.read' :
    case 'resourceGroup.update' :
    case 'role.delete' :
    case 'role.read' :
    case 'role.update' :
    case 'subscription.call-to' :
    case 'subscription.confirm' :
    case 'subscription.delete' :
    case 'subscription.forward' :
    case 'subscription.read' :
    // Messages
    case 'message.update':
    // Organization
    case 'organization.create':
    case 'organization.read':
    // Plans
    case 'plan.read':
    // Priority
    case 'priority.courtesy':
    case 'priority.authorized':
      // Scope is the ACTION SCOPE
      return actionScope;
  }
};

/**
 * @param permission
 * @param resource
 * @param requestedUserAction
 * @param resourceTargetId
 */
const checkIfResourceAndRoleMeetRequestedActions = (permission, resource, requestedUserAction, resourceTargetId, placeId) => {
  // Check if role satisfys requested actions for the resource
  const actionScope = ActionScope(requestedUserAction);

  const roleSatisfyRequestedAction = intersection(requestedUserAction, permission.role.actions).length > 0;

  const currentResourceIsTargetResource = resource._id.toString() === resourceTargetId;

  if (currentResourceIsTargetResource && roleSatisfyRequestedAction) {
    return true;
  }

  const resourceIsArea = resource.type === 'area';
  const resourceTargetIsAlineInsideResourceArea = intersection(resource.lines, [resourceTargetId]).length > 0;
  if (resourceIsArea && resourceTargetIsAlineInsideResourceArea) {
    if (roleSatisfyRequestedAction) {
      return true;
    }
  }

  const resourceIsPlace = resource.type === 'place';
  const resourceTargetBelongsToPlace = intersection(resource.lines, [resourceTargetId]).length > 0  || intersection(resource.areas, [resourceTargetId]).length > 0;

  if (resourceIsPlace && resourceTargetBelongsToPlace) {
    if (roleSatisfyRequestedAction) {
      return true;
    }
  }

  if (actionScope === 'place' && roleSatisfyRequestedAction && placeId === resourceTargetId) {
    return true;
  }

  return false;
};

class AccessControlService {

  static $inject = ['UserService'];

  constructor(injectedUserService) {
    UserService = injectedUserService;
  }
  isDisabledForPlace = placeId => {
    return UserService.DisabledForPlace(placeId);
  }

  isDisabledForOrganization = organizationId => {
    return UserService.DisabledForOrganization(organizationId);
  }

  CanPerformAction = (requestedUserAction, resourceTargetId) => {
    let authorized = false;

    return UserService.isSuperAdmin()
    .then(sudo => {
      if (!sudo) {
        return UserService.GetPermissions()
        .then(permissions => {
          permissions.forEach(permission => {
            if (!authorized) {
              if (checkIfIndividualPermissionMeetRequiredActions(permission, requestedUserAction, resourceTargetId)) {
                authorized = true;
              }
            }
          });
          return authorized;
        });
      } else {
        authorized = true;
        return authorized;
      }
    });
  }
}

module.exports = module.service('AccessControlService', AccessControlService);
