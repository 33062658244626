const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const getDaySchedule = (day, index) => ({
  firstPeriod: {
    opening: '0800',
    closing: '1200',
    quota: 0
  },
  secondPeriod: {
    opening: '1600',
    closing: '2000',
    quota: 0
  },
  hasSecondPeriod: false,
  name: day,
  open: index === 0 || index === 6 ? false : true,
  number: index,
  order: index === 0 ? 6 : index - 1,
});

const create = () => days.map(getDaySchedule);

const getHours = () => {
  const hours = [];
  for(let n = 0; n < 24; n++) {
    if(n > 9) {
      hours.push({hour: `${n}00`, displayHour: `${n}:00`});
      hours.push({hour: `${n}30`, displayHour: `${n}:30`});
    } else {
      hours.push({hour: `0${n}00`, displayHour: `0${n}:00`});
      hours.push({hour: `0${n}30`, displayHour: `0${n}:30`});
    }
  }
  hours.push({
    hour: '2359', displayHour: '23:59'
  });
  return hours;
};

const applyToAll = (data, schedule) => {
  schedule.forEach(day => {
    day.firstPeriod.opening = data.firstPeriod.opening;
    day.firstPeriod.closing = data.firstPeriod.closing;
    day.firstPeriod.quota = data.firstPeriod.quota;
    day.secondPeriod.opening = data.secondPeriod.opening;
    day.secondPeriod.closing = data.secondPeriod.closing;
    day.secondPeriod.quota = data.secondPeriod.quota;
    day.hasSecondPeriod = data.hasSecondPeriod;
  });
};

const sort = schedule => days.map(day => {
  for(let i = schedule.length - 1; i >= 0; i--) {
    if(schedule[i].name === day) {
      return schedule[i];
    }
  }
});

const reOrder = schedule => {
  let firstElem = schedule.shift();
  schedule.push(firstElem);
  return schedule;
};

const format = (schedule, addColon = true) => {
  if (addColon) {
    schedule.map(s => {
      s.firstPeriod.opening = `${s.firstPeriod.opening.slice(0, 2)}:${s.firstPeriod.opening.slice(2, 4)}`;
      s.firstPeriod.closing = `${s.firstPeriod.closing.slice(0, 2)}:${s.firstPeriod.closing.slice(2, 4)}`;
      s.secondPeriod.opening = `${s.secondPeriod.opening.slice(0, 2)}:${s.secondPeriod.opening.slice(2, 4)}`;
      s.secondPeriod.closing = `${s.secondPeriod.closing.slice(0, 2)}:${s.secondPeriod.closing.slice(2, 4)}`;
      return s;
    });
  } else {
    schedule.map(s => {
      s.firstPeriod.opening = s.firstPeriod.opening.replace(/[^0-9]/g, '');
      s.firstPeriod.closing = s.firstPeriod.closing.replace(/[^0-9]/g, '');
      s.secondPeriod.opening = s.secondPeriod.opening.replace(/[^0-9]/g, '');
      s.secondPeriod.closing = s.secondPeriod.closing.replace(/[^0-9]/g, '');
      return s;
    });
  }

  return schedule;
};

module.exports = {
  getDaySchedule,
  create,
  getHours,
  applyToAll,
  sort,
  reOrder,
  format
};
