import angular from 'angular';
import '../app.constants';
import './resource';
import './line';
import './area';
import './schema';
import '../services/place';
import { isEmpty, values } from 'lodash';

const module = angular.module('whyline.resources.device', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.services.place'
]);

const DeviceFactory = (API_URL, $http, Resource, $timeout, PlaceService) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'device?placeId';
  const _schema = {
    deviceId: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
    alias: {
      type: String,
      required: false,
      default: '',
    },
    placeId: {
        type: String,
        required: true,
        default: '',
      },
  };

  const Device = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
};

    Device.prototype = new Resource(_type, _schema, Device);

    Resource.Extend(_type, Device);

    Device.getLinkedDevices = (promise) => {
    const url = `${API_URL}${_type}/linked`;
    const place = PlaceService.GetCurrent();
    const cacheUrl = `${API_URL}device/linked?placeId=${place._id}`;
    const cacheExists = Device.SearchCacheExists(cacheUrl);
    const list = Device.GetCacheSearch(cacheUrl);
    if (isEmpty(list) && !cacheExists) {
      return $http.get(url)
        .then(devices => {
          if (devices) {
            Device.UpdateCacheSearch(cacheUrl, values(devices.data), {});
          }
          return values(devices.data);
        });
    }
    if(promise){
      return Promise.resolve(list)
    } else {
      return list;
    }
    
    };
    Device.getUnlinkedDevices = () => {
        const url = `${API_URL}device/unlinked`;
        return $http.get(url).then(response => response.data);
      };
    
    Device.getById = (deviceId) => {
        const url = `${API_URL}${_type}/${deviceId}`;
        return $http.get(url).then(response => response.data);
      };

    Device.linkToPlace = (name, alias) => {
        const url = `${API_URL}${_type}/link-to-place`;
        const body = {
            alias: alias,
            deviceId: name
        }
        return $http.put(url, body).then(response => {
          const device = response.data
          Device.UpdateCache(device._id, device);
          return Promise.resolve(device);
         });
      };

    Device.unlinkFromPlace = (deviceId) => {
        const url = `${API_URL}${_type}/${deviceId}/unlink-from-place`;
        return $http.put(url).then(response => {
          const device = response.data
          Device.UpdateCache(device._id, device);
          return Promise.resolve(device);
         });
    };

    Device.rename = (deviceId, alias) => {
        const place = PlaceService.GetCurrent();
        const url = `${API_URL}device/${deviceId}`;
        const body = {
            alias: alias,
            placeId: place._id
        }
        return $http.put(url, body).then(response => {
          const device = response.data
          Device.UpdateCache(device._id, device);
          return Promise.resolve(device);
         });
      };

    
    const validityTime = 5 * 60 * 1000;
    Device.InitCache(validityTime);

return Device;
}
module.exports = module.factory('Device', DeviceFactory);
