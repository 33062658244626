import angular from 'angular';
import '../app.constants';
import './place';
import moment from 'moment-timezone';
import { includes } from 'lodash';

const module = angular.module('whyline.services.date-handler', [
  'whyline.constants',
  'whyline.services.place',
]);

let PlaceService;
let CurrentPlace;

class DateHandlerService {

  static $inject = ['PlaceService'];

  constructor(injectedPlaceService) {
    PlaceService = injectedPlaceService;
    CurrentPlace = PlaceService.GetCurrent();
  }

    /**
   * Apply timezone to a date and get as neutral
   *
   * @param {date} date or null by default
   * @return {date}
   */
  getNeutralDate(date = null) {
    if (date) {
      return new Date(moment(date).tz(CurrentPlace.timezone).format('MM-DD-YYYY'));
    }
    return new Date(moment.tz(CurrentPlace.timezone).format('MM-DD-YYYY'));
  }

  /**
   * Get UTC Offset from current place
   *
   * @return {string}
   */
  getCurrentUTCOffset() {
    return moment.tz(CurrentPlace.timezone).format('Z').toString();
  }

  /**
   * @param {object} dynamicPeriod Area configuration object.
   * @param {array} daysClosed Array with days closed.
   * @param {date} dayToCheck Date to compare.
   * @param {string} timezone Place timezone.
   *
   * @return {boolean}
   */
  dynamicPeriod(dynamicPeriod, daysClosed, dayToCheck, timezone) {
    const todayPlace = moment().tz(timezone).format('MM-DD-YYYY');
    dayToCheck = moment(dayToCheck).format('MM-DD-YYYY');

    let isOpen = false;
    const from = dynamicPeriod.sinceNextDays > 0 ?
      moment(todayPlace).add(dynamicPeriod.sinceNextDays, 'days').format('MM-DD-YYYY') : todayPlace;
    let to = null;
    if (dynamicPeriod.untilNextDays === 0) {
      to = moment(todayPlace).add(1, 'year').format('MM-DD-YYYY');
    } else {
      const days = dynamicPeriod.sinceNextDays + dynamicPeriod.untilNextDays;
      to = moment(todayPlace).add(days, 'days').format('MM-DD-YYYY');
    }
    isOpen = moment(dayToCheck).isSameOrAfter(from) && moment(dayToCheck).isSameOrAfter(todayPlace)
      && moment(dayToCheck).isSameOrBefore(to);

    return isOpen && !includes(daysClosed, moment(dayToCheck).day());
  }

  /**
   * @param {object} fixedPeriod Area configuration object.
   * @param {array} daysClosed Array with days closed.
   * @param {date} dayToCheck Date to compare.
   * @param {string} timezone Place timezone.
   *
   * @return {boolean}
   */
  fixedPeriod(fixedPeriod, daysClosed, dayToCheck, timezone) {
    const todayPlace = moment().tz(timezone).format('MM-DD-YYYY');
    dayToCheck = moment(dayToCheck).format('MM-DD-YYYY');

    const from = moment(fixedPeriod.from).format('MM-DD-YYYY');
    const to = moment(fixedPeriod.to).format('MM-DD-YYYY');

    const isOpen = moment(dayToCheck).isSameOrAfter(from) && moment(dayToCheck).isSameOrAfter(todayPlace) && moment(dayToCheck).isSameOrBefore(to);
    return isOpen && !includes(daysClosed, moment(dayToCheck).day());
  }
}

module.exports = module.service('DateHandlerService', DateHandlerService);
