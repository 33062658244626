import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.holiday',
  ['whyline.resources.resource',
    'whyline.resources.area'
  ]);

const HolidayFactory = (Resource, Area, $http, API_URL) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'holiday?placeId';

  const _schema = {
    _id: {
      type: String
    },
    areas: {
      type: [{
        _id: String,
        label: String,
      }],
    },
    date: {
      type: Object,
      from: {
        type: String
      },
      to: {
        type: String
      }
    },
    description: {
      type: String
    },
    applyToAll: {
      type: Boolean
    },
    isPeriod: {
      type: Boolean
    }
  };

  const Holiday = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Holiday.prototype = new Resource(_type, _schema, Holiday);

  Resource.Extend(_type, Holiday);

  const validityTime = 10 * 60 * 1000;

  Holiday.InitCache(validityTime);

  Holiday.GetAllForArea = areaId => {
    let url = `${API_URL}holiday/by-area/${areaId}?placeId`;
    return $http.get(url);
  };

  return Holiday;
};

module.exports = module.factory('Holiday', HolidayFactory);
