import angular from 'angular';

const module = angular.module('whyline.modals.holiday-info', [
]);

 const HolidayInfoController = ($scope, $uibModalInstance, holiday) => {
  'ngInject';

  $scope.holiday = holiday;

   // Close modal
  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller('HolidayInfoController', HolidayInfoController);
