import angular from 'angular';
import { get, includes, isEmpty } from 'lodash';
import '../../components/status';
import '../../services/display';
import '../../services/place';
import 'angular-ui-tree';

const module = angular.module('whyline.modals.display', [
  'whyline.components.status',
  'whyline.services.display',
  'whyline.services.place',
  'ui.tree',
]);

const DisplayModalController = (
  $scope, 
  $timeout, 
  $uibModalInstance, 
  $translate, 
  $state, 
  NotificationService, 
  DisplayService, 
  displayId, 
  currentPlace, 
  PlaceService
  ) => {
  'ngInject';

  $scope.display = {
    lines: [],
    areas: [],
    banners: [],
    linesPopulated: {},
    areasPopulated: {},
    bannersPopulated: {},
    hasMultimedia: true,
    showAreaName: true,
    isCallNext: false,
    textToSpeech: false
  };

  $scope.place = currentPlace;
  $scope.lineAreaItemsPopulated = {};
  $scope.bannerItemsPopulated = {};
  const populateAlreadyShown = () => {
    $scope.lineAreaItemsPopulated = {
      ...$scope.display.areasPopulated,
      ...$scope.display.linesPopulated,
    };

    $scope.bannerItemsPopulated = {
      ...$scope.display.bannersPopulated,
    };
  };

  PlaceService.GetOneAsPromiseFromServer($state.params.placeId).then((place) => {
    $scope.place = place;
    $scope.placeTts = get(place, 'configuration.display.ttsEnabled.enabled', false);
    if (!displayId) {
      $scope.display.textToSpeech = $scope.placeTts;
    }
  });

  const validate = () => {
    const name = $scope.display.name;
    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_display_name'));
      return false;
    }
    if (name.length < 2) {
      NotificationService.Warning($translate.instant('min_char_display_name'));
      return false;
    }
    if (!$scope.display.lines.length && !$scope.display.areas.length) {
      NotificationService.Warning($translate.instant('select_min_fila'));
      return false;
    }
    return true;
  };

  const dismissOnError = err => {
    const message = get(err, 'data.message', false);
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
    if( message && message === 'banner.not-available' ){
      const bannersNotAvailable = get(err, "data.banners", []);
      bannersNotAvailable.map( banner => $scope.display.bannersPopulated[banner._id].isDeleted = true );
      NotificationService.Error($translate.instant('banner-not-available'));
    }
    DisplayService.GetAllAsPromiseFromServer();
  };

  const removeItem = (idToRemove, displayItemsArray, displayPopulatedItems) => {
    const elementIndex = displayItemsArray.indexOf(idToRemove);
    if (elementIndex >= 0) {
      displayItemsArray.splice(elementIndex, 1);
      Reflect.deleteProperty(displayPopulatedItems, idToRemove);
      populateAlreadyShown();
    }
  };

  if (displayId) {
    DisplayService.GetOnePopulatedAsPromiseFromServer(displayId)
      .then(display => {
        $timeout(() => {
          $scope.display = display;
          populateAlreadyShown();
        });
      })
      .catch(err => dismissOnError(err));
  }

  $scope.save = () => {
    if (validate()) {
      if (displayId) {
        return DisplayService.Update($scope.display)
          .then(() => {
            NotificationService.Success($translate.instant('display_update_sicc'));
            $uibModalInstance.close('cancel');
          })
          .catch(err => dismissOnError(err));
      }

      return DisplayService.Create($scope.display).then(() => {
        NotificationService.Success($translate.instant('display_create_succ'));
        $uibModalInstance.close('cancel');
      })
        .catch(err => dismissOnError(err));
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.selectItem = selection => {
    const displayElementArray = $scope.display[`${selection.type}s`];
    const displayItemsPopulated = $scope.display[`${selection.type}sPopulated`];

    if (!includes(displayElementArray, selection._id)) {
      displayElementArray.push(selection._id);
      displayItemsPopulated[selection._id] = selection;
      populateAlreadyShown();
    } 
  };

  $scope.remove = (id, type) => {
    const displayItemsArray = $scope.display[type];
    const displayPopulatedItems = $scope.display[`${type}Populated`];
    removeItem(id, displayItemsArray, displayPopulatedItems);
  };
};

module.exports = module.controller('DisplayModalController', DisplayModalController);
