import angular from 'angular';
import '../app.constants';
import { get } from 'lodash';
import '../resources/totem';
import '../factories/socket.io';
import '../resources/line';
import '../resources/area';
import '../services/errors';
import '../services/socket';

const module = angular.module('whyline.sockets.totem', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.line',
  'whyline.resources.area',
  'whyline.resources.totem',
  'whyline.services.errors',
  'whyline.services.socket',
]);

const TotemSocket = (Socket, Totem, $state, SAFE_STATES, ErrorService, Line, Area, $rootScope, SocketService) => {
  $rootScope.$on('socketReady', () => {

    const updateTotemAndRefresh = function() {
      const totemId = get($state.params, 'totemId', null);
      if(totemId !== null) {
        Totem.FindById(totemId, {promise: true, force: true})
        .then(totem => {
          Totem.UpdateCache(totemId, totem);
          if($state.params.totemId === totemId) {
            return $state.go(SAFE_STATES.totemTvWelcome, {}, {reload: SAFE_STATES.totemTvWelcome});
          }
        })
        .catch(ErrorService.handler);
      }
    };

    SocketService.GetInstance().then(socket => {
      socket.on('totem:created', totem => {
        Totem.UpdateCache(totem._id, totem);
      });
      socket.on('totem:updated', totem => {
        Totem.UpdateCache(totem._id, totem);
        if($state.params.totemId === totem._id) {
          $state.go(SAFE_STATES.totemTvWelcome, {}, {reload: true});
        }
      });
      socket.on('totem:deleted', totem => {
        Totem.RemoveFromCache(totem._id, totem._type);
      });

      socket.on('line:deleted', line => {
        Line.RemoveFromCache(line._id, line.type);
        updateTotemAndRefresh();
      });

      socket.on('area:deleted', area => {
        Area.RemoveFromCache(area._id, area.type);
        updateTotemAndRefresh();
      });
    });

    $rootScope.$on('socket:totem-connected', () => {
      const socket = SocketService.GetTotemInstance();

      socket.on('totem:identify', data => {
        $rootScope.$emit('totem:identify', data);
      });

      socket.on('totem:refresh', data => {
        $rootScope.$emit('totem:refresh', data);
      });

      socket.on('totem:disconnect', () => {
        socket.disconnect();
      });
    });
  });
};

module.exports = module.factory('TotemSocket', (Socket, Totem, $state, SAFE_STATES, ErrorService, Line, Area, $rootScope, SocketService) => new TotemSocket(Socket, Totem, $state, SAFE_STATES, ErrorService, Line, Area, $rootScope, SocketService));
