import angular from 'angular';
import '../../services/errors';
import '../../services/subscription';
import '../../services/notifications';
import '../../services/queue';
import { get } from 'lodash';

const module = angular.module('whyline.modals.load-subscriptions', [
  'whyline.services.errors',
  'whyline.services.subscription',
  'whyline.services.notifications',
  'whyline.services.queue',
]);

const LoadSubscriptionsController = (
  $scope,
  $uibModalInstance,
  $translate,
  $timeout,
  subscription,
  currentPlace,
  currentOrganization,
  ErrorService,
  SubscriptionService,
  NotificationService,
  QueueService,
) => {
  'ngInject';
  const place = currentPlace;
  const organization = currentOrganization;
  let selectedSubscription;
  $scope.currentSubscription = subscription;
  $scope.subscriptionsList = [];
  $scope.personId = get($scope.currentSubscription, 'externalData.personId', null);
  const totemId = get($scope.currentSubscription, 'externalData.totemId', '');
  let selectedPerson;
  $scope.peopleList = [];
  const includeSubscriptionsPresents = true;
  const date = new Date().toISOString().slice(0, 19);

  const loadSubscriptions = () => {  
    SubscriptionService.SigehosSubscriptions($scope.personId, date, place._id, includeSubscriptionsPresents).then((subscriptions)=> {
      $timeout(() => {
        $scope.subscriptionsList = subscriptions;
      });
    }).catch( (error) => {
      $scope.cancel();
      NotificationService.Error($translate.instant('sigehos_subscriptions_error'));
    });
  }

  if (!$scope.personId) {
    const person = $scope.currentSubscription.person;
    SubscriptionService.SigehosPeople(person.idType, person.id, place._id, organization._id, totemId).then((people)=> {
      $timeout(() => {
        $scope.peopleList = people.persons;
      });
    }).catch( (error) => {
      $scope.cancel();
      NotificationService.Error($translate.instant('sigehos_subscriptions_error'));
    });
  } else {
    loadSubscriptions();
  }

  $scope.getSelectedSubscription = (selection) => {
    selectedSubscription = selection;
  };

  $scope.setSelectedPerson = (selection) => {
    selectedPerson = selection;
  };

  $scope.getName = (person) => {
    return `${person.selfPerceivedName ? person.selfPerceivedName : person.firstName} ${person.lastName}`;
  }

  $scope.forwardSubscription = () => {
    if (!selectedSubscription) {
      return NotificationService.Error('must_select_subscription');
    }

    const person = (selectedPerson) ? selectedPerson : $scope.currentSubscription.person;
    const id = (selectedPerson) ? selectedPerson.nro_documento.toString() : person.id.toString();
    const idType = (selectedPerson) ? selectedPerson.tipo_documento_abreviatura.toLowerCase() : person.idType;
    const payloadSubscription = {
      appointmentId: selectedSubscription.idTurno,
      path: [
        {
          _id: selectedSubscription.areaId,
          type: "area",
          realType: "process",
          name: selectedSubscription.serviceName
        }
      ],
      person: {
        firstName: person.firstName,
        lastName: person.lastName,
        id,
        idType,
        email: person.email,
      },
      externalPersonId: $scope.personId,
      botiNotificationsAccepted: $scope.currentSubscription.termsAndConditions.botiNotificationsAccepted,
      fromLoadAppointmens: true
    }

    if (person.phone.full && person.phone.countryCode) {
      payloadSubscription.person.phone = {
        full: person.phone.full,
        countryCode: person.phone.countryCode
      };
    }

    SubscriptionService.CreateFromLoadSubscriptions(payloadSubscription, organization._id, place._id, totemId, includeSubscriptionsPresents).then((subscription)=> {
      if (subscription._id) {
        NotificationService.Success($translate.instant('success'));

        const status = $scope.currentSubscription.status;

        switch (status) {
          case 'processing':
            SubscriptionService.EndSubscription($scope.currentSubscription, 'completed')
            .then(() => {
              $timeout(() => { });
            })
            .catch(ErrorService.handler);
            break;
          
          case 'waiting': 
            SubscriptionService.Remove($scope.currentSubscription._id, QueueService.currentQueue)
            .then(() => {
              $timeout(() => { });
            })
            .catch(ErrorService.handler);
            break;

          default: //Do nothing with the current subscription
        }
      } else {
        NotificationService.Error($translate.instant('err_operation'));
      }
      $scope.cancel();
    }).catch( (error) => {
      $scope.cancel();
      NotificationService.Error($translate.instant('err_operation'));
    });
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.continue = () => {
    if (!selectedPerson) {
      return NotificationService.Error('must_select_person');
    }

    $scope.personId = selectedPerson.id;
    loadSubscriptions();
  };
  
  $scope.getShortDate = (longDate) => {
    const date = new Date(longDate);
    const hour = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hour}:${minutes}`;
  }
};

module.exports = module.controller('LoadSubscriptionsController', LoadSubscriptionsController);