import angular from 'angular';
import './modals/roles';
import '../services/role';
import '../services/errors';

const module = angular.module('whyline.controllers.roles', [
  'whyline.modals.roles',
  'whyline.services.role',
  'whyline.services.errors',
]);

const RolesController = ($scope, $uibModal, RoleService, NotificationService, $translate, ErrorService, dialog) => {
  /*@ngInject*/
  $scope.title = 'roles';
  $scope.quantityOfItems = 8;

  $scope.roles = RoleService.GetAll();

  $scope.remove = (roleId, name) => {
    dialog.confirm(`¿Seguro deseas eliminar el role ${name || ''}?`)
      .then(() => {
        RoleService.Remove(roleId)
        .then(() => {
          NotificationService.Success(`El role ${name || ''} fue removido exitosamente.`);
        })
        .catch(ErrorService.handler);
      });
  };

  $scope.switch = RoleService.Switch;

  // Modal
  $scope.open = roleId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/roles-modal.html',
      size: 'lg',
      controller: 'RoleModalController',
      resolve: {
        roleId: () => roleId
      }
    });
  };
};

module.exports = module.controller('RolesController', RolesController);
