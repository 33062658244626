import angular from 'angular';

const module = angular.module('whyline.components.stats.process', []);

const processComponent = {
    bindings: {
        process: '<'
    },
    replace:true,
    templateUrl: 'templates/views/stats/process.html',
    controller($scope) {
        'ngInject';
        $scope.showDetails = true; // to do: change back to false when reverting the changes
    }
};

export default module.component('process', processComponent);
