import angular from 'angular';
import '../services/organization-condition';
import './modals/organization-condition';
import '../services/globals';
import '../services/errors';
import '../services/notifications';

const module = angular.module('whyline.controllers.organization-conditions', [
  'whyline.services.organization-condition',
  'whyline.modals.organization-condition',
  'whyline.services.globals',
  'whyline.services.errors',
  'whyline.services.notifications',
]);

const OrganizationConditionsController = function (
  $scope,
  $uibModal,
  $translate,
  dialog,
  OrganizationConditionService,
  currentOrganization,
  GlobalsService,
  NotificationService,
  ErrorService,
) {
  /*@ngInject*/

  OrganizationConditionService.GetAllAsPromiseFromServer().then(data => {
    $scope.organizationConditions = data;
  });

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';
  
  $scope.open = conditionId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-condition.html',
      size: 'lg',
      controller: 'OrganizationConditionModalController',
      resolve: {
        conditionId: () => conditionId,
        currentOrganization,
      },
    });
  };

  $scope.delete = (conditionId, conditionSlug) => {
    const name = conditionSlug || '';
    dialog
      .confirm(
        $translate.instant('condition_{name}_remove_sure').replace('{name}', name)
      )
      .then(() => {
        OrganizationConditionService.Remove(conditionId)
          .then(() => {
            NotificationService.Success(
              $translate.instant('condition_{name}_remove_succ').replace('{name}', name)
            );
            $scope.organizationConditions = OrganizationConditionService.GetAll();
          })
          .catch(err => {
            ErrorService.handler(err);
          });
      });
  };
};

module.exports = module.controller('OrganizationConditionsController', OrganizationConditionsController);
