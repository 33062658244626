import angular from 'angular';
import './user';
import './notifications';

const module = angular.module('whyline.services.connection', [
  'whyline.services.user',
  'whyline.services.notifications',
]);

const ConnectionService = function($rootScope, toastr, $window, $interval, UserService, NotificationService, $translate) {
  let info = {
    status: 'online',
    toastrInstance: null
  };

  let checkConnection;

  function handleOnline() {
    //console.log('>>>>> handleOnline called');
    //console.log(`>>>>> toastrInstance ${info.toastrInstance}`);
    if (info.status === 'offline') {
      info.status = 'online';
      //console.log(`>>>>> current connection status: ${info.status}`);
      toastr.refreshTimer(info.toastrInstance, 200);
      NotificationService.Clear();
      info.toastrInstance = null;
      NotificationService.Information($translate.instant('conecction_normalize'));
      $rootScope.$broadcast('backOnline');
    }
  }

  function handleOffline() {
    //console.log('>>>>> handleOffline called');
    //console.log(`>>>>> toastrInstance ${info.toastrInstance}`);
    info.status = 'offline';
    //console.log(`>>>>> current connection status: ${info.status}`);
    if(info.toastrInstance === null) {
      info.toastrInstance = NotificationService.Error($translate.instant('connection_problem'), {
        timeOut: 0,
        preventDuplicates: true,
        preventOpenDuplicates: true,
        maxOpened: 5,
        closeButton: false,
        extendedTimeOut: 0,
        tapToDismiss: false,
      });

      // If checkConnection $interval is already running, do nothing
      if (angular.isDefined(checkConnection))
        return;

      // Otherwise, set the interval to check every n seconds if server is back
      let currentUser = UserService.GetCurrent();
      if (currentUser && currentUser._id) {
        checkConnection = $interval(function() {
          UserService.GetOneAsPromiseFromServer(currentUser._id).then(res => {
            //console.log('UserService.GetOneAsPromiseFromServer() SUCCESS!', res);
            if (res) {
              // Stop and unset interval
              $interval.cancel(checkConnection);
              checkConnection = undefined;
              handleOnline();
            }
          })
        }, 5000);
      }
    }
  }

  function startWatcher() {
    $window.addEventListener('online', function(e) {
      //console.log('went online');
      handleOnline();
    }, false);

    $window.addEventListener('offline', function(e) {
      //console.log('went offline');
      handleOffline(true);
    }, false);
  }

  return {info, handleOnline, handleOffline, startWatcher};
};

module.exports = module.service('ConnectionService', ConnectionService);
