import angular from 'angular';
import { chunk, cloneDeep, map } from 'lodash';
import Papa from 'papaparse';
import '../resources/appointment';
import '../services/notifications';
import '../services/area';
import '../services/line';

const module = angular.module('whyline.services.appointment', [
  'whyline.services.globals',
  'whyline.resources.appointment',
  'whyline.services.notifications',
  'whyline.services.area',
  'whyline.services.line',
  'ngFileSaver'
]);

let API_URL;
let Appointment;
let FileSaver;
let $translate;

class AppointmentService {
  static $inject = ['API_URL', 'Appointment', 'FileSaver', '$translate'];

  constructor(injAPI, injectedAppointment, fileSaverInjected, translationServiceInjected) {
    API_URL = injAPI;
    Appointment = injectedAppointment;
    FileSaver = fileSaverInjected;
    $translate = translationServiceInjected;
  }

  ClearSearchResults() {
    return Appointment.ClearCacheSearch(`${API_URL}appointment/search`);
  }

  Search(fields, options) {
    return Appointment.Search(fields, options);
  }

  SearchWithSubscriptions(fields, placeId, searchFrom = 'reception') {
    return Appointment.SearchWithSubscriptions(fields, placeId, searchFrom);
  }

  Remove(appointmentId) {
    const appointment = Appointment.FindById(appointmentId);
    return Appointment.Remove(appointment);
  }

  ToggleState() {

  }

  GetOne(appointmentId) {
    return Appointment.FindById(appointmentId);
  }

  GetOneAsPromise(appointmentId) {
    return Appointment.FindById(appointmentId, {
      promise: true
    });
  }

  GetOneFromServer(appointmentId) {
    const options = { force: true };
    return Appointment.FindById(appointmentId, options);
  }

  Copy(appointment) {
    const copy = cloneDeep(appointment);
    return new Appointment(copy);
  }

  Update(appointment) {
    return Appointment.Save(appointment);
  }

  CheckIn(appointment) {
    return Appointment.CheckIn(appointment._id);
  }

  GetAllByQueue(queue, options) {
    return Appointment.GetAllByQueue(queue, options);
  }

  Forward(appointmentId) {
    return Appointment.Forward(appointmentId);
  }

  SplitFileInChunks(file, limit = 100) {
    return new Promise(fullfill => {
      Papa.parse(
        file,
        {
          skipEmptyLines: true,
          header: true,
          complete: results => fullfill({ appointmentsCount: results.data.length, chunks: chunk(results.data, limit) }),
        }
      );
    });
  }

  async Upload(file, createdFrom, limit) {
    const { appointmentsCount, chunks } = await this.SplitFileInChunks(file, limit);
    const tasks = map(chunks, chunkRowsToUpload => () => Appointment.Upload(chunkRowsToUpload, createdFrom));
    return { tasks, appointmentsCount };
  }

  GetSchedule(date, dayCount) {
    return Appointment.GetSchedule(date, dayCount);
  }

  Requeue(appointment) {
    return Appointment.Requeue(appointment);
  }

  GetAppointmentsByHour(params) {
    return Appointment.GetAppointmentsByHour(params);
  }

  Process(appointmentId) {
    return Appointment.Process(appointmentId);
  }

  ExportReservations(configuration) {
    return Appointment.ExportReservations(configuration)
      .then(result => {
        const file = new Blob([result.data], { type: 'text/csv;charset=utf-8' });

        const day = new Date(configuration.startDate);

        const fileName = `${$translate.instant('text_results')}-${$translate.instant('appointments')}_${day.toLocaleDateString()}.csv`;

        return FileSaver.saveAs(file, fileName);
      });
  }
}

export default module.service('AppointmentService', AppointmentService);
