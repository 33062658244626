import angular from 'angular';
import { some } from 'lodash';
import 'angular-media-queries';
import './status';
import '../app.constants';
import '../services/place';
import '../services/user';
import '../services/globals';
import '../controllers/modals/desktop-caller';
import customProtocolCheck from 'custom-protocol-check';

const module = angular.module('whyline.components.carousel', [
  'ui.bootstrap',
  'matchMedia',
  'whyline.constants',
  'whyline.components.status',
  'whyline.services.place',
  'whyline.services.user',
  'whyline.services.globals',
  'whyline.modals.desktop-caller'
]);

const CarouselComponent = {
  bindings: {
    lines: '<',
    areas: '<',
  },
  templateUrl: '/templates/components/carousel.html',
  controller($scope, screenSize, PlaceService, $state, $rootScope, $timeout, SAFE_STATES, hotkeys, GlobalsService, $element, $translate, blockUI, AuthService, $uibModal, ENV_NAME) {
    'ngInject';
    //@TODO terminar de marcar como activo a una linea
    this.$onInit = () => {
      this.active = $state.params.lineId || $state.params.areaId;
      this.left = 0;
      this.models = [];
      this.totalPages = 0;
      this.currentPage = 1;
      this.filters = [];
      this.position = 0;
      this.currentLanguage = GlobalsService.CurrentLanguage;
      this.place = PlaceService.GetCurrent();
    };

    $scope.visibleItems = null;

    const resetCarouselPosition = () => {
      this.position = 0;
      this.left = 0;
      this.totalPages = Math.ceil($scope.carouselLength / $scope.visibleItems);
      this.currentPage = 1;
    };

    const setVisibleItems = () => {
      const elementWidth = $element[0].offsetWidth;
      const md = 992;
      const lg = 1200;

      if (elementWidth < md) {
        $scope.visibleItems = 4;
      } else if (elementWidth >= md && elementWidth < lg) {
          $scope.visibleItems = 5;
      } else if (elementWidth >= lg) {
        $scope.visibleItems = 6;
      }
    };

    $scope.$watch('visibleItems', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        resetCarouselPosition();
      }
    });

    screenSize.on('xs, sm, md, lg', function (isMatch) {
      setVisibleItems();
    });

    $rootScope.$watch('navBarLeftCollapsed', () => {
      if (!$rootScope.navBarLeftFloat) {
        // 300 === @sideNavTransitionTime defined on app.less
        $timeout(() => setVisibleItems(), 300);
      }
    });

    this.$onChanges = changes => {
      handleChanges(changes);
    };

    const handleChanges = (changes) => {
      if (changes.lines && changes.lines.currentValue && this.areas) {
        this.lines = changes.lines.currentValue;
        const sumOpenLines = Object.keys(changes.lines.currentValue).filter(lineKey => changes.lines.currentValue[lineKey].open);
        const sumOpenAreas = Object.keys(this.areas).filter(areaKey => this.areas[areaKey].open);
        $scope.carouselRealLength = sumOpenLines.length + sumOpenAreas.length;
      }
      if (changes.areas && changes.areas.currentValue && this.lines) {
        this.areas = changes.areas.currentValue;
        const sumOpenLines = Object.keys(this.lines).filter(lineKey => this.lines[lineKey].open);
        const sumOpenAreas = Object.keys(changes.areas.currentValue).filter(areaKey => changes.areas.currentValue[areaKey].open);
        $scope.carouselRealLength = sumOpenLines.length + sumOpenAreas.length;
      }
      generateFilters();
    };

    const generateFilters = () => {
      PlaceService.GetOneAsPromise($state.params.placeId).then(place => {
        $timeout(() => {
          const enterpriseType = place.enterpriseType;

          this.filters = [];

          if(some(this.lines, { realType: 'line'})) {
            this.filters.push({ key: 'line', name: 'lines' });
          }

          if(some(this.lines, { realType: 'supervisor'})) {
            this.filters.push({ key: 'supervisor', name: 'supervisors' });
          }

          if(enterpriseType === 'ba') {
            if(some(this.areas, { realType: 'process'})) {
              this.filters.unshift({ key: 'process', name: 'procedure' });
            }
          } else {
            if(some(this.areas, { realType: 'process'})) {
              this.filters.unshift({ key: 'process', name: 'processes' });
            }
          }
        });
      });
    };

    $scope.carouselLength;
    const openLines = this.lines ? Object.keys(this.lines).filter(lineKey => this.lines[lineKey].open) : 0;
    const openAreas = this.areas ? Object.keys(this.areas).filter(areaKey => this.areas[areaKey].open) : 0;
    $scope.carouselRealLength = openLines.length + openAreas.length;

    $scope.$watch('filtered', newVal => {
      $scope.carouselLength = newVal ? newVal.length : 0;
      resetCarouselPosition();
    });

    $rootScope.$on('updated-lines-areas', (event, args) => {
      handleChanges(args);
    });

    $rootScope.$on('update-active-queue', (event, args) => {
      $scope.currentQueue = args.currentQueue;
      this.active = $state.params.lineId || $state.params.areaId;
    });

    // Move carousel to right function
    this.moveToRight = () => {
      if(this.position + $scope.visibleItems < $scope.carouselLength) {
        this.left -= 100;
        this.position += $scope.visibleItems;
        this.currentPage += 1;
      }
    };

    // Move carousel to left function
    this.moveToLeft = () => {
      if(this.position - $scope.visibleItems >= 0) {
        this.left += 100;
        this.position -= $scope.visibleItems;
        this.currentPage -= 1;
      }
    };

    // When the user click in a item of the carousel
    this.makeActive = queue => {
      if (this.active !== queue._id) {
        this.active = queue._id;
  
        // Generate query params according with the queue type
        let query = {};
        if(queue.type === 'line') {
          query.lineId = queue._id;
          query.areaId = null;
        } else {
          query.lineId = null;
          query.areaId = queue._id;
        }
        // Change state to now serving queue with the generated params
        $state.go(SAFE_STATES.nowServingQueue, query);
      }
    };

    this.saveFilter = pressedFilter => {
      this.model === pressedFilter ? this.model = '' : this.model = pressedFilter;
    };

    this.openDesktopCaller = (queue) => {
      const token = AuthService.GetToken();
      const url = `web+whylinecaller://account/url-token-login?token=${token}&lineId=${
        queue._id
      }&placeId=${PlaceService.GetCurrent()._id}&organizationId=${
        PlaceService.GetCurrent().organizationId
      }&environment=${ENV_NAME}`;
      console.log('Checking for Whyline caller protocol.');
      window.open(url);
    };

    // Shorcuts definition
    hotkeys.bindTo($scope)
      .add({
        combo: 'right',
        description: 'Move carousel to right.',
        callback: () => {
          this.moveToRight();
        }
      })
      .add({
        combo: 'left',
        description: 'Move carousel to left.',
        callback: () => {
          this.moveToLeft();
        }
      });
  }
};

module.exports = module.component('carousel', CarouselComponent);
