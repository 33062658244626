import angular from 'angular';
import { Promise } from 'es6-promise';

import { filter, includes, get, cloneDeep, forEach, some } from 'lodash';

import { localStorage } from '../utils';
import '../resources/user';
import '../services/notifications';
import CryptoJS from 'crypto-js';
import '../services/area';
import '../services/globals';
import '../app.constants';

const module = angular.module('whyline.services.user', [
  'whyline.resources.user',
  'whyline.services.notifications',
  'whyline.services.area',
  'whyline.services.globals',
  'whyline.constants'
]);

let User;
let GlobalsService;
let NotificationService;
let $translate;
let UserActions;

class UserService {

  static $inject = ['User', 'GlobalsService', 'NotificationService', '$translate', 'UserActions'];
  static Me = undefined;
  static CurrentRole = undefined;

  constructor(injectedUser, injectedGlobalsService, injectedNotificationService, injected$translate, _UserActions) {
    /*@ngInject*/
    User = injectedUser;
    GlobalsService = injectedGlobalsService;
    NotificationService = injectedNotificationService;
    $translate = injected$translate;
    UserActions = _UserActions;
  }

  Create(data) {
    const newUser = new User(data);
    return User.Save(newUser);
  }

  Copy(user) {
    const copy = cloneDeep(user);
    return new User(copy);
  }

  Update(user) {
    return User.Save(user);
  }

  Remove(userId) {
    const user = User.FindById(userId);
    return User.Remove(user);
  }

  GetAll() {
    return User.FetchAll();
  }

  GetAllAsPromise() {
    return User.FetchAll({promise: true});
  }

  GetAllAsPromiseFromServer() {
    return User.FetchAll({promise: true, force: true});
  }

  GetOne(userId) {
    return User.FindById(userId);
  }

  GetOneAsPromise(userId) {
    return User.FindById(userId, {promise: true});
  }

  SetCurrent(user) {
    const isInstanceOfUser = user instanceof User;
    if(!isInstanceOfUser) {
      user = new User(user);
    }
    UserService.Me = User.SetCurrent(user);
  }

  RemoveCurrent() {
    UserService.Me = undefined;
  }

  GetCurrent() {
    return UserService.Me;
  }

  CopyCurrent() {
    const copy = cloneDeep(UserService.Me);
    return new User(copy);
  }

  SyncCurrent() {
    return User.GetCurrent({force: true, promise: true})
      .then(user => {
        UserService.Me = user;
        localStorage.get('user').then(localStorageUser => {
          Object.assign(localStorageUser, user);
          localStorage.set('user', localStorageUser);
          return user;
        });
      });
  }

  UpdateCurrent(newUser) {
    const allPromises = [];
    const firstNameChanged = newUser.profile.firstName !== UserService.Me.profile.firstName;
    const lastNameChanged = newUser.profile.lastName !== UserService.Me.profile.lastName;
    if(firstNameChanged || lastNameChanged) {
      const savePromise = User.Save(newUser).then(
        () => {
          UserService.SyncCurrent();
          NotificationService.Success($translate.instant('user_update_succ'));
        },
        () => NotificationService.Error($translate.instant('err_update_info'))
      );
      allPromises.push(savePromise);
    }
    const {
      currentPassword,
      newPassword,
      confirmPassword
    } = newUser;
    if(currentPassword && newPassword && confirmPassword) {
      const currentPasswordHashed = CryptoJS.SHA256(currentPassword).toString();
      const newPasswordHashed = CryptoJS.SHA256(newPassword).toString();
      const changePassPromise = User.ChangePassword(currentPasswordHashed, newPasswordHashed).then(
        () => NotificationService.Success($translate.instant('pw_change_succ')),
        error => {
          if(get(error, 'data', null) === false) {
            return NotificationService.Error($translate.instant('invalid_current_pw'));
          } else {
            return NotificationService.Error($translate.instant('err_change_pw'));
          }
        }
      );
      allPromises.push(changePassPromise);
    }
    return Promise.all(allPromises);
  }

  GetPlacesForStats() {
    let user = UserService.Me;
    const placeIds = filter(user.permissions, permission => {
      return includes(permission.role.actions, UserActions.section.statistics);
    }).map(permission => permission.resourceGroup.placeId);
    return filter(placeIds, placeId => {
      return !includes(user.disabledForPlaces, placeId)
    });
  }

  CanUseStats() {
    return !!this.GetPlacesForStats().length;
  }

  GetCurrentAsPromise() {
    let promise;
    if(UserService.Me && UserService.Me._id) {
      promise = new Promise(resolve => resolve(UserService.Me));
    } else {
      promise = User.GetCurrent();
      promise.then(user => {
        UserService.Me = user;
      });
    }
    return promise;
  }

  DisabledForPlace(placeId) {
    let user = UserService.Me;
    if(!user) {
      localStorage.get('user').then(localStorageUser => {
        user = localStorageUser;
        return includes(user.disabledForPlaces, placeId);
      });
    }
    const places = get(user, "disabledForPlaces", []);
    return includes(places, placeId);
  }

  DisabledForOrganization(organizationId) {
    let user = UserService.Me;
    if (!user) {
      localStorage.get('user').then(localStorageUser => {
        user = localStorageUser;
        return includes(user.disabledForOrganizations, organizationId);
      });
    }
    const orgs = get(user, "disabledForOrganizations", []);
    return includes(orgs, organizationId);
  }

  GetOneAsPromiseFromServer(userId) {
    return User.FindById(userId, {promise: true, force: true});
  }

  GetPermissions() {
    return this.GetCurrentAsPromise()
      .then(user => Promise.resolve(user.permissions));
  }

  GetCurrentRoleFor(user) {
    let role;

    let perm = user.permissions.find(permission => {
      return permission.resourceGroup && permission.resourceGroup.hasOwnProperty('global') && permission.resourceGroup.global;
    });

    if(perm) {
      role = 'Whyline - SuperUser';
    }

    perm = user.permissions.find(permission => {
      if(GlobalsService.CurrentPlace && GlobalsService.CurrentPlace._id && permission.resourceGroup && permission.resourceGroup.placeId === GlobalsService.CurrentPlace._id) {
        if(some(permission.resourceGroup.resources, { type: 'place', _id: GlobalsService.CurrentPlace._id })) {
          return true;
        }
      }

      return false;
    });

    if(perm) {
      role = perm.role;
    }

    if(user.hasOwnProperty('sigeci') && user.sigeci) {
      role = 'sigeci';
    }
    if(!role) {
      role = {
        name: 'operator',
      };
    }
    return role;
  }

  GetAllowedQueuesIds(placeId) {
    return new Promise((resolve, reject) => {
      let queuesIds = [];
      localStorage.get('user').then(user => {
        try {
          const placeUserPermissions = filter(user.permissions, { placeId });

          forEach(placeUserPermissions, permission => {
            if (permission.resourceGroup.type === 'place') {
              if (permission.resourceGroup.resources.length) {
                const lines = permission.resourceGroup.resources[0].lines || [];
                const areas = permission.resourceGroup.resources[0].areas || [];

                queuesIds = [...queuesIds, ...lines, ...areas];
              }
            } else {
              queuesIds = [...queuesIds, ...permission.resourceGroup.resources.map(queue => queue._id || queue.resourceId)];
            }
          });

          resolve(queuesIds);
        } catch (error) {
          reject(error);
        }
      }).catch(error => {
        reject(error);
      });
    });
  }

  CheckIfExists(email) {
    return User.Exists(email);
  }

  Switch(user) {
    return new User(user).enable(!user.enabled);
  }

  isSuperAdmin() {
    return this.GetCurrentAsPromise()
      .then(user => Promise.resolve(user.sudo));
  }

  SwitchOrganizationEnable(user) {
    return new User(user).enableForOrganization(!user.enabled);
  }

  setExternalSupportId(externalId) {
    const data = {
      externalSupportId: externalId,
      userId: UserService.Me._id,
    };
    return User.SetExternalSupportId(data);
  }
}

module.exports = module.service('UserService', UserService);
