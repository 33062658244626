import angular from 'angular';
import '../../components/osom';
import '../../services/errors';
import { schedule, options } from '../../utils';
import { cloneDeep } from 'lodash';

const module = angular.module('whyline.modals.organization-line', [
  'whyline.components.osom',
  'whyline.services.organization-line',
  'whyline.services.errors',
]);

const OrganizationLineModalController = ($scope, $uibModalInstance, $timeout, $translate, NotificationService, OrganizationLineService, ErrorService, organizationLineId, currentOrganization) => {
  'ngInject';

  $scope.blockSaveButton = false;

  const validateRanges = schedule => {
    var valid = true;
    const opened = schedule.filter(s => s.open);
    for (let i = 0; i < opened.length; i++) {
      const day = opened[i];
      if (day.firstPeriod.opening >= day.firstPeriod.closing) {
        NotificationService.Warning($translate.instant('opening_hours_first_period_msg_validator').replace('{dayname}', $translate.instant(day.name)));
        valid = false;
        break;
      }
      if (day.hasSecondPeriod) {
        if (day.secondPeriod.opening <= day.firstPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_must_be_later_first_msg_validator').replace('{dayname}', $translate.instant(day.name)));
          valid = false;
          break;
        }

        if (day.secondPeriod.opening >= day.secondPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_period_msg_validator').replace('{dayname}', $translate.instant(day.name)))
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  $scope.validateLabel = value => {
    if (!value) {
      NotificationService.Warning($translate.instant('mandatory_queue_name'));
      return false;
    }
    if (value.length > 50) {
      NotificationService.Warning($translate.instant('max_length_name'));
      return false;
    }
    if (!/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(value)) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }

    return true;
  };

  $scope.validateMessages = messages => {
    if (messages.preJoin && messages.preJoin.length > 300) {
      NotificationService.Warning($translate.instant('max_length_pre_join_message'));
      return false;
    }
    if (messages.postJoin && messages.postJoin.length > 300) {
      NotificationService.Warning($translate.instant('max_length_post_join_message'));
      return false;
    }
    if (messages.postCheckIn && messages.postCheckIn.length > 59) {
      NotificationService.Warning($translate.instant('max_length_post_check_in_message'));
      return false;
    }
    return true;
  };

  $scope.validateRequestSource = value => {
    if (!value.reception && !value.totem && !value.mobileApp) {
      $scope.showEditAttributes = true;
      NotificationService.Warning($translate.instant('min_one_request_type'));
      return false;
    }
    return true;
  };

  const validate = () => {
    if (!$scope.validateLabel($scope.organizationLine.label)) {
      return false;
    }

    if ($scope.organizationLine.configuration && $scope.organizationLine.configuration.messages
      && !$scope.validateMessages($scope.organizationLine.configuration.messages)) {
      return false;
    }

    if (!$scope.validateRequestSource($scope.organizationLine.requestSource)) {
      return false;
    }

    if (!validateRanges($scope.organizationLine.schedule)) {
      return false;
    }

    return true;
  };

  $scope.saveSchedule = () => {
    if (validateRanges($scope.organizationLine.schedule)) {
      $scope.organizationLine.schedule = schedule.format($scope.organizationLine.schedule, false);
      const data = {
        updateType: $scope.selectData.selectedOption,
        updateValues: { schedule: $scope.organizationLine.schedule }
      };
      $scope.save(data).then(() => {
        $scope.editStatus.schedule = cloneDeep($scope.organizationLine.schedule);
        $scope.organizationLine.schedule = schedule.format($scope.organizationLine.schedule, true);
        $scope.editStatus.editingDayList = false;
      });
    }
  };

  $scope.saveMessages = () => {
    const data = {
      updateType: $scope.selectDataMessages.selectedOption,
      updateValues: { configuration: $scope.organizationLine.configuration }
    };
    $scope.save(data).then(() => {
      $scope.editStatus.messages = cloneDeep($scope.organizationLine.configuration.messages);
      $scope.editStatus.editingMessages = false;
    });
  };

  $scope.saveRequestSource = () => {
    if ($scope.validateRequestSource($scope.organizationLine.requestSource)) {
      const data = {
        updateType: $scope.selectData.selectedOption,
        updateValues: { requestSource: $scope.organizationLine.requestSource }
      };
      $scope.save(data).then(() => {
        $scope.editStatus.requestSource = cloneDeep($scope.organizationLine.requestSource);
        $scope.editStatus.editingRequestSource = false;
      });
    }
  };

  $scope.validateRequestSource = requestSource => {
    if (!requestSource.reception && !requestSource.totem && !requestSource.mobileApp) {
      NotificationService.Warning($translate.instant('min_one_request_type'));
      return false;
    }

    return true;
  };

  $scope.cancelEdit = attribute => {
    switch (attribute) {
      case 'requestSource':
        $scope.editStatus.editingRequestSource = false;
        $scope.organizationLine.requestSource = cloneDeep($scope.editStatus.requestSource);
        break;

      case 'schedule':
        $scope.editStatus.editingDayList = false;
        $scope.organizationLine.schedule = schedule.format(cloneDeep($scope.editStatus.schedule), true);
        break;
    }
  };

  $scope.cancelEditMessages = () => {
    $scope.editStatus.editingMessages = false;
    $scope.organizationLine.configuration.messages = cloneDeep($scope.editStatus.messages);
  };

  const dismissOnError = err => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.dismiss('cancel');
    }
    OrganizationLineService.GetAllAsPromise();
  };

  $scope.currentOrganization = currentOrganization;

  $scope.hours = schedule.getHours();

  $scope.organizationLine = {
    schedule: schedule.format(schedule.reOrder(schedule.create()), true),
    maxNumbers: 0,
    requestSource: {
      mobileApp: true,
      reception: true,
      totem: true,
    }
  };
  $scope.showEditAttributes = false;
  $scope.hasOrganizationLineId = organizationLineId ? true : false;
  $scope.selectData = {
    options: options.filter(o => o.onlyOrganizations || o.all),
    selectedOption: options.filter(o => o.onlyOrganizations || o.all)[0].key,
  };
  $scope.selectDataMessages = {
    options: options.filter(o => o.onlyOrganizations || o.all),
    selectedOption: options.filter(o => o.onlyOrganizations || o.all)[0].key,
  };

  $scope.selectDataForRequestSource = {
    options: options.filter(o => o.onlyOrganizations || o.all),
    selectedOption: options.filter(o => o.onlyOrganizations || o.all)[0].key,
  };

  $scope.editStatus = {
    editingDayList: false,
    editingMessages: false,
    editingRequestSource: false,

  };

  if (organizationLineId) {
    OrganizationLineService.GetOneAsPromiseFromServer(organizationLineId)
      .then(organizationLine => {
        $timeout(() => {
          $scope.organizationLine = OrganizationLineService.Copy(organizationLine);
          $scope.organizationLine.schedule = schedule.format($scope.organizationLine.schedule, true);
          $scope.editStatus.schedule = cloneDeep(organizationLine.schedule);
          $scope.editStatus.messages = cloneDeep(organizationLine.configuration.messages);
        });
      })
      .catch(ErrorService.handler);
  }

  $scope.save = data => {
    if (organizationLineId && data) {
      const options = {};
      options.data = data;
      options.organizationLineId = organizationLineId;
      options.organizationId = $scope.organizationLine.organizationId;

      return OrganizationLineService.UpdatePartial(options)
        .then(() => {
          NotificationService.Success($translate.instant('queue_update_succ'));
        })
        .catch(ErrorService.handler);
    } else if (validate()) {
      $scope.blockSaveButton = true;
      $scope.organizationLine.schedule = schedule.format($scope.organizationLine.schedule, false);

      return OrganizationLineService.Create($scope.organizationLine)
        .then(() => {
          $timeout(() => {
            NotificationService.Success($translate.instant('queue_create_succ'));
            $uibModalInstance.close('cancel');
          });
        })
        .catch(error => {
          $scope.blockSaveButton = false;
          ErrorService.handler(error);
        });
    }
  };

  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.setHasSecondPeriod = (day, hasSecondPeriod) => {
    day.hasSecondPeriod = hasSecondPeriod;
  };

  $scope.applyToAll = day => {
    schedule.applyToAll(day, $scope.organizationLine.schedule);
  };

  $scope.validateNumber = () => {
    let number = parseInt($scope.organizationLine.maxNumbers);
    number = isNaN(number) ? 0 : number;
    $scope.organizationLine.maxNumbers = number;
  }
};

module.exports = module.controller('OrganizationLineModalController', OrganizationLineModalController);
