import angular from 'angular';
import {
  Promise
} from 'es6-promise';
import _ from 'lodash';
import '../app.constants';
import './line';
import './area';
import {
  formatAwt
} from '../utils';

const module = angular.module('whyline.services.reception', [
  'whyline.services.line',
  'whyline.services.area',
  'whyline.constants',
]);

let API_URL;
let SAFE_STATES;
let $window; // @TODO: improve
let $http;
let $timeout;
let LineService;
let AreaService;
let UserService;
let Area;
let SubscriptionService;
let Line;
let Schema;
let $state;
let ErrorService;

let CurrentItem;
let Queue;
let enqueueIn;
let Stack = [];
let validate;
let toValidate;
let historyButtons = {
  back: false,
  forward: false,
  'status-text': false,
};

const populateItem = item => {
  if (item.type === 'sector' && item._id) {
    item.linesPopulated = _.filter(item.tree, { type: 'line' });
    item.schemasPopulated = _.filter(item.tree, { type: 'sector' });
    item.areasPopulated = _.filter(item.tree, { type: 'area' });
    return item;
  } else if ((item.type === 'line' || item.type === 'area') && item._id) {
    return item;
  } else {
    console.error('unable to populate item that is not line, area or schema', item);
    return item;
  }
};

const getPath = () => {
  let path = [];
  Stack.forEach(item => {
    let toSave = {};
    toSave.name = item.label || item.name;
    toSave.type = item.type;
    toSave.realType = item.realType;
    toSave._id = item._id;
    if (item.type === 'area') {
      toSave.ordered = item.ordered;
    }
    path.push(toSave);
  });
  return path;
};

const resetStack = () => {
  Stack = [];
};

const handleError = err => {
  if (err && [401, 403, 404].indexOf(err.status) >= 0) {
    $timeout(() => {
      $window.location.reload();
    }, 2000);
  }
  throw err;
};

class ReceptionService {

  static $inject = ['API_URL', '$http', '$window', '$timeout', 'LineService', 'AreaService', 'UserService', 'Area', 'Line', 'Schema', '$state', 'SubscriptionService', 'ErrorService', 'SAFE_STATES'];

  constructor(injAPI, inj$http, inj$window, inj$timeout, injLineSS, injAreaSS, injUserSS, injArea, injLine, injSchema, inj$state, injSubscriptionService, injErrorService, injSAFE_STATES) {
    API_URL = injAPI;
    SAFE_STATES = injSAFE_STATES;
    $http = inj$http;
    $window = inj$window;
    $timeout = inj$timeout;
    LineService = injLineSS;
    AreaService = injAreaSS;
    UserService = injUserSS;
    Area = injArea;
    Line = injLine;
    Schema = injSchema;
    $state = inj$state;
    SubscriptionService = injSubscriptionService;
    ErrorService = injErrorService;
  }

  GetSubscription(subscriptionId, lineId) {
    return $http.get(`${API_URL}subscription/${subscriptionId}?lineId=${lineId}`)
      .then(res => res)
      .catch(handleError);
  }

  Enqueue(person, queue) {
    return $http.post(`${API_URL}subscription/?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      person
    })
      .catch(handleError);
  }

  CancelSubscription(subscriptionId, queue) {
    return $http.patch(`${API_URL}subscription?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`, {
      status: 'cancel',
      reason: 'not-present'
    }).catch(handleError);
  }

  ForwardSubscription(subscriptionId, queue, destination) {
    const destinationId = destination ? `&lineIdDest=${destination}` : '';
    return $http.post(`${API_URL}subscription/${subscriptionId}/forward?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}${destinationId}`)
      .catch(handleError);
  }

  DeleteSubscription(subscriptionId, queue) {
    return $http.delete(`${API_URL}subscription/${subscriptionId}?${queue.type === 'line' ? 'lineId' : 'areaId'}=${queue._id}`)
      .catch(handleError);
  }

  GetButtonStatus() {
    return historyButtons;
  }

  SetButtonStatus(which, value) {
    switch (which) {
      case 'both':
        historyButtons.back = value;
        historyButtons.forward = value;
        break;
      case 'back':
      case 'forward':
      case 'status-text':
        historyButtons[which] = value;
        break;
    }
  }

  SetQueue(queue) {
    Queue = queue;
  }

  GetQueue() {
    return Queue;
  }

  SetEnqueueIn(data) {
    enqueueIn = data;
  }

  GetEnqueueIn() {
    return enqueueIn;
  }

  GetCurrent() {
    const currentStep = $state.params.n;
    if (currentStep > Stack.length) {
      Stack = [];
      $state.go(SAFE_STATES.receptionStep, {
        n: 0
      });
    } else if (currentStep != 0) {
      CurrentItem = Stack[currentStep - 1];
    } else if (currentStep != 0) {
      CurrentItem = Stack[currentStep - 1];
    }
    return CurrentItem;
  }

  Select(item) {
    // Set next number step
    const currentStep = $state.params.n;
    // Populate select item
    CurrentItem = populateItem(item);
    if (Stack[currentStep]) {
      Stack[currentStep] = CurrentItem;
      Stack = _.take(Stack, currentStep + 1);
    } else {
      Stack.push(CurrentItem);
    }
    const nextStep = Stack.length;
    if (item.type === 'sector' || item.type === 'area' && item.ordered === false) {
      $state.go(SAFE_STATES.receptionStep, {
        n: nextStep
      });
    }
  }

  GetPath = getPath;
  ResetStack = resetStack;

  Validate(evaluation, obj) {
    validate = evaluation;
    toValidate = obj;
  }

  GetLineToEnqueue() {
    return Stack[Stack.length - 1];
  }

  History = (direction, opts) => {
    // Going back
    if (direction === 'back') {
      // Steps to back
      if ($state.current.name === SAFE_STATES.receptionStep && $state.params.n != 0) {
        $state.go(SAFE_STATES.receptionStep, {
          n: $state.params.n - 1
        });
      }
      // Steps to welcome
      if ($state.current.name === SAFE_STATES.receptionStep && $state.params.n == 0) {
        resetStack();
        $state.go(SAFE_STATES.receptionWelcome);
      }
      // Status to steps
      if ($state.current.name === SAFE_STATES.receptionStatus) {
        resetStack();
        $state.go(SAFE_STATES.receptionStep, {
          n: 0
        });
      }
      // User number to status
      if ($state.current.name === SAFE_STATES.receptionUser && $state.params.step === 'identification') {
        $state.go(SAFE_STATES.receptionStatus);
      }
      // User info to user number
      if ($state.current.name === SAFE_STATES.receptionUser && $state.params.step === 'info') {
        $state.go(SAFE_STATES.receptionUser, {
          step: 'identification'
        });
      }

      // APPOINTMENTS
      // Search to welcome
      if ($state.current.name === SAFE_STATES.receptionAppointmentSearch) {
        $state.go(SAFE_STATES.receptionWelcome);
      }
      // Results to welcome
      if ($state.current.name === SAFE_STATES.receptionAppointmentResults) {
        $state.go(SAFE_STATES.receptionWelcome);
      }

      // SCHEDULE SECTION
      if ($state.current.name === SAFE_STATES.scheduleAppointmentFilters) {
        $state.go(SAFE_STATES.scheduleManagement);
      }

      if ($state.current.name === SAFE_STATES.scheduleAppointmentResults) {
        $state.go(SAFE_STATES.scheduleAppointmentFilters);
      }
    }

    // Going forward
    if (direction === 'forward') {
      // Go to home
      if ($state.current.name === SAFE_STATES.receptionStatus) {
        $state.go(SAFE_STATES.receptionWelcome, {});
      }
      // APPOINTMENTS
      // Search to results
      if ($state.current.name === SAFE_STATES.receptionAppointmentSearch) {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.receptionAppointmentResults, {
            fields: toValidate
          });
        }
      }

      // Results to confirm
      if ($state.current.name === SAFE_STATES.receptionAppointmentResults) {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.receptionAppointmentConfirm, {
            id: toValidate._id,
            type: toValidate.type
          });
        }
      }
      // Confirm to welcome
      if ($state.current.name === SAFE_STATES.receptionAppointmentConfirm) {
        $state.go(SAFE_STATES.receptionWelcome);
      }

      // SCHEDULE SECTION
      if ($state.current.name === SAFE_STATES.scheduleAppointmentFilters) {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.scheduleAppointmentResults, {
            fields: toValidate,
          });
        }
      }

      // Results to confirm
      if ($state.current.name === SAFE_STATES.scheduleAppointmentResults) {
        if (validate(toValidate)) {
          $state.go(SAFE_STATES.scheduleAppointmentConfirm, {
            id: toValidate._id,
            type: toValidate.type
          });
        }
      }
      // Confirm to welcome
      if ($state.current.name === SAFE_STATES.scheduleAppointmentConfirm) {
        $state.go(SAFE_STATES.scheduleManagement);
      }
    }

    if ($state.current.name === SAFE_STATES.receptionAppointmentResults && direction === 'to-appointment-search') {
      $state.go(SAFE_STATES.receptionAppointmentSearch, {
        fields: opts
      });
    }
  };
}

module.exports = module.service('ReceptionService', ReceptionService);
