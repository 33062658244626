import angular from 'angular';
import '../services/organization-scenario';
import '../services/errors';
import './modals/organization-scenario';
import '../services/globals';

const module = angular.module('whyline.controllers.organization-scenarios', [
  'whyline.modals.organization-scenario',
  'whyline.services.organization-scenario',
  'whyline.services.errors',
  'whyline.services.globals',
]);

const OrganizationScenariosController = (
  $scope,
  $uibModal,
  $translate,
  OrganizationScenarioService,
  NotificationService,
  ErrorService,
  dialog,
  currentOrganization,
  GlobalsService
) => {
  /*@ngInject*/

  // View init
  OrganizationScenarioService.GetAllAsPromiseFromServer().then(data => {
    $scope.organizationScenarios = data;
  });

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';

  const defaultScenarioText = $translate.instant('scenario_is_default');
  $scope.scenarioDefault = defaultScenarioText.charAt(0);

  // Modal
  $scope.open = organizationScenarioId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-scenario.html',
      size: 'lg',
      controller: 'OrganizationScenarioModalController',
      resolve: {
        organizationScenarioId: () => organizationScenarioId,
        currentOrganization,
      },
    });
  };

  $scope.delete = (scenarioId, scenarioName) => {
    const name = scenarioName || '';
    dialog
      .confirm(
        $translate.instant('scenario_{name}_remove_sure').replace('{name}', name)
      )
      .then(() => {
        OrganizationScenarioService.Remove(scenarioId)
          .then(() => {
            NotificationService.Success(
              $translate.instant('scenario_{name}_remove_succ').replace('{name}', name)
            );
            $scope.organizationScenarios = OrganizationScenarioService.GetAll();
          })
          .catch(err => {
            ErrorService.handler(err);
          });
      });
  };
};

module.exports = module.controller(
  'OrganizationScenariosController',
  OrganizationScenariosController,
);
