import angular from 'angular';
import { get, isNaN, toNumber, some } from 'lodash';
import '../../services/errors';
import '../../services/plan';

const module = angular.module('whyline.modals.plans', [
  'whyline.services.plan',
  'whyline.services.errors',
]);


const PlansModalController = ($scope, $timeout, $uibModalInstance, $translate, NotificationService, PlanService, ErrorService, planId) => {
  'ngInject';

  const planNumericProps = [
    'maxAreas',
    'maxDisplays',
    'maxLines',
    'maxSectors',
    'maxTotems',
    'maxPlaces',
    'maxVideos',
    'maxUsers'
  ];

  const noHasLimits = planNumericProps.reduce((plan, prop) => {
    plan[prop] = -1; return plan;
  }, { hasLimits: false, reception: true });

  const hasLimits = {
    hasLimits: true,
    reception: false,
    maxAreas: 0,
    maxDisplays: 1,
    maxLines: 5,
    maxSectors: 0,
    maxTotems: 1,
    maxPlaces: 1,
    maxUsers: 1,
    maxVideos: 0,
  };

  $scope.plan = {
    name: '',
    ...hasLimits,
  };

  const validate = () => {
    const name = $scope.plan.name;
    const type = $scope.plan.type;

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_plan_name'));
      return false;
    }

    if (!type) {
      NotificationService.Warning($translate.instant('mandatory_plan_type'));
      return false;
    }

    for (let i = 0; i < planNumericProps.length; i++) {
      const prop = planNumericProps[i];
      const number = toNumber($scope.plan[prop]);
      if (number < -1 || isNaN(number)) {
        NotificationService.Warning($translate.instant('plan_minus_one_are_not_allowed'));
        return false;
      }
    }

    if ($scope.plan.maxPlaces === 0 || $scope.plan.maxLines === 0 || $scope.plan.maxUsers === 0) {
      NotificationService.Warning($translate.instant('plan_zero_are_not_allowed_lines_users_places'));
      return false;
    }

    return true;
  };

  if (planId) {
    PlanService.GetOneAsPromiseFromServer(planId)
      .then(plan => {
        $timeout(() => {
          $scope.plan = PlanService.Copy(plan);
        });
      })
      .catch(ErrorService.handler);
  }

  $scope.onHasLimitChanged = () => {
    const { plan } = $scope;

    const newValues = plan.hasLimits ? hasLimits : noHasLimits;

    $scope.plan = { ...plan, ...newValues };
  };

  $scope.save = () => {
    if (validate()) {
      if (planId) {
        $scope.plan = PlanService.Copy($scope.plan);
        return PlanService.Update($scope.plan)
          .then(() => {
            NotificationService.Success($translate.instant('plan_update_succ'));
            $uibModalInstance.close('cancel');
          })
          .catch(ErrorService.handler);
      }

      return PlanService.Create($scope.plan)
        .then(() => {
          NotificationService.Success($translate.instant('plan_create_succ'));
          $uibModalInstance.close('cancel');
        })
        .catch(ErrorService);
    }
  };

  $scope.remove = toRemove => {
    PlanService.Remove(toRemove);
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  const getPlanAttribute = modelAttribute => {
    const splittedAttributes = modelAttribute.split('.');
    return splittedAttributes[1];
  };

  $scope.handleOnBlur = $event => {
    const planAttr = getPlanAttribute($event.target.getAttribute('ng-model'));
    const initialValue = get($scope.plan, planAttr, 0);
    let numericProp = toNumber($event.target.value);

    if ($event.target.value === '' || !Number.isInteger(numericProp)) {
      numericProp = initialValue;
    }

    $scope.plan = {
      ...$scope.plan,
      [planAttr]: numericProp,
    };

    const limits = $scope.plan.hasLimits;

    $scope.plan.hasLimits = some(planNumericProps, prop => $scope.plan[prop] !== -1);

    if (limits && !$scope.plan.hasLimits) {
      $scope.plan.reception = true;
    }

    $event.target.value = numericProp;
  };
};

module.exports = module.controller('PlansModalController', PlansModalController);
