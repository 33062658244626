import angular from 'angular';
import '../services/auth';
import '../services/globals';

const module = angular.module('whyline.controllers.recover-password', [
  'whyline.services.auth',
  'whyline.services.globals',
]);

const RecoverPasswordController = function($scope, $state, AuthService, NotificationService, GlobalsService, $translate) {
  'ngInject';

  const showSignInError = () => {
    NotificationService.Error($translate.instant('err_recovery_pw'));
  };

  $scope.sentEmail = false;

  // Enterprise type for branding
  $scope.enterpriseType = GlobalsService.EnterpriseType;
  $scope.currentYear = new Date().getFullYear();

  this.save = () => {
    return AuthService.RecoverPassword(this.email)
    .then(() => {
      $scope.sentEmail = true;
    })
    .catch(showSignInError);
  };
};

module.exports = module.controller('RecoverPasswordController', RecoverPasswordController);
