import angular from 'angular';
import moment from 'moment-timezone';
import '../../services/appointment';
import '../../services/appointment-reservation';
import '../../services/area';
import '../../services/errors';
import '../../services/holiday';
import '../../services/validation';
import '../../services/notifications';
import '../../services/date-handler';
import { get, includes, last, toUpper, filter, forEach, isEmpty, set } from 'lodash';
import { countriesWithCodes } from '../../utils/countries-and-codes';
import { AreaCalendarType } from '../../utils/areaCalendarType';
import { CreatedFromEnum } from '../../utils/createdFromEnum';

const module = angular.module('whyline.modals.edit-appointment', [
  'whyline.services.appointment',
  'whyline.services.appointment-reservation',
  'whyline.services.area',
  'whyline.services.errors',
  'whyline.services.validation',
  'whyline.services.notifications',
  'whyline.services.date-handler',
  'whyline.services.holiday',
]);

const EditAppointmentController = (
  $scope, $uibModalInstance, appointment, area, $translate,
  AppointmentService, AppointmentReservationService, AreaService, DateHandlerService, ErrorService, HolidayService, NotificationService, PlaceService, ValidationService) => {
  'ngInject';

  const appointmentId = appointment._id ? appointment._id : false;

  $scope.idTypesList = PlaceService.getIdentificationsForCurrentPlace() || [];
  $scope.appointment = AppointmentService.Copy(appointment);
  $scope.modalTitle = appointmentId ? 'edit_appointment' : 'new_appointment';
  $scope.area = area ? AreaService.Copy(area) : false;
  $scope.place = PlaceService.GetCurrent();
  $scope.placeUTCOffset = moment.tz($scope.place.timezone).format('Z').toString();
  $scope.format = $translate.use() === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
  $scope.countries = countriesWithCodes;
  $scope.fetchSlotsExecuted = false;

  const defaultPlaceId = $scope.idTypesList.find(id => id.default);
  const isAgendaEnabled = $scope.place.configuration.canGiveAppointments;

  $scope.init = () => {
    let selectedId = null;

    // Set appointment editing configuration
    if (appointmentId) {
      selectedId = $scope.idTypesList.find(id => id.key === $scope.appointment.person.idType);
      $scope.appointment.person.phone.countryCode = $scope.appointment.person.phone.countryCode ? $scope.appointment.person.phone.countryCode : toUpper($scope.place.location.countryCode);
      $scope.appointmentDate = {
        calendar: DateHandlerService.getNeutralDate($scope.appointment.appointment.date),
        selected: $scope.appointment.appointment.date,
        showProcessMessage: false
      };
    } else {
      set($scope.appointment, 'person.phone.countryCode', toUpper($scope.place.location.countryCode));
      set($scope.appointment, 'person.idType', defaultPlaceId.key);
    }

    // Options Id type
    if (!isEmpty(selectedId)) {
      $scope.selectedIdType = {
        value: selectedId
      };
    } else {
      $scope.selectedIdType = {
        value: defaultPlaceId
      };
      set($scope.appointment, 'person.idType', defaultPlaceId.key);
    }

    // Agenda options
    $scope.daysClosed = [];
    $scope.datesClosed = [];
    if (isAgendaEnabled && $scope.area) {
      $scope.fetchSlots();
      closedDays();
      setCalendarOptions();
    }
    if (!$scope.area) {
      $scope.fetchAreas();
    }
  };

  $scope.fetchSlots = () => {
    const selectedDate = moment($scope.appointmentDate.calendar).format('YYYY-MM-DD');

    AreaService.GetSlotsByHour({ areaIds: [$scope.area._id], hideEmptySlots: false, showWorkingDay: false, date: selectedDate, addNextDay: true, placeId: $scope.place._id })
      .then(slots => {
        $scope.fetchSlotsExecuted = true;
        let firstDate = true;

        $scope.slots = filter(slots.today, (slot, index, array) => {
          let availability = true;

          if (selectedDate === moment(slot.hour).tz($scope.place.timezone).startOf('day').format('YYYY-MM-DD')) {
            const appointmentDateUTCFormat = moment($scope.appointment.appointment.date).utc();
            const appointmentEndDateUTCFormat = moment($scope.appointment.appointment.endDate).utc();

            for (let i = 0; i < $scope.area.appointmentsConfiguration.timeSlotsQuantity; i++) {
              let currentSlot = false;

              const thisSlot = array[index + i];
              const nextSlot = array[index + i + 1];
              const lastSlotQuantity = $scope.area.appointmentsConfiguration.timeSlotsQuantity !== i + 1;

              if (!thisSlot || (thisSlot && nextSlot && lastSlotQuantity &&
                moment(thisSlot.hour).utc().add($scope.area.appointmentsConfiguration.duration, 'minutes').format() !== moment(nextSlot.hour).utc().format())) {
                availability = false;

                break;
              }

              const thisSlotHourUTC = moment(thisSlot.hour).utc();

              if (thisSlotHourUTC.isBetween(appointmentDateUTCFormat, appointmentEndDateUTCFormat, null, '[)')) {
                currentSlot = true;
                firstDate = false;

                if (thisSlotHourUTC.isSame(appointmentDateUTCFormat)) {
                  $scope.appointmentDate.selected = slot.hour;
                }
              }

              const now = moment().tz($scope.place.timezone).format();
              const slotTimezone = moment(thisSlot.hour).tz($scope.place.timezone).format();
              const isInThePast = moment(now).isAfter(slotTimezone);

              if (!(currentSlot || thisSlot.reserved < thisSlot.slots && !isInThePast)) {
                availability = false;

                break;
              }
            }
          } else {
            availability = false;
          }

          return availability;
        });

        if (firstDate && $scope.slots.length) {
          $scope.appointmentDate.selected = $scope.slots[0].hour;
        } else if (!$scope.slots.length) {
          $scope.appointmentDate.selected = null;
        }

        validateProcessConfig();
      });
  };

  $scope.fetchAreas = () => {
    const areas = AreaService.GetAll();
    $scope.areas = [];
    Object.keys(areas).forEach(key => {
      if (areas[key].appointmentsConfiguration.enabled && areas[key].open) {
        $scope.areas.push(areas[key]);
      }
    });
  };

  $scope.changeSelectedArea = selectedArea => {
    $scope.daysClosed = [];
    $scope.datesClosed = [];
    $scope.area = selectedArea;
    set($scope.appointment, 'notes.label', $scope.area.appointmentsConfiguration.notes.label || null);
    closedDays();
    setCalendarOptions();

    $scope.appointmentDate = {
      calendar: DateHandlerService.getNeutralDate(),
      selected: null
    };
    $scope.fetchSlots();
  };

  $scope.updateIdType = () => {
    $scope.appointment.person.idType = get($scope.selectedIdType, 'value.key', undefined);
  };

  $scope.save = () => {
    $scope.appointment.person.identifications = buildIdentifications($scope.appointment.person.id, $scope.appointment.person.idType);

    if (ValidationService.validatePerson($scope.appointment.person, undefined, $scope, { timeOut: 10000 }, 'appointments') && validate()) {
      $scope.blockSaveButton = true;
      if (isAgendaEnabled && (get($scope, 'appointment.createdFrom', false) !== CreatedFromEnum.sigeci)) {
        $scope.appointment.appointment.date = $scope.appointmentDate.selected;
        $scope.appointment.appointment.ISODate = $scope.appointmentDate.selected;
      }
      if (appointmentId) {
        $scope.appointment.updatedFrom = CreatedFromEnum.virtualineAgenda;
        AppointmentService.Update($scope.appointment)
          .then(() => {
            $scope.blockSaveButton = false;
            NotificationService.Success($translate.instant('appointment_edit_succ'));
            $uibModalInstance.close('saved');
          })
          .catch(error => {
            $scope.blockSaveButton = false;
            ErrorService.handler(error);
          });
      } else {
        AppointmentReservationService.createAppointmentReservation(
          $scope.place._id, $scope.area._id, $scope.appointment.person,
          $scope.appointment.appointment.date, $scope.appointment.appointment.date, $scope.appointment.notes, CreatedFromEnum.virtualineAgenda)
          .then(() => {
            $scope.blockSaveButton = false;
            NotificationService.Success($translate.instant('appointment_create_succ'));
            $uibModalInstance.close('saved');
          })
          .catch(error => {
            $scope.blockSaveButton = false;
            ErrorService.handler(error);
          });
      }
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };


  const validate = () => {
    if (isAgendaEnabled && (get($scope, 'appointment.createdFrom', false) !== CreatedFromEnum.sigeci)) {
      if (!$scope.appointment.person.email) {
        NotificationService.Warning($translate.instant('mandatory_email'));
        return false;
      }
      if ($scope.appointmentDate && !$scope.appointmentDate.selected) {
        NotificationService.Warning($translate.instant('no_appointment_available'));
        return false;
      }
      if ($scope.area.appointmentsConfiguration.notes.required && !$scope.appointment.notes.text) {
        NotificationService.Warning($translate.instant('mandatory_notes'));
        return false;
      }
    }

    return true;
  };

  const setMaxDate = () => {
    const todayPlace = moment().tz($scope.place.timezone).startOf('day');
    return moment(todayPlace).add(1, 'year').toDate();
  };

  const setMinDate = () => {
    const todayPlace = moment().tz($scope.place.timezone);
    return todayPlace.startOf('day').toDate();
  };

  const validateProcessConfig = () => {
    const serviceCalendarType = $scope.area.appointmentsConfiguration.areaCalendarType;
    const dayToCheck = $scope.appointmentDate.calendar;

    if (serviceCalendarType === AreaCalendarType.ForADynamicPeriod) {
      $scope.appointmentDate.showProcessMessage = !DateHandlerService.dynamicPeriod(
        $scope.area.appointmentsConfiguration.dynamicPeriod,
        $scope.daysClosed,
        dayToCheck,
        $scope.place.timezone);
    } else if (serviceCalendarType === AreaCalendarType.ForAFixedPeriod) {
      $scope.appointmentDate.showProcessMessage = !DateHandlerService.fixedPeriod(
        $scope.area.appointmentsConfiguration.fixedPeriod,
        $scope.daysClosed,
        dayToCheck,
        $scope.place.timezone
      );
    }
  };

  const buildIdentifications = (id, idType) => Object.assign(
    {},
    $scope.appointment.person.identifications,
    {
      [`${idType}`]: { type: idType, value: id }
    }
  );

  // Date picker
  const setCalendarOptions = () => {
    $scope.dateOptions = {
      formatYear: 'yy',
      minDate: setMinDate(),
      maxDate: setMaxDate(),
      startingDay: 1,
      dateDisabled: date => includes($scope.daysClosed, date.date.getDay()) || includes($scope.datesClosed, moment(date.date).format('DD/MM/YYYY'))
    };
  };

  const closedDays = () => {
    forEach($scope.area.schedule, day => {
      if (!day.open) {
        $scope.daysClosed.push(day.number);
      }
    });
    HolidayService.GetAllForArea($scope.area._id).then(response => {
      $scope.datesClosed = response.data;
    })
      .catch(ErrorService.handler);
  };

  $scope.init();
};

module.exports = module.controller('EditAppointmentController', EditAppointmentController);
