import angular from 'angular';
import '../services/globals';
import '../services/notifications';
import './modals/incident-report';
import '../services/errors';
import Lodash from 'lodash';

const module = angular.module(
  'whyline.controllers.uploader', [
    'whyline.services.appointment',
    'whyline.services.globals',
    'whyline.services.notifications',
    'whyline.services.errors',
    'whyline.modals.incident-controller'
  ]
);

const UploaderController = ($scope, AppointmentService, NotificationService, ErrorService, $translate, $uibModal) => {
  /*@ngInject*/

  $scope.showUploadButton = true;

  /************** Error Handler **************/
  class AreaNotFoundError {
    Show(error) {
      $scope.errorsThen.push(error);
      return NotificationService.Warning($translate.instant('appointments_area_not_found'));
    }
  }

  class IdentificationTypeNotFoundError {
    Show(error) {
      const errorMessage = $translate.instant('appointments_identification_type_not_found');
      error.message = errorMessage;
      $scope.errorsThen.push(error);
      return NotificationService.Warning(errorMessage);
    }
  }

  class IdentificationTypeNotValidError {
    Show(error) {
      const errorMessage = $translate.instant('appointments_identification_type_not_valid');
      error.message = errorMessage;
      $scope.errorsThen.push(error);
      return NotificationService.Warning(errorMessage);
    }
  }

  class IdentificationValidationError {
    Show(error) {
      const errorMessage = $translate.instant('appointments_identification_validator_error');
      error.message = errorMessage;
      $scope.errorsThen.push(error);
      return NotificationService.Warning(errorMessage);
    }
  }

  class AppointmentsAlreadySaved {
    Show(error) {
      $scope.alreadySavedCount++;

      const from = error.limit * error.counter;

      if (!($scope.alreadySavedCount % from)) {
        const to = error.limit * (error.counter + 1);

        const message = $translate
          .instant('appointments_already_saved')
          .replace('{qty}', $scope.alreadySavedCount)
          .replace('{from}', from)
          .replace('{to}', $scope.totalAppointments < to ? $scope.totalAppointments : to);

        $scope.errorsThen.push({ reason: 'appointments_already_saved_range', message });
        return NotificationService.Warning(message);
      }
    }
  }

  class AppointmentsDontExist {
    Show(error) {
      $scope.dontExistCount++;

      const from = error.limit * error.counter;

      if (!($scope.dontExistCount % from)) {
        const to = error.limit * (error.counter + 1);

        const message = $translate
          .instant('appointments_dont_exist')
          .replace('{qty}', $scope.dontExistCount)
          .replace('{from}', from)
          .replace('{to}', $scope.totalAppointments < to ? $scope.totalAppointments : to);

        $scope.errorsThen.push({ reason: 'appointments_dont_exist_range', message });
        return NotificationService.Warning(message);
      }
    }
  }

  class AppointmentsDuplicatedInFile {
    Show(error) {
      $scope.errorsThen.push(error);
      $scope.errorsThen = Lodash.uniqWith($scope.errorsThen, Lodash.isEqual);
      const message = $translate.instant('appointment.duplicated-in-file').replace('{identifier}', error.appointment.NroCita);
      return NotificationService.Warning(message);
    }
  }

  class AppointmentsCantMapHeaders {
    Show(error) {
      $scope.errorFlags.headers = true;
      $scope.errorsThen.push(error);
      return error;
    }
  }
  class AppointmentsIncorrectNumberOfHeaders {
    Show(error) {
      $scope.errorFlags.headers = true;
      $scope.errorsThen.push(error);
      return error;
    }
  }

  class AppointmentsMissingRequiredValue {
    Show(error) {
      $scope.errorsThen.push(error);
      const appointment = error.appointment;
      const appointmentKeys = Lodash.keys(appointment);
      const keyFound = Lodash.find(appointmentKeys, key => !!appointment[key] && appointment[key] !== '');
      const message = $translate
        .instant(error.reason)
        .replace('{property}', keyFound)
        .replace('{propValue}', appointment[keyFound]);
      $scope.missingRequiredValueMessage = message;
    }
  }

  class DefaultError {
    Show() {
      NotificationService.Warning($translate.instant('err_prog_appointment_upload'));
    }
  }

  class ErrorHandler {
    constructor(errorHandler) {
      this.errorHandler = errorHandler;
    }

    SetStategy(errorHandler) {
      this.errorHandler = errorHandler;
      return this;
    }

    Show(error) {
      return this.errorHandler.Show(error);
    }
  }

  function errorsNotifications(error) {
    $scope.errorsThen = $scope.errorsThen || [];
    const errorHandler = new ErrorHandler();

    const errorMapper = {
      'appointment.no-area-found': new AreaNotFoundError(),
      'appointment.all-appointments-already-saved': new AppointmentsAlreadySaved(),
      'appointment.all-appointments-dont-exist': new AppointmentsDontExist(),
      'appointment.some-appointments-already-saved': new AppointmentsAlreadySaved(),
      'appointment.some-appointments-dont-exist': new AppointmentsDontExist(),
      'appointment.duplicated-in-file': new AppointmentsDuplicatedInFile(),
      'appointment.cant-map-headers': new AppointmentsCantMapHeaders(),
      'appointment.missing-required-value': new AppointmentsMissingRequiredValue(),
      'appointment.incorrect-number-of-headers': new AppointmentsIncorrectNumberOfHeaders(),
      'appointment.identification-type-not-found': new IdentificationTypeNotFoundError(),
      'appointment.identification-type-not-valid': new IdentificationTypeNotValidError(),
      'appointment.identification-validator-error': new IdentificationValidationError(),
      default: new DefaultError(),
    };
    const strategy = errorMapper[error.reason] || errorMapper.default;
    return errorHandler.SetStategy(strategy).Show(error);
  }
  /************** Fin Error Handler **************/

  async function processUpload(uploadTask, limit) {
    try {
      const result = await uploadTask();
      const { appointments, errors } = Lodash.get(result, 'data', result);

      Lodash.map(errors, error => {
        const errorToShow = Lodash.merge(error, { limit, counter: $scope.counter });
        return errorsNotifications(errorToShow);
      });

      if (appointments.length) {
        NotificationService.Success($translate.instant('appointments_uploaded').replace('{count}', `${appointments.length}`));
      }

      $scope.counter++;

      return appointments.length;
    } catch(error) {
      $scope.errorsCatch = $scope.errorsCatch || [];
      $scope.errorsCatch.push(error.data);
      errorsNotifications({ reason: error.reason || error.data });
      ErrorService.handler(error);
      return 0;
    }
  }

  async function runSerial(tasks, limit) {
    for(const task of tasks) {
      const fatalErrorFound = Object.values($scope.errorFlags).reduce((p, c) => p || c);
      if (fatalErrorFound) {
        throw new Error('fatal error exception');
      }
      $scope.appointmentsCreated += await processUpload(task, limit);
    }

    if ($scope.alreadySavedCount) {
      $scope.errorsThen.push({ appointment: Array($scope.alreadySavedCount), reason: 'appointment.some-appointments-already-saved' });
    }

    if ($scope.appointmentsCreated) {
      return NotificationService.Success($translate.instant('appointments_uploaded').replace('{count}', `${$scope.appointmentsCreated}`), { timeOut: 60000 });
    } else {
      return NotificationService.Warning($translate.instant('appointments_no_appointment_created'));
    }
  }


  $scope.getIncidentReport = async () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/incident-report.html',
      size: 'lg',
      controller: 'IncidentReportModalController',
      resolve: {
      }
    });
  }

  $scope.uploadFiles = async (file, createdFrom) => {
    try {
      $scope.errorFlags = {
        headers: false,
      };
      $scope.errorsThen = null;
      $scope.errorsCatch = null;
      $scope.appointmentsCreated = null;
      $scope.missingRequiredValueMessages = [];
      $scope.alreadySavedCount = 0;
      $scope.dontExistCount = 0;
      $scope.counter = 0;
      $scope.file = file;

      const limit = 100;

      if (file) {
        $scope.showUploadButton = false;
        const { appointmentsCount, tasks } = await AppointmentService.Upload(file, createdFrom, limit);
        $scope.totalAppointments = appointmentsCount;
        const result = await runSerial(tasks, limit);
        $scope.showUploadButton = true;
        return result;
      }
    } catch(error) {
      $scope.showUploadButton = true;
    }
  };
};

export default module.controller('UploaderController', UploaderController);
