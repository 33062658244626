import angular from 'angular';
import moment from 'moment-timezone';
import '../../../app.constants';
import '../../../resources/appointment';
import '../../../services/appointment';
import '../../../services/place';
import '../../../services/reception';
import '../../../services/totem-tv';
import '../../../services/notifications';

const module = angular.module('whyline.controllers.totem-tv.appointment-results', [
  'whyline.constants',
  'whyline.resources.appointment',
  'whyline.services.appointment',
  'whyline.services.place',
  'whyline.services.reception',
  'whyline.services.totem-tv',
  'whyline.services.notifications',
]);

const AppointmentResultsController = ($scope, $state, AppointmentService, PlaceService, NotificationService, TotemTvService, $translate) => {
  /*@ngInject*/

  AppointmentService.ClearSearchResults();

  $scope.place = PlaceService.GetCurrent();

  $scope.placeUTCOffset = moment.tz($scope.place.timezone).format('Z').toString();


  // Init
  $scope.selectedService = {
    _id: '',
  };
  $scope.appointmentsFound = false;

  // Controls
  TotemTvService.SetButtonStatus('back', true);
  TotemTvService.SetButtonStatus('forward', true);
  TotemTvService.SetButtonStatus('browserBack', false);
  TotemTvService.SetButtonStatus('status-text', false);

  AppointmentService.Search($state.params.fields, {
    force: true
  }).then(response => {
    $scope.appointmentsFound = response.data;
  });


  const validate = selected => {
    if (!selected._id) {
      NotificationService.Warning($translate.instant('select_appointment_mandatory'), TotemTvService.TOASTER_OPTIONS);
      return false;
    }

    return true;
  };

  TotemTvService.Validate(validate, $scope.selectedService);
};

module.exports = module.controller('TotemTvAppointmentResultsController', AppointmentResultsController);
