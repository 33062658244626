import angular from 'angular';
import '../../services/reception';
import '../../services/subscription';
import '../../services/globals';
import '../../services/errors';
import '../../services/validation';
import '../../services/place';
import '../../services/third-party-subscription-info';
import '../../services/access-control';
import { countriesWithCodes } from '../../utils/countries-and-codes';
import { get, isEmpty, first, set, size, toUpper } from 'lodash';
import { CreatedFromEnum } from '../../utils/createdFromEnum';

const module = angular.module('whyline.modals.reception-enqueue', [
  'whyline.services.reception',
  'whyline.services.subscription',
  'whyline.services.globals',
  'whyline.services.errors',
  'whyline.services.validation',
  'whyline.services.third-party-subscription-info',
  'whyline.services.access-control',
]);

const ReceptionEnqueueController = (
  SAFE_STATES,
  $scope,
  $state,
  $timeout,
  $uibModalInstance,
  SubscriptionService,
  NotificationService,
  ReceptionService,
  ErrorService,
  ValidationService,
  AreaService,
  PlaceService,
  currentOrganization,
  ThirdPartySubscriptionInfoService,
  AccessControlService,
  enqueueIn,
  path,
  blockUI,
  $translate,
  rejoining,
  currentPlace,
) => {
  'ngInject';

  const place = currentPlace;
  $scope.enterpriseType = place.enterpriseType;

  if (place.configuration.authorizedAndCourtesyDefault) {
    $scope.courtesyAndAuthorizedEnabled = true;
  } else {
    AccessControlService.CanPerformAction(['priority.courtesy'], place._id).then((canCourtesy) => {
      if (canCourtesy) {
        AccessControlService.CanPerformAction(['priority.authorized'], place._id).then(
          (canAuthorized) => {
            if (canAuthorized) {
              $scope.courtesyAndAuthorizedEnabled = true;
            } else {
              $scope.courtesyAndAuthorizedEnabled = false;
            }
          },
        );
      } else {
        $scope.courtesyAndAuthorizedEnabled = false;
      }
    });
  }

  $scope.currentArea = {
    withoutAppointment: {
      withPriority: true,
      withoutPriority: true,
    },
  };

  const currentAreaId = get(enqueueIn, 'areaId', null);
  if (currentAreaId) {
    $scope.currentArea = AreaService.GetOne(currentAreaId);
  }

  $scope.countries = countriesWithCodes;

  const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

  let selected =
    identifications.find((id) => id.default) || identifications.find((id) => isEmpty(id.key));

  let enqueueBlock = blockUI.instances.get('enqueueBlock');
  $scope.blockEnqueueButton = false;
  $scope.linesOfArea = [];
  $scope.enqueueIn = ReceptionService.GetEnqueueIn();
  $scope.currentPlace = PlaceService.GetOne($state.params.placeId);
  $scope.selectedLineToJoinReception = { value: null };

  const returnedPath = ReceptionService.GetPath();
  if (!returnedPath.length) {
    $state.go(SAFE_STATES.receptionStep, { n: 0 });
  } else {
    $scope.line = returnedPath[returnedPath.length - 1];
  }

  const reasons = get($scope, 'currentArea.configuration.reasons', []);
  $scope.reasonsEnabled = reasons.filter((r) => r.enabled);

  $scope.requestReasonAndMotive =
    get($scope, 'currentArea.configuration.requestReasonAndMotive', false) &&
    (enqueueIn.where !== 'line' || $scope.enterpriseType === 'whyline') &&
    $scope.reasonsEnabled.length > 0;

  $scope.reasonRequired =
    get($scope, 'currentArea.configuration.reasonRequired', false) &&
    $scope.reasonsEnabled.length > 0;

  $scope.selectedReason = { value: '' };

  $scope.motive = undefined;

  $scope.selectedIdType = {
    value: selected,
  };

  $scope.showEmail = !(
    !place.requirements.virtualine.email &&
    enqueueIn.where === 'process' &&
    !$scope.currentArea.appointmentsConfiguration.personalData.email
  );

  $scope.showPhone = !(
    !place.requirements.virtualine.phone &&
    enqueueIn.where === 'process' &&
    !$scope.currentArea.appointmentsConfiguration.personalData.phone
  );

  $scope.emptyPerson = () => ({
    id: '',
    idType: selected ? selected.key : undefined,
    phone: {
      countryCode: toUpper(place.location.countryCode),
      full: '',
    },
    email: '',
    priority: null,
  });

  const setPersonPriority = () => {
    if ($scope.priority.show) {
      $scope.person.priority = {
        category: $scope.priority.selectedCategory,
      };
    } else {
      $scope.person.priority = null;
    }
  };

  $scope.setPriority = () => {
    setPersonPriority();
  };

  $scope.setPriorityCategory = (priorityCategory) => {
    $scope.priority.selectedCategory = priorityCategory;
    $scope.person.priority.category = priorityCategory;
  };

  if (rejoining) {
    let dataPerson = SubscriptionService.subscriptionToClone.person;
    $scope.person = {
      priority: dataPerson.priority,
      firstName: dataPerson.firstName || '',
      lastName: dataPerson.lastName || '',
      idType: dataPerson.idType || '',
      id: dataPerson.id || '',
      email: dataPerson.email || '',
      phone: dataPerson.phone || {
        countryCode: toUpper(place.location.countryCode),
        full: '',
      },
    };

    if (dataPerson.priority && dataPerson.priority.category) {
      $scope.priority = {
        show: true,
        selectedCategory: dataPerson.priority.category,
        canBeChanged: false,
      };
    } else if (
      $scope.currentArea.withoutAppointment.withPriority &&
      !$scope.currentArea.withoutAppointment.withoutPriority
    ) {
      $scope.priority = {
        show: true,
        selectedCategory: 'disabled',
        canBeChanged: false,
      };
      set($scope, 'person.priority', { category: $scope.priority.selectedCategory });
    } else {
      $scope.priority = {
        show: false,
        selectedCategory: 'disabled',
        canBeChanged: true,
      };
    }

    if (!$scope.person.id || !$scope.person.idType) {
      for (const type in dataPerson.identifications) {
        if (dataPerson.identifications[type].hasOwnProperty('value')) {
          $scope.person.id = dataPerson.identifications[type].value;
          $scope.person.idType = type || '';
        }
      }
    }

    selected = identifications.find((id) => id.key === $scope.person.idType);

    $scope.selectedIdType.value = selected;
  } else {
    $scope.person = $scope.emptyPerson();
    if (
      $scope.currentArea.withoutAppointment.withPriority &&
      !$scope.currentArea.withoutAppointment.withoutPriority
    ) {
      $scope.priority = {
        show: true,
        selectedCategory: 'disabled',
        canBeChanged: false,
      };
      set($scope, 'person.priority', { category: $scope.priority.selectedCategory });
    } else {
      $scope.priority = {
        show: false,
        selectedCategory: 'disabled',
        canBeChanged: true,
      };
    }
  }

  $scope.thirdPartyApi = get(
    currentOrganization.configuration,
    'thirdPartyInfo.getSubscriptionInfo',
    false,
  );

  $scope.validatePerson = () => {
    if (!ValidationService.validatePerson($scope.person, 'personId')) {
      return;
    }

    ThirdPartySubscriptionInfoService.ValidatePerson(
      $scope.person.idType,
      $scope.person.id,
      currentOrganization._id,
    )
      .then((re) => {
        if (!isEmpty(re)) {
          $scope.person.id = re.id.toString();
          $scope.person.firstName = re.firstName.toString();
          $scope.person.lastName = re.lastName.toString();
          $scope.person.email = re.email.toString();
        } else {
          const id = $scope.person.id;
          const idType = $scope.person.idType;

          $scope.person = $scope.emptyPerson();
          $scope.person.id = id;
          $scope.person.idType = idType;
        }
      })
      .catch(() => {
        NotificationService.Error($translate.instant('error_search_person'));
      });
    $scope.apiSearch = true;
  };

  $scope.idTypesList = identifications;

  $scope.updateIdType = () => {
    $scope.person.idType = get($scope.selectedIdType, 'value.key', undefined);
  };

  $scope.enqueue = (person) => {
    const requestReasonAndMotive =
      get($scope, 'currentArea.configuration.requestReasonAndMotive', false) &&
      (enqueueIn.where !== 'line' || $scope.enterpriseType === 'whyline') &&
      $scope.reasonsEnabled.length > 0;

    const createdFrom = CreatedFromEnum.operator;
    const requestPriority = currentAreaId && !$scope.currentArea.withoutAppointment.withoutPriority;

    if (requestReasonAndMotive) {
      if ($scope.selectedReason.value === '' || !$scope.selectedReason.value) {
        if (
          get($scope, 'currentArea.configuration.reasonRequired', false) &&
          $scope.reasonsEnabled.length > 0
        ) {
          NotificationService.Warning($translate.instant('select_a_reason_err'));
          return;
        } else {
          enqueueIn = Object.assign(enqueueIn, { motive: $scope.motive });
        }
      } else {
        enqueueIn = Object.assign(enqueueIn, {
          reason: $scope.selectedReason.value.name,
          motive: $scope.motive,
        });
      }
    }

    if (requestPriority && !$scope.person.priority) {
      NotificationService.Warning($translate.instant('select_a_priority_err'));
      return;
    }

    if (ValidationService.validatePerson(person)) {
      if (!$scope.person.priority) {
        Reflect.deleteProperty($scope.person, 'priority');
      }

      $scope.blockEnqueueButton = true;
      if (
        $scope.currentPlace.configuration.canSkipProcessOrder &&
        $scope.selectedLineToJoinReception.value
      ) {
        $scope.enqueueIn.lineId = $scope.selectedLineToJoinReception.value._id;
        ReceptionService.SetEnqueueIn($scope.enqueueIn);
      }
      enqueueBlock.start('Creando suscripción...');
      SubscriptionService.Create(person, enqueueIn, createdFrom, path)
        .then(() => {
          $timeout(() => {
            delete enqueueIn.reason;
            delete enqueueIn.motive;
            NotificationService.Success($translate.instant('user_add_fila_succ'));
            enqueueBlock.stop();
            $scope.blockEnqueueButton = false;
            if (rejoining) {
              $state.go(SAFE_STATES.nowServingQueue, {
                lineId: SubscriptionService.subscriptionToClone.lineId,
              });
            }
            $scope.cancel();
          });
        })
        .catch((err) => {
          ErrorService.handler(err);
          enqueueBlock.stop();
          $scope.blockEnqueueButton = false;
          $scope.cancel();
        });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  if ($scope.enqueueIn.areaId) {
    AreaService.GetLinesOfArea($scope.enqueueIn.areaId).then((lines) => {
      $timeout(() => {
        $scope.linesOfArea = lines;

        const firstLine = first(lines);

        $scope.selectedLineToJoinReception.value = firstLine || undefined;

        $scope.hasMoreThanOneLine = size(lines) > 1;
      });
    });
  }
};

module.exports = module.controller('ReceptionEnqueueController', ReceptionEnqueueController);
