/* eslint-disable arrow-parens */
import angular from 'angular';
import '../services/organization-scenario';
import '../services/place';
import '../services/errors';
import '../services/globals';
import { startCase } from 'lodash';
const module = angular.module('whyline.controllers.scenarios', [
  'whyline.services.organization-scenario',
  'whyline.services.place',
  'whyline.services.errors',
  'whyline.services.globals',
]);

const ScenariosController = (
  $scope,
  $translate,
  OrganizationScenarioService,
  PlaceService,
  ErrorService,
  currentPlace,
  GlobalsService,
  $timeout,
  dialog,
) => {
  /*@ngInject*/

  const organizationScenariosPromise = OrganizationScenarioService.GetAllAsPromiseFromServer();
  const placePromise = PlaceService.GetOneAsPromiseFromServer(currentPlace._id);

  const promises = [organizationScenariosPromise, placePromise];

  Promise.all(promises).then(([organizationScenarios, place]) => {
    let allActiveScenariosInPlace = [];
    if (place.scenarios && place.scenarios.length) {
      allActiveScenariosInPlace = place.scenarios.filter((e) => e.enabled);
    }
    const allOrgScenarios = Object.values(organizationScenarios).filter((os) => !os.isDeleted);
    const orgScenariosDefault = allOrgScenarios.find((org) => org.isDefault);
    const placeScenarioDefault = place.scenarios.find(
      (scenarioPlace) => scenarioPlace.scenarioId == orgScenariosDefault._id,
    );

    orgScenariosDefault.isDefault = placeScenarioDefault.isDefault;
    const orgScenariosInactive = allOrgScenarios.filter(
      ({ _id }) => !allActiveScenariosInPlace.some(({ scenarioId }) => _id === scenarioId),
    );
    const allOrgScenariosWithEnableValue = orgScenariosInactive.map((os) => ({
      ...os,
      enabled: false,
    }));
    $timeout(() => {
      $scope.scenarios = [...allActiveScenariosInPlace, ...allOrgScenariosWithEnableValue];
    });
  });

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';

  const defaultScenarioText = $translate.instant('scenario_is_default');
  $scope.scenarioDefault = defaultScenarioText.charAt(0);

  $scope.switch = (scenario) => {
    if (!scenario.isDefault) {
      PlaceService.EnableScenario(scenario, currentPlace._id)
        .then(() => {})
        .catch((err) => {
          scenario.enabled = false;
          ErrorService.handler(err);
        });
    }
  };

  $scope.changeDefault = (scenario) => {
    if (scenario.enabled) {
      const dialogContent = $translate
        .instant('scenarios_set_default')
        .replace('{schema_name}', startCase(scenario.name));

      dialog
        .confirm(dialogContent)
        .then((result) => {
          $scope.scenarios.filter((item) => {
            if (item.isDefault) {
              item.isDefault = !item.isDefault;
              return scenario;
            }
          });
          scenario.isDefault = !scenario.isDefault;
          PlaceService.SetDefaultScenario(scenario, currentPlace._id)
            .then(() => {})
            .catch((err) => {
              scenario.isDefault = !scenario.isDefault;
              ErrorService.handler(err);
            });
        })
        .catch(ErrorService.handler);
    }
  };
};

module.exports = module.controller('ScenariosController', ScenariosController);
