import angular from 'angular';
import './resource';
import '../app.constants';
import '../resources/subscription';
import { schedule } from '../utils';
import { isEmpty, values } from 'lodash';

const module = angular.module('whyline.resources.organization-line', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.resources.subscription',
]);

const OrganizationLineFactory = (API_URL, $http, Resource, $timeout, Subscription, Socket) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'organization/:organizationId/lines';

  const _schema = {
    _id: {
      type: String
    },
    organizationId: {
      type: String,
      required: true
    },
    organizationAreaId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      required: true,
    },
    averageWaitingByNumber: {
      type: Number,
      required: true
    },
    lastNumberCalled: {
      type: Number,
      required: true
    },
    lastNumberGiven: {
      type: Number,
      required: true
    },
    schedule: {
      type: Object,
      required: true,
      default: schedule.create()
    },
    maxNumbers: {
      type: Number,
      required: true,
      default: 0
    },
    lastCallTime: {
      type: Date,
      required: true
    },
    type: {
      type: String,
      required: false,
    },
    boxLabel: {
      type: String,
      required: false,
    },
    configuration: {
      messages: {
        preJoin: {
          type: String,
          default: '',
        },
        postJoin: {
          type: String,
          default: '',
        },
        postCheckIn: {
          type: String,
          default: '',
        }
      }
    },
    updateType: {
      type: String,
      required: false
    },
    requestSource: {
      mobileApp: {
        type: Boolean,
        required: true,
        default: false,
      },
      reception: {
        type: Boolean,
        required: true,
        default: false,
      },
      totem: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    isDeleted: {
      type: Boolean,
    },
  };

  const OrganizationLine = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.clear = () => {
      const url = `${API_URL}${_type}/${this._id}/clear`;
      return $http.post(url).then(response => response);
    };

    this.enable = (enabled, enableInPlaces) => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
        enableInPlaces
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            // Cuando halla respuesta, modificar el cache
            OrganizationLine.UpdateCache(item._id, item, null);
          });
          // Devuelve la respuesta desde el cache.
          return OrganizationLine.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  OrganizationLine.SavePartial = options => {
    const url = `${API_URL}organization/${options.organizationId}/lines/${options.organizationLineId}`;
    return $http({
      method: 'PATCH',
      url: url,
      data: options.data,
    })
      .then(response => {
        if (response.data) {
          const item = response.data;

          OrganizationLine.UpdateCache(item._id, item, null);

          return response;
        }
      }).catch(e => {
        throw e;
      });
  };
  OrganizationLine.prototype = new Resource(_type, _schema, OrganizationLine);

  Resource.Extend(_type, OrganizationLine);

  //five minutes
  const validityTime = 5 * 60 * 1000;
  OrganizationLine.InitCache(validityTime);

  return OrganizationLine;
};

module.exports = module.factory('OrganizationLine', OrganizationLineFactory);
