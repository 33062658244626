import angular from 'angular';
import '../app.constants';
import { get, pullAt, pickBy, indexOf, keys } from 'lodash';
import '../factories/socket.io';
import '../services/socket';
import '../resources/device';
import '../services/place';

const module = angular.module('whyline.sockets.device', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.device',
  'whyline.services.errors',
  'whyline.services.socket',
  'whyline.services.place'
]);

const DeviceSocket = (Socket, Device, $state, SAFE_STATES, ErrorService, $rootScope, SocketService, API_URL, PlaceService) => {
  $rootScope.$on('socketReady', () => {


    
    SocketService.GetInstance().then(socket => {
      socket.on('device:linked', device => {
        const place = PlaceService.GetCurrent();
        Device.UpdateCache(device._id, device);
        const cacheUrl = `${API_URL}device/linked?placeId=${place._id}`;
        const list = Device.GetCacheSearch(cacheUrl);
        list[device._id] = device;
        Device.UpdateCacheSearch(cacheUrl, list, {});
      });
      socket.on('device:updated', device => {
        const place = PlaceService.GetCurrent();
        Device.UpdateCache(device._id, device);
        const cacheUrl = `${API_URL}device/linked?placeId=${place._id}`;
        const list = Device.GetCacheSearch(cacheUrl);
        list[device._id] = device;
        Device.UpdateCacheSearch(cacheUrl, list, {});
      });
      socket.on('device:unlinked', device => {
        const place = PlaceService.GetCurrent();
        Device.RemoveFromCache(device._id, 'device?placeId');
        const cacheUrl = `${API_URL}device/linked?placeId=${place._id}`;
        const list = Device.GetCacheSearch(cacheUrl);
        if(list.hasOwnProperty(device._id)) {
          Reflect.deleteProperty(list, device._id);
        }
        Device.UpdateCacheSearch(cacheUrl, list, {});
      });

    });

  });
};

module.exports = module.factory('DeviceSocket', (Socket, Device, $state, SAFE_STATES, ErrorService, $rootScope, SocketService, API_URL, PlaceService) => new DeviceSocket(Socket, Device, $state, SAFE_STATES, ErrorService, $rootScope, SocketService, API_URL, PlaceService));