import angular from 'angular';
import '../app.constants';
import '../services/auth';
import '../services/globals';

const module = angular.module('whyline.controllers.verify-account', [
  'whyline.constants',
  'whyline.services.auth',
  'whyline.services.globals',
]);

const VerifyAccountController = function(SAFE_STATES, $scope, $state, AuthService, NotificationService, GlobalsService, $translate) {
  'ngInject';

  $scope.enterpriseType = GlobalsService.EnterpriseType;
  $scope.currentYear = new Date().getFullYear();

  this.cancelVerify = () => {
    AuthService.SignOut();
    $state.go(SAFE_STATES.signIn);
  };

  this.save = () => {
    if(this.password !== this.passwordRepeated || !this.password || !this.passwordRepeated) {
      NotificationService.Error($translate.instant('unmatch_pw'));
    } else {
      return AuthService.VerifyAccount(this.password, $state.params.token)
      .then(token => {
        if(token) {
          $state.go(SAFE_STATES.placesList);
        } else {
          NotificationService.Error($translate.instant('err_verify_acc'));
        }
      })
      .catch(() => {
        NotificationService.Error($translate.instant('err_verify_acc'));
      });
    }
  };
};
module.exports = module.controller('VerifyAccountController', VerifyAccountController);
