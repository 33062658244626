import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/organization-condition';

const module = angular.module('whyline.services.organization-condition', [
  'whyline.resources.organization-condition',
]);

let OrganizationCondition;

class OrganizationConditionService {
  static $inject = ['OrganizationCondition'];

  constructor(injectedOrganizationCondition) {
    OrganizationCondition = injectedOrganizationCondition;
  }

  GetAll() {
    return OrganizationCondition.FetchAll();
  }

  GetAllAsPromise() {
    return OrganizationCondition.FetchAll({ promise: true});
  }

  GetAllAsPromiseFromServer() {
    return OrganizationCondition.FetchAll({ promise: true, force: true});
  }

  GetOne(conditionId) {
    return OrganizationCondition.FindById(conditionId);
  }

  GetOneAsPromise(conditionId) {
    return OrganizationCondition.FindById(conditionId, {promise: true});
  }

  Create(data) {
    const newBanner = new OrganizationCondition(data);
    return OrganizationCondition.Save(newBanner);
  }

  Copy(condition) {
    const copy = cloneDeep(condition);
    return new OrganizationCondition(copy);
  }

  Update(condition) {
    return OrganizationCondition.Save(condition);
  }

  Remove(conditionId) {
    const condition = OrganizationCondition.FindById(conditionId);
    return OrganizationCondition.Remove(condition);
  }

  Switch() {
    //console.log('TODO: implement banner switch');
  }
}

module.exports = module.service('OrganizationConditionService', OrganizationConditionService);
