import angular from 'angular';
import '../../components/osom';
import '../../components/queue';
import '../../components/permission';
import '../../services/place';
import '../../services/line';
import '../../services/area';
import '../../services/user';
import '../../services/organization-user';
import '../../services/auth';
import '../../services/errors';
import { set } from 'lodash';

const module = angular.module('whyline.modals.organization-user', [
  'whyline.components.osom',
  'whyline.components.queue',
  'whyline.components.permission',
  'whyline.services.place',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.user',
  'whyline.services.organizationuser',
  'whyline.services.resource-group',
  'whyline.services.auth',
  'whyline.services.errors',
]);

const OrganizationUserModalController = function (
  $uibModalInstance,
  $translate,
  NotificationService,
  UserService,
  OrganizationUserService,
  ErrorService,
  userId,
  organizationId,
  $timeout,
  AuthService,
  dialog,
) {
  'ngInject';

  // Initialization
  this.blockSaveButton = false;
  this.newPw = {
    newPassword: '',
    newConfirmPassword: '',
  };
  this.showChangePassword = false;
  this.currentUser = UserService.GetCurrent();
  this.organizationId = organizationId;
  this.user = {
    profile: {},
    local: {},
  };

  if (userId) {
    OrganizationUserService.GetOneAsPromise(userId)
      .then(user => {
        $timeout(() => {
          this.user = OrganizationUserService.Copy(user);
        });
      })
      .catch(ErrorService.handler);
  }

  let validate = () => {
    const firstName = this.user.profile.firstName;
    const lastName = this.user.profile.lastName;
    const email = this.user.local.email;
    const password = this.newPw.newPassword;
    const confirmPassword = this.newPw.newConfirmPassword;
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const stringPattern = /^[a-zA-Z .-]+$/;

    if (!firstName) {
      NotificationService.Warning($translate.instant('mandatory_user_name'));
      return false;
    }

    if (firstName.length < 2 || firstName.length > 512) {
      NotificationService.Warning($translate.instant('user_firstname_length'));
      return false;
    }

    if(firstName && !stringPattern.test(firstName)) {
      NotificationService.Warning($translate.instant('invalid_name_format'));
      return false;
    }

    if (!lastName) {
      NotificationService.Warning($translate.instant('mandatory_user_lastname'));
      return false;
    }

    if (lastName.length < 2 || lastName.length > 512) {
      NotificationService.Warning($translate.instant('user_lastname_length'));
      return false;
    }

    if(lastName && !stringPattern.test(lastName)) {
      NotificationService.Warning($translate.instant('invalid_surname_format'));
      return false;
    }

    if (!email) {
      NotificationService.Warning($translate.instant('mandatory_user_mail'));
      return false;
    }

    if (email && !emailPattern.test(email)) {
      NotificationService.Warning($translate.instant('invalid_email_format'));
      return false;
    }

    if (password !== confirmPassword) {
      NotificationService.Warning($translate.instant('pw_must_match'));
      return false;
    }

    if ((password || confirmPassword) & (password.length < 5 || confirmPassword.length < 5)) {
      NotificationService.Warning($translate.instant('pw_min_length'));
      return false;
    }

    return true;
  };

  this.save = () => {
    if (validate()) {
      this.blockSaveButton = true;

      if (this.newPw.newPassword) {
        set(this, 'user.local.password', AuthService.Encrypt(this.newPw.newPassword));
      } else {
        set(this, 'user.local.password', null);
      }
      if (userId) {
        this.user.sudo = !!this.user.sudo;
        dialog.confirm($translate.instant('active_permisos_change'))
          .then(() => OrganizationUserService.Update(this.user)
            .then(() => {
              NotificationService.Success($translate.instant('user_{name}_update_succ').replace('{name}', this.user.local.email));
              $uibModalInstance.close('cancel');
            })
            .catch(err => {
              let text = $translate.instant('unable_user_save');
              NotificationService.Warning(`${text} ${err}`);
              this.blockSaveButton = false;
              $uibModalInstance.close('cancel');
            }))
          .catch(() => {
            NotificationService.Information($translate.instant('operation_cancelled'));
            this.blockSaveButton = false;
            $uibModalInstance.close('cancel');
          });
      } else {
        OrganizationUserService.Create(this.user)
          .then(() => {
            NotificationService.Success(`Usuario ${this.user.local.email} creado correctamente.`);
            $uibModalInstance.close('cancel');
          })
          .catch(error => {
            ErrorService.handler(error);
            this.blockSaveButton = false;
          });
      }
    }
  };

  this.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller('OrganizationUserModalController', OrganizationUserModalController);
