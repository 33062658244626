import angular from 'angular';
import './process';
import './line';
import './schema';
const module = angular.module('whyline.components.stats', [
    'whyline.components.stats.process',
    'whyline.components.stats.line',
    'whyline.components.stats.schema']);

export default module;
