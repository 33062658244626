import angular from 'angular';
const module = angular.module('whyline.components.image-uploader', []);

const ImageUploaderComponent = {
  bindings: {
    selectedImage: '='
  },
  templateUrl: '/templates/components/image-uploader.html',
  controller($scope) {
    'ngInject';

    const ctrl = this;

    $scope.uploadedImage = '';
    $scope.resultImage = '';

    $scope.$watch('resultImage', (newVal, oldVal) => {
      if (newVal !== oldVal) {
        ctrl.selectedImage = newVal;
      }
    });

    const handleFileSelect = evt => {
      const file = evt.currentTarget.files[0];
      const reader = new FileReader();
      reader.onload = evt => {
        $scope.$apply($scope => {
          $scope.uploadedImage = evt.target.result;
        });
      };
      reader.readAsDataURL(file);
    };

    angular.element(document.querySelector('#imageUploaderInput')).on('change', handleFileSelect);
  },
};

module.exports = module.component('imageUploader', ImageUploaderComponent);
