import angular from 'angular';
import '../../services/banner';
import '../../services/errors';
import { get } from 'lodash';

const module = angular.module('whyline.modals.banner', [
  'whyline.services.banner',
  'whyline.services.errors',
]);

const BannerModalController = (
  $scope,
  $timeout,
  $uibModalInstance,
  $translate,
  BannerService,
  NotificationService,
  ErrorService,
  bannerId,
  currentPlace
) => {
  'ngInject';

  $scope.place = currentPlace;

  $scope.banner = {
    name: '',
    description: '',
  };

  const validate = () => {
    const name = get($scope.banner, 'name', '');
    const description = get($scope.banner, 'description', '');
    const nameMaxLength = 30;
    const descriptionMaxLength = 500;
    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_name'));
      return false;
    }
    if (name.length > nameMaxLength) {
      NotificationService.Warning(
        $translate
          .instant('max_{maxNumber}_length_name')
          .replace('{maxNumber}', nameMaxLength)
      );
      return false;
    }
    if (!description) {
      NotificationService.Warning($translate.instant('mandatory_description'));
      return false;
    }
    if (description.length > descriptionMaxLength) {
      NotificationService.Warning(
        $translate
          .instant('max_{maxNumber}_length_description')
          .replace('{maxNumber}', descriptionMaxLength)
      );
      return false;
    }
    return true;
  };

  const dismissOnError = err => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
    BannerService.GetAllAsPromiseFromServer();
  };

  if (bannerId) {
    BannerService.GetOneAsPromise(bannerId)
      .then(banner => {
        $timeout(() => {
          $scope.banner = BannerService.Copy(banner);
        });
      })
      .catch(error => {
        dismissOnError(error);
        ErrorService.handler(error);
      });
  }

  $scope.save = () => {
    if (validate()) {
      if (bannerId) {
        return BannerService.Update($scope.banner)
          .then(() => {
            NotificationService.Success(
              $translate.instant('banner_update_succ')
            );
            $uibModalInstance.close('cancel');
          })
          .catch(error => {
            dismissOnError(error);
            ErrorService.handler(error);
          });
      }

      BannerService.Create($scope.banner)
        .then(() => {
          NotificationService.Success($translate.instant('banner_create_succ'));
          $uibModalInstance.close('cancel');
        })
        .catch(error => {
          dismissOnError(error);
          ErrorService.handler(error);
        });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller(
  'BannerModalController',
  BannerModalController
);
