import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.organization-messages', [
  'whyline.resources.resource',
]);

const OrganizationMessagesFactory = (Resource, $http, API_URL) => {
  /*@ngInject*/

  //API REST Resource Name
  const _type = 'organization?organizationId';

  const _schema = {
    _id: String,
    configuration: [
      {
        _id: String,
        enabled: Boolean,
        readOnly: Boolean,
        type: String,
      }
    ],
    language: String,
    message: String,
    organizationId: String,
    slug: String,
    title: String,
  };

  const OrganizationMessages = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  OrganizationMessages.prototype = new Resource(_type, _schema, OrganizationMessages);

  Resource.Extend(_type, OrganizationMessages);

  //  ad-infinitum
  const validityTime = 24 * 60 * 60 * 1000;
  OrganizationMessages.InitCache(validityTime);

  OrganizationMessages.GetAllMessages = organizationId => {
    const url = `${API_URL}organization/${organizationId}/messages`;
    return $http.get(url).then(({ data }) => data);
  };

  OrganizationMessages.UpdateAllMessages = (messages, organizationId, forAllPlaces) => {
    const url = `${API_URL}organization/${organizationId}/messages?forAllPlaces=${forAllPlaces}`;
    return $http.put(url, messages).then(({ data }) => data);
  };

  return OrganizationMessages;
};

module.exports = module.factory('OrganizationMessages', OrganizationMessagesFactory);
