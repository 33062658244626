import angular from 'angular';
import '../app.constants';
import '../resources/place';
import '../factories/socket.io';
import '../services/socket';
import '../services/user';

const module = angular.module('whyline.sockets.place', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.services.socket',
  'whyline.resources.place',
  'whyline.services.user',
]);

class PlaceSocket {
  constructor(Socket, Place, $rootScope, SocketService, UserService) {
    this.RootScope = $rootScope;
    this.Socket = Socket;
    this.Place = Place;
    this.SocketService = SocketService;
    this.UserService = UserService;
  }

  Init() {
    return this.RootScope.$on('socketReady', async () => {
      const socket = await this.SocketService.GetInstance();
      socket.on('place:created', place => {
        this.UserService.SyncCurrent()
          .then(() => {
            this.Place.UpdateCache(place._id, place);
          });
      });
      socket.on('place:updated', place => this.Place.UpdateCache(place._id, place));
    });
  }
}

export default module.factory('PlaceSocket', (Socket, Place, $rootScope, SocketService, UserService) => new PlaceSocket(Socket, Place, $rootScope, SocketService, UserService).Init());
