import angular from 'angular';
import './resource';
import '../app.constants';
import '../resources/subscription';

const module = angular.module('whyline.resources.line-flow-type-organization', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.resources.subscription',
]);

const LineFlowTypeOrganizationFactory = (API_URL, $http, Resource) => {
    /*@ngInject*/
    //API REST Resource Name
    const _type = 'line-flow-type-organization?organizationId';

    const _schema = {
        _id: {
            type: String
        },
        disabledInPlaces: {
            type: [String],
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        isWhylineDefault: {
            type: Boolean,
            required: true,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        lineTypeComposition: {
            type: [],
        },
        priorityCategories: {
          type: [],
        },
        organizationId: {
            type: String,
        },
    };

    const LineFlowTypeOrganization = function (data) {
        angular.forEach(_schema, (prop, key) => {
        this[key] = prop.default;
        });

        angular.forEach(data, (prop, key) => {
        this[key] = prop;
        });
    }

    LineFlowTypeOrganization.GetAllByOrganization = (organizationId) => {
        const url = `${API_URL}${_type}?organizationId=${organizationId}`;
        return $http.get(url)
          .then(response => {
            return Promise.resolve(response.data);
          });
    };

    LineFlowTypeOrganization.GetAllEnabledByOrganization = (organizationId, fromPlace) => {
        const url = fromPlace ?
            `${API_URL}line-flow-type-organization/enabled?organizationId` :
            `${API_URL}line-flow-type-organization/enabled?organizationId?organizationId=${organizationId}`;
        return $http.get(url)
          .then(response => {
            return Promise.resolve(response.data);
          });
    };
    
    LineFlowTypeOrganization.Enable = (LineFlowTypeOrganization) => {
        const url = `${API_URL}line-flow-type-organization/${LineFlowTypeOrganization._id}/enable?organizationId`;
        return $http.patch(url)
          .then(response => {
            return Promise.resolve(response.data);
          });
    };

    LineFlowTypeOrganization.EnableInPlace = (LineFlowTypeOrganization) => {
        const url = `${API_URL}line-flow-type-organization/${LineFlowTypeOrganization._id}/enableInPlace?placeId`;
        return $http.patch(url)
          .then(response => {
            return Promise.resolve(response.data);
          });
    };

    LineFlowTypeOrganization.GetAllEnabledByPlace = (placeId, fromOrganization) => {
        const url = fromOrganization ?
            `${API_URL}line-flow-type-organization/places/enabled?placeId?placeId=${placeId}` :
            `${API_URL}line-flow-type-organization/places/enabled?placeId`;
        return $http.get(url)
          .then(response => {
            return Promise.resolve(response.data);
          });
    };

    LineFlowTypeOrganization.prototype = new Resource(_type, _schema, LineFlowTypeOrganization);

    Resource.Extend(_type, LineFlowTypeOrganization);

    //five minutes
    const validityTime = 5 * 60 * 1000;
    LineFlowTypeOrganization.InitCache(validityTime);

    return LineFlowTypeOrganization;    

};

module.exports = module.factory('LineFlowTypeOrganization', LineFlowTypeOrganizationFactory);
