import angular from 'angular';

const module = angular.module('whyline.services.redirect-to-new-version', []);

let $window;
let $http;
let OLD_VERSION_SERVER_URL;
let OLD_VERSION_CLIENT_URL;

class RedirectToNewVersionService {

  static $inject = ['$http', '$window', 'OLD_VERSION_SERVER_URL', 'OLD_VERSION_CLIENT_URL'];

  constructor(inj$http, inj$window, injOldVersionServerUrl, injOldVersionClientUrl) {
    $http = inj$http;
    $window = inj$window;
    OLD_VERSION_SERVER_URL = injOldVersionServerUrl;
    OLD_VERSION_CLIENT_URL = injOldVersionClientUrl;
  }

  Redirect(action, placeId, userId, resourceId) {
    const url = `${OLD_VERSION_SERVER_URL}api/v1/v2tov1/create-hash-v2-to-v1`;
    $http.post(url, {
      action,
      placeId,
      userId
    })
    .then(response => {
      $window.location.href = `${OLD_VERSION_CLIENT_URL}#!/v2tov1?action=${action}&placeId=${placeId}&userId=${userId}&resourceId=${resourceId}&hash=${response.data}`;
    });
  }
}

module.exports = module.service('RedirectToNewVersionService', RedirectToNewVersionService);
