const appointmentStatusEnum = [
  'requeued',
  'forwarded',
  'not-present',
  'completed',
  'not-approved',
  'unsubscribe',
  'expired',
];

module.exports = {
  appointmentStatusEnum,
};
