import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/resource-group';

const module = angular.module('whyline.services.resource-group', [
  'whyline.resources.resource-group'
]);

let ResourceGroup;

const handleError = err => {
  console.error(err);
  throw err;
};

class ResourceGroupService {

  static $inject = ['ResourceGroup'];

  constructor(injectedResourceGroup) {
    ResourceGroup = injectedResourceGroup;
  }

  Create(data) {
    const newResourceGroup = new ResourceGroup(data);
    newResourceGroup.type = 'resourceGroup';
    return ResourceGroup.Save(newResourceGroup);
  }

  Copy(resourceGroup) {
    const copy = cloneDeep(resourceGroup);
    return new ResourceGroup(copy);
  }

  Update(resourceGroup) {
    return ResourceGroup.Save(resourceGroup);
  }

  Remove(resourceGroupId) {
    const resourceGroup = ResourceGroup.FindById(resourceGroupId);
    return ResourceGroup.Remove(resourceGroup);
  }

  GetAll() {
    return ResourceGroup.FetchAll();
  }

  GetAllAsPromise() {
    return ResourceGroup.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return ResourceGroup.FetchAll({ promise: true, force: true });
  }

  GetOne(resourceGroupId) {
    return ResourceGroup.FindById(resourceGroupId);
  }

  GetOneAsPromise(resourceGroupId) {
    return ResourceGroup.FindById(resourceGroupId, { promise: true });
  }

  GetOneAsPromiseFromServer(resourceGroupId) {
    return ResourceGroup.FindById(resourceGroupId, { promise: true, force: true });
  }

}

module.exports = module.service('ResourceGroupService', ResourceGroupService);
