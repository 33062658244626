import moment from 'moment';
import { forEach, some, every } from 'lodash';

/**
 * Format Average Waiting Time (Awt) to be shown in UI
 */
const padToTwo = number => {
  if (number <= 9) {
    return `0${number}`;
  } else {
    return number;
  }
};

export const formatAwt = awtInMinutes => {
  if (!awtInMinutes) {
    return '0:00';
  }

  let duration = moment.duration(awtInMinutes, 'minutes');
  let hours = Math.floor(duration.asHours());
  duration.subtract(hours, 'h');
  let minutes = padToTwo(Math.floor(duration.asMinutes()));
  return `${hours}:${minutes}`;
};

export function validatePersonId(idNumber, idType, $window, identification) {
  idNumber = idNumber || '';

  if (idNumber.length === 0) {
    return false;
  }

  if (identification && identification.validator) {
    try {
      const evaluation = 'var validateByIdentificationValidator = ' + identification.validator + '; //# sourceURL=val-' + identification.key + '.js';
      $window.eval(evaluation);

      return validateByIdentificationValidator(idNumber, idType);
    } catch (e) {
      console.error(e);
    }
  }
};
