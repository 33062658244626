import angular from 'angular';
import './modals/totems';
import '../services/totem';
import '../services/errors';
import '../services/place';
import '../app.constants';
import '../services/globals';

const module = angular.module('whyline.controllers.totems', [
  'whyline.modals.totems',
  'whyline.services.totem',
  'whyline.services.place',
  'whyline.services.errors',
  'whyline.constants',
  'whyline.services.globals',  
]);

const TotemsController = ($scope, $state, $uibModal, $translate, TotemService, GlobalsService, PlaceService, NotificationService, ErrorService, dialog, currentPlace, URL_TOTEM_BA) => {
  /*@ngInject*/
  const merchantMaxTotems = 1; // TODO: change when plan scope gets implemented

  $scope.title = 'totems';
  $scope.quantityOfItems = 8;
  $scope.organizationId = $state.params.organizationId;
  $scope.urlTotemBa = URL_TOTEM_BA;

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';
  PlaceService.GetCurrentAsPromise()
    .then(place => {
      $scope.place = place;
    });

  $scope.totems = TotemService.GetAll();

  $scope.plan = {};
  $scope.plan.maxTotems = currentPlace.plan && currentPlace.plan.maxTotems ? currentPlace.plan.maxTotems : merchantMaxTotems;

  $scope.remove = (totemId, name) => {
    let n = name || '';
    let text = $translate.instant('totem_{name}_remove_sure').replace('{name}', n);
    dialog.confirm(text)
      .then(() => {
        TotemService.Remove(totemId)
          .then(() => {
            let n = name || '';
            let text = $translate.instant('totem_{name}_remove_succ').replace('{name}', n);
            NotificationService.Success(text);
          })
          .catch(ErrorService.handler);
      });
  };

  $scope.switch = TotemService.Switch;

  // Modal
  $scope.open = totemId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/totems-modal.html',
      size: 'lg',
      controller: 'TotemModalController',
      resolve: {
        totemId: () => totemId
      }
    });
  };

  $scope.redirectToTotemBA = (totemId) => {
    TotemService.GetTotemBAHash(totemId)
    .then((hash) => {
      window.open(`${$scope.urlTotemBa}?virtualine=${hash}&organizationId=${$scope.organizationId}&placeId=${$scope.place._id}&totemId=${totemId}`, '_blank');
    })
    .catch(ErrorService.handler);
  }
};

module.exports = module.controller('TotemsController', TotemsController);
