import localForage from 'localforage';

const set = (key, value) => {
  if (key.toLowerCase() === 'user') {
    localForage.setItem(key, JSON.stringify({ value }));
  } else {
    localStorage.setItem(key, JSON.stringify({ value }));
  }
  };

const get = key => {
  const str = localStorage.getItem(key);
  if (str && str.length) {
    const { value } = JSON.parse(str);
    if (key.toLowerCase() === 'user') {
      localForage.setItem(key, str);
      localStorage.removeItem(key);
      return new Promise(resolve => {
        resolve(str);
      });
    } else {
      return value;
    }
  } else if (key.toLowerCase() === 'user') {
    return localForage.getItem(key).then(value => {
      if (typeof value === 'string') {
        try {
          const parsed = JSON.parse(value);
          if (parsed.value) {
            return parsed.value;
          }
          return undefined;
        } catch(e) {
          return undefined;
        }
      }
      return undefined;
    });
  }
};

const remove = key => {
  if (key.toLowerCase() === 'user') {
    localForage.removeItem(key);
  } else {
    localStorage.removeItem(key);
  }
}

module.exports = {
  set,
  get,
  remove
};
