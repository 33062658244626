import angular from 'angular';
import moment from 'moment-timezone';
import _ from 'lodash';
import './../app.constants';

const module = angular.module('whyline.services.incident-report', [
  'whyline.constants',
  'ngFileSaver',
]);

let API_URL;
let $http;
let $translate;
let $timeout;
let FileSaver;

const handleError = err => {
  throw err.data;
};

class IncidentReportService {
  static $inject = ['API_URL', '$http', '$timeout', 'FileSaver'];

  constructor(injectedAPI, injected$http, injected$translate, fileSaverInjected, injected$timeout) {
    API_URL = injectedAPI;
    $http = injected$http;
    $timeout = injected$timeout;
    $translate = injected$translate;
    FileSaver = fileSaverInjected;
  }

  GenerateReport(data) {
    return $http.post(`${API_URL}backup-report/generate-csv`, data).catch(handleError);
  }

  DownloadReport(data) {
    const url = `${API_URL}backup-report/download-csv`;
    return $http
      .post(url, data, {
        responseType: 'arraybuffer',
        cache: false,
      })
      .then(res => {
        const blob = new Blob([res.data], {type: 'octet/stream'});
        const fileName = `${moment().format('DD-MM-YYYY')}_contingency_report.csv.zip`;

        return FileSaver.saveAs(blob, fileName);
      });
  }
}

module.exports = module.service('IncidentReportService', IncidentReportService);
