import angular from 'angular';
import '../../services/errors';
import '../../services/organization-custom-roles';

const module = angular.module('whyline.modals.organization-custom-role', [
  'whyline.services.organization-custom-role',
  'whyline.services.errors',
]);

const OrganizationCustomRoleModalController = ($scope, $uibModalInstance, $timeout, $translate, NotificationService, OrganizationCustomRoleService, ErrorService, organizationCustomRoleId, currentOrganization, rolesCreated) => {
  'ngInject';

  $scope.blockSaveButton = false;
  $scope.userActionSelected = [];
  $scope.userActions = {
    display: {
        create: 'display.create',
        delete: 'display.delete',
        read: 'display.read',
        update: 'display.update',
      },
      totem: {
        create: 'totem.create',
        delete: 'totem.delete',
        read: 'totem.read',
        update: 'totem.update',
      },
      area: {
        read: 'area.read',
        update: 'area.update',
      },
      line: {
        read: 'line.read',
        update: 'line.update',
      },
      box: {
        create: 'box.create',
        delete: 'box.delete',
        update: 'box.update',
      },
      user: {
        create: 'user.create',
        delete: 'user.delete',
        read: 'user.read',
        update: 'user.update',
      },
  };
  $scope.keys = Object.keys($scope.userActions);
  $scope.actionKeys = ['create', 'update', 'read', 'delete'];
  $scope.permissionsToAdd = [];
  $scope.rolesCreatedNames = [];
  const rolesCreatedKeys = Object.keys(rolesCreated);
  for (const key of rolesCreatedKeys) {
    $scope.rolesCreatedNames.push(rolesCreated[key]);
  }
  $scope.validateLabel = value => {
    if (!value) {
      NotificationService.Warning($translate.instant('mandatory_role_name'));
      return false;
    }
    if (value.length > 50) {
      NotificationService.Warning($translate.instant('max_length_name'));
      return false;
    }
    if (value.length < 3) {
      NotificationService.Warning($translate.instant('role_min_char_name'));
      return false;
    }
    if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }
    const roleNameExists = $scope.rolesCreatedNames.find(r => r.name.toLowerCase() === value.toLowerCase());
    if ((roleNameExists && !organizationCustomRoleId) || (roleNameExists && organizationCustomRoleId && organizationCustomRoleId !== roleNameExists._id)) {
      NotificationService.Warning($translate.instant('role_name_already_exists'));
      return false;
    }

    return true;
  };

  $scope.checkPermission = action => {
    const actionSplit = action.split('.');
    if ($scope.organizationCustomRole.actions.indexOf(action) > -1) {
      $scope.userActionSelected.splice($scope.userActionSelected.indexOf(action),1);
      $scope.organizationCustomRole.actions.splice($scope.organizationCustomRole.actions.indexOf(action), 1);
      const filteredActions = $scope.organizationCustomRole.actions.filter(a => a.includes(actionSplit[0]));
      if (filteredActions.length === 1
        && $scope.organizationCustomRole.actions.indexOf(`${actionSplit[0]}.read`) > -1 &&
        actionSplit[0] !== 'box') {
        $scope.organizationCustomRole.actions.splice($scope.organizationCustomRole.actions.indexOf(`${actionSplit[0]}.read`), 1);
        const actionHtmlElement = angular.element(document.getElementById(`${actionSplit[0]}.read`));
        if( actionHtmlElement[0] ){
          actionHtmlElement[0].checked = false;
        }
      }
    } else {
      if ($scope.organizationCustomRole.actions.indexOf(`${actionSplit[0]}.read`) < 0 &&
        actionSplit[0] !== 'box') {
        $scope.organizationCustomRole.actions.push(`${actionSplit[0]}.read`);
        const actionHtmlElement = angular.element(document.getElementById(`${actionSplit[0]}.read`));
        if(actionHtmlElement[0]) {
          actionHtmlElement[0].checked = true;
        }
      }
      $scope.userActionSelected.push(action);
      $scope.organizationCustomRole.actions.push(action);
    }
  }

  $scope.isValueChecked = action => {
    $scope.organizationCustomRole.actions.indexOf(action) > -1;
  }

  $scope.validatePermissions = () => {
      if (!$scope.organizationCustomRole.actions.length) {
        NotificationService.Warning($translate.instant('mandatory_permissions'));
        return false;
      }

      return true;
  }



  const validate = () => {
    if (!$scope.validateLabel($scope.organizationCustomRole.name)) {
      return false;
    }

    if (!$scope.validatePermissions()) {
        return false;
      }

    return true;
  };

  $scope.currentOrganization = currentOrganization;

  $scope.organizationCustomRole = {
    actions: [],
    name: '',
  };

  if (organizationCustomRoleId) {
    OrganizationCustomRoleService.GetOneAsPromiseFromServer(organizationCustomRoleId)
      .then(organizationCustomRole => {
        $timeout(() => {
          $scope.organizationCustomRole = OrganizationCustomRoleService.Copy(organizationCustomRole);
          const actions = JSON.parse(JSON.stringify($scope.organizationCustomRole.actions));
          actions.forEach(p => {
            if (!p.includes('section')) {
              const actionHtmlElement = angular.element(document.getElementById(p));
              if( actionHtmlElement[0] ){
                actionHtmlElement[0].checked = true;
              }
              const sectionAction = p.split('.');
              if( sectionAction && $scope.keys.includes(sectionAction[0]) && !$scope.userActionSelected.includes(p) )
              {
                $scope.userActionSelected.push(p)
              }
            } else {
              $scope.organizationCustomRole.actions.splice($scope.organizationCustomRole.actions.indexOf(p), 1);
            }
          });
        });
      })
      .catch(ErrorService.handler);
  }

  $scope.save = data => {
    if( !$scope.userActionSelected.length ){
      NotificationService.Warning($translate.instant('mandatory_role_actions_required'));
      return false;
    }
    const areaPermission = $scope.organizationCustomRole.actions.filter(a => a.includes('area'));
    const boxPermission = $scope.organizationCustomRole.actions.filter(a => a.includes('box'));
    const totemPermission = $scope.organizationCustomRole.actions.filter(a => a.includes('totem'));
    const displayPermission = $scope.organizationCustomRole.actions.filter(a => a.includes('display'));
    const linesPermission = $scope.organizationCustomRole.actions.filter(a => a.includes('line'));
    const usersPermission = $scope.organizationCustomRole.actions.filter(a => a.includes('user'));
    if (areaPermission.length) {
      $scope.organizationCustomRole.actions.push('section.areas');
      const updatePermission = areaPermission.filter(ap => ap === 'area.update');
      if (updatePermission) {
        $scope.organizationCustomRole.actions.push('user.read');
      }
    }
    if (boxPermission.length) {
      $scope.organizationCustomRole.actions.push('section.boxes');
    }
    if (totemPermission.length) {
      $scope.organizationCustomRole.actions.push('section.totems');
    }
    if (displayPermission.length) {
      $scope.organizationCustomRole.actions.push('section.displays');
    }
    if (linesPermission.length) {
      $scope.organizationCustomRole.actions.push('section.lines');
    }
    if (usersPermission.length) {
      $scope.organizationCustomRole.actions.push('section.users');
      $scope.organizationCustomRole.actions.push('role.read');
      if( $scope.organizationCustomRole.actions.includes('user.create') ){
        $scope.organizationCustomRole.actions.push('resourceGroup.read');
      }
    }

    if( $scope.organizationCustomRole && $scope.organizationCustomRole.actions.length > 0 ){
      $scope.organizationCustomRole.actions = $scope.organizationCustomRole.actions.filter( (item, index) => $scope.organizationCustomRole.actions.indexOf(item) === index );
    }

    if (organizationCustomRoleId && data && validate()) {

      return OrganizationCustomRoleService.Update($scope.organizationCustomRole)
        .then(() => {
          NotificationService.Success($translate.instant('role_update_succ'));
        })
        .catch(ErrorService.handler);
    } else if (validate()) {
      $scope.blockSaveButton = true;
      $scope.organizationCustomRole.organizationId = $scope.currentOrganization._id;
      return OrganizationCustomRoleService.Create($scope.organizationCustomRole)
        .then(() => {
          $timeout(() => {
            NotificationService.Success($translate.instant('role_create_succ'));
            $uibModalInstance.close('cancel');
          });
        })
        .catch(error => {
          $scope.blockSaveButton = false;
          ErrorService.handler(error);
        });
    }
  };

  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };
};

module.exports = module.controller('OrganizationCustomRoleModalController', OrganizationCustomRoleModalController);
