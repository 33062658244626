import angular from 'angular';
import { get } from 'lodash';
import moment from 'moment-timezone';
import '../../app.constants';
import '../../services/totem-tv';
import '../../services/totem';
import '../../services/errors';
import '../../services/notifications';

const module = angular.module('whyline.controllers.totem-tv-welcome-and-thanks', [
  'whyline.constants',
  'whyline.services.totem-tv',
  'whyline.services.totem',
  'whyline.services.errors',
  'whyline.services.notifications',
]);

const TotemTvWelcomeAndThanksController = (SAFE_STATES, $scope, $state, $timeout, TotemService, TotemTvService, NotificationService, ErrorService, $translate, currentPlace) => {
  /*@ngInject*/

  // Init
  $scope.isWhylineEnterprise = currentPlace.enterpriseType === 'whyline';
  $scope.totemEnabled = true;
  $scope.thanksMessage = 'your_place_confirmed';

  $scope.onlyForTurnConfirmation = false;

  TotemTvService.SetButtonStatus('both', false);
  TotemTvService.SetButtonStatus('status-text', false);
  TotemTvService.SetButtonStatus('browserBack', false);

  TotemService.GetOneAsPromise($state.params.totemId)
    .then(totem => {
      $timeout(() => {

        $scope.onlyForTurnConfirmation = totem.onlyForTurnConfirmation;

        if (!totem.enabled) {
          $scope.totemEnabled = false;
          NotificationService.Information($translate.instant('disabled_totem'), {
            timeOut: 0,
            preventDuplicates: true,
            preventOpenDuplicates: true,
            maxOpened: 5,
            closeButton: false,
            extendedTimeOut: 0,
            tapToDismiss: false,
          });
        } else {
          $scope.totemEnabled = true;
          NotificationService.Clear();
        }

        if ($state.current.name === SAFE_STATES.totemTvWelcome) {
          TotemTvService.SetPostMessage(null);
        }
      
        if ($state.current.name === SAFE_STATES.totemTvThanks) {
          $scope.postCheckInMessage = TotemTvService.PostMessage;
          let service = TotemTvService.SelectedService;
          let averageWaitTimeLess10 = get(service, 'waitingPeople', 0) * get(service, 'statistics.velocity', 0) - 10;
          if (totem.showComeBackAt && get(service, 'statistics.velocity', 0) > 0 && averageWaitTimeLess10 > 20) {
            $scope.thanksMessage = $translate.instant('come_back_at_x_to_be_assisted', { hour: moment().add(averageWaitTimeLess10, 'minutes').format('h:mm A') });
          }
          $timeout(() => {
            $state.go(SAFE_STATES.totemTvWelcome);
          }, 9000);
        }
      });
    })
    .catch(ErrorService.handler);
};

module.exports = module.controller('TotemTvWelcomeAndThanksController', TotemTvWelcomeAndThanksController);
