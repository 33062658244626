import angular from 'angular';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import '../../../app.constants';
import '../../../resources/appointment';
import '../../../services/appointment';
import '../../../services/place';
import '../../../services/reception';
import '../../../services/totem-tv';
import '../../../services/notifications';

const module = angular.module('whyline.controllers.reception.appointment-results', [
  'whyline.constants',
  'whyline.resources.appointment',
  'whyline.services.appointment',
  'whyline.services.place',
  'whyline.services.reception',
  'whyline.services.totem-tv',
  'whyline.services.notifications',
]);

const AppointmentResultsController = (SAFE_STATES, $scope, $state, AppointmentService, ReceptionService, NotificationService, $translate, PlaceService) => {
  /*@ngInject*/

  $state.params.fields = $state.params.fields || {};
  $scope.resultsFounded = false;

  AppointmentService.ClearSearchResults();

  $scope.place = PlaceService.GetCurrent();

  $scope.placeUTCOffset = moment.tz($scope.place.timezone).format('Z').toString();

  // Init
  $scope.selectedService = {
    _id: '',
    type: ''
  };

  // Controls
  ReceptionService.SetButtonStatus('back', true);
  ReceptionService.SetButtonStatus('forward', true);
  ReceptionService.SetButtonStatus('status-text', false);

  if (!isEmpty($state.params.fields)) {
    AppointmentService.SearchWithSubscriptions($state.params.fields, $state.params.placeId, $state.params.searchFrom).then(response => {
      $scope.resultsFounded = response.data;
    });
  } else if (isEmpty($state.params.fields) && $state.params.searchFrom == 'agenda') {
    $state.go(SAFE_STATES.scheduleAppointmentFilters);
  } else {
    $state.go(SAFE_STATES.receptionAppointmentSearch);
  }

  $scope.goToSearch = () => {
    ReceptionService.History('to-appointment-search', $state.params.fields);
  };

  $scope.changeSelected = result => {
    $scope.selectedService._id = result._id;
    $scope.selectedService.type = result.type;
  };

  const validate = selected => {
    if (!selected._id) {
      NotificationService.Warning($translate.instant('select_appointment_mandatory'));
      return false;
    }

    return true;
  };

  ReceptionService.Validate(validate, $scope.selectedService);
};

module.exports = module.controller('ReceptionAppointmentResultsController', AppointmentResultsController);
