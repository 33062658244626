import angular from 'angular';
import {
  get,
  toUpper
} from 'lodash';
import {
  validatePersonId
} from '../../../utils';
import * as libPhoneNumber from 'libphonenumber-js';
import '../../../app.constants';
import '../../../resources/appointment';
import '../../../services/appointment';
import '../../../services/reception';
import '../../../services/totem-tv';
import '../../../services/notifications';
import { countriesWithCodes } from '../../../utils/countries-and-codes';

const module = angular.module('whyline.controllers.reception.appointment-search', [
  'whyline.constants',
  'whyline.services.appointment',
  'whyline.resources.appointment',
  'whyline.services.reception',
  'whyline.services.totem-tv',
  'whyline.services.notifications',
]);

const AppointmentSearchController = (SAFE_STATES, $scope, $timeout, $state, $window, ReceptionService, AppointmentService, Appointment, NotificationService, ValidationService, $translate, PlaceService) => {
  /*@ngInject*/

  // Regex
  const validCharacters = '[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]';
  const namePattern = new RegExp(`^${validCharacters}+(${validCharacters}{1,} {0,1}${validCharacters}{1,}){0,}${validCharacters}+$`, 'u');
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // INIT
  $scope.place = PlaceService.GetOne($state.params.placeId);

  $scope.validationErrors = {};
  $scope.appointmentFields = $state.params.fields || {
    firstName: '',
    lastName: '',
    id: '',
    idType: '',
    nroCita: '',
    email: '',
    phone: {
      countryCode: toUpper($scope.place.location.countryCode),
      full: '',
    }
  };
  $scope.countries = countriesWithCodes;

  PlaceService.GetCurrentAsPromise().then(() => {
    const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

    // Options
    $scope.idTypesList = identifications;

    $scope.selectedIdType = {
      value: identifications.find(id => id.key == $scope.appointmentFields.idType) || empty
    };
  });

  // Update selected
  $scope.updateIdType = () => {
    $scope.person.idType = get($scope.selectedIdType, 'value.key', undefined);
  };

  $scope.updateIdType = () => {
    $scope.appointmentFields.idType = get($scope.selectedIdType, 'value.key', undefined);
  };

  ReceptionService.SetButtonStatus('back', true);
  ReceptionService.SetButtonStatus('forward', false);
  ReceptionService.SetButtonStatus('status-text', false);

  // Search appointments
  $scope.searchAppointments = () => {
    const {
      firstName,
      lastName,
      id,
      idType,
      nroCita,
      email,
      phone
    } = $scope.appointmentFields;

    if ($scope.place.configuration.canGiveAppointments) {
      if (!firstName && !lastName && !id && !idType && !nroCita && !email && !phone.full) {
        NotificationService.Warning($translate.instant('mandatory_at_least_one'));
        return false;
      }
    } else if (!firstName && !lastName && !id && !idType && !nroCita) {
      NotificationService.Warning($translate.instant('mandatory_at_least_one'));
      return false;
    }

    if (id && !idType) {
      NotificationService.Warning($translate.instant('mandatory_id_type'));
      return false;
    }

    if (!id && idType) {
      NotificationService.Warning($translate.instant('mandatory_id_number'));
      return false;
    }

    if (firstName && firstName.length < 2) {
      NotificationService.Warning($translate.instant('min_lenght_person_name'));
      return false;
    }

    if (firstName && !namePattern.test(firstName)) {
      NotificationService.Warning($translate.instant('person_name_letters_only'));
      return false;
    }

    if (lastName && lastName.length < 2) {
      NotificationService.Warning($translate.instant('min_lenght_lastname'));
      return false;
    }

    if (lastName && !namePattern.test(lastName)) {
      NotificationService.Warning($translate.instant('person_lastname_letters_only'));
      return false;
    }

    let identification;
    try {
      identification = $scope.selectedIdType.value;
    } catch(err) {
      identification = null;
    }

    if (id && idType && !validatePersonId(id, idType, $window, identification)) {
      NotificationService.Warning($translate.instant('invalid_person_id'));
      return false;
    }

    if (email && !emailPattern.test(email)) {
      NotificationService.Warning($translate.instant('invalid_email_format'));
      return false;
    }

    if (phone.full && !libPhoneNumber.isValidNumber(phone.full, phone.countryCode)) {
      NotificationService.Warning($translate.instant('invalid_tel'));
      return false;
    }

    if (get($state.params, 'searchFrom', false) === 'agenda') {
      $state.go(SAFE_STATES.scheduleAppointmentResults, {
        fields: $scope.appointmentFields
      });
    } else {
      $state.go(SAFE_STATES.receptionAppointmentResults, {
        fields: $scope.appointmentFields
      });
    }
  };
};

module.exports = module.controller('ReceptionAppointmentSearchController', AppointmentSearchController);
