import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.banner', [
  'whyline.resources.resource',
]);

const BannerFactory = (Resource, API_URL, $http) => {
  /*@ngInject*/

  //API REST Resource Name
  const _type = 'banner?placeId';

  const _schema = {
    _id: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  };

  const Banner = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Banner.prototype = new Resource(_type, _schema, Banner);

  Resource.Extend(_type, Banner);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Banner.InitCache(validityTime);

  return Banner;
};

module.exports = module.factory('Banner', BannerFactory);
