import routes from './routes';
import httpInterceptors from './http-interceptors';
import translations from './translations';
import translationsDatepicker from './translations.datepicker';
import notifications from './notifications';
import blockUI from './blockUI';
import modals from './modals';
import '../app.constants';

const config = ($stateProvider, $provide, $urlRouterProvider, $httpProvider, jwtOptionsProvider, toastrConfig, $translateProvider, SAFE_STATES, blockUIConfig, $uibModalProvider) => {
  'ngInject';

  const cfg = $provide.provider('whylineBlockUIConfig', function () {
    let show = true;

    this.$get = () => {
        return {
            set(val) {
              show = val;
            },
            get() {
              return show;
            }
        };
    };
  });

  httpInterceptors($httpProvider, jwtOptionsProvider, SAFE_STATES);
  routes($stateProvider, $urlRouterProvider);
  translations($translateProvider);
  translationsDatepicker($provide, $translateProvider);
  notifications(toastrConfig);
  blockUI(blockUIConfig, () => cfg.$get().get());
  modals($uibModalProvider);
};

module.exports = config;
