import angular from 'angular';
import '../../services/area';
import '../../services/line';
import '../../services/globals';
import '../../services/errors';
import '../../services/subscription';
import '../../services/notifications';
import { sortBy, head, filter, trim, get } from 'lodash';

const module = angular.module('whyline.modals.forward', [
  'whyline.services.area',
  'whyline.services.line',
  'whyline.services.place',
  'whyline.services.globals',
  'whyline.services.errors',
  'whyline.services.subscription',
  'whyline.services.notifications',
]);

const ForwardController = (
  $scope,
  $uibModalInstance,
  GlobalsService,
  SubscriptionService,
  PlaceService,
  $timeout,
  AreaService,
  LineService,
  subscription,
  line,
  ErrorService,
  NotificationService,
  $translate,
  preference,
) => {
  'ngInject';

  $scope.destination = [];
  $scope.subscription = subscription;
  $scope.searchOn = 'all';
  $scope.status = subscription.status;
  $scope.destination = {};
  $scope.blockForwardButton = false;

  if (head(subscription.movementsHistory)) {
    $scope.fromLine = LineService.GetOne(head(subscription.movementsHistory).queueId);
  } else {
    $scope.fromLine = null;
  }

  // Filters
  $scope.filters = {
    filterType: 'all',
    areas: [],
    selected: {
      id: null,
      label: null,
    },
  };

  $scope.showPreference = preference;
  $scope.servicesList = null;
  let allServices = [];

  $scope.servicesSettings = {
    template: '<translate>{{option.label}}</translate>',
    externalIdProp: '',
    displayProp: 'label',
    idProp: '_id',
    searchField: 'label',
    enableSearch: true,
    showCheckAll: false,
    showUncheckAll: false,
    keyboardControls: true,
    selectedToTop: true,
    scrollable: true,
    smartButtonMaxItems: 1,
    closeOnSelect: true,
    selectionLimit: 1,
    // eslint-disable-next-line consistent-return
    groupByTextProvider: (value) => {
      if (value === 'supervisor') {
        return $translate.instant('supervisors');
      }
      if (value === 'process') {
        return $translate.instant('processes');
      }
      if (value === 'line') {
        return $translate.instant('lines');
      }
    },
    groupBy: 'realType',
  };

  $scope.multiselectTranslations = {
    dynamicButtonTextSuffix: $translate.instant('multiselect_seleccionados'),
    buttonDefaultText: $translate.instant('select_option'),
    searchPlaceholder: $translate.instant('search_service'),
  };

  const init = () => {
    PlaceService.GetAllPlaceServices(GlobalsService.CurrentPlace._id)
      .then((placeServices) => {
        $timeout(() => {
          allServices = sortBy(placeServices, ['label']);
          $scope.servicesList = allServices;
          $scope.filterServices();
        });
      })
      .catch(ErrorService.handler);
  };

  $scope.update = () => {
    $scope.destination = $scope.selectedItem;
  };

  $scope.assignPreference = (type) => {
    if (get(subscription, 'preference', '') === type) {
      subscription.preference = '';
    } else {
      subscription.preference = type;
    }
  };

  $scope.forwardSubscription = () => {
    if (!$scope.destination[0]) {
      return NotificationService.Error('must_select_service_in_forward_to');
    }
    $scope.blockForwardButton = true;
    const serviceId = $scope.destination[0]._id;
    const serviceType = $scope.destination[0].type;
    const reasons = $scope.forwardReasons;
    const preference = get(subscription, 'preference', '');

    if (subscription.preference) {
      SubscriptionService.ChangePreference(subscription._id, preference).then(() =>
        $timeout(() => { }),
      ).catch((err) => {
        ErrorService.handler(err);
      });
    }

    SubscriptionService.ForwardTo(
      subscription._id,
      serviceId,
      serviceType,
      $scope.status,
      reasons,
      preference,
    )
      .then(() =>
        $timeout(() => {
          NotificationService.Success($translate.instant('user_derived_correct'));
          $scope.blockForwardButton = false;
          $scope.cancel();
        }),
      )
      .catch((err) => {
        ErrorService.handler(err);
        $scope.blockForwardButton = false;
      });
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.filterServices = () => {
    if ($scope.searchOn !== 'all') {
      $scope.servicesList = filter(allServices, { realType: $scope.searchOn });
    } else {
      $scope.servicesList = allServices;
    }
    $scope.destination = [];
  };

  init();
  $scope.getShowName = SubscriptionService.getShowName;
};

module.exports = module.controller('ForwardController', ForwardController);
