import angular from 'angular';
const module = angular.module('whyline.components.stats.schema', []);
const schemaComponent = {
    bindings: {
        schema: '<',
        statuses: '<'
    },
    templateUrl: 'templates/views/stats/schema.html',
    controller($scope) {
        'ngInject';
        $scope.showDetails = false;
    }
};

export default module.component('schema', schemaComponent);
