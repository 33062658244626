import angular from 'angular';
import { filter, get, head } from 'lodash';
import moment from 'moment-timezone';
import '../../components/status';
import '../../services/subscription';
import '../../services/place';
import '../../services/line';
import '../../services/area';
import '../../services/organization';
import '../../services/globals';
import * as utils from '../../utils';
import '../../services/user';
import './load-subscriptions';

const module = angular.module('whyline.modals.info', [
  'whyline.components.status',
  'whyline.services.subscription',
  'whyline.services.place',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.organization',
  'whyline.services.globals',
  'whyline.services.user',
  'whyline.modals.load-subscriptions',
]);

const InfoController = (
  $scope,
  $timeout,
  $uibModalInstance,
  SubscriptionService,
  PlaceService,
  LineService,
  AreaService,
  OrganizationService,
  GlobalsService,
  subscriptionId,
  areaConfiguration,
  currentPlaceId,
  $translate,
  $uibModal,
) => {
  'ngInject';

  $scope.forwardedData = null;
  $scope.forwardedToData = null;
  $scope.currentPlace = PlaceService.GetOne(currentPlaceId);
  $scope.allowMultipleSubscriptions = $scope.currentPlace.configuration.allowMultipleSubscriptions;
  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  const dismissOnError = (err) => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
  };

  $scope.place = PlaceService.GetCurrent();
  $scope.showPreference = get(areaConfiguration, 'preference.enabled', false);
  $scope.getShowName = SubscriptionService.getShowName;
  $scope.showLoadSubscriptions = false;

  const getSubscriptionData = () => {
    // Initialize data
    $scope.user = {};
    $scope.subscription = {};

    // Get info of a subscription
    SubscriptionService.GetOneAsPromiseFromServer(subscriptionId)
      .then((res) => {
        $timeout(() => {
          // Check if a response was returned
          if (res) {
            // Check if is enqueued in an area
            if (res.areaId) {
              $scope.enqueuedInArea = AreaService.GetOne(res.areaId);
            }
            // Get a copy of the element
            $scope.subscription = SubscriptionService.Copy(res);

            // Get the current line of the subscription
            const line = LineService.GetOne(res.lineId);
            $scope.line = line;

            $scope.showLoadSubscriptions = line.checkAppointments;
            

            // Do format to times
            $scope.subscription.waitingTime = utils.formatAwt(res.waitingTime);

            const lang = $translate.use();
            moment.locale(lang);

            $scope.openAt = moment();

            const timezone = $scope.currentPlace.timezone;
            $scope.subscription.subscriptionTimeInWords = moment(
              $scope.subscription.subscriptionTime,
            )
              .tz(timezone)
              .format('LLLL');

            $scope.showName = $scope.getShowName($scope.subscription);

            // If forwarded, show previous queue
            const forwardedArray = filter(res.movementsHistory, {action: "forward"});
            const joinArray = filter(res.movementsHistory, {action: "join"});
            if (forwardedArray.length > 0) {
              $scope.forwardedData = joinArray[0];
              if (forwardedArray[0].forwardReasons) {
                $scope.forwardedData.forwardReasons = forwardedArray[0].forwardReasons;
              }
            }

            // If is a original subscription, to show which line or process it was derived to
            const forwardedToArray = filter(res.movementsHistory, {action: "forward-to"});
            if (forwardedToArray.length > 0) {
              $scope.forwardedToData = forwardedToArray[0];
              if (forwardedToArray[0].forwardReasons) {
                $scope.forwardedToData.forwardReasons = forwardedToArray[0].forwardReasons;
              }
            }
          }
        });
      })
      .catch(dismissOnError);
  };

  getSubscriptionData();

  $scope.updateSubscriptionData = getSubscriptionData;

  $scope.loadSubscriptions = (subscription) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/load-subscriptions.html',
      size: 'md',
      controller: 'LoadSubscriptionsController',
      resolve: {
        subscription: () => subscription,
        currentPlace: () => PlaceService.GetOneAsPromise(GlobalsService.CurrentPlace._id),
        currentOrganization: () => OrganizationService.GetCurrent(),
      },
    });
    $scope.cancel();
  }
};

module.exports = module.controller('InfoController', InfoController);
