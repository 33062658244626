import angular from 'angular';
import '../app.constants';
import 'ngmap';
import { values, includes, get } from 'lodash';

const module = angular.module('whyline.services.location', [
  'ngMap',
  'whyline.constants',
  'whyline.services.globals',
]);

let GeoCoder;
let autocompleteService;
let placesService;
let map;
class LocationService {
  static $inject = ['GeoCoder', '$window'];

  constructor(injectedGeoCoder, injected$window) {
    /*@ngInject*/
    GeoCoder = injectedGeoCoder;
    autocompleteService = new injected$window.google.maps.places.AutocompleteService();
    map = new injected$window.google.maps.Map(document.createElement('div'));
    placesService = new injected$window.google.maps.places.PlacesService(map);
  }

  Find(address) {
    return GeoCoder.geocode({address})
    .then(locations => values(locations).map(location => {
        const locationInfo = this.GetLocationInfo(location);
        return {
          label: location.formatted_address,
          address: locationInfo.address,
          code: get(locationInfo, 'code', '').toLowerCase(),
          googlePlaceId: location.place_id,
          location: {
            lng: location.geometry.location.lng(),
            lat: location.geometry.location.lat()
          },
          country: locationInfo.country,
          state: locationInfo.state,
          city: locationInfo.city,
          zipCode: locationInfo.zipCode,
        };
      }));
  }

  FindStablishments(input) {
    return this.FindPlace(input, ['establishment']);
  }

  FindAddresses(input) {
    return this.FindPlace(input, ['address']);
  }


  /**
   * @param types
   * An array of types that the prediction belongs to (establishment, address, cities, geocode)
   */
  FindPlace(input, types) {
    return new Promise(resolve => {
      autocompleteService.getPlacePredictions({ input, types }, places => resolve(places));
    });
  }

  GetPlaceDetails(placeId) {
    return new Promise(resolve => {
      placesService.getDetails({ placeId }, place => resolve(place));
    });
  }

  GetLocationInfo = placeDetails => {
    const locationInfo = {};
    placeDetails.address_components.forEach(component => {
      if (includes(component.types, 'country')) {
        locationInfo.country = component.long_name;
        locationInfo.code = component.short_name;
      }
      if (includes(component.types, 'administrative_area_level_1')) {
        locationInfo.state = component.long_name;
      }
      if (includes(component.types, 'locality')) {
        locationInfo.city = component.long_name;
      }
      if (includes(component.types, 'sublocality')) {
        locationInfo.city = component.long_name;
      }
      if (includes(component.types, 'route')) {
        locationInfo.street = component.long_name;
      }
      if (includes(component.types, 'street_number')) {
        locationInfo.streetNumber = component.long_name;
      }
      if (includes(component.types, 'postal_code')) {
        locationInfo.zipCode = component.long_name;
      }
    });
    return locationInfo;
  };
}

module.exports = module.service('LocationService', LocationService);
