import angular from 'angular';
import { filter } from 'lodash';
import '../app.constants';
import './resource';
import './area';
import './line';

const module = angular.module('whyline.resources.schema', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.resources.area',
  'whyline.resources.line',
]);

const SchemaFactory = (API_URL, $http, $timeout, $q, Resource, Area, Line) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'sector?placeId';

  const _schema = {
    name: {
      type: String,
      max: 128,
      required: true
    },
    placeId: {
      type: String,
      required: true
    },
    root: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    realType: {
      type: String,
      default: 'sector',
    },
    tree: [{
      type: {
        type: String
      },
      _id: {
        type: String
      }
    }]
  };

  const Schema = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Schema.prototype = new Resource(_type, _schema, Schema);

  Resource.Extend(_type, Schema);

  //one minute
  const validityTime = 1 * 60 * 1000;
  Schema.InitCache(validityTime);

  Schema.Populate = item => {
    const lines = filter(item.tree, obj => obj.type === 'line');
    const areas = filter(item.tree, obj => obj.type === 'area');
    const schemas = filter(item.tree, obj => obj.type === 'sector');
    item.linesPopulated = [];
    item.areasPopulated = [];
    item.schemasPopulated = [];
    lines.forEach(line => {
      item.linesPopulated.push(Line.FindById(line._id));
    });
    areas.forEach(area => {
      item.areasPopulated.push(Area.FindById(area._id, {populate: ['lines']}));
    });
    schemas.forEach(schema => {
      item.schemasPopulated.push(Schema.FindById(schema._id, {populate: ['areas', 'lines', 'schemas']}));
    });
    item._populated = ['areas', 'lines', 'schemas'];
  };

  Schema.FindById = (id, options) => {
    const forceUpdate = options && options.force;
    const isCacheValid = Schema.IsCacheValid();
    if(forceUpdate || !isCacheValid || !Schema.GetByIdFromCache(id)) {
      const url = `${API_URL}${_type}/${id}`;
      Schema.ReserveCache(id);
      const promise = $http.get(url).then(
        response => $timeout(() => {
          const item = response.data;
          Schema.UpdateCache(id, item, options);
          if(options && options.populate && options.populate.length) {
            Schema.Populate(Schema.GetByIdFromCache(id), options.populate);
          }
          return Schema.GetByIdFromCache(id);
        }),
        error => {
          if(error && [403, 404].indexOf(error.status) >= 0) {
            Schema.RemoveFromCache(id);
          }
          return $q.reject(error);
        }
      );
      if(options && options.promise) {
        return promise;
      }
    }
    if(options && options.populate && options.populate.length) {
      Schema.Populate(Schema.GetByIdFromCache(id), options.populate);
    }
    if(options && options.promise) {
      return new Promise(resolve => resolve(Schema.GetByIdFromCache(id)));
    } else {
      return Schema.GetByIdFromCache(id);
    }
  };

  Schema.GetAllPopulated = placeId => {
    const url = `${API_URL}${_type}/reception`;
    return $http.get(url).then(response => response.data);
  };

  return Schema;
};

module.exports = module.factory('Schema', SchemaFactory);
