import angular from 'angular';
import moment from 'moment-timezone';
import { get, concat, head, last, toArray, filter, sortBy, map } from 'lodash';
import '../../services/appointment';
import '../../services/area';
import '../../services/notifications';
import '../../services/place';
import '../../services/errors';
import '../../services/date-handler';
import '../modals/appointment';
import '../modals/appointment-info';
import '../modals/holiday';

const module = angular.module('whyline.controllers.schedule', [
  'whyline.services.appointment',
  'whyline.services.area',
  'whyline.modals.edit-appointment',
  'whyline.modals.appointment-info',
  'whyline.modals.holiday',
  'whyline.services.notifications',
  'whyline.services.errors',
  'whyline.services.place',
  'whyline.services.date-handler',
]);

const ScheduleController = ($scope, $translate, $timeout, $uibModal, AppointmentService, AreaService, PlaceService, ErrorService, NotificationService, DateHandlerService, dialog) => {
  /*@ngInject*/

  $scope.place = PlaceService.GetCurrent();
  $scope.showSlotsByHourLoader = false;
  $scope.showAppointmentsLoader = false;
  $scope.appointments = null;
  $scope.slotsByHour = null;
  $scope.selectedSlot = null;
  $scope.showExportButton = false;
  $scope.format = $translate.use() === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

  const CHECK_IN_MIN = 15;
  const placePromise = PlaceService.GetCurrentAsPromise()
    .then(place => {
      $scope.place = place;
      $scope.placeUTCOffset = moment.tz(place.timezone).format('Z').toString();
      $scope.showEditAppointments = place.configuration.canEditAppointments;
      $scope.filters.showWorkingDay = get(place, 'configuration.showWorkingDay', false);
      return place;
    });

  // Filters
  $scope.filters = {
    date: DateHandlerService.getNeutralDate(),
    areas: [],
    showWorkingDay: false,
    hideEmptySlots: false,
  };

  // Date picker
  $scope.dateOptionsFrom = {
    formatYear: 'yy',
    minDate: new Date(2016, 1, 1),
    startingDay: 1,
    timezone: 'utc'
  };

  // Available processes
  AreaService.GetAllAsPromise()
    .then(areas => {
      $timeout(() => {
        $scope.areasList = sortBy(toArray(filter(areas, area => area.appointmentsConfiguration.enabled)), ['label']);
      });
    })
    .catch(ErrorService.handler);

  const getAreasIds = () => map($scope.filters.areas, '_id');

  // Search appointments
  const searchAppointments = () => {
    $scope.showAppointmentsLoader = true;

    AppointmentService.GetAppointmentsByHour({ areaIds: getAreasIds(), date: new Date($scope.selectedSlot.hour).toISOString(), placeId: $scope.place._id })
      .then(response => {
        $timeout(() => {
          $scope.appointments = response;
          $scope.showAppointmentsLoader = false;
        });
      });
  };

  // Apply filters
  // unselectSlot, is a flag to keep/unselect the slot when pressing Apply filters button or Refresh button
  $scope.applyFilters = unselectSlot => {
    if (!$scope.filters.areas.length || !$scope.filters.date) {
      return NotificationService.Warning($translate.instant('select_date_area_and_apply_filters'));
    }

    if (unselectSlot) {
      $scope.selectedSlot = null;
    }

    $scope.showSlotsByHourLoader = true;
    $scope.appointments = null;

    const getSlotConfiguration = {
      areaIds: getAreasIds(),
      hideEmptySlots: $scope.filters.hideEmptySlots,
      date: moment($scope.filters.date).format('YYYY-MM-DD'),
      showWorkingDay: $scope.filters.showWorkingDay,
      addNextDay: false,
      placeId: $scope.place._id,
    };

    return AreaService.GetSlotsByHour(getSlotConfiguration)
      .then(response => {
        $timeout(() => {
          $scope.slotsByHour = response;
          $scope.showExportButton = true;
          $scope.showSlotsByHourLoader = false;
          $scope.isToday = isToday($scope.filters.date);
        });
      });
  };

  // Set slot
  $scope.setSlot = slot => {
    $scope.selectedSlot = slot;
    searchAppointments();
  };

  // Refresh query
  $scope.refresh = () => {
    if ($scope.filters.areas.length && $scope.selectedSlot) {
      $scope.applyFilters(false)
        .then(() => {
          if ($scope.selectedSlot) {
            searchAppointments();
          }
        });
    }
  };

  $scope.processAppointment = appointment => {
    placePromise.then(place => {
      const checkInMin = get(place, 'configuration.checkIn.min', CHECK_IN_MIN);
      AppointmentService.Process(appointment._id).then(() => {
        $timeout(() => {
          searchAppointments();
          NotificationService.Success($translate.instant('appointment_confirmed_succ'), $translate.instant('success'));
        });
      }).catch(err => {
        err.checkInMin = checkInMin;
        const subtractTime = moment.duration(checkInMin, 'm');
        const appointmentTime = moment(appointment.appointment.ISODate).subtract(subtractTime);
        err.comeBackAt = appointmentTime.format('h:mm a');
        ErrorService.handler(err);
      });
    });
  };

  $scope.confirmAppointment = appointment => {
    placePromise.then(place => {
      const checkInMin = get(place, 'configuration.checkIn.min', CHECK_IN_MIN);
      AppointmentService.CheckIn(appointment).then(() => {
        $timeout(() => {
          searchAppointments();
          NotificationService.Success($translate.instant('appointment_confirmed_succ'));
        });
      }).catch(err => {
        if (err.data == 'appointment.already-checked-in') {
          $scope.refresh();
        }
        err.checkInMin = checkInMin;
        const subtractTime = moment.duration(checkInMin, 'm');
        const appointmentTime = moment(appointment.appointment.ISODate).subtract(subtractTime);
        err.comeBackAt = appointmentTime.format('h:mm a');
        ErrorService.handler(err);
      });
    });
  };

  // Cancel appointment
  $scope.cancelAppointment = appointment => {
    dialog.confirm($translate.instant('cancel_appointment_sure'))
      .then(() => {
        AppointmentService.Remove(appointment._id).then(() => {
          $timeout(() => {
            $scope.refresh();
            NotificationService.Success($translate.instant('appointment_canceled_succ'));
          });
        }).catch(ErrorService.handler);
      });
  };

  // Edit appointment
  $scope.editAppointment = appointment => {
    AreaService.GetOneAsPromise(appointment.area._id).then(area => {
      $uibModal.open({
        templateUrl: '/templates/components/modals/appointment.html',
        size: 'md',
        controller: 'EditAppointmentController',
        resolve: {
          appointment: () => AppointmentService.Copy(appointment),
          area: () => area,
        }
      })
        .closed.then(() => {
          $scope.refresh();
        });
    });
  };

  // Create appointment
  $scope.createAppointment = () => {
    const appointment = {
      appointment: {},
    };
    $uibModal.open({
      templateUrl: '/templates/components/modals/appointment.html',
      size: 'md',
      controller: 'EditAppointmentController',
      resolve: {
        appointment: () => appointment,
        area: () => { },
      }
    })
      .closed.then(() => {
        $scope.refresh();
      });
  };

  $scope.createHoliday = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/holiday.html',
      size: 'md',
      controller: 'HolidayController',
      resolve: {
        holiday: () => { },
      }
    });
  };

  /**
   * Modals
   */
  // Modal to show appointment information
  $scope.seeAppointmentInfo = appointment => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/appointment-info.html',
      size: 'md',
      controller: 'AppointmentInfoController',
      resolve: {
        appointment: () => appointment,
      }
    });
  };

  $scope.exportAppointmentsAsCSV = () => {
    const todayHours = map(get($scope, 'slotsByHour.today', []), 'hour');
    const tomorrowHours = map(get($scope, 'slotsByHour.tomorrow', []), 'hour');

    const allSlots = concat([], todayHours, tomorrowHours);
    const startDate = head(allSlots);
    const endDate = last(allSlots);

    const configuration = {
      areaIds: getAreasIds(),
      endDate,
      lang: $translate.use(),
      placeId: $scope.place._id,
      startDate,
    };

    return AppointmentService.ExportReservations(configuration);
  };

  // Dropdown services
  $scope.areasSettings = {
    template: '<b translate>{{option.label}}</b>',
    externalIdProp: '',
    displayProp: 'label',
    idProp: '_id',
    searchField: 'label',
    enableSearch: true,
    keyboardControls: true,
    selectedToTop: true,
    scrollable: true,
  };

  $scope.multiselectTranslations = {
    checkAll: $translate.instant('all'),
    uncheckAll: $translate.instant('multiselect_ninguno'),
    dynamicButtonTextSuffix: $translate.instant('multiselect_seleccionados'),
    buttonDefaultText: $translate.instant('select_one_or_more_services'),
    searchPlaceholder: $translate.instant('search_service'),
  };

  const isToday = date => {
    const today = moment().tz($scope.place.timezone).startOf('day').format('YYYY-MM-DD');
    const day = moment(date).format('YYYY-MM-DD');
    return day === today;
  };

  $scope.$watch('filters', () => {
    $scope.slot = null;
    $scope.slotsByHour = null;
    $scope.appointments = null;
  }, true);
};

module.exports = module.controller('ScheduleController', ScheduleController);
