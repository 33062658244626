import angular from 'angular';

const module = angular.module('whyline.constants.intercom_id', [

]);

let id;
id = 'l5h5hgjx';

module.constant('INTERCOM_ID', id);

module.exports = module;
