import angular from 'angular';
import '../app.constants';
import './resource';

const module = angular.module('whyline.resources.sector', [
  'whyline.resources.resource',
]);

const SectorFactory = ($timeout, $http, API_URL, Resource) => {
  /*@ngInject*/

  const _type = 'sector/organization?placeId';

  const _schema = {
    enableInPlace: {
      required: false,
      type: Boolean,
      default: false,
    },
    name: {
      lowercase: true,
      max: 128,
      required: true,
      type: String,
    },
    organizationId: {
      required: true,
      type: String,
    },
    root: {
      required: true,
      type: Boolean,
      default: false,
    },
    tree: [
      {
        _id: {
          required: true,
          type: String,
        },
        type: {
          required: true,
          type: String,
        },
        enableInPlace: {
          required: false,
          type: Boolean,
          default: false,
        },
      }
    ],
    type: {
      required: true,
      type: String,
    },
  };

  const Sector = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Sector.GetAllPlaceSectors = () => {
    const url = `${API_URL}${_type}`;
    return $http.get(url).then(response => {
      if(response.data) {
        return response.data;
      }
    });
  };

  Sector.SavePlaceSectors = placeSectors => {
    const url = `${API_URL}${_type}`;
    return $http.put(url, placeSectors);
  };

  Sector.prototype = new Resource(_type, _schema, Sector);
  Resource.Extend(_type, Sector);

  const validityTime = 5 * 60 * 1000;
  Sector.InitCache(validityTime);

  return Sector;
};

module.exports = module.factory('Sector', SectorFactory);
