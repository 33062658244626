
import angular from 'angular';
// import { includes } from 'lodash';
import '../../services/role';

const module = angular.module('whyline.modals.roles', [
  'whyline.components.status',
  'whyline.services.role',
]);

const RoleModalController = ($scope, $timeout, $uibModalInstance, $translate, NotificationService, RoleService, roleId) => {
  'ngInject';

  let validate = () => {
    const name = $scope.role.name;

    if(!name) {
      NotificationService.Warning('El nombre del role es requerido.');
      return false;
    }

    if(name.length < 2) {
      NotificationService.Warning('El nombre debe tener al menos 2 caracteres.');
      return false;
    }
    if(!$scope.role.lines.length && !$scope.role.areas.length && !$scope.role.schemas.length) {
      NotificationService.Warning('Debes seleccionar al menos, una fila, un área o un esquema.');
      return false;
    }
    return true;
  };

  const dismissOnError = err => {
    if([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
  };

  $scope.role = {
    _id: '',
    actions: [],
    placeId: '',
    name: '',
  };

  if(roleId) {
   console.log('not-implemented-yet')
  }

  $scope.save = () => {
    if(validate()) {
      if(roleId) {
        return RoleService.Update($scope.role)
        .then(() => {
          NotificationService.Success('El role fue actualizado exitosamente.');
          $uibModalInstance.close('cancel');
        })
        .catch(err => dismissOnError(err));
      }

      return RoleService.Create($scope.role)
      .then(() => {
        NotificationService.Success('El role fue creado exitosamente.');
        $uibModalInstance.close('cancel');
      })
      .catch(err => dismissOnError(err));
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  // $scope.selectLine = selection => {
  //   // console.log(selection);
  //   const id = selection._id;

  //   switch (selection.type) {
  //     case 'line':
  //       if(!includes($scope.role.lines, selection._id)) {
  //         $scope.role.lines.push(id);
  //         $scope.role.linesPopulated[id] = selection;
  //         populateAlreadyShown();
  //       }
  //       break;
  //   }
  // };

//  $scope.remove = id => {
//     const schemaIndex = $scope.role.schemas.indexOf(id);
//     if(schemaIndex >= 0) {
//       Reflect.deleteProperty($scope.role.schemasPopulated, id);
//       populateAlreadyShown();
//     }
//   };
};

module.exports = module.controller('RoleModalController', RoleModalController);
