import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/line';

const module = angular.module('whyline.services.line', ['whyline.resources.line']);

let Line;
const socketEvents = {
  create: 'line:created',
  update: 'line:updated',
  deleted: 'line:deleted',
  clear: 'line:clear',
};
class LineService {
  static $inject = ['Line'];

  constructor(injectedLine) {
    Line = injectedLine;
  }

  Create(data) {
    const newLine = new Line(data);
    return Line.Save(newLine);
  }

  Copy(line) {
    const copy = cloneDeep(line);
    return new Line(copy);
  }

  CallNext(lineId) {
    return Line.CallNext(lineId);
  }

  CallTo(subscriptionId, lineId) {
    return Line.CallTo(subscriptionId, lineId);
  }

  Update(line) {
    return Line.Save(line);
  }

  Remove(lineId) {
    const lineToRemove = Line.FindById(lineId);
    return Line.Remove(lineToRemove);
  }

  GetAreaPreference(lineId, placeId) {
    return Line.GetAreaPreference(lineId, placeId);
  }

  GetMyLinesAsPromise() {
    return Line.GetMyLines({ promise: true });
  }

  GetMyLinesAsPromiseFromServer() {
    return Line.GetMyLines({ promise: true, force: true });
  }

  GetAll() {
    return Line.FetchAll();
  }

  GetAllFromServer() {
    return Line.FetchAll({ force: true });
  }

  GetAllAsPromise() {
    return Line.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return Line.FetchAll({ promise: true, force: true });
  }

  GetOne(lineId) {
    return Line.FindById(lineId);
  }

  GetOneFromServer(lineId) {
    return Line.FindById(lineId, { force: true });
  }

  GetOneAsPromiseFromServer(lineId) {
    return Line.FindById(lineId, { promise: true, force: true });
  }

  GetOneAsPromise(lineId) {
    return Line.FindById(lineId, { promise: true });
  }

  FindByIds(ids, options) {
    return Line.FindByIds(ids, options);
  }

  Switch(line) {
    return line.enable(!line.open);
  }

  Clear(line) {
    return line.clear();
  }
}

module.exports = module.service('LineService', LineService);
