import angular from 'angular';
import yaml from 'js-yaml';
import _ from 'lodash';

const module = angular.module('whyline.modals.desktop-caller', []);

function DesktopCaller($scope, $uibModalInstance, $http) {
  'ngInject';

  $scope.osAvailable = null;
  $scope.downloadUrl = null;

  const windows = {
    name: 'Windows',
  };

  $scope.osOptions = [windows];

  if (navigator.userAgent.indexOf('Win') !== -1) {
    $scope.osAvailable = true;
    $scope.selectedOS = windows;
  }
  if (navigator.userAgent.indexOf('Mac') !== -1) {
    $scope.osAvailable = false;
  }
  if (navigator.userAgent.indexOf('X11') !== -1) {
    $scope.osAvailable = false;
  }
  if (navigator.userAgent.indexOf('Linux') !== -1) {
    $scope.osAvailable = false;
  }

  $http
    .get('https://desktop-caller-release.s3.amazonaws.com/latest.yml')
    .then((response) => {
      try {
        const parsedResponse = yaml.load(response.data);
        const fileName = _.get(parsedResponse, 'files[0].url', 'unknown');
        $scope.downloadUrl = `https://desktop-caller-release.s3.amazonaws.com/${fileName}`;
      } catch (e) {
        console.log(e);
      }
    });

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
}

module.exports = module.controller('DesktopCaller', DesktopCaller);
