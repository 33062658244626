import angular from 'angular';
import '../resources/display';
import '../factories/socket.io';
import '../services/socket';

const module = angular.module('whyline.sockets.display', [
  'whyline.factories.socket',
  'whyline.services.socket',
  'whyline.resources.display'
]);

const DisplaySocket = (Socket, Display, $rootScope, SocketService) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('display:created', display => {
        Display.UpdateCache(display._id, display);
      });
      socket.on('display:updated', display => {
        Display.UpdateCache(display._id, display);
      });
      socket.on('display:deleted', display => {
        Display.RemoveFromCache(display._id, display._type);
      });
    });
  });

  $rootScope.$on('socket:display-connected', () => {
    const socket = SocketService.GetDisplayInstance();

    socket.on('display:call-subscription', data => {
      $rootScope.$emit('display:call-subscription', data);
    });

    socket.on('display:recall-subscription', data => {
      $rootScope.$emit('display:recall-subscription', data);
    });

    socket.on('display:update-next-calls', data => {
      $rootScope.$emit('display:update-next-calls', data);
    });

    socket.on('display:identify', data => {
      $rootScope.$emit('display:identify', data);
    });

    socket.on('display:minmax', data => {
      $rootScope.$emit('display:minmax', data);
    });

    socket.on('display:refresh', data => {
      $rootScope.$emit('display:refresh', data);
    });

    socket.on('display:disconnect', () => {
      socket.disconnect();
    });
  });
};

module.exports = module.factory('DisplaySocket', (Socket, Display, $rootScope, SocketService) => new DisplaySocket(Socket, Display, $rootScope, SocketService));
