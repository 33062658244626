import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.role', [
  'whyline.resources.resource',
]);

const RoleFactory = (Resource) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'role?placeId';

  const _schema = {
    _id: {
      type: String,
      required: false,
    },
    actions: {
      type: [String],
      required: true,
    },
    placeId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  };

  const Role = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Role.prototype = new Resource(_type, _schema, Role);

  Resource.Extend(_type, Role);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Role.InitCache(validityTime);

  return Role;
};

module.exports = module.factory('Role', RoleFactory);
