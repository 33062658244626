import angular from 'angular';
import { get, split } from 'lodash';
import { localStorage } from '../utils';
import semver from 'semver';
import '../app.constants';
import '../factories/socket.io';
import '../services/socket';
import '../services/notifications';

const module = angular.module('whyline.sockets.application', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.services.socket',
  'whyline.services.notifications',
]);

const ApplicationSocket = ($rootScope, $translate, $state, SocketService, NotificationService, SAFE_STATES, blockUI, $window) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('application:update-virtualine', data => {
        const currentVersion = localStorage.get('version', null);
        const newVersion = get(data, 'version', null);

        // Set new version to local storage if doesn't exists
        if (!currentVersion && semver.valid(newVersion)) {
          localStorage.set('version', newVersion);
        }

        // Check if current and new versions are valid and are different
        if (semver.valid(currentVersion) && semver.valid(newVersion) && semver.gt(newVersion, currentVersion) && semver.diff(currentVersion, newVersion)) {
          // Set new version to local storage
          localStorage.set('version', newVersion);

          const changeType = semver.diff(currentVersion, newVersion);

          if(changeType === 'major') {
            blockUI.instances.get('wholeApp').start($translate.instant('system_update'));
            $rootScope.$emit('importantMessage', {
              type: 'danger',
              show: true,
              canClose: false,
              message: 'system_update_require_refresh',
            });
          } else {
            $rootScope.$emit('importantMessage', {
              type: 'warning',
              show: true,
              canClose: true,
              message: 'system_update_no_require_refresh',
            });
          }

          // Desktop notification
          NotificationService.ShowDesktop({
            title: $translate.instant('system_update'),
            body: $translate.instant('system_update_browser_notification'),
            onclick: () => {
              $window.location.reload();
            },
          });
        }
      });

      socket.on('application:update-totems', data => {
        const totemId = get($state.params, 'totemId', null);
        if (totemId) {
          $state.go(SAFE_STATES.totemTvWelcome, {}, { reload: SAFE_STATES.totemTvWelcome });
        }
      });
    });
  });
};

module.exports = module.factory('ApplicationSocket', ($rootScope, $translate, $state, SocketService, NotificationService, SAFE_STATES, blockUI, $window) => new ApplicationSocket($rootScope, $translate, $state, SocketService, NotificationService, SAFE_STATES, blockUI, $window));
