import angular from 'angular';
import '../factories/socket.io';
import '../services/notifications';
import '../services/cache';
import '../services/socket';
import '../app.constants';
import {
  localStorage
} from '../utils';

const module = angular.module('whyline.sockets.connection', [
  'whyline.factories.socket',
  'whyline.services.notifications',
  'whyline.services.cache',
  'whyline.services.socket',
  'whyline.constants',
  'whyline.services.globals',
]);

const ConnectionSocket = (Socket, NotificationService, CacheService, blockUI, $timeout, $state, $rootScope, $window, SocketService, SAFE_STATES, $translate, GlobalsService) => {
  // Instance of block ui for all the app
  const wholeApp = blockUI.instances.get('wholeApp');

  // Notification instances must be saved in this object
  let notificationInstances = {
    disconnect: null,
    reconnect: null,
  };

  let isSignout = false;

  $rootScope.$on('unauthenticated', () => {
    isSignout = true;
    SocketService.GetInstance().then(socket => {
      $timeout(() => {
        if (socket) {
          socket.disconnect(true);
          SocketService.SetInstance(null)
        }
      });
    });
  });

  $rootScope.$on('socketReady', function () {
    SocketService.GetInstance().then(function (socket) {
      // Socket disconnected
      socket.on('disconnect', () => {
        if (!isSignout) {
          if (localStorage.get('refreshed')) {
            NotificationService.Error($translate.instant('sorry_please_contact'), {
              timeOut: 0,
              preventDuplicates: true,
              preventOpenDuplicates: true,
              maxOpened: 5,
              closeButton: false,
              extendedTimeOut: 0,
              tapToDismiss: false,
            });
            localStorage.set('refreshed', false);
            wholeApp.start('El problema persiste.');
          } else {
            notificationInstances.disconnect = NotificationService.Error($translate.instant('err_server_connection'), {
              timeOut: 0,
              preventDuplicates: true,
              preventOpenDuplicates: true,
              maxOpened: 5,
              closeButton: false,
              extendedTimeOut: 0,
              tapToDismiss: false,
            });
            wholeApp.start('Intentando reconectar...');
          }
        } else {
          // $window.location.reload();
        }
      });

      // Socket reconnected
      socket.on('reconnect', () => {
        NotificationService.Clear(notificationInstances.disconnect);
        NotificationService.Success($translate.instant('connection_restablished'));
        wholeApp.stop();

        const place = GlobalsService.CurrentPlace;

        if (place && place._id) {
          socket.emit('place:join', place._id);
        }
        //$window.location.reload();
      });

      // Socket reconnection error
      socket.on('reconnect_failed', () => {
        NotificationService.Clear(notificationInstances.disconnect);
        NotificationService.Error($translate.instant('err_restablish_connection'), {
          timeOut: 0,
          preventDuplicates: true,
          preventOpenDuplicates: true,
          maxOpened: 5,
          closeButton: false,
          extendedTimeOut: 0,
          tapToDismiss: false,
        });

        // Refresh after 5 seconds
        $timeout(() => {
          localStorage.set('refreshed', true);
          //location.reload();
        }, 5000);
      });
    });
  });
};

module.exports = module.factory('ConnectionSocket', (Socket, NotificationService, CacheService, blockUI, $timeout, $state, $rootScope, $window, SocketService, SAFE_STATES, $translate, GlobalsService) => new ConnectionSocket(Socket, NotificationService, CacheService, blockUI, $timeout, $state, $rootScope, $window, SocketService, SAFE_STATES, $translate, GlobalsService));
