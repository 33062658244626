import angular from 'angular';
import { includes } from 'lodash';
import './modals/line-flow-type-organization';
import '../services/line-flow-type-organization';
import '../services/globals';
import '../services/organization';

const module = angular.module('whyline.controllers.line-flow-type-organization', [
  'whyline.components.status',
  'whyline.services.line-flow-type-organization',
  'whyline.modals.line-flow-type-organization',
  'whyline.services.globals',
  'whyline.services.organization',
]);

const LineFlowTypeOrganizationController = ($scope, GlobalsService, LineFlowTypeOrganizationService, $uibModal, dialog, $translate, ErrorService, NotificationService, $state) => {
  /*@ngInject*/
  $scope.placeId = $state.params.placeId ? $state.params.placeId : false;
  $scope.quantityOfItems = 8;
  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';
  // View init
  if ($scope.placeId) {
    LineFlowTypeOrganizationService.GetAllEnabledByOrganization($state.params.organizationId, true)
    .then(lftoList => {
      $scope.lineFlowTypeOrganizations = lftoList;
      $scope.lineFlowTypeOrganizations.forEach(lfto => {
        if (includes(lfto.disabledInPlaces, $scope.placeId)) {
          lfto.enabledPlace = false;
        } else {
          lfto.enabledPlace = true;
        }
      });
    });
  } else {
    $scope.lineFlowTypeOrganizations = LineFlowTypeOrganizationService.GetAll();
  }

  $scope.remove = (lineFlowTypeOrganizationId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('lineFlowTypeOrganization_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        LineFlowTypeOrganizationService.Remove(lineFlowTypeOrganizationId)
          .then(() => {
            NotificationService.Success($translate.instant('lineFlowTypeOrganization_{name}_remove_succ').replace('{name}', name));
          })
          .catch(err => {
            ErrorService.handler(err);
          });
      });
  };

  $scope.switch = lineFlowTypeOrganization => {
    let n = name || '';
    let enabled = !lineFlowTypeOrganization.enabled;
    let enabledPlace = !lineFlowTypeOrganization.enabledPlace;
    const confirmMessage = enabled ? 'lineFlowTypeOrganization_{name}_enable_sure' : 'lineFlowTypeOrganization_{name}_disable_sure';
    dialog.confirm($translate.instant(confirmMessage).replace('{name}', n))
      .then(() => {
        if ($scope.placeId) {
          const successMessage = enabledPlace ? 'lineFlowTypeOrganization_{name}_enable_succ' : 'lineFlowTypeOrganization_{name}_disable_succ';
          LineFlowTypeOrganizationService.EnableInPlace(lineFlowTypeOrganization._id)
          .then(() => {
            NotificationService.Success($translate.instant(successMessage).replace('{name}', n));
          })
          .catch(err => {
            lineFlowTypeOrganization.enabledPlace = !lineFlowTypeOrganization.enabledPlace;
            ErrorService.handler(err);
          });
        } else {
          const successMessage = enabled ? 'lineFlowTypeOrganization_{name}_enable_succ' : 'lineFlowTypeOrganization_{name}_disable_succ';
          LineFlowTypeOrganizationService.Enable(lineFlowTypeOrganization._id)
          .then(() => {
            NotificationService.Success($translate.instant(successMessage).replace('{name}', n));
          })
          .catch(err => {
            if ($scope.placeId) {
              lineFlowTypeOrganization.enabledPlace = !lineFlowTypeOrganization.enabledPlace;
            } else {
              lineFlowTypeOrganization.enabled = !lineFlowTypeOrganization.enabled;
            }
            ErrorService.handler(err);
          });
        }
      })
      .catch(() => {
        if ($scope.placeId) {
          lineFlowTypeOrganization.enabledPlace = !lineFlowTypeOrganization.enabledPlace;
        } else {
          lineFlowTypeOrganization.enabled = !lineFlowTypeOrganization.enabled;
        }
      });
  };

  // Modal
  $scope.open = lineFlowTypeOrganizationId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/line-flow-type-organization-modal.html',
      size: 'lg',
      controller: 'LineFlowTypeOrganizationModalController',
      resolve: {
        lineFlowTypeOrganizationId: () => lineFlowTypeOrganizationId
      }
    });
  };
};

module.exports = module.controller('LineFlowTypeOrganizationController', LineFlowTypeOrganizationController);
