import angular from 'angular';
import { includes, get } from 'lodash';
import '../../services/totem';
import '../../services/place';
import '../../services/device';
import '../../services/organization';
import '../../services/errors';
import '../../app.api_url';
import { localStorage } from '../../utils';

const module = angular.module('whyline.modals.totems', [
  'whyline.components.status',
  'whyline.services.totem',
  'whyline.services.place',
  'whyline.services.device',
  'whyline.services.ticket-template',
  'whyline.services.organization',
  'whyline.services.errors',
  'whyline.constants.api_url',
]);

const TotemModalController = (
  $scope,
  $timeout,
  $uibModalInstance,
  $translate,
  $state,
  NotificationService,
  TotemService,
  PlaceService,
  totemId,
  DeviceService,
  TicketTemplateService,
  OrganizationService,
  ErrorService,
  ENV_NAME,
) => {
  'ngInject';
  const place = PlaceService.GetCurrent();

  OrganizationService.GetOneAsPromiseFromServer($state.params.organizationId).then((org) => {
    $scope.useScenarios = get(org, 'configuration.queuer.useScenarios', false);
  });

  $scope.env = ENV_NAME.toLowerCase();
  $scope.validateTotemId = false;
  $scope.place = place;

  $scope.cantConfirmAppointments = !get(place, 'configuration.canConfirmAppointments', false);
  $scope.canViewTotemToggles = get(place, 'configuration.canViewTotemToggles', false);

  localStorage.get('user').then(user => {
    $scope.superAdmin = user && user.sudo;
      $scope.hasAdminPermissions =
        !$scope.superAdmin
        && user
        && !!user.permissions.filter(
          p =>
            p.role.name === 'Admin General' && p.role.placeId === place._id
            || p.role.name === 'Organization Admin'
              && p.role.organizationId === place.organizationId
        );
  });

  $scope.itemsPopulated = [];
  const populateAlreadyShown = () => {
    $scope.itemsPopulated = {
      ...$scope.totem.linesPopulated,
      ...$scope.totem.areasPopulated,
      ...$scope.totem.schemasPopulated,
      ...$scope.totem.devicePopulated,
    };
  };
  $scope.ticketTemplateList = [];

  let validate = () => {
    const name = $scope.totem.name;

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_totem_name'));
      return false;
    }

    if (name.length < 2) {
      NotificationService.Warning($translate.instant('min_char_name'));
      return false;
    }

    if ($scope.useScenarios && ($scope.totem.friendlyId || $scope.totem.conditionsApply)) {
      const regex = /^[a-zA-Z0-9-]{3,20}$/;
      const isValid = regex.test($scope.totem.friendlyId);
      if (!isValid) {
        NotificationService.Warning($translate.instant('mandatory_totem_id'));
        return false;
      }
    }

    if (!$scope.totem.onlyForTurnConfirmation && !$scope.totem.lines.length && !$scope.totem.areas.length && !$scope.totem.schemas.length) {
      NotificationService.Warning($translate.instant('select_min_fila_area_scheme'));
      return false;
    }

    return true;
  };

  const dismissOnError = err => {
    if ([401, 403, 404].indexOf(err.status) >= 0) {
      $uibModalInstance.close('cancel');
    }
  };

  $scope.enableControls = true;

  $scope.toggleState = () => {
    $scope.enableControls = $scope.totem.onlyForTurnConfirmation === false;
  };

  $scope.toggleConditionsApply = () => {
    $scope.validateTotemId = $scope.totem.conditionsApply;
  };

  $scope.totem = {
    name: '',
    lines: [],
    areas: [],
    schemas: [],
    deviceId: '',
    devicePopulated: {},
    linesPopulated: {},
    areasPopulated: {},
    schemasPopulated: [],
    mobileApp: false,
    onlyForTurnConfirmation: false,
    showComeBackAt: false,
    ticketTemplate: '',
    documentsReader: false,
    printFromBrowser: {
      enabled: false,
    },
    fields: {
      firstName: true,
      lastName: true,
      phone: true,
      email: true,
      identification: true,
    },
  };

  $scope.setDisabledLineFromConfiguration = line => {
    if ($scope.totem.mobileApp) {
      line.disableForConfiguration = !line.requestSource.mobileApp ? 'No se encuentra habilitado para la app mobile' : '';
    } else {
      line.disableForConfiguration = !line.requestSource.totem ? 'No se encuentra habilitado para el totem' : '';
    }
  };

  $scope.setDisabledAreaFromConfiguration = area => {
    if ($scope.totem.mobileApp) {
      area.disableForConfiguration = !(area.isWithoutAppointment && area.withoutAppointment.requestSource.mobileApp) ? 'No se encuentra habilitado para la app mobile' : '';
    } else {
      area.disableForConfiguration = !(area.isWithoutAppointment && area.withoutAppointment.requestSource.totem) ? 'No se encuentra habilitado para el totem' : '';
    }
  };

  $scope.setDisableServicesFromConfiguration = () => {
    const lines = Object.values($scope.totem.linesPopulated);

    lines.length && lines.forEach(line => {
      $scope.setDisabledLineFromConfiguration(line);
    });

    const areas = Object.values($scope.totem.areasPopulated);

    areas.length && areas.forEach(area => {
      $scope.setDisabledAreaFromConfiguration(area);
    });
  };

  TicketTemplateService.GetAllByOrganizationId($scope.place.organizationId)
  .then(ticketTemplates => {
    $scope.ticketTemplateList = ticketTemplates;
    $scope.ticketTemplateSelected = ticketTemplates[0];
  });

  if (totemId) {
    TotemService.GetOnePopulatedAsPromiseFromServer(totemId)
      .then(totem => {
        $timeout(() => {
          $scope.totem = totem;
          $scope.validateTotemId = $scope.totem.conditionsApply;
          const ticketTemplate = $scope.ticketTemplateList.find((t) => t._id === $scope.totem.ticketTemplate);
          $scope.ticketTemplateSelected = ticketTemplate ? ticketTemplate : $scope.ticketTemplateList[0];

          if (typeof $scope.totem.fields === 'undefined' || Object.keys($scope.totem.fields).length == 0) {
            $scope.totem.fields = {
              firstName: true,
              lastName: true,
              phone: true,
              email: true,
              identification: true,
            };
          }
          $scope.totem.onlyForTurnConfirmation = $scope.totem.onlyForTurnConfirmation || false;

          if (totem.deviceId !== '') {
            DeviceService.GetById(totem.deviceId)
              .then(data => {
                $timeout(() => {
                  $scope.totem.devicePopulated = {};
                  $scope.totem.devicePopulated[totem.deviceId] = data;
                });
              })
              .catch(error => {
                if (error.status === 404) {
                  $scope.totem.deviceId = '';
                  $scope.totem.devicePopulated = {};
                }
              });
          } else {
            $scope.totem.devicePopulated = {};
          }
          $scope.setDisableServicesFromConfiguration();
          $scope.toggleState();
          populateAlreadyShown();
          $timeout(() => {
            $scope.$apply();
          });
        });
      })
      .catch(err => dismissOnError(err));
  }

  $scope.save = () => {
    if (validate()) {

      if ($scope.totem.onlyForTurnConfirmation) {
        $scope.totem.lines = [];
        $scope.totem.areas = [];
        $scope.totem.schemas = [];
      }

      $scope.totem.ticketTemplate;

      // Uppercase friendlyId
      if ($scope.totem.friendlyId) {
        $scope.totem.friendlyId = $scope.totem.friendlyId.toUpperCase();
      }

      if (totemId) {
        return TotemService.Update($scope.totem)
          .then(() => {
            NotificationService.Success($translate.instant('totem_update_succ'));
            $uibModalInstance.close('cancel');
          })
          .catch(ErrorService.handler);
      }

      return TotemService.Create($scope.totem)
        .then(() => {
          NotificationService.Success($translate.instant('totem_create_succ'));
          $uibModalInstance.close('cancel');
        })
        .catch(ErrorService.handler);
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.selectLine = selection => {
    const id = selection._id;

    switch (selection.type) {
      case 'line':
        if (!includes($scope.totem.lines, selection._id)) {
          $scope.totem.lines.push(id);
          $scope.totem.linesPopulated[id] = selection;
          $scope.setDisabledLineFromConfiguration(selection);
          populateAlreadyShown();
        }
        break;
      case 'area':
        if (!includes($scope.totem.areas, selection._id)) {
          $scope.totem.areas.push(id);
          $scope.totem.areasPopulated[id] = selection;
          $scope.setDisabledAreaFromConfiguration(selection);
          populateAlreadyShown();
        }
        break;
      case 'sector':
        if (!includes($scope.totem.schemas, selection._id)) {
          $scope.totem.schemas.push(id);
          $scope.totem.schemasPopulated[id] = selection;
          populateAlreadyShown();
        }
        break;
        case 'device':
          if ($scope.totem.deviceId === '') {
            $scope.totem.deviceId = selection._id;
            $scope.totem.devicePopulated[id] = selection;
            populateAlreadyShown();
          }
          break;
      default:
        return NotificationService.Error($translate.instant('ups_some_wrong'));
    }
  };

  $scope.remove = id => {
    const lineIndex = $scope.totem.lines.indexOf(id);
    if (lineIndex >= 0) {
      $scope.totem.lines.splice(lineIndex, 1);
      $scope.totem.linesPopulated[id] = undefined;
      Reflect.deleteProperty($scope.totem.linesPopulated, id);
      populateAlreadyShown();
    }
    const areaIndex = $scope.totem.areas.indexOf(id);
    if (areaIndex >= 0) {
      $scope.totem.areas.splice(areaIndex, 1);
      $scope.totem.areasPopulated[id] = undefined;
      Reflect.deleteProperty($scope.totem.areasPopulated, id);
      populateAlreadyShown();
    }
    const schemaIndex = $scope.totem.schemas.indexOf(id);
    if (schemaIndex >= 0) {
      $scope.totem.schemas.splice(schemaIndex, 1);
      $scope.totem.schemasPopulated[id] = undefined;
      Reflect.deleteProperty($scope.totem.schemasPopulated, id);
      populateAlreadyShown();
    }
    const deviceIndex = $scope.totem.deviceId.includes(id);
    if (deviceIndex) {
      $scope.totem.deviceId = '';
      $scope.totem.devicePopulated[id] = undefined;
      Reflect.deleteProperty($scope.totem.devicePopulated, id);
      populateAlreadyShown();
    }
  };

  $scope.setTicketTemplateId = ticketTemplateSelected => {
    $scope.totem.ticketTemplate = ticketTemplateSelected._id;
  };
};

module.exports = module.controller('TotemModalController', TotemModalController);
