import angular from 'angular';
import './resource';
import '../app.constants';

const module = angular.module('whyline.resources.box', [
  'whyline.constants',
  'whyline.resources.resource',
]);

const BoxFactory = (API_URL, $http, Resource) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'box?placeId';

  const _schema = {
    _id: {
      type: String,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    display: {
      type: String,
      default: '',
    },
    placeId: {
      type: String,
      required: true,
    },
    sectors: {
      type: [String],
      required: true,
    },
  };

  const Box = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Box.Enable = (boxId) => {
    const url = `${API_URL}${_type}/${boxId}/enable`;
    return $http.patch(url).then((response) => Promise.resolve(response.data));
  };

  Box.GetAllEnabledByPlaceId = (userId, emitSocket) => {
    const url = `${API_URL}${_type}/enabled?userId=${userId}&emitSocket=${emitSocket}`;
    return $http.get(url).then((response) => Promise.resolve(response.data));
  };

  Box.prototype = new Resource(_type, _schema, Box);

  Resource.Extend(_type, Box);

  //five minutes
  const validityTime = 5 * 60 * 1000;
  Box.InitCache(validityTime);

  return Box;
};

module.exports = module.factory('Box', BoxFactory);
