import angular from 'angular';
import { schedule, options } from '../../utils';
import { get, includes, forEach, cloneDeep } from 'lodash';
import 'angular-ui-tree';
import '../../services/organization-area';
import '../../services/organization';
import '../../services/errors';
import '../../services/line';
import '../../components/osom';

const module = angular.module('whyline.modals.organization-area', [
  'whyline.services.organization-area',
  'whyline.services.organization',
  'whyline.services.errors',
  'whyline.services.line',
  'whyline.components.osom',
]);

const OrganizationAreaModalController = ($scope, $timeout, $state, $uibModalInstance, $translate, GlobalsService, NotificationService, OrganizationAreaService, organizationAreaId, ErrorService, dialog, OrganizationService, LineService) => {
  'ngInject';

  /*PlaceService.GetOneAsPromise($state.params.placeId).then(place => {
    $timeout(() => {
      $scope.place = place;
    });
  });*/
  $scope.currentOrganization = OrganizationService.GetCurrent();

  $scope.blockSaveButton = false;
  $scope.addReason = () => {
    $scope.organizationArea.configuration.reasons.unshift({
      name: '',
      enabled: true,
      isInherited: false,
    });
  };

  $scope.deleteReason = index => {
    dialog.confirm($translate.instant('reason_remove_sure'))
      .then(() => {
        $scope.organizationArea.configuration.reasons.splice(index, 1);
        NotificationService.Information($translate.instant('reason_remove_succ'));
      })
      .catch(ErrorService.handler);
  };

  const populateAlreadyShown = () => {
    $scope.itemsPopulated = {};
    forEach($scope.items, line => {
      $scope.itemsPopulated[line._id] = line;
    });
  };

  $scope.requestReasonAndMotiveChange = value => {
    if (!value) {
      $scope.organizationArea.configuration.reasonRequired = false;
    }
  };

  $scope.saveMotiveReason = () => {
    if ($scope.validateMotiveReason($scope.organizationArea.configuration)) {
      const data = {
        updateType: $scope.selectData.selectedOption,
        updateValues: { 
          configuration: {
            requestReasonAndMotive: $scope.organizationArea.configuration.requestReasonAndMotive,
            reasonRequired: $scope.organizationArea.configuration.reasonRequired,
            reasons: $scope.organizationArea.configuration.reasons,
          },
        },
      };
      $scope.save(data).then(() => {
        $scope.editStatus.openEditingReasonMotive = false;
      });
    }
  };

  $scope.saveWithoutAppointment = () => {
    if ($scope.validateWithoutAppointment($scope.organizationArea.withoutAppointment)) {
      const data = {
        updateType: $scope.selectData.selectedOption,
        updateValues: { withoutAppointment: $scope.organizationArea.withoutAppointment }
      };
      $scope.save(data).then(() => {
        $scope.editStatus.withoutAppointment = cloneDeep($scope.organizationArea.withoutAppointment);
        $scope.editStatus.openEditingWithout = false;
      });
    }
  };

  $scope.saveLines = () => {
    if ($scope.validateLines($scope.items)) {
      const data = {
        updateType: $scope.selectData.selectedOption,
        updateValues: { 
          organizationLines: $scope.items.map(item => ( 
            {
              id: item._id,
              enabled: item.enabled
            } 
          )) 
        }
      };
      $scope.save(data).then(() => {
        $scope.editStatus.items = cloneDeep($scope.items);
        $scope.editStatus.openEditingLines = false;
      });
    }
  };

  $scope.saveSchedule = () => {
    if ($scope.validateRanges($scope.organizationArea.schedule)) {
      $scope.organizationArea.schedule = schedule.format($scope.organizationArea.schedule, false);
      const data = {
        updateType: $scope.selectData.selectedOption,
        updateValues: { schedule: $scope.organizationArea.schedule }
      };
      $scope.save(data).then(() => {
        $scope.editStatus.schedule = cloneDeep($scope.organizationArea.schedule);
        $scope.organizationArea.schedule = schedule.format($scope.organizationArea.schedule, true);
        $scope.editStatus.openEditingDayList = false;
      });
    }
  };

  $scope.editReasonMotive = () => {
    $scope.editStatus.openEditingReasonMotive = true;
    $scope.selectData.selectedOption = 'all';
  };

  $scope.editLines = () => {
    $scope.editStatus.openEditingLines = true;
    $scope.selectData.selectedOption = 'all';
  };

  $scope.validateRanges = schedule => {
    var valid = true;
    const opened = schedule.filter(s => s.open);
    for (let i = 0; i < opened.length; i++) {
      const day = opened[i];
      if (day.firstPeriod.opening >= day.firstPeriod.closing) {
        NotificationService.Warning($translate.instant('opening_hours_first_period_msg_validator').replace('{dayname}', $translate.instant(day.name)));
        valid = false;
        break;
      }
      if (day.hasSecondPeriod) {
        if (day.secondPeriod.opening <= day.firstPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_must_be_later_first_msg_validator').replace('{dayname}', $translate.instant(day.name)));
          valid = false;
          break;
        }

        if (day.secondPeriod.opening >= day.secondPeriod.closing) {
          NotificationService.Warning($translate.instant('opening_hours_second_period_msg_validator').replace('{dayname}', $translate.instant(day.name)))
          valid = false;
          break;
        }
      }
    }
    return valid;
  };

  $scope.validateMotiveReason = configuration => {
    const arrayWithoutDuplicates = [...new Set(configuration.reasons.map((r) => r.name))];
    if (arrayWithoutDuplicates.length !== configuration.reasons.length) {
      NotificationService.Warning($translate.instant('duplicated-reasons'));
      return false;
    }
    for (const reason of configuration.reasons) {
      if (!reason.name) {
        NotificationService.Warning($translate.instant('reason and motive empty'));
        return false;  
      }
    }
    return true;
  };

  $scope.validateWithoutAppointment = withoutAppointment => {
    if (!withoutAppointment.withPriority && !withoutAppointment.withoutPriority) {
      NotificationService.Warning($translate.instant('min_one_priority_type'));
      return false;
    }

    if (!withoutAppointment.requestSource.reception && !withoutAppointment.requestSource.totem && !withoutAppointment.requestSource.mobileApp) {
      NotificationService.Warning($translate.instant('min_one_request_type'));
      return false;
    }

    return true;
  };

  $scope.validateLabel = name => {
    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_area_name'));
      return false;
    }

    if (name.length > 50) {
      NotificationService.Warning($translate.instant('max_length_name'));
      return false;
    }

    if (!/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(name)) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }

    return true;
  };

  $scope.validateRequestType = values => {
    if (!values.isWithAppointment && !values.isWithoutAppointment) {
      NotificationService.Warning($translate.instant('min_one_request_type'));
      return false;
    }
    return true;
  };

  $scope.validateLines = items => {
    if (items.length <= 0) {
      NotificationService.Warning($translate.instant('min_fila_assign'));
      return false;
    }
    return true;
  };

  $scope.validateMessage = postMessage => {
    if (postMessage.length > 59) {
      NotificationService.Warning($translate.instant('max_length_post_check_in_message'));
      return false;
    }
    return true;
  };

  const validate = () => {
    if (!$scope.validateLabel($scope.organizationArea.label)) {
      return false;
    }
    if (!$scope.validateMessage(get($scope.organizationArea.configuration, 'postMessage', ''))) {
      return false;
    }
    if (!$scope.validateLines($scope.items)) {
      return false;
    }
    const requestTypes = {
      isWithAppointment: $scope.organizationArea.isWithAppointment,
      isWithoutAppointment: $scope.organizationArea.isWithoutAppointment
    };
    if (!$scope.validateRequestType(requestTypes)) {
      return false;
    }
    if ($scope.organizationArea.isWithoutAppointment && !$scope.validateWithoutAppointment($scope.organizationArea.withoutAppointment)) {
      return false;
    }
    if ($scope.organizationArea.configuration && !$scope.validateMotiveReason($scope.organizationArea.configuration)) {
      return false;
    }
    if (!$scope.validateRanges($scope.organizationArea.schedule)) {
      return false;
    }

    return true;
  };

  $scope.selectData = {
    options: options.filter(o => o.onlyOrganizations || o.all),
    selectedOption: options.filter(o => o.onlyOrganizations || o.all)[0].key,
  };

  $scope.enterpriseType = GlobalsService.EnterpriseType;

  $scope.selectDataForWithoutAppointment = {
    options: options.filter(o => o.onlyOrganizations || o.all),
    selectedOption: options.filter(o => o.onlyOrganizations || o.all)[0].key,
  };

  $scope.items = [];
  $scope.hours = schedule.getHours();
  $scope.organizationArea = {
    schedule: schedule.format(schedule.reOrder(schedule.create()), true),
    maxNumbers: 0,
    isWithoutAppointment: true,
    isWithAppointment: $scope.enterpriseType === 'ba',
    withoutAppointment: {
      withPriority: true,
      withoutPriority: true,
      requestSource: {
        mobileApp: true,
        reception: true,
        totem: true,
      }
    },
    configuration: {
      postMessage: '',
      reasonRequired: $scope.currentOrganization.configuration.reasonRequired,
      reasons: [],
      requestReasonAndMotive: $scope.currentOrganization.configuration.requestReasonAndMotive
    }
  };
  $scope.showEditAttributes = false;
  $scope.currentOrganization.configuration.reasons.forEach(reason => {
    const re = {
      enabled: false,
      id: reason.id,
      isInherited: true,
      name: reason.name,
    }
    $scope.organizationArea.configuration.reasons.push(re);
  });
  if (organizationAreaId) {
    OrganizationAreaService.GetWithLinesAsPromiseFromServer(organizationAreaId)
      .then(res => {
        $timeout(() => {
          $scope.organizationArea = OrganizationAreaService.Copy(res);
          $scope.organizationArea.schedule = $scope.organizationArea.schedule;
          $scope.linesPopulated = res.organizationLinesPopulated;
          $scope.requestTypes = {
            value: {
              isWithoutAppointment: $scope.organizationArea.isWithoutAppointment,
              isWithAppointment: $scope.organizationArea.isWithAppointment
            },
            isBA: $scope.enterpriseType === 'ba'
          };

          // Define initial editing status
          $scope.editStatus = {
            openEditingLines: false,
            openEditingWith: false,
            openEditingWithout: false,
            openEditingDayList: false,
            openEditingReasonMotive: false,
            withoutAppointment: cloneDeep($scope.organizationArea.withoutAppointment),
            schedule: cloneDeep($scope.organizationArea.schedule),
            items: [],
          };

          // Fill item with lines
          for (let key in res.organizationLinesPopulated) {
            const item = {
              label: res.organizationLinesPopulated[key].label,
              _id: key, 
              type: res.organizationLinesPopulated[key].type,
              enabled: res.organizationLinesPopulated[key].enabledForAllPlacesAreas
            };
            $scope.items.push(item);
            $scope.editStatus.items.push(item);
          }
          populateAlreadyShown();
          $scope.organizationArea.schedule = schedule.format($scope.organizationArea.schedule, true);
        });
      })
      .catch(ErrorService.handler);
  }

  $scope.save = data => {
    if (organizationAreaId && data) {
      const optionsSave = {};
      optionsSave.data = data;
      optionsSave.organizationAreaId = organizationAreaId;
      optionsSave.organizationId = $scope.organizationArea.organizationId;

      if (validate()) {
        $scope.blockSaveButton = true;

        return OrganizationAreaService.UpdatePartial(optionsSave)
          .then(() => {
            NotificationService.Success($translate.instant('area_update_succ'));
          })
          .catch(error => {
            ErrorService.handler(error);
            $scope.blockSaveButton = false;
          });
      }
    } else {
      $scope.organizationArea.organizationLines = $scope.items.map(item => item._id);

      if (validate()) {
        $scope.blockSaveButton = true;
        $scope.organizationArea.schedule = schedule.format($scope.organizationArea.schedule, false);
        return OrganizationAreaService.Create($scope.organizationArea)
          .then(() => {
            NotificationService.Success($translate.instant('area_create_succ'));
            $uibModalInstance.close('cancel');
          })
          .catch(error => {
            ErrorService.handler(error);
            $scope.blockSaveButton = false;
          });
      }
    };
  };

  // OSOM line selector
  $scope.selectLine = selection => {
    const itemSelected = {
      label: selection.name,
      _id: selection._id,
      type: selection.type,
      enabled: false,
      //realType: selection.realType,
      //statistics: selection.statistics,
      //waitingPeople: selection.waitingPeople
    };
    if (!includes($scope.items.map(item => item._id), selection._id)) {
      $scope.items.push(itemSelected);
    }
    populateAlreadyShown();
  };

  $scope.removeLine = lineId => {
    $scope.items = $scope.items.filter(item => item._id !== lineId);
    populateAlreadyShown();
  };

  $scope.setHasSecondPeriod = (day, hasSecondPeriod) => {
    day.hasSecondPeriod = hasSecondPeriod;
  };

  $scope.applyToAll = day => {
    schedule.applyToAll(day, $scope.organizationArea.schedule);
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.cancelEdit = attribute => {
    switch (attribute) {
      case 'withoutAppointment':
        $scope.editStatus.openEditingWithout = false;
        $scope.organizationArea.withoutAppointment = cloneDeep($scope.editStatus.withoutAppointment);
        break;

      case 'lines':
        $scope.editStatus.openEditingLines = false;
        $scope.items = cloneDeep($scope.editStatus.items);
        break;

      case 'motiveReason':
        $scope.editStatus.openEditingReasonMotive = false;
        break;

      case 'schedule':
        $scope.editStatus.openEditingDayList = false;
        break;
    }
  };

  $scope.title = 'processes';

  /*if(modalType === 'group') {
    $scope.title = 'groups';
    $scope.isDraggable = false;
  } else {
    $scope.title = 'processes';
    $scope.isDraggable = true;
  }*/

  $scope.validateNumber = () => {
    let number = parseInt($scope.organizationArea.maxNumbers);
    number = isNaN(number) ? 0 : number;
    $scope.organizationArea.maxNumbers = number;
  };

  $scope.maxNumberKeyPress = e => {
    if (e.keyCode === 101 || e.keyCode === 69) {
      e.preventDefault();
    }
  };
};

module.exports = module.controller('OrganizationAreaModalController', OrganizationAreaModalController);


