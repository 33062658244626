import angular from 'angular';
import '../resources/holiday';
import '../services/area';
import '../factories/socket.io';
import '../services/socket';

const module = angular.module('whyline.sockets.holiday', [
  'whyline.factories.socket',
  'whyline.services.socket',
  'whyline.services.area',
  'whyline.resources.holiday',
]);

const HolidaySocket = (Holiday, $rootScope, SocketService, AreaService) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('holiday:created', holiday => {
        Holiday.UpdateCache(holiday._id, holiday);
      });
      socket.on('holiday:updated', holiday => {
        Holiday.UpdateCache(holiday._id, holiday);
      });
      socket.on('holiday:deleted', holiday => {
        Holiday.RemoveFromCache(holiday._id, holiday._type);
      });
    });
  });
};

module.exports = module.factory('HolidaySocket', (Holiday, $rootScope, SocketService, AreaService) => new HolidaySocket(Holiday, $rootScope, SocketService, AreaService));
