import { forEach, isEmpty, filter, map } from 'lodash';

/**
 * Main cache
 */
const _cache = {};

const _cleanObject = obj => {
  Object.keys(obj).forEach(prop => {
    Reflect.deleteProperty(obj, prop);
  });
};

/**
 * Sets the cache for the given type
 *
 * @param {number} validityTime
 * @param {string} type
 * @return {Object}
 * @public
 * @static
 */
const Init = (validityTime, type) => {
  if(!_cache[type]) {
    _cache[type] = {
      list: { },
      search: { },
      validityTime,
      lastUpdate: 0
    };
  }
  return _cache[type];
};

/**
 * Sets the cache for the given type
 *
 * @param {string} type
 * @return {Object}
 * @public
 * @static
 */
const Truncate = type => {
  if(_cache[type]) {
    _cleanObject(_cache[type].list);
    _cleanObject(_cache[type].search);
    _cache[type].lastUpdate = 0;
  }
  return _cache[type];
};

/**
 * Empties the whole cache
 *
 * @return {Object}
 * @public
 * @static
 * @final
 */
const Restart = () => {
  forEach(_cache, (value, type) => {
    Truncate(type);
  });
  return _cache;
};

/**
 * Turns the last update time to 0
 *
 * @param {string} type
 * @public
 * @static
 */
const Invalidate = type => {
  _cache[type].lastUpdate = 0;
};

/**
 * Gets all items from the cache for the given type
 *
 * @param {string} type
 * @param {Array <Resource>}
 * @public
 * @static
 */
const IsValid = type => {
  const validityTime = _cache[type].validityTime;
  return Date.now() - _cache[type].lastUpdate < validityTime;
};

/**
 * Removes an item from the cache for the given type
 *
 * @param {string} id
 * @param {string} type
 * @public
 * @static
 */
const RemoveFrom = (id, type) => {
  if(_cache[type].list[id]) {
    _cache[type].list[id] = undefined;
    Reflect.deleteProperty(_cache[type].list, id);
  }
};

/**
 * Updates an item in the cache for the given type
 *
 * @param {string} id
 * @param {Object} item
 * @param {Object} options
 * @param {string} type
 * @param {Object} ChildClass
 * @return {Object}
 * @public
 * @static
 */
const Update = (id, item, options, type, ChildClass) => {
  if(_cache[type].list[id]) {
    Object.assign(_cache[type].list[id], item);
  } else {
    _cache[type].list[id] = new ChildClass(item);
  }
  if(options && options.populate && options.populate.length) {
    _cache[type].list[id]._populate(_cache[type].list[id], options.populate);
  }
  _cache[type].lastUpdate = Date.now();
  return;
};

/**
 * Updates part of the cache for the given type
 *
 * @param {Array <Resource>} list
 * @param {Object} options
 * @param {string} type
 * @param {Object} ChildClass
 * @public
 * @static
 */
const UpdateArray = (list, options, type, ChildClass) => {
  forEach(list, item => Update(item._id, item, options, type, ChildClass));
};

/**
 * Removes all items that are not in the given array, and update the rest
 *
 * @param {Array <Resource>} list
 * @param {Object} options
 * @param {string} type
 * @param {Object} ChildClass
 * @public
 * @static
 */
const Sync = (list, options, type, ChildClass) => {
  const idsInCache = Object.keys(_cache[type].list);
  const idsInList = map(list, item => item._id);
  forEach(idsInCache, id => {
    if(idsInList.indexOf(id) < 0) {
      RemoveFrom(id, type);
    }
  });
  UpdateArray(list, options, type, ChildClass);
};

/**
 * Gets a cached search for the given type
 *
 * @param {string} url
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const GetSearch = (url, type) => {
  if(!_cache[type].search[url]) {
    _cache[type].search[url] = {};
  }
  return _cache[type].search[url];
};

/**
 * checks for a given cache exists
 *
 * @param {string} url
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const SearchExists = (url, type) => {
  return !!_cache[type].search[url];
};



/**
 * Updates a cached search for the given type
 *
 * @param {string} url
 * @param {Array <Resource>} list
 * @param {Object} options
 * @param {string} type
 * @param {Object} ChildClass
 * @public
 * @static
 */
const UpdateSearch = (url, list, options, type, ChildClass) => {
  UpdateArray(list, options, type, ChildClass);
  forEach(list, item => {
    _cache[type].search[url][item._id] = _cache[type].list[item._id];
  });
};

/**
 * Removes a cached search for the given type
 *
 * @param {string} url
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const ClearSearch = (url, type) => {
  if(_cache[type].search[url]) {
    Reflect.deleteProperty(_cache[type].search, url);
  }
};

/**
 * Removes the data of a given cache
 *
 * @param {string} url
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const ClearData = (url, type) => {
  if(_cache[type].search[url]) {
    forEach(_cache[type].search[url], item => {
      Reflect.deleteProperty(_cache[type].search[url], item._id);
    });
  }
};

/**
 * Creates an empty item in the cache for the given type
 *
 * @param {string} id
 * @param {string} type
 * @param {Object} ChildClass
 * @return {Resource}
 * @public
 * @static
 */
const Reserve = (id, type, ChildClass) => {
  if(!_cache[type].list[id]) {
    _cache[type].list[id] = new ChildClass({_id: id});
  }
  return _cache[type].list[id];
};

/**
 * Gets one item from the cache by id
 *
 * @param {string} id
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const GetByIdFrom = (id, type) => _cache[type].list[id];

/**
 * Gets all items from the cache for the given type
 *
 * @param {string} type
 * @param {<Array <Resource>>}
 * @public
 * @static
 */
const GetAllFrom = type => _cache[type].list;

/**
 * Gets a list of items from the cache for the given type
 *
 * @param {string} prop
 * @param {string} value
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const GetFrom = (prop, value, type) => {
  // Devuelve el objeto o undefined.
  const list = filter(_cache[type].list, item => item[prop] === value);
  return list;
};

/**
 * Gets one item from the cache for the given type
 *
 * @param {string} prop
 * @param {string} value
 * @param {string} type
 * @param {<Object <Resource>>}
 * @public
 * @static
 */
const GetOneFrom = (prop, value, type) => {
  const matches = GetFrom(prop, value, type);
  // Returns the first item found, or undefined
  return isEmpty(matches) ? undefined : matches[0];
};


export default {
  Restart,
  Init,
  Truncate,
  Invalidate,
  IsValid,
  Update,
  UpdateArray,
  Sync,
  GetSearch,
  UpdateSearch,
  ClearSearch,
  ClearData,
  Reserve,
  RemoveFrom,
  GetByIdFrom,
  GetAllFrom,
  GetFrom,
  GetOneFrom,
  SearchExists
};
