import angular from 'angular';
import moment from 'moment-timezone';

import 'moment/locale/es';
import 'moment/locale/pt';

const module = angular.module('whyline.filters.formatDate', []);

const filter = () => (date, timezone) => moment(date).tz(timezone).format('LLLL')

module.exports = module.filter('formatDate', filter);
