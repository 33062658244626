import angular from 'angular';
import {
  localStorage
} from '../utils';

const module = angular.module('whyline.controllers.v1tov2', [
  'whyline.services.user'
]);

const V1toV2Controller = (UserService, API_URL, $scope, $http, $timeout, $location, $state, SAFE_STATES) => {
  'ngInject';
  const action = $location.search().action;
  const placeId = $location.search().placeId;
  const userId = $location.search().userId;
  const resourceId = $location.search().resourceId;
  const hash = $location.search().hash;

  const url = `${API_URL}v1tov2/login-v1-to-v2`;
  $http.post(url, {
    action,
    placeId,
    userId,
    hash
  }).then(response => {
    if (response.data.token && response.data.token.length) {
      localStorage.set('token', response.data.token);
    }
    if (response.data.user && response.data.user._id) {
      UserService.SetCurrent(response.data.user);
      localStorage.set('user', response.data.user);
    }

    switch (action) {
      case 'now-serving':
        if (response.data.user.permissions.find(p => p.role.name === 'Base Receptionist')) {
          return $state.go(SAFE_STATES.receptionWelcome, { placeId: response.data.place._id, organizationId: response.data.place.organizationId });
        }
        $state.go(SAFE_STATES.nowServing, { placeId: response.data.place._id, organizationId: response.data.place.organizationId });
        break;
      case 'totem':
        $state.go(SAFE_STATES.totemTvWelcome, { placeId: response.data.place._id, organizationId: response.data.place.organizationId, totemId: resourceId });
        break;
      case 'display':
        $state.go(SAFE_STATES.displayTv, { placeId: response.data.place._id, organizationId: response.data.place.organizationId, displayId: resourceId });
        break;
      case 'reception':
        $state.go(SAFE_STATES.receptionWelcome, { placeId: response.data.place._id, organizationId: response.data.place.organizationId });
        break;
      default:
        $state.go(SAFE_STATES.organizationsList);
    }
  });
};

module.exports = module.controller('V1toV2Controller', V1toV2Controller);
