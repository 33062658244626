import angular from 'angular';
import {
  forEach,
  includes,
} from 'lodash';
import '../services/organization-user';
import '../services/globals';
import '../services/role';
import '../services/errors';
import '../services/resource-group';
import '../services/user';
import './modals/organization-users';
import './modals/organization-user-delete';

const module = angular.module('whyline.controllers.organization-users', [
  'whyline.services.organizationuser',
  'whyline.services.globals',
  'whyline.services.resource-group',
  'whyline.services.role',
  'whyline.services.errors',
  'whyline.services.user',
  'whyline.modals.organization-user',
  'whyline.modals.organization-user-delete',
]);

const OrganizationUsersController = ($translate, $scope, $uibModal, $window, UserService, OrganizationUserService, RoleService, GlobalsService, ResourceGroupService, ErrorService, dialog, $state, $timeout) => {
  'ngInject';
  $scope.title = 'Users';
  $scope.quantityOfItems = 8;
  $scope.organizationId = $state.params.organizationId;

  $scope.currentUser = UserService.GetCurrent();
  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';

  OrganizationUserService.GetAllAsPromise()
    .then(users => {
      forEach(users, user => {
        user.enabled = !includes(user.disabledForOrganizations, $scope.organizationId);
      });
      $scope.users = users;
    });

  $scope.switch = function (user) {
    OrganizationUserService.SwitchOrganizationEnable(user);
  };

  $scope.delete = function (user) {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-users-modal-delete.html',
      size: 'md',
      controller: 'OrganizationUserDeleteController',
      controllerAs: 'modal',
      resolve: {
        user: () => user,
        organizationId: () => $scope.organizationId,
      }
    });
  };

  // Modal
  $scope.open = function (userId) {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-users-modal.html',
      size: 'lg',
      controller: 'OrganizationUserModalController',
      controllerAs: 'modal',
      resolve: {
        userId: () => userId,
        organizationId: () => $scope.organizationId,
      }
    });
  };
};

module.exports = module.controller('OrganizationUsersController', OrganizationUsersController);
