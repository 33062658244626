import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.third-party-subscription-info', [
  'whyline.resources.resource',
]);

const ThirdPartySubscriptionInfoFactory = ($http, API_URL, Resource) => {
  /*@ngInject*/
  // API REST Resource Name
  const _type = 'third-party-subscription-info';
  const apiEndPoint = `${API_URL}${_type}`;

  const _schema = {
    firstName: String,
    lastName: String,
    id: String,
    idType: String,
    email: String,
    phone: String,
  };

  const ThirdPartySubscriptionInfo = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  ThirdPartySubscriptionInfo.prototype = new Resource(_type, _schema, ThirdPartySubscriptionInfo);

  Resource.Extend(_type, ThirdPartySubscriptionInfo);

  //one hour
  const validityTime = 60 * 60 * 1000;
  ThirdPartySubscriptionInfo.InitCache(validityTime);

  ThirdPartySubscriptionInfo.ValidatePerson = (identificatorType, identificator, organizationId) => {
    const url = `${apiEndPoint}/validatePerson?identificatorType=${identificatorType}&identificator=${identificator}&organizationId=${organizationId}`;
    return $http.get(url).then(response => response.data)
    .catch(e => {
      throw e;
    });
  };

  return ThirdPartySubscriptionInfo;
};

module.exports = module.factory('ThirdPartySubscriptionInfo', ThirdPartySubscriptionInfoFactory);
