import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.appointment-reservation', [
  'ngFileUpload',
  'whyline.resources.resource',
]);

const AppointmentReservationFactory = (Resource, $http, $timeout, API_URL, Upload) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'appointment-reservation';
  const _schema = {
    _id: {
      type: String
    },
    person: {
      firstName: {
        type: String,
        lowercase: true
      },
      lastName: {
        type: String,
        lowercase: true
      },
      priority: {
        type: Object,
      },
      colorPriority: Number,
      phone: {
        countryCode: {
          type: Number,

        },
        full: {
          type: Number,
        }
      },
      idType: String,
      id: String,
      identifications: {
        dni: {
          value: String,
          type: {
            type: String,
          },
        },
        lc: {
          value: String,
          type: {
            type: String,
          },
        },
        le: {
          value: String,
          type: {
            type: String,
          },
        },
        ci: {
          value: String,
          type: {
            type: String,
          },
        },
        dni_ext: {
          value: String,
          type: {
            type: String,
          },
        },
        pasaporte: {
          value: String,
          type: {
            type: String,
          },
        },
      },
    },
    appointment: {
      time: String,
      date: String,
      ISODate: String,
    },
    sede: String,
    service: String,
    statusInfo: {
      type: Object,
      displayName: {
        type: String
      },
      reason: {
        type: String
      },
      status: {
        type: String
      }
    },
    notes: {
      label: {
        type: String,
      },
      text: {
        type: String,
      }
    }
  };

  const AppointmentReservation = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    if (this.hasOwnProperty('person') && this.person) {
      this.fullName = `${this.person.firstName} ${this.person.lastName}`;
    }
  };

  AppointmentReservation.createAppointmentReservation = (placeId, areaId, person, day, slot, notes, createdFrom) => {
    const url = `${API_URL}${_type}?placeId`;
    return $http.post(url, {
      placeId,
      areaId,
      date: new Date(day).setTime(new Date(slot).getTime()).toString(),
      person,
      notes,
      createdFrom,
      forceNoConfirmation: true
    });
  };

  return AppointmentReservation;
};

module.exports = module.factory('AppointmentReservation', AppointmentReservationFactory);
