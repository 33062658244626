/*eslint-disable */
module.exports = $translateProvider => {
  $translateProvider.registerAvailableLanguageKeys(['en', 'es', 'pt'], {
    'en_*': 'en',
    'es_*': 'es',
    'pt_*': 'pt',
  });
  
  $translateProvider.determinePreferredLanguage();
  $translateProvider.fallbackLanguage('en');
  $translateProvider.usePostCompiling(true);
  $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

  $translateProvider.useStaticFilesLoader({
    prefix: 'locale/',
    suffix: '.json'
  });
};
