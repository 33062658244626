import angular from 'angular';
import '../resources/resource';
import '../resources/place';

const module = angular.module('whyline.services.cache', [
  'whyline.resources.resource',
  'whyline.resources.place',
]);

let Resource;
let Place;

class CacheService {

  static $inject = ['Resource', 'Place'];

  constructor(injectedResource, injectedPlace) {
    Resource = injectedResource;
    Place = injectedPlace;
  }

  Restart() {
    return Resource.RestartCache();
  }

  RestartForPlace() {
    return Place.RestartCaches();
  }
}

module.exports = module.service('CacheService', CacheService);
