import angular from 'angular';
import { formatAwt } from '../utils';

const module = angular.module('whyline.filters.waitedTime', [
  'angularMoment',
]);

const filter = moment => (createdTime, calledTime) => {
  const startTime = moment(new Date(createdTime)).format();
  const endTime = moment(calledTime).format();
  const duration = moment.duration(moment(endTime).diff(startTime)).asMinutes();
  return formatAwt(duration);
};

module.exports = module.filter('waitedTime', filter);
