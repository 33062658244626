import angular from 'angular';
import './modals/organization-custom-role';
import '../components/status';
import '../services/organization-custom-roles';
import '../services/errors';
import '../services/notifications';
import '../services/globals';

const module = angular.module('whyline.controllers.organization-custom-role', [
  'whyline.components.status',
  'whyline.services.organization-custom-role',
  'whyline.services.errors',
  'whyline.services.notifications',
  'whyline.modals.organization-custom-role',
  'whyline.services.globals',
]);

const OrganizationCustomRoleController = ($scope, $window, $timeout, $translate, $uibModal, NotificationService, OrganizationCustomRoleService, ErrorService, dialog, currentOrganization) => {
  /*@ngInject*/

  // View init
  $scope.organizationRoles = OrganizationCustomRoleService.GetAll();

  // Remove
  $scope.remove = (organizationCustomRoleId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('role_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        OrganizationCustomRoleService.Remove(organizationCustomRoleId)
          .then(() => {
            NotificationService.Success($translate.instant('custom_role_remove_succ'));
            $scope.organizationRoles = OrganizationCustomRoleService.GetAll();
          })
          .catch(err => {
            $scope.organizationRoles = OrganizationCustomRoleService.GetAllFromServer();
            ErrorService.handler(err);
          });
      });
  };

  // Modal
  $scope.open = organizationCustomRoleId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-custom-role-modal.html',
      size: 'lg',
      controller: 'OrganizationCustomRoleModalController',
      resolve: {
        organizationCustomRoleId: () => organizationCustomRoleId,
        currentOrganization,
        rolesCreated: () => $scope.organizationRoles,
      }
    });
  };
};

module.exports = module.controller('OrganizationCustomRoleController', OrganizationCustomRoleController);
