import angular from 'angular';
import 'angular-socket-io';

const module = angular.module('whyline.services.socket', [
    'btford.socket-io',
]);

let socketFactory;
let $timeout;
let $rootScope;
let socketInstance;
let socketDisplayInstance;
let socketTotemInstance;

class SocketService {

  static $inject = ['$timeout', 'socketFactory', '$rootScope'];

  constructor(injected$timeout, injectedsocketFactory, injected$rootScope) {
    $timeout = injected$timeout;
    socketFactory = injectedsocketFactory;
    $rootScope = injected$rootScope;
  }

  GetInstance() {
    return Promise.resolve(socketInstance);
  }

  ResetInstance() {
    socketInstance = null;
  }

  GetDisplayInstance() {
   return socketDisplayInstance;
  }

  GetTotemInstance() {
    return socketTotemInstance;
   }

  SetInstance(socket) {
    socketInstance = socket;

    if (socket) {
      $rootScope.$emit('socketReady', socket);
    }
  }

  SetDisplayInstance(socket) {
    socketDisplayInstance = socket;
  }

  SetTotemInstance(socket) {
    socketTotemInstance = socket;
  }
}

module.exports = module.service('SocketService', SocketService);
