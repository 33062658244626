import angular from 'angular';
import './resource';
import '../app.constants';

const module = angular.module('whyline.resources.organization-custom-role', [
  'whyline.constants',
  'whyline.resources.resource',
]);

const OrganizationCustomRoleFactory = Resource => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'organization/:organizationId/custom-role';

  const _schema = {
    _id: {
      type: String
    },
    organizationId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    actions: Array,
    enabled: {
      type: Boolean,
      default: true
    }
  };

  const OrganizationCustomRole = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  OrganizationCustomRole.prototype = new Resource(_type, _schema, OrganizationCustomRole);

  Resource.Extend(_type, OrganizationCustomRole);

  //five minutes
  const validityTime = 5 * 60 * 1000;
  OrganizationCustomRole.InitCache(validityTime);

  return OrganizationCustomRole;
};

module.exports = module.factory('OrganizationCustomRole', OrganizationCustomRoleFactory);
