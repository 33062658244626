import angular from 'angular';
import './app.api_url';
import './app.intercom_id';

const module = angular.module('whyline.constants', [
  'whyline.constants.api_url',
  'whyline.constants.intercom_id',
]);

// The user is saved in localStorage for one hour, and expires
module.constant('USER_CACHE_VALIDITY', 5 * 60 * 1000);

module.constant('ROLLBAR_ID', '97ae4ff8947f47bb9fbe002085fcfdeb');

module.constant('G_PLACES_KEY', 'AIzaSyDiferjg7-p38VSdD7N1bw7IPvXoEKxGfI');

module.constant('PUBLIC_KEY', '6Lchkc0ZAAAAAAbHgKelY17LOeao833QiLAeywvO');

// Use this constant to redirect
// $state.go(SAFE_STATES.prop)
module.constant('SAFE_STATES', {
  //
  // Outside app
    signIn: 'sign-in',
    recoverPassword: 'recover-password',
    resetPassword: 'reset-password',
    verifyAccount: 'verify-account',
    displayTv: 'display-tv',

    // TotemTV
    totemTvWelcome: 'totem-tv.welcome',
    totemTvStep: 'totem-tv.step',
    totemTvStatus: 'totem-tv.status',
    totemTvUser: 'totem-tv.user',
    totemTvThanks: 'totem-tv.thanks',
    // TotemTV
    totemNewTvWelcome: 'totem-new-tv.welcome',
    totemNewTvStep: 'totem-new-tv.step',
    totemNewTvStatus: 'totem-new-tv.status',
    totemNewTvUser: 'totem-new-tv.user',
    totemNewTvThanks: 'totem-new-tv.thanks',
  //
  // Inside app
    places: 'app.places',
    placesEdit: 'app.places.edit',
    placesList: 'app.places.list',
    organizations: 'app.organizations',
    organizationsList: 'app.organizations.list',
    organizationsEdit: 'app.organizations.edit',
    organizationAreas: 'app.organization-areas',
    organizationAreasEdit: 'app.organization-areas.edit',
    organizationCustomRole: 'app.organization-custom-role',
    organizationLines: 'app.organization-lines',
    organizationLinesEdit: 'app.organization-lines.edit',
    organizationsCreate: 'app.organizations.create',
    organizationUsers: 'app.organization-users',
    organizationUsersEdit: 'app.organization-users.edit',
    organizationCallLogics: 'app.call-logics',
    nowServing: 'app.now-serving',
    nowServingQueue: 'app.now-serving.queue',
    lines: 'app.lines',
    areas: 'app.areas',
    schemas: 'app.schemas',
    sectors: 'app.sectors',
    totems: 'app.totems',
    devices: 'app.devices',
    displays: 'app.displays',
    users: 'app.users',
    roles: 'app.roles',
    resourceGroups: 'app.resource-groups',
    stats: 'app.statistics',
    appointments: 'app.appointments',
    qrGenerator: 'app.qr-generator',
    videos: 'app.videos',
    schedule: 'app.schedule',
    scheduleManagement: 'app.schedule.management',
    scheduleAppointmentFilters: 'app.schedule.search.filters',
    scheduleAppointmentResults: 'app.schedule.search.appointment-results',
    scheduleAppointmentConfirm: 'app.schedule.search.appointment-confirm',
    scheduleHolidays: 'app.schedule.holidays',
    placeSectors: 'app.placeSectors',
    placeCallLogics: 'app.place-call-logics',
    boxes: 'app.boxes',
    banners: 'app.banners',
    organizationConditions: 'app.organization-conditions',
    organizationScenarios: 'app.organization-scenarios',
    scenarios: 'app.scenarios',
    // Reception
    receptionWelcome: 'app.reception.welcome',
    receptionAppointmentSearch: 'app.reception.appointment',
    receptionAppointmentResults: 'app.reception.appointment-results',
    receptionAppointmentConfirm: 'app.reception.appointment-confirm',
    totemTvAppointmentSearch: 'totem-tv.appointment',
    totemTvAppointmentResults: 'totem-tv.appointment-results',
    totemTvAppointmentConfirm: 'totem-tv.appointment-confirm',
    receptionStep: 'app.reception.step',
    receptionStatus: 'app.reception.status',
    receptionUser: 'app.reception.user',
});

module.constant('UserActions', {
  appointment: {
    create: 'appointment.create',
    delete: 'appointment.delete',
    forward: 'appointment.forward',
    read: 'appointment.read',
    update: 'appointment.update',
    uploadCSV: 'appointment.upload-csv',
  },
  area: {
    callNext: 'area.call-next',
    callTo: 'area.call-to',
    create: 'area.create',
    crud: 'area.crud',
    delete: 'area.delete',
    forward: 'area.forward',
    join: 'area.join',
    read: 'area.read',
    removeSubscription: 'area.remove-subscription',
    removeSubscriptionNotCheckedIn: 'area.remove-subscription-not-checked-in',
    update: 'area.update',
    enabled: 'area.enabled',
  },
  banner: {
    create: 'banner.create',
    delete: 'banner.delete',
    getAll: 'banner.get-all',
    read: 'banner.read',
    update: 'banner.update',
  },
  box: {
    create: 'box.create',
    update: 'box.update',
    delete: 'box.delete',
  },
  display: {
    create: 'display.create',
    crud: 'display.crud',
    delete: 'display.delete',
    read: 'display.read',
    update: 'display.update',
  },
  line: {
    callNext: 'line.call-next',
    callTo: 'line.call-to',
    clear: 'line.clear',
    create: 'line.create',
    crud: 'line.crud',
    delete: 'line.delete',
    forwad: 'line.forward',
    join: 'line.join',
    read: 'line.read',
    removeSubscription: 'line.remove-subscription',
    removeSubscriptionNotCheckedIn: 'line.remove-subscription-not-checked-in',
    update: 'line.update',
  },
  lineFlowTypeOrganization: {
    create: 'line-flow-type-organization.create',
    delete: 'line-flow-type-organization.delete',
    read: 'line-flow-type-organization.read',
    update: 'line-flow-type-organization.update',
  },
  organizationConditions: {
    create: 'organization-conditions.create',
    delete: 'organization-conditions.delete',
    getAll: 'organization-conditions.get-all',
    read: 'organization-conditions.read',
    update: 'organization-conditions.update',
  },
  organizationScenarios: {
    create: 'organization-scenarios.create',
    delete: 'organization-scenarios.delete',
    getAll: 'organization-scenarios.get-all',
    read: 'organization-scenarios.read',
    update: 'organization-scenarios.update',
  },
  place: {
    create: 'place.create',
    crud: 'place.crud',
    delete: 'place.delete',
    read: 'place.read',
    update: 'place.update',
    checkInAppointment: 'place.checkInAppointment',
  },
  resourceGroup: {
    create: 'resourceGroup.create',
    crud: 'resourceGroup.crud',
    delete: 'resourceGroup.delete',
    read: 'resourceGroup.read',
    update: 'resourceGroup.update',
  },
  role: {
    create: 'role.create',
    crud: 'role.crud',
    delete: 'role.delete',
    read: 'role.read',
    update: 'role.update',
  },
  section: {
    areas: 'section.areas',
    banners: 'section.banners',
    boxes: 'section.boxes',
    callLogics: 'section.call-logics',
    displays: 'section.displays',
    holiday: 'section.holiday',
    lines: 'section.lines',
    nowServing: 'section.now-serving',
    organizationConditions: 'section.organization-conditions',
    organizationScenarios: 'section.organization-scenarios',
    scenarios: 'section.scenarios',
    places: 'section.places',
    reception: 'section.reception',
    resourceGroups: 'section.resourceGroups',
    roles: 'section.roles',
    sectors: 'section.sectors',
    sigeciCSV: 'section.sigeci-csv',
    statistics: 'section.statistics',
    totems: 'section.totems',
    devices: 'section.devices',
    users: 'section.users',
  },
  sector: {
    create: 'sector.create',
    crud: 'sector.crud',
    delete: 'sector.delete',
    read: 'sector.read',
    update: 'sector.update',
  },
  statistic: {
    read: 'statistic.read',
  },
  subscription: {
    callTo: 'subscription.call-to',
    confirm: 'subscription.confirm',
    create: 'subscription.create',
    delete: 'subscription.delete',
    deleteNotCheckedIn: 'subscription.delete-not-checked-in',
    forward: 'subscription.forward',
    read: 'subscription.read',
    update: 'subscription.update',
  },
  totem: {
    create: 'totem.create',
    crud: 'totem.crud',
    delete: 'totem.delete',
    read: 'totem.read',
    update: 'totem.update',
  },
  device: {
    getLinked: 'device.get-linked',
    getUnlinked: 'device.get-unlinked',
    getById: 'device.get-by-id',
    linkToPlace: 'device.link-to-place',
    unlinkFromPlace: 'device.unlink-from-place',
    rename: 'device.rename',
  },
  user: {
    create: 'user.create',
    crud: 'user.crud',
    delete: 'user.delete',
    read: 'user.read',
    update: 'user.update',
  },
});

module.exports = module;
