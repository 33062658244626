import angular from 'angular';
import { formatAwt } from '../utils';
import { cloneDeep } from 'lodash';
import '../resources/area';
import '../resources/line';
import '../resources/user';
import '../resources/subscription';

const module = angular.module('whyline.services.area', [
  'whyline.resources.area',
  'whyline.resources.line',
  'whyline.resources.user',
  'whyline.resources.subscription',
]);

let Area;
let Line;
let User;
let Subscription;
let $timeout;

const handleError = err => {
  throw err;
};

class AreaService {

  static $inject = ['Area', 'Line', 'User', 'Subscription', '$timeout'];

  constructor(injectedArea, injectedLine, injectedUser, injectedSubscription, injected$timeout) {
    Area = injectedArea;
    Line = injectedLine;
    User = injectedUser;
    Subscription = injectedSubscription;
    $timeout = injected$timeout;
  }

  GetAll() {
    return Area.FetchAll();
  }

  GetAllPopulated() {
    return Area.FetchAll({populate: ['lines']});
  }

  Copy(area) {
    const copy = cloneDeep(area);
    return new Area(copy);
  }

  CallTo(subscriptionId, areaId) {
    return Area.CallTo(subscriptionId, areaId);
  }

  CallNext(areaId) {
    return Area.CallNext(areaId);
  }

  Update(area) {
    return Area.Save(area);
  }

  Create(data) {
    const newArea = new Area(data);
    return Area.Save(newArea);
  }

  Remove(areaId) {
    const area = Area.FindById(areaId);
    return Area.Remove(area);
  }

  GetMyAreasAsPromise() {
    return Area.GetMyAreas({ promise: true });
  }

  GetMyAreasAsPromiseFromServer() {
    return Area.GetMyAreas({ promise: true, force: true });
  }

  GetAllAsPromise() {
    return Area.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return Area.FetchAll({ promise: true, force: true });
  }

  GetCurrentWithLines(areaId) {
    return User.FetchAll({ promise: true })
    .then(() => Line.FetchAll({ promise: true }))
    .then(() => Area.FindById(areaId, {
    populate: ['lines', 'linesDisabled', 'supervisors'],
      promise: true,
      force: true
    }))
    .catch(handleError);
  }

  GetWithLinesAsPromise(areaId) {
    return Area.FindById(areaId, { promise: true, populate: ['lines'] })
    .catch(handleError);
  }

  GetWithLinesAsPromiseFromServer(areaId) {
    return Area.FindById(areaId, { promise: true, force: true, populate: ['lines'] })
    .catch(handleError);
  }

  FindByIds(ids, options) {
    return Area.FindByIds(ids, options);
  }

  Switch(area) {
    return area.enable(!area.open);
  }

  GetOne(areaId) {
    return Area.FindById(areaId);
  }

  GetOneAsPromise(areaId) {
    return Area.FindById(areaId, {promise: true});
  }

  GetOneFromServer(areaId) {
    return Area.FindById(areaId, {force: true});
  }

  GetOneWithLines(areaId) {
    return Area.FindById(areaId, {populate: ['lines'] });
  }

  GetOneWithLinesAsPromise(areaId) {
    return Area.FindById(areaId, {populate: ['lines'], promise: true });
  }

  GetOneWithLinesAsPromiseFromServer(areaId) {
    return Area.FindById(areaId, {populate: ['lines'], promise: true, force: true });
  }

  GetLinesOfArea(areaId) {
    return Area.GetLines(areaId);
  }

  GetSlotsByHour(params) {
    return Area.GetSlotsByHour(params);
  }
}

module.exports = module.service('AreaService', AreaService);
