import angular from 'angular';

module.exports = toastrConfig => {
 angular.extend(toastrConfig, {
    allowHtml: true,
    closeButton: true,
    extendedTimeOut: 1000,
    progressBar: true,
    tapToDismiss: true,
    timeOut: 6000,
    preventDuplicates: false,
    positionClass: 'toast-bottom-full-width',
    maxOpened: 3,
    preventOpenDuplicates: true
  });
};
