import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.resource-group', [
  'whyline.resources.resource',
]);

const ResourceGroupFactory = (Resource) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'resource-group?placeId';

  const _schema = {
    _id: {
      type: String
    },
    name: {
      type: String
    },
    placeId: {
     type: String
    },
    organizationId: {
     type: String
    },
    resources: {
      type: [{
        resourceId: String,
        resourceType: String,
      }]
    },
  };

  const ResourceGroup = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  ResourceGroup.prototype = new Resource(_type, _schema, ResourceGroup);

  Resource.Extend(_type, ResourceGroup);

  //one minute
  const validityTime = 5 * 60 * 1000;
  ResourceGroup.InitCache(validityTime);

  return ResourceGroup;
};

module.exports = module.factory('ResourceGroup', ResourceGroupFactory);
