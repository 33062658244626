import angular from 'angular';
import '../../resources/resource';

const module = angular.module('whyline.controllers.organizations', [
  'whyline.resources.resource',
]);

const OrganizationsController = ($scope, Resource) => {
  /*@ngInject*/

  Resource.RestartCache();
};

module.exports = module.controller('OrganizationsController', OrganizationsController);
