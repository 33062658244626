import angular from 'angular';
import '../resources/country';

class CountryService {
  static $inject = ['Country'];

  constructor(CountryResource) {
    this.countryResource = CountryResource;
  }

  Create(data) {
    const country = new this.countryResource(data);
    return this.countryResource.Save(country);
  }

  FindByCode(code) {
    return this.countryResource.FindOneBy('code', `${code}`.toLowerCase(), { promise: true });
  }

}

export default angular
  .module('whyline.services.country', ['whyline.resources.country'])
  .service('CountryService', CountryService);
