import angular from 'angular';
import { cloneDeep, includes, get, trim, set } from 'lodash';
import '../resources/subscription';
import '../app.constants';
import '../resources/area';
import '../services/line';
import '../services/user';

const module = angular.module('whyline.services.subscription', [
  'whyline.constants',
  'whyline.resources.subscription',
  'whyline.resources.area',
  'whyline.services.place',
  'whyline.services.line',
  'whyline.services.user',
]);

let Subscription;
let AreaService;
let PlaceService;
let LineService;
let UserService;
let $timeout;
let $state;
let API_URL;
let SAFE_STATES;

/**
 * This is a function to keep updated
 * the cache search of each queue by the
 * lineId or areaId
 *
 * @param {Queue} item
 * @param {Subscription} item
 *
 */
const updateCacheSearch = (queue, subscription, blockUI, updateCache = false) => {
  const areaId = get($state.params, 'areaId', null);
  const lineId = get($state.params, 'lineId', null);
  const placeId = get($state.params, 'placeId', null);

  if (
    (subscription.hasOwnProperty('areaId') && subscription.areaId === areaId) ||
    subscription.lineId === lineId
  ) {
    let queuesIds;
    UserService.GetAllowedQueuesIds(placeId).then(ids => {
      queuesIds = ids;
      
      // Update cache of Subscriptions
      if (updateCache) {
        Subscription.UpdateCache(subscription._id, subscription);
      }

      // There are two cases when you have to update a line cache search,
      // that is the reason of this code block
      const updateLine = (lineId) =>
        LineService.GetOneAsPromise(lineId).then((line) =>
          $timeout(() => {
            const url = `${API_URL}subscription?enqueueIn=line&lineId=${lineId}`;
            const list = Subscription.GetAllByQueue(line, {}, blockUI);
            list[subscription._id] = subscription;
            return Subscription.UpdateCacheSearch(url, list);
          }),
        );

      // If the queue is a line, execute the update of the LineCacheSearch
      if (
        (queue.realType === 'line' || queue.realType === 'supervisor') &&
        includes(queuesIds, queue._id)
      ) {
        return updateLine(queue._id);
      }

      // If the queue is a group, execute the update of the GroupCacheSearch
      if (queue.realType === 'group') {
        const areaId = queue._id;
        return AreaService.GetOneAsPromise(areaId).then((area) =>
          $timeout(() => {
            const url = `${API_URL}subscription?enqueueIn=group&areaId=${areaId}`;
            const list = Subscription.GetAllByQueue(area, {}, blockUI);
            list[subscription._id] = subscription;
            return Subscription.UpdateCacheSearch(url, list);
          }),
        );
      }
      // If the queue is a process, we have to update the LineCacheSearch and the ProcessCacheSearch
      if (queue.realType === 'process' && includes(queuesIds, queue._id)) {
        const areaId = queue._id;
        return AreaService.GetOneAsPromise(areaId).then((area) =>
          $timeout(() => {
            const url = `${API_URL}subscription?enqueueIn=process&areaId=${areaId}`;
            const list = Subscription.GetAllByQueue(area, {}, blockUI);
            list[subscription._id] = subscription;
            Subscription.UpdateCacheSearch(url, list);
            return updateLine(subscription.lineId);
          }),
        );
      } else {
        updateLine(subscription.lineId);
      }

    })
    .catch(error => {
      console.error(error);
    });
  }
};

const handleError = (err) => {
  throw err;
};

class SubscriptionService {
  static $inject = [
    'Subscription',
    'API_URL',
    'AreaService',
    'LineService',
    '$timeout',
    'UserService',
    '$state',
    'SAFE_STATES',
    'PlaceService',
  ];

  constructor(
    injectedSubscription,
    injectedAPI_URL,
    injectedAreaService,
    injectedLineService,
    injected$timeout,
    injectedUserService,
    injected$state,
    injectedSAFE_STATES,
    injectedPlaceService,
  ) {
    Subscription = injectedSubscription;
    API_URL = injectedAPI_URL;
    SAFE_STATES = injectedSAFE_STATES;
    AreaService = injectedAreaService;
    LineService = injectedLineService;
    UserService = injectedUserService;
    PlaceService = injectedPlaceService;
    $timeout = injected$timeout;
    $state = injected$state;
    this.subscriptionToClone = null;
  }

  Create(person, enqueueIn, createdFrom, path = []) {
    let query = '';
    const { where, lineId, areaId, reason, motive } = enqueueIn;

    // Generate query
    switch (where) {
      // Enqueue to a line
      case 'line':
        query = `lineId=${lineId}`;
        break;
      // Enqueue to a process
      case 'process':
        query = `areaId=${areaId + (lineId ? `&lineId=${lineId}` : '')}`;
        break;
      // Enqueue to a group
      case 'group':
        query = `areaId=${areaId}&lineId=${lineId}`;
        break;
    }

    const additionalInfo = {
      reason,
      motive,
    };

    const item = new Subscription({
      person,
      path,
      additionalInfo,
      createdFrom,
    });

    return Subscription.Save(item, {
      query,
    })
      .then((subscription) => {
        if (areaId) {
          return AreaService.GetOneAsPromise(areaId).then((area) =>
            $timeout(() => updateCacheSearch(area, subscription)),
          );
        }
        if (lineId) {
          return LineService.GetOneAsPromise(lineId).then((line) =>
            $timeout(() => updateCacheSearch(line, subscription)),
          );
        }
      })
      .catch(handleError);
  }

  Copy(subscription) {
    const copy = cloneDeep(subscription);
    return new Subscription(copy);
  }

  ChangePreference(id, preference) {
    return Subscription.ChangePreference(id, preference);
  }

  Update(subscription) {
    return Subscription.Save(subscription);
  }

  Remove(subscriptionId, queue) {
    return Subscription.Remove(subscriptionId, queue);
  }

  Switch() {
    //console.log('TODO: implement subscription switch');
  }

  GetAll() {
    return Subscription.FetchAll();
  }

  GetAllAsPromise() {
    return Subscription.FetchAll({
      promise: true,
    });
  }

  GetAllAsPromiseFromServer() {
    return Subscription.FetchAll({
      promise: true,
      force: true,
    });
  }

  GetAllByQueue(queue, options) {
    return Subscription.GetAllByQueue(queue, options);
  }

  GetOne(subscriptionId) {
    return Subscription.FindById(subscriptionId);
  }

  GetOneByAppointmentId(appointmentId) {
    return Subscription.GetOneByAppointmentId(appointmentId);
  }

  GetOneAsPromise(subscriptionId) {
    return Subscription.FindById(subscriptionId, {
      promise: true,
    });
  }

  GetOneFromServer(subscriptionId) {
    return Subscription.FindById(subscriptionId, { force: true });
  }

  GetOneAsPromiseFromServer(subscriptionId) {
    return Subscription.FindById(subscriptionId, {
      promise: true,
      force: true,
    });
  }

  getShowName(item, customFields) {
    // eslint-disable-next-line prefer-destructuring
    // Alias computed to avoid alias recalculation
    const data = cloneDeep(item);
    const personCustomField = get(item, 'person.customFields', null);
    let fields = [];
    if (customFields && personCustomField) {
      customFields.forEach((field) => {
        const fieldValue = personCustomField[field.name];
        if (field.type === 'boolean' && fieldValue) {
          fields.push(field.label);
        } else if (
          field.type === 'text' ||
          field.type === 'number' ||
          field.type === 'radioButton'
        ) {
          fields.push(fieldValue);
        }
      });
    }
    let fieldString = '';
    if (fields.length) {
      for (let i = 0; i < fields.length; i++) {
        fieldString = i === 0 ? fields[i] : `${fieldString} - ${fields[i]}`;
      }
    }
    if (data.aliasComputed) {
      return data.aliasComputed;
    }

    let firstName = get(data, 'person.firstName', '');
    let lastName = get(data, 'person.lastName', '');

    // Alias calculation
    let showName;
    let place = PlaceService.GetCurrent();
    const aliasType = get(place, 'configuration.aliasType', null);
    firstName = trim(firstName);
    lastName = trim(lastName);
    const alias = get(data, 'alias', []);
    if (data.type === 'appointment') {
      return `${firstName} ${lastName} (${fieldString})`;
    }

    if (aliasType === 'fullNameAlias') {
      showName = `${firstName} ${lastName} (${fieldString})`;
    } else if (firstName && lastName) {
      showName = `${alias.join(' ')} (${firstName} ${lastName} - ${fieldString})`;
    } else if (firstName && !lastName) {
      showName = `${alias.join(' ')} (${firstName} - ${fieldString})`;
    } else if (!firstName && lastName) {
      showName = `${alias.join(' ')} (${lastName} - ${fieldString})`;
    } else {
      showName = alias.join(' ');
    }

    set(item, 'aliasComputed', showName);
    return showName;
  }

  Forward(subscriptionId, queue, destination, areaId) {
    return Subscription.Forward(subscriptionId, queue, destination, areaId).then((data) => {
      const { originalSubscription, newSubscription } = data;
      Subscription.UpdateCache(originalSubscription._id, originalSubscription);
      Subscription.UpdateCache(newSubscription._id, newSubscription);
      // To supervisor action
      if (!newSubscription.areaId) {
        return LineService.GetOneAsPromise(newSubscription.lineId).then((line) =>
          $timeout(() => updateCacheSearch(line, newSubscription)),
        );
      }
      // From supervisor action
      if (newSubscription.areaId) {
        return AreaService.GetOneAsPromise(newSubscription.areaId).then((area) =>
          $timeout(() => updateCacheSearch(area, newSubscription)),
        );
      }
    });
  }

  ForwardTo(subscriptionId, serviceId, serviceType, forwardFrom, forwardReasons, preference) {
    return Subscription.ForwardTo(
      subscriptionId,
      serviceId,
      serviceType,
      forwardFrom,
      forwardReasons,
      preference,
    ).then((data) => {
      const { originalSubscription, newSubscription } = data;
      Subscription.UpdateCache(originalSubscription._id, originalSubscription);
      Subscription.UpdateCache(newSubscription._id, newSubscription);
      // To supervisor action
      if (!newSubscription.areaId) {
        return LineService.GetOneAsPromise(newSubscription.lineId).then((line) =>
          $timeout(() => updateCacheSearch(line, newSubscription)),
        );
      }
      // From supervisor action
      if (newSubscription.areaId) {
        return AreaService.GetOneAsPromise(newSubscription.areaId).then((area) =>
          $timeout(() => updateCacheSearch(area, newSubscription)),
        );
      }
    });
  }

  UndoForwardTo(subscriptionId, newSubscriptionId) {
    return Subscription.UndoForwardTo(
      subscriptionId,
      newSubscriptionId,
    ).then((data) => {
      const { originalSubscription, newSubscription } = data;
      Subscription.UpdateCache(originalSubscription._id, originalSubscription);
      Subscription.UpdateCache(newSubscription._id, newSubscription);
      return data;
    });
  }

  EndSubscription(subscription, status, reason) {
    return Subscription.EndSubscription(subscription, status, reason).then((res) => {
      const { nextSubscription } = res;
      if (nextSubscription) {
        AreaService.GetOneAsPromise(nextSubscription.areaId).then((area) => {
          updateCacheSearch(area, nextSubscription);
        });
      }
    });
  }

  UpdateSubscriptionsCacheSearch = updateCacheSearch;

  Stats(placeId, linesIds, from, to, status, priorityOnly = false) {
    return Subscription.Stats(placeId, linesIds, from, to, status, priorityOnly);
  }

  StatsProcess(areas, from, to, priorityOnly = false) {
    return Subscription.StatsProcess(areas, from, to, priorityOnly);
  }

  SchemaStats(schemaId, from, to, priorityOnly = false) {
    return Subscription.SchemaStats(schemaId, from, to, priorityOnly);
  }

  Rejoin(subscription) {
    $state.go(SAFE_STATES.receptionStep, { n: 0, rejoining: true });
    this.subscriptionToClone = subscription;
  }

  Requeue(subscription) {
    return Subscription.Requeue(subscription);
  }

  Retain(subscriptionId, queueId, reason) {
    return Subscription.Retain(subscriptionId, queueId, reason);
  }

  Confirm(subscription) {
    return Subscription.Confirm(subscription);
  }

  SigehosSubscriptions(personId, date, place, includeSubscriptionsPresents = false) {
    return Subscription.SigehosSubscriptions(personId, date, place, includeSubscriptionsPresents);
  }

  CreateFromLoadSubscriptions(payload, organizationId, placeId, totemId, includeSubscriptionsPresents = false) {
    return Subscription.CreateFromLoadSubscriptions(payload, organizationId, placeId, totemId, includeSubscriptionsPresents);
  }

  SigehosPeople(typeId, id, placeId, organizationId, totemId = '') {
    return Subscription.SigehosPeople(typeId, id, placeId, organizationId, totemId);
  }
}

module.exports = module.service('SubscriptionService', SubscriptionService);
