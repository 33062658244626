import angular from 'angular';
import {
  forEach
} from 'lodash';
import './status';
import '../services/line';
import '../services/area';
const module = angular.module('whyline.components.permission', [
  'whyline.components.status',
  'whyline.services.line',
  'whyline.services.area'
]);

const permission = {
  bindings: {
    permission: '=',
    resourceGroup: '<',
    globalPermission: '<',
    roles: '<',
    label: '=',
    onRemove: '&',
    currentRole: '<',
    boxLabelRequired: '<',
  },
  templateUrl: '/templates/components/permission.html',
  controller($scope, LineService, AreaService, $translate) {
    'ngInject';
    this.operatorBaseRole;
    // Init
    this.$onInit = () => {
      this.rolesForAreas = [];
      this.rolesForLines = [];

      this.permission.boxLabelEnabled = true;

      forEach(this.roles, role => {
        if(role.name === 'Operator Base' || role.name === 'Operator Full') {
          this.rolesForLines.push(role);
          this.rolesForAreas.push(role);
        }
        if(role.name === 'Supervisor') {
          this.rolesForAreas.push(role);
        }
        if(role.name === 'Operator Base') {
          this.operatorBaseRole = role;
        }
      });
      // Set operator base as role if there is no current role and global permission is operator
      if(this.resourceGroup.type !== 'place' && !this.currentRole && (this.globalPermission.name === 'operator' || this.globalPermission.name === 'Receptionist' || this.globalPermission.name === 'Base Receptionist')) {
        this.permission.role = this.operatorBaseRole;
      }

      this.permission.boxLabelEnabled = (!!this.permission.role && !((this.permission.role.name === 'Operator Base' || this.permission.role.name === 'Operator Full') && this.resourceGroup.type === 'area')
                                          || (this.globalPermission.name === 'Coordinator' || this.globalPermission.name === 'Admin General')) ;

      this.boxLabelPlaceholder = this.boxLabelRequired && this.permission.boxLabelEnabled? $translate.instant('box label placeholder') + ' *': $translate.instant('box label placeholder');

      this.permission.boxLabelVisible = this.resourceGroup.parent && this.resourceGroup.type !== 'place';
    };

    this.roleChanged = role => {
      this.permission.boxLabelEnabled = !(role.name === 'Operator Base' || role.name === 'Operator Full');
    };

    // On changes, update the data
    this.$onChanges = changes => {
      if(changes.resourceGroup && changes.resourceGroup.currentValue) {
        const { currentValue } = changes.resourceGroup;
        if(currentValue.parent) {
          // If resourceGroup is a line or area, do the next things
          if(currentValue.type === 'line') {
            this.queue = LineService.GetOne(currentValue.resources[0].resourceId || currentValue.resources[0]._id);
          } else if(currentValue.type === 'area') {
            this.queue = AreaService.GetOne(currentValue.resources[0].resourceId || currentValue.resources[0]._id);
          }
        }
      }
      if(changes.globalPermission && changes.globalPermission.currentValue) {
        if(!changes.globalPermission.isFirstChange() && (changes.globalPermission.currentValue.name === 'operator' || changes.globalPermission.currentValue.name === 'Receptionist' || changes.globalPermission.currentValue.name === 'Base Receptionist') && this.resourceGroup.type !== 'place') {
          this.permission.role = this.operatorBaseRole;
        }
        // Reset to this permission all selected resources
        if(changes.globalPermission.currentValue.name !== 'operator' && this.resourceGroup.type === 'place') {
          this.permission.role = changes.globalPermission.currentValue;
        }
      }

      this.permission.boxLabelEnabled = (!!this.permission.role && !((this.permission.role.name === 'Operator Base' || this.permission.role.name === 'Operator Full') && this.resourceGroup.type === 'area')
                                          || (this.globalPermission.name === 'Coordinator' || this.globalPermission.name === 'Admin General')) ;

      this.boxLabelPlaceholder = this.boxLabelRequired && this.permission.boxLabelEnabled? $translate.instant('box label placeholder') + ' *': $translate.instant('box label placeholder');
    };

    // Remove permission
    this.remove = permission => {
      this.onRemove(permission);
    };
  },
};

module.exports = module.component('permission', permission);
