import angular from 'angular';
import '../app.constants';
import './resource';

const module = angular.module('whyline.resources.organization-sector', [
  'whyline.resources.organization-sector',
]);

const OrganizationSectorFactory = ($timeout, $http, API_URL, Resource) => {
  /*@ngInject*/

  const _type = 'organization/:organizationId/sector';

  const _schema = {
    name: {
      lowercase: true,
      max: 128,
      required: true,
      type: String,
    },
    organizationId: {
      required: true,
      type: String,
    },
    root: {
      required: true,
      type: Boolean,
      default: false,
    },
    tree: [
      {
        _id: {
          required: true,
          type: String,
        },
        type: {
          required: true,
          type: String,
        },
      }
    ],
    type: {
      required: true,
      type: String,
    },
  };

  const OrganizationSector = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  OrganizationSector.prototype = new Resource(_type, _schema, OrganizationSector);

  Resource.Extend(_type, OrganizationSector);
  /**
   *
   * @param {sector} sector to be updated
   * @param {rootSectorId}  root sector id passed for creating subSector
   * @param {options} options for updating the cache
   */
  OrganizationSector.Save = (sector, rootSectorId, deletedId, options = {}) => {
    let method = 'post';
    // Creates endpoint url based in the instance type
    let url = API_URL + _type;
    // If the sector has a newName it means that the user is wanting to change it so we send it to the backend
    if (sector.editName && sector.newName) {
      sector.name = sector.newName;
    }
    // When renaming a subSector, adding or removing a node to a subSector use the sector rootSectorId
    if (!sector.root && !rootSectorId) {
      rootSectorId = sector.rootSectorId;
    }
    // Map properties to match BE DTO
    const { name, root, tree, type } = sector;
    const obj = { deletedId, name, root, tree, type }
    obj.tree = obj.tree.map(node => ({
      _id: node._id,
      type: node.type
    }));
    // If the instance has _id then it's an update
    if(sector._id) {
      method = 'put';
      url += `/${sector._id}`;
    }
    // Add url param when renaming a subSector, adding or removing a node to a subSector
    if(rootSectorId) {
      url += `?rootSectorId=${rootSectorId}`;
    }
    return $http[method](url, obj).then(
      response => {
        const newSector = response.data;
        let isNewSubSector = true;
        return $timeout(() => {
          // If it is a subSector get the parent
          if (!newSector.root) {
            const parentSector = OrganizationSector.GetByIdFromCache(rootSectorId, sector._type);
            parentSector.tree.forEach((subSector, idx) => {
              // If the subSector already existed update it
              if (newSector._id === subSector._id) {
                parentSector.tree[idx] = newSector;
                isNewSubSector = false;
              }
            });
            // If the subSector is new add it to the parent tree
            if (isNewSubSector) {
              parentSector.tree.push(newSector);
            }
            OrganizationSector.UpdateCache(rootSectorId, parentSector, options, parentSector._type, parentSector._ChildClass);
            return parentSector;
          } else {
            //Updates root sector
            OrganizationSector.UpdateCache(newSector._id, newSector, options, newSector._type, newSector._ChildClass);
            // Return the cache reference of the sector in a promise
            return OrganizationSector.GetByIdFromCache(newSector._id, newSector._type);
          }
        });
      }
    );
  };

  /**
   * Removes a subSector
   * @param {sectorId} subSector id
   */
  OrganizationSector.DeleteSubSector = sectorId => {
    let url = `${API_URL}${_type}/${sectorId}`;
    return $http.delete(url);
  };

  //one minute
  const validityTime = 5 * 60 * 1000;
  OrganizationSector.InitCache(validityTime);

  return OrganizationSector;
};

module.exports = module.factory('OrganizationSector', OrganizationSectorFactory);
