import angular from 'angular';
import { cloneDeep, forEach, values, includes, get } from 'lodash';
import '../components/status';
import '../services/schema';
import '../services/line';
import '../services/area';
import '../services/errors';
import '../services/globals';

const module = angular.module('whyline.controllers.schemas', [
  'whyline.components.status',
  'whyline.services.schema',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.globals',
  'whyline.services.errors',
]);

const SchemasController = ($scope, $timeout, $translate, $window, SchemaService, LineService, AreaService, GlobalsService, ErrorService, dialog, NotificationService) => {
  /*@ngInject*/
  // Show lists?
  $scope.areasList = false;
  $scope.linesList = false;
  // Offsets for columns
  $scope.offsetsareasOffset = 0;
  $scope.linesOffset = 0;
  // Current items
  $scope.currentSector = null;
  $scope.currentGap = 0;
  // Areas and lines init
  $scope.activeSchema = null;
  $scope.activeArea = null;
  $scope.hasAreasOrLines = true;

  // Init for new schema
  $scope.newSchemaBlock = false;
  $scope.newSchemaName = '';

  $scope.$fakeEnterEvent = {
    keyCode: 13,
    type: 'blur',
    stopPropagation() {}
  };

  // Schemas from service
  SchemaService.GetAllRootAsPromise().then(schemas => {
    $scope.schemas = values(schemas);
    $scope.originalSchemas = cloneDeep($scope.schemas);
    return;
  });

  // Creation of schemas and sub schemas
  $scope.addNewSchema = ($event, name, isRoot, parentSchema) => {
    if($event.keyCode === 13) {
      if(name) {
        const tempSchema = SchemaService.Create({name, root: isRoot, tree: [], type: 'sector', placeId: $scope.isPlaceSelected()});
        if(isRoot) {
          $scope.newSchemaBlock = false;
        } else {
          parentSchema.status.addChildSchema = false;
        }
        SchemaService.Save(tempSchema).then(newSchema => {
          tempSchema._id = newSchema._id;
          if(parentSchema) {
            parentSchema.tree.push(newSchema);
            SchemaService.Save(parentSchema).then(() => {
              NotificationService.Success($translate.instant('item_create_succ'));
            });
          } else if(isRoot) {
            $scope.schemas.push(newSchema);
            NotificationService.Success($translate.instant('item_create_succ'));
          }
        }).catch(() => {
          NotificationService.Information($translate.instant('operation_not_complete'));
        });
      } else if($event.type !== 'blur') {
        NotificationService.Warning($translate.instant('item_blank_name'));
      }
    }
    if($event.keyCode === 27 && parentSchema) {
      parentSchema.status.addChildSchema = false;
      NotificationService.Information($translate.instant('operation_canceled'));
    } else if($event.keyCode === 27 && !parentSchema) {
      $scope.newSchemaBlock = false;
      NotificationService.Information($translate.instant('operation_canceled'));
    }
  };

  // Update name schema
  $scope.changeName = ($event, schema) => {
    if($event.keyCode === 13) {
      if(schema.status.newName && schema.status.newName.length) {
        schema.name = schema.status.newName;
        $scope.toggleStatus($event, schema, 'isEditable');
        SchemaService.Save(schema)
          .then(() => {
            NotificationService.Success($translate.instant('item_update_succ'));
          })
          .catch(() => {
            NotificationService.Information($translate.instant('err_item_update'));
          });
      } else {
        NotificationService.Warning($translate.instant('item_blank_name'));
      }
    } else if($event.keyCode === 27) {
      schema.status.newName = schema.name;
      $scope.toggleStatus($event, schema, 'isEditable');
      NotificationService.Information($translate.instant('operation_canceled'));
    }
  };

  // Cancel creation of schema
  $scope.cancelNewSchema = () => {
    $scope.newSchemaBlock = false;
    $scope.newSchema = {};
    NotificationService.Information($translate.instant('operation_canceled'));
  };

  // When you press a item
  $scope.activeItem = ($event, _id, type) => {
    if(type === 'panel-child' || type === 'parent') {
      $scope.currentSector = _id;
      // $scope.showListsBoolean = true;
      $scope.areasOffset = angular.element($event.target).prop('offsetTop');
      $scope.linesOffset = 0;
      $scope.currentGap = 0;
    } else {
      $scope.linesOffset = angular.element($event.target).prop('offsetTop');
      $scope.currentGap = _id;
    }
  };

  // Restart active items
  $scope.unmarkActives = () => {
    $scope.linesList = false;
    $scope.areasList = false;
    $scope.activeSchema = null;
    $scope.activeArea = null;
  };

  $scope.setHasAreasOrLines = () => {
    $scope.hasAreasOrLines = false;
    const items = get($scope, 'activeSchema.tree', []);
    for(let i = items.length - 1; i >= 0; i--) {
      if(items[i].type !== 'sector') {
        $scope.hasAreasOrLines = true;
        break;
      }
    }
  };

  const _updateAreaTree = itemSelected => {
    const parentSchema = $scope.activeSchema;
    parentSchema.tree.push(itemSelected);
    $scope.setHasAreasOrLines();
    SchemaService.Save(parentSchema).then(() => {
      $timeout(() => {
        NotificationService.Success($translate.instant('item_add_succ'));
      });
    });
  };

  // Osom selection and update of schema
  $scope.selectLine = selection => {
    let itemSelected = {};
    if(selection.type === 'line') {
      itemSelected = LineService.GetOne(selection._id);
      if(!includes($scope.activeSchema.tree.map(item => item._id), selection._id)) {
        _updateAreaTree(itemSelected);
      }
    } else if(selection.type === 'area') {
      AreaService.GetCurrentWithLines(selection._id)
        .then(currentArea => {
          $timeout(() => {
            $scope.activeArea = currentArea;
            if(!includes($scope.activeSchema.tree.map(item => item._id), selection._id)) {
              _updateAreaTree(currentArea);
            }
          });
        })
        .catch(() => {
          NotificationService.Error($translate.instant('err_operation'));
        });
    }
  };

  // On memory and then save all the schemas
  // Remove
  $scope.remove = (parent, element, $event) => {
    let n = element.name || element.label;
    dialog.confirm($translate.instant('item_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        let promise;
        if(parent && parent.tree) {
          promise = SchemaService.Remove(parent, element);
        } else {
          promise = SchemaService.Remove({tree: parent}, element);
        }
        promise.then(() => {
          $timeout(() => {
            NotificationService.Success($translate.instant('item_delete_succ'));
            if(element.type === 'sector') {
              $scope.areasList = false;
              $scope.linesList = false;
            }
            if(element.type === 'area' || element.type === 'line') {
              $scope.linesList = false;
            }
            $scope.setHasAreasOrLines();
          });
          $scope.setHasAreasOrLines();
        })
        .catch(ErrorService.handler);
      })
      .catch(() => {
        $event.preventDefault();
        $event.stopPropagation();
        return;
      });
  };

  // Reset the schema
  $scope.reset = () => {
    $scope.schemas = undefined;
    $scope.areasList = false;
    $scope.linesList = false;
    $scope.newSchemaBlock = false;
    SchemaService.GetAllRootAsPromise()
      .then(schemas => {
        $timeout(() => {
          $scope.schemas = values(schemas);
          NotificationService.Success($translate.instant('edit_resarted'));
        });
      })
      .catch(() => {
        NotificationService.Error($translate.instant('err_operation'));
      });
  };

  // Toggle accordion options
  $scope.toggleStatus = ($event, schema, option) => {
    if(option === 'isOpen') {
      $event.stopPropagation();
      forEach($scope.schemas, element => {
        if(element.hasOwnProperty('status')) {
          if(element.status.hasOwnProperty('isOpen')) {
            element.status.isOpen = false;
          }
          if(element.status.hasOwnProperty('addChildSchema')) {
            element.status.addChildSchema = false;
          }
        }
      });
    }
    if(option === 'isEditable') {
      $event.stopPropagation();
      $scope.areasList = false;
      $scope.linesList = false;
      $scope.activeSchema = schema;
      $scope.setHasAreasOrLines();
    }
    if(option === 'addChildSchema') {
      $scope.toggleStatus($event, schema, 'isOpen');
    }
    schema.status[option] = !schema.status[option];
    $scope.areasList = false;
    $scope.linesList = false;
    if(option === 'isOpen') {
      $scope.activeSchema = schema;
      $scope.setHasAreasOrLines();
      if($event.target.className == 'icon-arrow-down') {
        $timeout(() => {
          $scope.areasOffset = angular.element($event.target.offsetParent.offsetParent.offsetParent).prop('offsetTop');
          $scope.areasList = true;
        }, 400);
      } else if($event.target.className == 'toggleAccordion') {
        $timeout(() => {
          $scope.areasOffset = angular.element($event.target.offsetParent.offsetParent).prop('offsetTop');
          $scope.areasList = true;
        }, 400);
      }
    }
  };

  // Execute this actions after click a schema or subschema
  $scope.schemaActions = ($event, schema) => {
    $scope.areasOffset = angular.element($event.target).prop('offsetTop');
    $scope.areasList = true;
    $scope.linesList = false;
    $scope.activeSchema = schema;
    $scope.setHasAreasOrLines();
    $scope.activeArea = null;

    // Get areas/lines for this schema
    $scope.areasList = true;
    $scope.linesList = false;
  };

  // Execute this actions after click an area
  $scope.areaActions = ($event, area) => {
    if(area.type === 'area') {
      $scope.linesOffset = angular.element($event.target).prop('offsetTop');
      $scope.linesList = true;
      $scope.activeArea = AreaService.GetOneWithLines(area._id);
      // Get lines for this area
      $scope.linesList = true;
    }
  };
};

module.exports = module.controller('SchemasController', SchemasController);
