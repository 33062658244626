import { Promise } from 'es6-promise';
import { forEach, isEmpty, compact } from 'lodash';
import Cache from './cache';
import Instance from './instance';

let API_URL;
let $q;
let $http;
let $timeout;

const $inject = (name, value) => {
  switch (name) {
    case 'API_URL':
      API_URL = value;
      break;
    case '$q':
      $q = value;
      break;
    case '$http':
      $http = value;
      break;
    case '$timeout':
      $timeout = value;
      break;
  }
};

/**
 * Gets all instances for a class
 *
 * @param {Object} options
 * @param {string} type
 * @param {Object} ChildClass
 * @return {Array <Resource>}
 * @public
 * @static
 */
const FetchAll = (options, type, ChildClass) => {
  const forceUpdate = options && options.force;
  const isCacheValid = Cache.IsValid(type);
  const all = Cache.GetAllFrom(type);
  if(forceUpdate || !isCacheValid) {
    const url = API_URL + type;

    const cancel = $q.defer();
    const request = {
      method: 'GET',
      url,
      timeout: cancel.promise, // cancel promise, standard thing in $http request
      cancel // attach cancel method to request
    };

    const promise = $http(request).then(
      response => {
        const prom = $timeout(() => {
          Cache.Sync(response.data, options, type, ChildClass);
          if(options && options.populate && options.populate.length) {
            forEach(all, item => {
              Instance.Populate(item, options.populate);
            });
          }
          return all;
        });
        return prom;
      }
    );
    if(options && options.promise) {
      return promise;
    }
  }
  if(options && options.populate && options.populate.length) {
    forEach(all, item => {
      Instance.Populate(item, options.populate);
    });
  }
  if(options && options.promise) {
    return new Promise(resolve => resolve(all));
  } else {
    return all;
  }
};

/**
 * Filters all instances for a class by field
 *
 * @param {string} prop
 * @param {string} value
 * @param {Object} options
 * @param {string} type The resource type for /api/v1/${type}...
 * @param {Class} ChildClass The child class constructor
 * @return {Array <Resource>}
 * @public
 * @static
 */
const FindBy = (prop, value, options, type, ChildClass) => {
  const forceUpdate = options && options.force;
  const url = `${API_URL}${type}/${prop}/${value}`;
  const list = Cache.GetSearch(url, type);
  if(forceUpdate || isEmpty(list)) {
    const promise = $http.get(url).then(
      response => {
        const prom = $timeout(() => {
          Cache.UpdateSearch(url, response.data, options, type, ChildClass);
          if(options && options.populate && options.populate.length) {
            forEach(list, item => {
              Instance.Populate(item, options.populate);
            });
          }
          return list;
        });
        return prom;
      },
      error => {
        if(error && [403, 404].indexOf(error.status) >= 0) {
          Cache.ClearSearch(url, type);
        }
        return $q.reject(error);
      }
    );
    if(options && options.promise) {
      return promise;
    }
  }
  if(options && options.populate && options.populate.length) {
    forEach(list, item => {
      Instance.Populate(item, options.populate);
    });
  }
  if(options && options.promise) {
    return new Promise(resolve => resolve(list));
  } else {
    return list;
  }
};

/**
 * Gets one instance for a class by field
 *
 * @param {string} prop
 * @param {string} value
 * @param {Object} options
 * @param {string} type The resource type for /api/v1/${type}...
 * @param {Class} ChildClass The child class constructor
 * @return {Promise}
 * @public
 * @static
 */
const FindOneBy = async (prop, value, options, type, ChildClass) => {
  const forceUpdate = options && options.force;

  const url = `${API_URL}${type}/${prop}/${value}`;

  const found = Cache.GetOneFrom(prop, value, type);

  if(forceUpdate || !found) {
    try {
      const response = await $http.get(url);
      return $timeout(() => {
        Cache.Update(response.data._id, response.data, options, type, ChildClass);

        const item = Cache.GetOneFrom(prop, value, type);

        if(options && options.populate && options.populate.length) {
          Instance.Populate(item, options.populate);
        }
        return item;
      });
    } catch (error) {}
  } else {
    if(options && options.populate && options.populate.length) {
      Instance.Populate(found, options.populate);
    }
    return found;
  }
};

/**
 * Gets one instance by id
 *
 * @param {string} id
 * @param {Object} options
 * @param {string} type The resource type for /api/v1/${type}...
 * @param {Class} ChildClass The child class constructor
 * @return {Resource}
 * @public
 * @static
 */
const FindById = (id, options, type, ChildClass) => {
  const forceUpdate = options && options.force;
  const isCacheValid = Cache.IsValid(type);
  if(forceUpdate || !isCacheValid || !Cache.GetByIdFrom(id, type)) {
    const url = `${API_URL}${type}/${id}`;
    Cache.Reserve(id, type, ChildClass);
    const promise = $http.get(url).then(
      response => $timeout(() => {
        const item = response.data;
        Cache.Update(id, item, options, type, ChildClass);
        if(options && options.populate && options.populate.length) {
          Instance.Populate(Cache.GetByIdFrom(id, type), options.populate);
        }
        return Cache.GetByIdFrom(id, type);
      }),
      error => {
        if(error && [403, 404].indexOf(error.status) >= 0) {
          Cache.RemoveFrom(id, type);
        }
        return $q.reject(error);
      }
    );
    if(options && options.promise) {
      return promise;
    }
  }
  if(options && options.populate && options.populate.length) {
    Instance.Populate(Cache.GetByIdFrom(id, type), options.populate);
  }
  if(options && options.promise) {
    return new Promise(resolve => resolve(Cache.GetByIdFrom(id, type)));
  } else {
    return Cache.GetByIdFrom(id, type);
  }
};

/**
 * Gets a list of instances by id
 *
 * @param {Array <string>} ids
 * @param {Object} options
 * @param {string} type The resource type for /api/v1/${type}...
 * @param {Class} ChildClass The child class constructor
 * @return {Array <Resource>}
 * @public
 * @static
 */
const FindByIds = (ids, options, type, ChildClass) => {
  const forceUpdate = options && options.force;
  //assuming cache valid, and then making sure it is
  ids = compact(ids);
  let isInCache = true;
  const idsQuery = ids.join('&ids[]=');
  const url = `${API_URL}${type}/search?ids[]=${idsQuery}`;
  const list = Cache.GetSearch(url, type);
  ids.forEach(id => {
    if(Cache.GetByIdFrom(id, type)) {
      list[id] = Cache.GetByIdFrom(id, type);
    } else {
      //if item not in cache then reserve cache and set cache as invalid
      Cache.Reserve(id, type, ChildClass);
      Cache.Invalidate(type);
      isInCache = false;
    }
  });
  if(isInCache && Cache.IsValid(type) && !forceUpdate) {
    Cache.UpdateSearch(url, list, options, type, ChildClass);
  } else {
    const promise = $http.get(url).then(
      response => $timeout(() => {
        Cache.UpdateSearch(url, response.data, options, type, ChildClass);
        if(options && options.populate && options.populate.length) {
          Instance.Populate(list, options.populate);
        }
        return list;
      }),
      error => {
        if(error && [403, 404].indexOf(error.status) >= 0) {
          Cache.ClearSearch(url, type);
        }
        return $q.reject(error);
      }
    );
    if(options && options.promise) {
      return promise;
    }
  }
  if(options && options.populate && options.populate.length) {
    Instance.Populate(list, options.populate);
  }
  if(options && options.promise) {
    return new Promise(resolve => resolve(list));
  } else {
    return list;
  }
};

export default {
  $inject,
  FetchAll,
  FindBy,
  FindOneBy,
  FindById,
  FindByIds
};
