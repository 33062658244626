import angular from 'angular';

const module = angular.module('whyline.components.no-data', [

]);

const NoDataComponent = {
  bindings: {
    icon: '@',
    resource: '@',
    redirectTo: '@',
    buttonText: '@',
    buttonAction: '=',
  },
  templateUrl: '/templates/components/no-data.html',
  controller($scope, $state) {
    'ngInject';

    $scope.showButton = false;

    this.$onChanges = changes => {
      if(changes.icon && changes.icon.currentValue) {
        $scope.icon = changes.icon.currentValue;
      }
      if(changes.resource && changes.resource.currentValue) {
        $scope.resource = changes.resource.currentValue;
      }
      if(changes.buttonText && changes.buttonText.currentValue) {
        $scope.buttonText = changes.buttonText.currentValue;
      }
      if(changes.buttonAction && changes.buttonAction.currentValue || changes.redirectTo && changes.redirectTo.currentValue) {
        $scope.showButton = true;
      }
    };

    $scope.executeAction = () => {
      if(this.buttonAction) {
        this.buttonAction();
      } else if(this.redirectTo) {
        $state.go(this.redirectTo);
      }
    };
  }
};

module.exports = module.component('nodata', NoDataComponent);
