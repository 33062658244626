import angular from 'angular';
import '../app.constants';
import './resource';
import './line';
import './area';
import './schema';
import './device';
import { required } from 'joi';

const module = angular.module('whyline.resources.totem', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.resources.line',
  'whyline.resources.area',
  'whyline.resources.schema',
  'whyline.resources.device',
]);

const TotemFactory = (Resource, Line, Area, Schema, $timeout, $http, API_URL) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'totem?placeId';

  const _schema = {
    name: {
      type: String,
      max: 128,
      required: true,
    },
    lines: {
      type: [Line],
      default: [],
      required: false,
    },
    areas: {
      type: [Area],
      default: [],
      required: false,
    },
    schemas: {
      type: [Schema],
      default: [],
      required: false,
    },
    deviceId: {
      type: String,
      default: '',
      required: false,
    },
    mobileApp: {
      type: Boolean,
      required: false
    },
    onlyForTurnConfirmation: {
      type: Boolean,
      required: true,
      default: false,
    },
    fields: {
      type: Object,
      required: false,
      default: {},
    },
    showComeBackAt: {
      type: Boolean,
      required: false,
      default: false,
    },
    ticketTemplate: {
      type: String,
      required: false,
    },
    printFromBrowser: {
      enabled: {
        type: Boolean,
        default: false,
      },
    },
    conditionsApply: {
      type: Boolean,
      required: false,
      default: false,
    },
    friendlyId: {
      type: String,
      required: false,
      default: '',
    },
    documentsReader: {
      type: Boolean,
      required: false,
      default: false
    }
  };

  const Totem = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.enable = enabled => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            // Cuando halla respuesta, modificar el cache
            Totem.UpdateCache(item._id, item);
          });
          // Devuelve la respuesta desde el cache.
          return Totem.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  Totem.prototype = new Resource(_type, _schema, Totem);

  Resource.Extend(_type, Totem);

  Totem.GetTotemPopulated = totemId => {
    const url = `${API_URL}${_type}/${totemId}/populated`;
    return $http.get(url).then(response => response.data);
  };

  Totem.GetTotemBAHash = (totemId) => {
    const url = `${API_URL}totemba/get-hash?totemId=${totemId}`;
    return $http.get(url).then(response => response.data);
  };

  //one minute
  const validityTime = 5 * 60 * 1000;
  Totem.InitCache(validityTime);

  return Totem;
};

module.exports = module.factory('Totem', TotemFactory);
