import angular from 'angular';
import '../resources/place';
import '../resources/place-messages';
import {
  Promise
} from 'es6-promise';

const module = angular.module('whyline.services.place-messages', [
  'whyline.resources.place-messages',
]);

let PlaceMessage;
let ErrorService;
let $timeout;

class PlaceMessagesService {

  static $inject = ['$timeout', 'PlaceMessages', 'ErrorService'];

  constructor(injected$timeout, injectedPlaceMessage, injectedErrorService) {
    $timeout = injected$timeout;
    PlaceMessage = injectedPlaceMessage;
    ErrorService = injectedErrorService;
  }

  GetAll(placeId) {
    return PlaceMessage.GetCurrent(placeId);
  }

  GetAllAsPromise(placeId) {
    return PlaceMessage.GetAllMessages(placeId)
      .then(messages => {
        return $timeout(() => {
          return Promise.resolve(messages || []);
        });
      })
      .catch(ErrorService.handler);
  }

  UpdateAllMessages(message, placeId) {
    return PlaceMessage.UpdateAllMessages(message, placeId)
      .then(messages => {
        return $timeout(() => {
          return Promise.resolve(messages || []);
        });
      })
      .catch(ErrorService.handler);
  }

  Update(message) {
    return new Error('not-implemented');
  }

  Create(message) {
    return new Error('not-implemented');
  }

  Remove(message) {
    return new Error('not-implemented');
  }

}

module.exports = module.service('PlaceMessagesService', PlaceMessagesService);
