import angular from 'angular';
import './status';
import '../services/line';
import '../services/area';
const module = angular.module('whyline.components.queue', [
  'whyline.components.status',
  'whyline.services.line',
  'whyline.services.area'
]);

const queueComponent = {
  bindings: {
    queueId: '<',
    label: '=',
    onRemove: '&',
    type: '<'
  },
  templateUrl: '/templates/components/queue.html',
  controller($scope, LineService, AreaService) {
    'ngInject';
    this.$onInit = () => {};
    this.$onChanges = changes => {
      if(changes.queueId && changes.queueId.currentValue) {
        if(this.type === 'line') {
          this.queue = LineService.GetOne(this.queueId);
        } else {
          this.queue = AreaService.GetOne(this.queueId);
        }
      }
    };
    this.remove = () => {
      this.onRemove({ queueId: this.queueId });
    };
  },
};

module.exports = module.component('queue', queueComponent);
