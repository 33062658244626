import angular from 'angular';
import './resource';
import '../app.constants';

const module = angular.module('whyline.resources.user', [
  'whyline.constants',
  'whyline.resources.resource'
]);

const UserFactory = ($http, $timeout, API_URL, USER_CACHE_VALIDITY, Resource) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'user?placeId';

  const _schema = {
    _id: {
      type: String
    },
    local: {
      type: Object,
      required: true
    },
    profile: {
      type: Object,
      required: true
    },
    permissions: {
      type: Array,
      required: true
    },
    provider: {
      type: String
    },
    sudo: {
      type: Boolean,
    },
    externalSupportId: {
      type: Boolean
    },
    externalId: {
      type: String
    }
  };

  const _saveInCache = user => {
    const isInstanceOfUser = user instanceof User;
    if (!isInstanceOfUser) {
      user = new User(user);
    }
    User.UpdateCache(user._id, user);
    User.InvalidateCache();
    return User.GetByIdFromCache(user._id);
  };

  const User = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.enable = enabled => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            // Cuando halla respuesta, modificar el cache
            User.UpdateCache(item._id, item);
          });
          // Devuelve la respuesta desde el cache.
          return User.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  User.prototype = new Resource(_type, _schema, User);

  Resource.Extend(_type, User);

  User.InitCache(USER_CACHE_VALIDITY);

  /**
   * Sets the current user
   *
   * @param {User} user
   * @public
   * @static
   */
  User.SetCurrent = user => _saveInCache(user);

  /**
   * Gets the current user
   *
   * @return {User}
   * @public
   * @static
   */
  User.GetCurrent = () => {
    const url = `${API_URL}user/me`;
    return $http.get(url).then(
      response => _saveInCache(response.data)
    );
  };

  /**
   * Gets Auth Token from server
   *
   * @param {Object} data
   * @public
   * @static
   */
  User.SignIn = data => {
    const url = `${API_URL}auth/local`;
    return $http.post(url, data).then(
      response => {
        response.data.user = _saveInCache(response.data.user);
        return response.data;
      }
    );
  };

  User.Verify = (password, token) => {
    const url = `${API_URL}user/verify?token=${token}`;
    return $http.post(url, {
      password
    }, response => response.data);
  };

  User.ResetPassword = (password, token) => {
    const url = `${API_URL}user/reset-password?token=${token}`;
    return $http.post(url, {
      password
    }, response => response.data);
  };

  User.RecoverPassword = (email, app) => {
    const url = `${API_URL}user/recover-password-virtualine`;
    return $http.post(url, {
      email,
    }, response => response.data);
  };

  User.ChangePassword = (oldPassword, newPassword) => {
    const url = `${API_URL}user/me/password`;
    return $http.put(url, {
      oldPassword,
      newPassword
    }, response => response.data);
  };

  User.SignUp = user => {
    const url = `${API_URL}user/sign-up`;
    return $http.post(url, user).then(response => response.data);
  };

  User.Exists = email => {
    const url = `${API_URL}user/findByEmail/${email}`;
    return $http.get(url).then(response => response.data);
  };

  User.SetExternalSupportId = data => {
    const url = `${API_URL}user/externalSupportId`;
    return $http.put(url, data)
      .then(response => response.data);
  };

  return User;
};

module.exports = module.factory('User', UserFactory);
