/* eslint-disable arrow-parens */
import angular from 'angular';
import '../../services/organization';
import '../../services/line-flow-type-organization';
import '../../services/plan';
import '../../services/category';
import '../../services/organization-messages';
import { localStorage, options } from '../../utils';
import { get, includes, isNil } from 'lodash';

const module = angular.module('whyline.modals.organization', [
  'whyline.services.organization',
  'whyline.services.organization-messages',
  'whyline.services.line-flow-type-organization',
  'whyline.services.plan',
  'whyline.services.category',
]);

const OrganizationModalController = (
  $scope,
  $uibModalInstance,
  $timeout,
  $translate,
  dialog,
  Slug,
  OrganizationService,
  organizationId,
  TimezoneService,
  NotificationService,
  PlanService,
  CategoryService,
  ErrorService,
  LineFlowTypeOrganizationService,
  OrganizationMessagesService,
) => {
  /*@ngInject*/

  $scope.currentLanguage = $translate.use();
  $scope.languages = { list: { en: 'English', es: 'Español', pt: 'Portugués' } };
  $scope.organization = {};
  $scope.organizationGeneral = {};
  $scope.organizationConfiguration = {};
  $scope.organizationMessages = [];
  $scope.plans = [];
  $scope.lineFlowTypeOrganizationList = [];
  $scope.lineFlowTypeOrganizationSelected = {};
  $scope.timezones = { list: TimezoneService.List };

  $scope.notificationProviders = {
    selected: {
      provider: {},
      channel: '',
    },
    configuration: [
      {
        provider: 'aws',
        channels: ['sms'],
        default: true,
      },
      {
        provider: 'boti',
        channels: ['whatsapp'],
        default: false,
      },
      {
        provider: 'optiwe',
        channels: ['sms', 'whatsapp'],
        default: false,
      },
    ],
  };
  $scope.notificationProvidersChange = () => {
    $scope.organizationConfiguration.notifications = {
      provider: $scope.notificationProviders.selected.provider.provider || '',
      channel: $scope.notificationProviders.selected.channel || '',
    };
  };

  $scope.editMessagesScope = {
    options: options.filter((o) => o.onlyOrganizations || o.all),
    selectedOption: options.filter((o) => o.onlyOrganizations || o.all)[0].key,
  };

  $scope.editCallLogicsScope = {
    options: options.filter((o) => o.onlyOrganizations || o.all || o.orgPlacesLines),
    selectedOption: options.filter((o) => o.onlyOrganizations || o.all || o.orgPlacesLines)[0].key,
  };

  localStorage.get('user').then(user => {
    $scope.superAdmin = user && user.sudo;

    $scope.hasAdminPermissions =
      !$scope.superAdmin &&
      user &&
      !!user.permissions.filter(
        (p) => p.role.name === 'Organization Admin' && p.role.organizationId === organizationId,
      );
  });

  const SUPPORTED_LANGUAGES = ['en', 'es', 'pt'];
  const DEFAULT_LANGUAGE = 'en';
  const language = includes(SUPPORTED_LANGUAGES, $translate.use())
    ? $translate.use()
    : DEFAULT_LANGUAGE;

  const field = `name_${language}`;

  $scope.categoriesSettings = {
    template: `<b>{{option.${field}}}</b>`,
    externalIdProp: 'category',
    displayProp: field,
    idProp: '_id',
    searchField: field,
    enableSearch: true,
    keyboardControls: true,
    selectedToTop: true,
    scrollable: true,
  };

  $scope.tooltips = {
    checkInEarly: 'tooltip_check_in_early',
    numberPeopleAhead: 'tooltip_people_ahead',
    youAreNext: 'tooltip_you_are_next',
    checkIn: 'tooltip_check_in',
    itsYourTurn: 'tooltip_its_your_turn',
  };

  $scope.multiselectTranslations = {
    checkAll: $translate.instant('multiselect_todos'),
    uncheckAll: $translate.instant('multiselect_ninguno'),
    dynamicButtonTextSuffix: $translate.instant('multiselect_seleccionados'),
    buttonDefaultText: $translate.instant('multiselect_seleccionar'),
    searchPlaceholder: $translate.instant('multiselect_buscar'),
    selectionCount: $translate.instant('multiselect_seleccionados'),
  };

  $scope.cancel = () => {
    dialog
      .confirm($translate.instant('modal_cancel'))
      .then(() => $uibModalInstance.dismiss('cancel'))
      .catch(() => 'canceled');
  };

  $scope.setMinorThanZero = (value, property) => {
    if (value < -1 && $scope.organizationConfiguration.plan.hasOwnProperty(property)) {
      $scope.organizationConfiguration.plan[property] = -1;
    } else if (value === '' || value === null) {
      $scope.organizationConfiguration.plan[property] = 0;
    }
  };

  $scope.changeIdToPlanId = (plan) => {
    if (!plan) {
      return;
    }
    $scope.organizationConfiguration.plan = {
      ...plan,
      planId: plan._id,
    };

    Reflect.deleteProperty($scope.organizationConfiguration.plan, '_id');
  };

  $scope.setLFTOId = (lftoSelected) => {
    $scope.organization.defaultLineFlowTypeOrganization = lftoSelected._id;
  };

  const validateGeneral = () => {
    const name = $scope.organizationGeneral.name;
    const slug = $scope.organizationGeneral.slug;
    const timezone = $scope.organizationGeneral.timezone;
    const language = $scope.organizationGeneral.language;
    const website = $scope.organizationGeneral.website;
    const phoneNumber = $scope.organizationGeneral.phoneNumber;

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_name'));
      return false;
    }

    if (
      name &&
      !/^[a-zA-Zàáâäãåąč&ćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ\-ð:/ ,.'-+\d.*"]+$/u.test(
        name,
      )
    ) {
      NotificationService.Warning($translate.instant('invalid_organization_name'));
      return false;
    }

    if (!slug) {
      NotificationService.Warning($translate.instant('mandatory_alias'));
      return false;
    }

    if (!language) {
      NotificationService.Warning($translate.instant('mandatory_language'));
      return false;
    }

    if (!timezone) {
      NotificationService.Warning($translate.instant('mandatory_timezone_place'));
      return false;
    }

    if (!$scope.data.selectedCategory || !$scope.data.selectedCategory.length > 0) {
      NotificationService.Warning($translate.instant('mandatory_category'));
      return false;
    }

    if (
      website &&
      !/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/.test(
        website.toLowerCase(),
      )
    ) {
      NotificationService.Warning($translate.instant('url regex'));
      return false;
    }

    if (phoneNumber && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phoneNumber)) {
      NotificationService.Warning($translate.instant('invalid_tel'));
      return false;
    }

    return true;
  };

  $scope.saveGeneral = () => {
    if (validateGeneral()) {
      $scope.organizationGeneral.categories = $scope.data.selectedCategory.map(
        (category) => category.key,
      );
      OrganizationService.UpdateGeneral($scope.organizationGeneral, $scope.organization._id)
        .then(() => NotificationService.Success($translate.instant('organization_update_succ')))
        .catch(ErrorService.handler);
    }
  };

  const validateConfigurations = () => {
    const checkInTolerance = $scope.organization.configuration.queuer.checkInTolerance;
    const checkInMin = get($scope, 'organization.configuration.checkIn.min', null);
    const checkInMax = get($scope, 'organization.configuration.checkIn.max', null);

    if (!$scope.organization.configuration.queuer.qr) {
      $scope.organizationConfiguration.queuer.qr = `${Slug.slugify(
        $scope.organization.name,
      )}_check_in`;
    }

    if (
      (checkInTolerance && (checkInTolerance > 100 || checkInTolerance < 2)) ||
      checkInTolerance.toString().indexOf('.') === 1
    ) {
      NotificationService.Warning($translate.instant('organization_checkin_tolerance_range'));
      return false;
    }

    if (
      $scope.organizationConfiguration &&
      !validateMotiveReason($scope.organizationConfiguration)
    ) {
      return false;
    }

    if (isNil(checkInMin) || checkInMin < 1 || checkInMin > 10000) {
      NotificationService.Warning($translate.instant('error_pre_check_in_invalid_range'));
      return false;
    }

    if (isNil(checkInMax) || checkInMax < 1 || checkInMax > 10000) {
      NotificationService.Warning($translate.instant('error_post_check_in_invalid_range'));
      return false;
    }

    return true;
  };

  const validateMessages = () => {
    const organizationMessages = $scope.organizationMessages;
    const messagesCondition = organizationMessages.find((organizationMessage) =>
      organizationMessage.messages.find((message) => !message),
    );
    if (messagesCondition) {
      NotificationService.Warning($translate.instant('messages_required'));
      return false;
    } else {
      return true;
    }
  };

  const validateMotiveReason = (configuration) => {
    const arrayWithoutDuplicates = [...new Set(configuration.reasons.map((r) => r.name))];
    if (arrayWithoutDuplicates.length !== configuration.reasons.length) {
      NotificationService.Warning($translate.instant('duplicated-reasons'));
      return false;
    }
    for (const reason of configuration.reasons) {
      if (!reason.name) {
        NotificationService.Warning($translate.instant('reason and motive empty'));
        return false;
      }
    }
    return true;
  };

  $scope.saveConfigurations = () => {
    if (validateConfigurations()) {
      OrganizationService.UpdateConfiguration(
        {
          configuration: $scope.organizationConfiguration,
          enabledForMobileApp: $scope.organization.enabledForMobileApp,
          hiddenInMobileApp: $scope.organization.hiddenInMobileApp,
          defaultLineFlowTypeOrganization: $scope.organization.defaultLineFlowTypeOrganization,
        },
        $scope.organization._id,
        $scope.editCallLogicsScope.selectedOption,
      )
        .then(() => NotificationService.Success($translate.instant('organization_update_succ')))
        .catch(() => NotificationService.Error($translate.instant('err_operation')));
    }
  };

  $scope.saveMessages = () => {
    if (validateMessages()) {
      const forAllPlaces = $scope.editMessagesScope.selectedOption === 'all';

      OrganizationMessagesService.UpdateAllMessages(
        $scope.organizationMessages,
        $scope.organization._id,
        forAllPlaces,
      )
        .then(() => NotificationService.Success($translate.instant('organization_update_succ')))
        .catch(() => NotificationService.Error($translate.instant('err_operation')));
    }
  };

  $scope.addReason = () => {
    $scope.organization.configuration.reasons.unshift({
      name: '',
    });
  };

  $scope.deleteReason = (index) => {
    dialog
      .confirm($translate.instant('reason_remove_sure'))
      .then(() => {
        $scope.organizationConfiguration.reasons.splice(index, 1);
        NotificationService.Information($translate.instant('reason_remove_succ'));
      })
      .catch(ErrorService.handler);
  };

  $scope.requestReasonAndMotiveChange = (value) => {
    if (!value) {
      $scope.organizationConfiguration.reasonRequired = false;
    }
  };

  $scope.defaultAppointmentConfirmation = () => {
    if (
      !$scope.organizationConfiguration.queuer.canGiveAppointments ||
      !$scope.organizationConfiguration.queuer.canConfirmAppointments
    ) {
      $scope.organizationConfiguration.queuer.requireAppointmentConfirmation = false;
    }
    if (!$scope.organizationConfiguration.queuer.canGiveAppointments) {
      $scope.organization.configuration.queuer.showWorkingDay = false;
    }
    if (!$scope.organization.configuration.queuer.canGiveAppointments) {
      $scope.organization.configuration.queuer.canProcessAppointment = false;
    }
    if ($scope.organizationConfiguration.queuer.canGiveAppointments) {
      $scope.organizationConfiguration.queuer.canConfirmAppointments = true;
    }
  };

  $scope.checkAliasType = () => {
    if (!$scope.organization.configuration.requirements.id) {
      $scope.organization.configuration.queuer.aliasType = 'fullNameAlias';
      NotificationService.Information($translate.instant('alias_has_been_changed'));
    }
  };

  const init = () => {
    const categoriesPromises = CategoryService.GetAllAsPromise();
    const aliasTypesPromises = OrganizationService.GetAliasTypes();

    Promise.all([categoriesPromises, aliasTypesPromises]).then(([categories, aliasTypes]) => {
      $scope.data = {
        aliasTypes,
        categories,
        selectedCategory: [],
      };

      if (organizationId) {
        OrganizationService.GetOneAsPromiseFromServer(organizationId)
          .then((organization) => {
            $scope.organization = OrganizationService.Copy(organization);
            $scope.organizationGeneral = $scope.organization.getGeneralPartial();
            $scope.organizationConfiguration = $scope.organization.getConfigurationPartial();

            const providerType = get(
              $scope.organizationConfiguration,
              'notifications.provider',
              'aws',
            );
            const providerChannel = get(
              $scope.organizationConfiguration,
              'notifications.channel',
              'sms',
            );
            $scope.notificationProviders.selected.provider =
              $scope.notificationProviders.configuration.find(
                (item) => item.provider === providerType,
              );
            $scope.notificationProviders.selected.channel = providerChannel;

            OrganizationMessagesService.GetAllAsPromise(organizationId).then((messages) =>
              $timeout(() => {
                $scope.organizationMessages = messages;
              }),
            );

            $scope.organization.categories.forEach((category) => {
              let c = $scope.data.categories.find((cate) => category == cate.key);
              if (c) {
                $scope.data.selectedCategory.push(c);
              }
            });
          })
          .then(() => {
            LineFlowTypeOrganizationService.GetAllEnabledByOrganization(organizationId, false).then(
              (lftoList) => {
                $scope.lineFlowTypeOrganizationList = lftoList;
                const found = $scope.lineFlowTypeOrganizationList.find(
                  (lfto) => lfto._id == $scope.organization.defaultLineFlowTypeOrganization,
                );
                $scope.lineFlowTypeOrganizationSelected = found
                  ? found
                  : $scope.lineFlowTypeOrganizationList[0];
              },
            );
          })
          .catch(() => $scope.cancel());
      }
      if ($scope.superAdmin) {
        PlanService.GetAllActive().then((plans) => {
          $scope.plans = plans;
        });
      }
    });
  };
  init();
};

module.exports = module.controller('OrganizationModalController', OrganizationModalController);
