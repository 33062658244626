import angular from 'angular';
import '../../app.constants';
import '../../services/totem';
import '../../services/globals';
import '../../services/line';
import '../../services/totem-tv';
import '../../services/errors';
import '../../components/operator';
import '../../components/status';

const module = angular.module('whyline.controllers.totem-tv-path', [
  'whyline.services.totem',
  'whyline.services.globals',
  'whyline.services.line',
  'whyline.services.totem-tv',
  'whyline.services.errors',
  'whyline.components.operator',
  'whyline.components.status',
  'whyline.constants',
]);

const TotemTvPathController = ($scope, TotemService, LineService, $interval, GlobalsService, $state, TotemTvService, $timeout, toastr, $translate, ErrorService, SAFE_STATES) => {
  /*@ngInject*/

  $scope.currentPage = 1;

  TotemTvService.SetButtonStatus('back', true);
  TotemTvService.SetButtonStatus('forward', false);
  TotemTvService.SetButtonStatus('browserBack', false);
  TotemTvService.SetButtonStatus('status-text', false);

  // TOTEM INIT
  if(GlobalsService.CurrentPlace.placeId) {
    $scope.currentPlaceId = GlobalsService.CurrentPlace.placeId;
  } else {
    $scope.currentPlaceId = $state.params.placeId;
  }

  // Current totemId
  $scope.totemId = $state.params.totemId;

  if($scope.totemId && $state.params.n == 0) {
    TotemService.GetOnePopulatedAsPromiseFromServerWithPropAllPopulated($scope.totemId)
      .then(res => {
        $timeout(() => {
          if(!res.enabled) {
            $state.go(SAFE_STATES.totemTvWelcome);
          }
          $scope.items = res.schemasPopulated.concat(res.areasPopulated).concat(res.linesPopulated);
        });
      })
      .catch(ErrorService.handler);
  }

  // Get current item in the actual path
  if($state.params.n != 0) {
    if($state.params.n > 0) {
      TotemTvService.SetButtonStatus('browserBack', false);
    }
    $timeout(() => {
      $scope.totem = TotemTvService.GetCurrent();
      $scope.items = $scope.totem.items;
    });
  }
};

module.exports = module.controller('TotemTvPathController', TotemTvPathController);
