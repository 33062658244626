import angular from 'angular';

const module = angular.module('whyline.filters.toArray', []);

const filter = () => obj => {
  const result = [];
  angular.forEach(obj, val => {
    result.push(val);
  });
  return result;
};

module.exports = module.filter('toArray', filter);
