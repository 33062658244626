import angular from 'angular';
import { localStorage } from '../utils';
import { includes, get } from 'lodash';
import '../app.constants';
import './modals/account';
import '../services/auth';
import '../services/user';
import '../services/organization';
import '../services/place';
import '../services/globals';
import '../services/connection';
import '../services/socket';
import '../services/access-control';

const module = angular.module('whyline.controllers.main', [
  'whyline.constants',
  'whyline.controllers.modals.account',
  'whyline.services.auth',
  'whyline.services.user',
  'whyline.services.organization',
  'whyline.services.place',
  'whyline.services.globals',
  'whyline.services.connection',
  'whyline.services.socket',
  'whyline.services.access-control',
]);

const MainController = (
  // Angular's propietary modules
  $scope,
  $state,
  $translate,
  $uibModal,
  $focus,
  $rootScope,
  $timeout,
  // Constants
  USER_CACHE_VALIDITY,
  SAFE_STATES,
  ADMIN_CLIENT_URL,
  // Services
  AuthService,
  UserService,
  PlaceService,
  GlobalsService,
  ConnectionService,
  OrganizationService,
  AccessControlService,
  // Sockets
  AreaSocket,
  BoxSocket,
  LineSocket,
  DeviceSocket,
  HolidaySocket,
  UserSocket,
  OrganizationUserSocket,
  DisplaySocket,
  TotemSocket,
  SubscriptionSocket,
  PlaceSocket,
  ConnectionSocket,
  AppointmentSocket,
  ResourceGroupSocket,
  SocketService,
  ApplicationSocket,
  Socket,
  // Other Packages
  blockUI,
  hotkeys,
) => {
  'ngInject';

  $scope.navbarInclude = '/templates/parts/navbar-organization.html';

  $scope.currentYear = new Date().getFullYear();

  $scope.enterpriseType = GlobalsService.EnterpriseType;

  $scope.root = $rootScope;

  $scope.toggleNavBarLeftCollapsed = () =>
    ($rootScope.navBarLeftCollapsed = !$rootScope.navBarLeftCollapsed);

  $scope.openNavBarLeft = () => ($rootScope.navBarLeftCollapsed = false);

  $scope.closeNavBarLeft = () => ($rootScope.navBarLeftCollapsed = true);

  $scope.lockNavBarLeft = true;

  $scope.lockNavBarLeft = () => {
    $rootScope.navBarLeftFloat = false;
    $rootScope.navBarLeftCollapsed = false;
    $rootScope.lockNavBarLeft = true;
    localStorage.set('lockNavBarLeft', true);
  };

  $scope.unlockNavBarLeft = () => {
    $rootScope.navBarLeftFloat = true;
    $rootScope.navBarLeftCollapsed = true;
    $rootScope.lockNavBarLeft = false;
    localStorage.set('lockNavBarLeft', false);
  };

  // popovers flags
  $scope.popovers = {};
  $scope.popovers.isStatOpen = false;
  $scope.popovers.isAccountOpen = false;

  // Navbar collapse true by default
  $scope.isNavCollapsed = true;

  $scope.hasAdminPermissions = false;

  // Get the current user
  localStorage.get('user').then(user => {
    if (user && user._id) {
      // Broadcast that the user is authenticated
      $rootScope.$broadcast('authenticated', user, GlobalsService.EnterpriseType);
      // Verifiy if it is valid
      const lastUpdate = localStorage.get('lastUpdate');
      const isValid = Date.now() - lastUpdate < USER_CACHE_VALIDITY;

      if (isValid) {
        UserService.SetCurrent(user);
        UserService.SyncCurrent().then(() => {
          $scope.currentGlobalRole = UserService.GetCurrentRoleFor(user);
          const rolesAllowedToMonitor = [
            'Whyline - SuperUser',
            'Admin General',
            'Organization Admin',
          ];
          $scope.canGoToAdmin = rolesAllowedToMonitor.indexOf($scope.currentGlobalRole > -1);
          let organizationEnabled = true;

          OrganizationService.GetAllAsPromise().then((organizations) => {
            for (const orgId of Object.values(organizations)) {
              if (includes(user.disabledForOrganizations, orgId)) {
                organizationEnabled = false;
              }
            }

            if (organizationEnabled) {
              $scope.canGoToStats = AuthService.CanUseStats();
            } else {
              $scope.canGoToStats = false;
            }
          });
        });
      } else {
        signOut();
      }
    }
  });


  $scope.goToMonitor = () => {
    console.log(`${ADMIN_CLIENT_URL}url-token-login?token=${localStorage.get('token')}`)
    window.open(`${ADMIN_CLIENT_URL}url-token-login?token=${localStorage.get('token')}`, '_blank');
  };

  $scope.openDownloadDesktopCallerModal = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/desktop-caller-modal.html',
      size: 'md',
      controller: 'DesktopCaller',
    });
  };

  // Set selectedBox from localStorage
  $scope.selectedBox = localStorage.get('selectedBox', null);

  $scope.$watch('$state.params', (newParams, oldParams) => {
    const actualBox = localStorage.get('selectedBox');
    if (newParams.placeId) {
      $scope.showBox = true;
      $scope.currentPlaceId = newParams.placeId;
    } else if (actualBox && actualBox.placeId === oldParams.placeId) {
      $scope.showBox = false;
    } else {
      localStorage.remove('selectedBox');
      $scope.selectedBox = null;
      $scope.showBox = false;
    }
    if (newParams && newParams.organizationId) {
      $scope.currentOrganizationId = newParams.organizationId;
    }
  });

  const setAdminPermissions = () => {
    $scope.hasAdminPermissions =
      $scope.superAdmin ||
      ($scope.user &&
        !!$scope.user.permissions.filter(
          (p) =>
            p.role.name === 'Organization Admin' &&
            p.role.organizationId === $scope.organization._id,
        ));
  };

  // Check url to join user to a room by placeId
  $rootScope.$on('socketReady', () => {
    $rootScope.$on('organization-join', (event, newOrganizationId) => {
      if (newOrganizationId) {
        SocketService.GetInstance().then((socket) => {
          $timeout(() => {
            socket.emit('organization:join', newOrganizationId);
          });
        });
      }
    });

    $scope.$watch('$state.params', (newParams) => {
      if (newParams.placeId) {
        // Emit place:join on socket
        SocketService.GetInstance().then((socket) => {
          $timeout(() => {
            socket.emit('place:join', newParams.placeId);
          });
        });
      } else {
        // Emit place:unjoin on socket
        SocketService.GetInstance().then((socket) => {
          $timeout(() => {
            socket.emit('place:unjoin');
          });
        });
      }

      if (newParams.organizationId) {
        $scope.currentOrganizationId = newParams.organizationId;
        $scope.organization = OrganizationService.GetOne(newParams.organizationId);
        setAdminPermissions();
        SocketService.GetInstance().then((socket) => {
          $timeout(() => {
            socket.emit('organization:join', newParams.organizationId);
          });
        });
        $scope.navbarInclude = `/templates/parts/navbar-organization.html?r=${Math.random()}`;
      }
    });
  });

  // Start watching the server/client connection
  ConnectionService.startWatcher();

  GlobalsService.SetHttpError();

  // Set current language
  $scope.currentLanguage = $translate.use();
  GlobalsService.SetCurrentLanguage($scope.currentLanguage);

  // Get the global instance of blockUI
  const wholeApp = blockUI.instances.get('wholeApp');

  // Signout function
  const signOut = () => {
    wholeApp.start('Cerrando sesión...');
    localStorage.remove('selectedBox');
    AuthService.SignOut().then(() => {
      $rootScope.$broadcast('unauthenticated');
    });
  };

  // Function to show 8 elements at least on previos/next in line
  $scope.completeItems = (quantity, length) => {
    if (quantity - length >= 0) {
      return new Array(quantity - length);
    } else {
      return new Array(0);
    }
  };

  $scope.user = UserService.GetCurrent();

  $scope.isAuthenticated = AuthService.IsAuthenticated;

  $scope.superAdmin = $scope.user && $scope.user.sudo;

  $scope.openHelpModal = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/help-modal.html',
      size: 'md',
      controller: 'AccountModalController',
    });
  };
  $scope.openHelpMdaModal = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/help-modal-mda.html',
      size: 'md',
      controller: 'AccountModalController',
    });
  };

  $scope.isPlaceSelected = () => {
    if ($state.params && $state.params.placeId) {
      $scope.showBox = true;
      if (!$scope.place) {
        $scope.place = PlaceService.GetOne($state.params.placeId);
        $scope.setAgenda();
      }
    } else {
      $scope.showBox = false;
      $scope.place = undefined;
    }
    const isSelected = $scope.place && $scope.place._id;

    $scope.clickMessage = isSelected ? '' : 'POR FAVOR, SELECCIONE UN LUGAR';
    return isSelected;
  };

  $scope.showPlaceListIcon = () =>
    $scope.isPlaceSelected() && (get($scope, 'place.plan.type', '') !== 'free' || $scope.user.sudo);

  $scope.showOrganizationListIcon = () =>
    $scope.isOrganizationSelected() &&
    ((get($scope, 'place.plan.type', '') !== 'free' &&
      get($scope, 'organization.configuration.plan.type', '') !== 'free') ||
      $scope.user.sudo);

  $scope.isOrganizationSelected = () => {
    if ($state.params && $state.params.organizationId) {
      if (!$scope.organization) {
        $scope.organization = OrganizationService.GetOne($state.params.organizationId);
        setAdminPermissions();
        if (GlobalsService.SingleOrganization === null) {
          OrganizationService.GetAllAsPromise().then((organizations) => {
            GlobalsService.SetSingleOrganization(Object.keys(organizations).length === 1);
          });
        }
      }
    } else {
      $scope.organization = undefined;
    }
    const isSelected = $scope.organization && $scope.organization._id;

    $scope.clickMessage = isSelected ? '' : 'POR FAVOR, SELECCIONE UNA ORGANIZACION';
    return isSelected;
  };

  $scope.goToOrganizationList = () => {
    GlobalsService.SetCurrentOrganization(null);
    $state.go(SAFE_STATES.organizationsList);
  };

  $scope.goToStats = () => {
    GlobalsService.SetCurrentPlace(null);
    if ($state.params.placeId) {
      return $state.go(SAFE_STATES.stats, { placeId: $state.params.placeId });
    }
    $state.go(SAFE_STATES.stats);
  };

  $scope.signOut = () => {
    signOut();
  };

  $scope.setAgenda = () => {
    $state.params.placeId
      ? AccessControlService.CanPerformAction(['section.schedule'], $state.params.placeId).then(
          (result) => {
            $scope.canAccessAgenda = result;
          },
        )
      : null;
  };

  // Modal to enqueue/add user to line
  $scope.openAccountModal = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/account.html',
      size: 'md',
      controller: 'AccountModalController',
    });
  };

  // Important messages
  $scope.importantMessage = {
    type: 'info',
    show: false,
    canClose: false,
    message: '',
  };

  $rootScope.$on('importantMessage', (event, data) => {
    $scope.importantMessage = {
      ...data,
    };
  });

  $rootScope.$on('selectedBox', () => {
    $scope.selectedBox = localStorage.get('selectedBox', null);
  });

  $scope.openSelectBox = () => {
    $uibModal
      .open({
        templateUrl: '/templates/components/modals/box-select-modal.html',
        size: 'md',
        controller: 'BoxSelectController',
      })
      .result.then(() => $rootScope.$emit('updateLinesAreas'));
  };

  // Shorcuts definition
  hotkeys
    .bindTo($scope)
    .add({
      combo: 'ctrl+f',
      description: 'Search with the input filter',
      callback: ($event) => {
        $event.preventDefault();
        $focus('inputSearch');
      },
    })
    .add({
      combo: 'ctrl+w+t',
      description: 'Best team ever.',
      callback: ($event) => {
        $event.preventDefault();
        window.open('http://www.whyline.com/whyline-team/', '_blank');
      },
    });

  $scope.showHelpButton = (button) => {
    let showButton = false;
    if ($state.params && $state.params.organizationId) {
      const organization = $scope.organization;
      const useScenarios = get(organization, 'configuration.queuer.useScenarios', false);
      if (organization) {
        switch (button.toUpperCase()) {
          case 'MDA':
            showButton = useScenarios;
            break;
          case 'NOC':
            showButton = !useScenarios;
            break;
          default: showButton = false;
        }
      }
    }
    return showButton;
  };

};

module.exports = module.controller('MainController', MainController);
