import angular from 'angular';
import { localStorage } from '../utils';
import '../app.constants';
import '../resources/box';
import '../services/socket';
import '../factories/socket.io';

const module = angular.module('whyline.sockets.box', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.box',
  'whyline.services.socket',
]);

const BoxSocket = (Socket, Box, $rootScope, $state, SocketService) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('box:report-selected', userId => {
        const placeId = $state.params.placeId;
        socket.emit('box:reporting', {
          box: localStorage.get('selectedBox', null),
          placeId,
        });
      });

      socket.on('box:reporting', (box) => {
        if (box) {
          $rootScope.$emit('box:reporting', box._id);
        }
      });
    });
  });
};

module.exports = module.factory('BoxSocket', (Socket, Box, $rootScope, $state, SocketService) => new BoxSocket(Socket, Box, $rootScope, $state, SocketService));
