import angular from 'angular';
import {
  includes,
  isString,
  set,
  get
} from 'lodash';
import {
  validatePersonId
} from '../../../utils';
import '../../../app.constants';
import '../../../resources/appointment';
import '../../../services/appointment';
import '../../../services/reception';
import '../../../services/totem-tv';
import '../../../services/notifications';
import '../../../services/validation';

const module = angular.module('whyline.controllers.totem-tv.appointment-search', [
  'whyline.constants',
  'whyline.services.appointment',
  'whyline.resources.appointment',
  'whyline.services.reception',
  'whyline.services.totem-tv',
  'whyline.services.notifications',
  'whyline.services.validation'
]);

const AppointmentSearchController = (SAFE_STATES, $window, $scope, $state, $timeout, ReceptionService, AppointmentService, Appointment, NotificationService, TotemTvService, ValidationService, $translate, PlaceService) => {
  /*@ngInject*/

  // INIT
  $scope.validationErrors = {};
  const place = PlaceService.GetCurrent();

  const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

  const selected = identifications.find(id => id.default);

  // Options
  $scope.idTypesList = identifications;

  $scope.selectedIdType = {
    value: selected
  };

  $scope.appointmentFields = {
    id: '',
    idType: selected ? selected.key : undefined,
  };

  $scope.updateIdType = () => {
    $scope.appointmentFields.idType = get($scope.selectedIdType, 'value.key', undefined);
  };

  TotemTvService.SetButtonStatus('back', true);
  TotemTvService.SetButtonStatus('forward', true);
  TotemTvService.SetButtonStatus('browserBack', false);
  TotemTvService.SetButtonStatus('status-text', false);

  // Search appointments in totem
  const searchTotemAppointments = () => {
    if (ValidationService.validatePerson($scope.appointmentFields, 'personId', $scope, TotemTvService.TOASTER_OPTIONS, 'appointments')) {
      $state.go(SAFE_STATES.totemTvAppointmentResults, {
        fields: $scope.appointmentFields
      });
    }
  };

  // Search appointments
  TotemTvService.Validate(searchTotemAppointments, $scope.appointmentFields);

  // Keyboard
  $scope.keyboard = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 'clear'];

  $scope.keyboardAction = key => {
    if (key === 'clear') {
      if (place.enterpriseType === 'ba') {
        $scope.appointmentFields.id = '';
      } else {
        $scope.appointmentFields.id = $scope.appointmentFields.id.substring(0, $scope.appointmentFields.id.length - 1);
      }
      $scope.validationErrors.personId.$invalid = false;
    } else {
      if (!isString($scope.appointmentFields.id)) {
        $scope.appointmentFields.id = '';
      }
      $scope.appointmentFields.id = $scope.appointmentFields.id + key;
    }
    $scope.validationErrors.personId.$dirty = false;
    $scope.validationErrors.personId.$error.badFormat = false;
    if (place.enterpriseType !== 'ba') {
      $window.document.getElementById('personIdField').focus();
    }
  };
};

module.exports = module.controller('TotemTvAppointmentSearchController', AppointmentSearchController);
