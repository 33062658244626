import angular from 'angular';
import '../resources/holiday';

const module = angular.module('whyline.services.holiday', [
  'whyline.resources.holiday',
  'whyline.resources.area'
]);

let Holiday;
let Area;

class HolidayService {
  static $inject = ['Holiday', 'Area'];

  constructor(injectedHoliday, injectableArea) {
    Holiday = injectedHoliday;
    Area = injectableArea;
  }

  Create(holiday) {
    const newHoliday = new Holiday(holiday);
    return Holiday.Save(newHoliday);
  }

  Update(holiday) {
    return Holiday.Save(holiday);
  }

  Remove(holidayId) {
    const holiday = Holiday.FindById(holidayId);
    return Holiday.Remove(holiday);
  }

  GetAll() {
    return Holiday.FetchAll();
  }

  GetAllForArea(areaId) {
    return Holiday.GetAllForArea(areaId);
  }

  GetAllForAreaAsPromise(areaId) {
    return Holiday.GetAllForArea(areaId, { promise: true });
  }

  GetOne(holidayId) {
    return Holiday.FindById(holidayId);
  }

  GetOneAsPromise(holidayId) {
    return Holiday.FindById(holidayId, {promise: true});
  }

  GetOneAsPromiseFromServer(holidayId) {
    return Holiday.FindById(holidayId, {promise: true, force: true});
  }
}

module.exports = module.service('HolidayService', HolidayService);
