import angular from 'angular';
import '../../app.constants';
import '../../services/place';
import { localStorage } from '../../utils';

const module = angular.module('whyline.controllers.reception', [
  'whyline.constants',
  'whyline.services.place',
]);

const ReceptionController = ($scope, PlaceService, RECEPTION_URL) => {
  $scope.redirectToNewReceptionModule = () => {
    const place = PlaceService.GetCurrent();
    const token = localStorage.get('token');
    const params = JSON.stringify({ placeId: place._id });
    const url = new URL(`${RECEPTION_URL}external`);
    url.searchParams.append('token', token);
    url.searchParams.append('url', '/');
    url.searchParams.append('params', params);
    console.log(url.toString());

    window.open(url, '_blank');
  };
};

module.exports = module.controller('ReceptionController', ReceptionController);
