import angular from 'angular';
import Papa from 'papaparse';
import json2csv from 'json2csv'
import 'angular-file-saver';

const module = angular.module('whyline.services.exportTo', ['ngFileSaver']);

let FileSaver;

class ExportToService {
  static $inject = ['FileSaver'];

  constructor(FileSaverInjected) {
    FileSaver = FileSaverInjected;
  }

  CSV(result, file) {
    const nameLikeDate = new Date().toLocaleString(
      'es-AR',
      {
        hour12: false,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        second: '2-digit',
        minute: '2-digit',
        hour: '2-digit',
      });

    return FileSaver.saveAs(
      new Blob(
        [Papa.unparse(result)],
        { type: 'text/csv;charset=utf-8' }
      ),
      `${file}_${nameLikeDate}.csv`
    );

    
  }

  //result -> {data,fileds}
  jsonToCSV(result, file) {
    const nameLikeDate = new Date().toLocaleString(
      'es-AR',
      {
        hour12: false,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        second: '2-digit',
        minute: '2-digit',
        hour: '2-digit',
      });

    return FileSaver.saveAs(
      new Blob(
        [json2csv(result)],
        { type: 'text/csv;charset=utf-8' }
      ),
      `${file}_${nameLikeDate}.csv`
    );


  }
}
export default module.service('ExportToService', ExportToService);
