import angular from 'angular';
import '../resources/organization-sector';

const module = angular.module('whyline.services.organization-sector', [
  'whyline.resources.organization-sector',
]);

let OrganizationSector;

class OrganizationSectorService {
  static $inject = ['OrganizationSector'];

  constructor(injectedOrganizationSector) {
    OrganizationSector = injectedOrganizationSector;
  }

  Create(sector, rootSectorId) {
    const newSector = new OrganizationSector(sector);
    return OrganizationSector.Save(newSector, rootSectorId);
  }

  Delete(sectorId) {
    const sectorToRemove = OrganizationSector.FindById(sectorId);
    return OrganizationSector.Remove(sectorToRemove);
  }

  DeleteSubSector(subSectorId) {
    return OrganizationSector.DeleteSubSector(subSectorId);
  }

  GetAll() {
    return OrganizationSector.FetchAll();
  }

  GetAllAsPromise() {
    return OrganizationSector.FetchAll({ promise: true });
  }

  GetById(sectorId) {
    return OrganizationSector.FetchById(sectorId);
  }

  Update(sector, removedId) {
    const newSector = new OrganizationSector(sector);
    return OrganizationSector.Save(newSector, null, removedId);
  }
}

module.exports = module.service('OrganizationSectorService', OrganizationSectorService);
