import angular from 'angular';
import '../../services/notifications';
import '../../services/organization-user';

const module = angular.module('whyline.modals.organization-user-delete', [
  'whyline.services.notifications',
  'whyline.services.organizationuser',
]);

const OrganizationUserDeleteController = function(
  $scope,
  $uibModalInstance,
  $translate,
  NotificationService,
  OrganizationUserService,
  user,
  organizationId
) {
  'ngInject';

  this.save = () => {
    const name = `${user.profile.firstName || ''} ${user.profile.lastName || ''}`;
    $uibModalInstance.close();
    OrganizationUserService.DeleteUserForOrganization($scope.userId, organizationId, $scope.deleteUserForOrganizationAndPlaces).then(() => {
      NotificationService.Success($translate.instant('list_{name}_remove_succ').replace('{name}', name));
    });
  };

  this.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  const init = () => {
    $scope.userId = user._id;
    $scope.deleteUserForOrganizationAndPlaces = true;
  };
  init();
};

module.exports = module.controller('OrganizationUserDeleteController', OrganizationUserDeleteController);
