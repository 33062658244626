import angular from 'angular';
import '../../services/now-serving';
import '../../services/subscription';
import '../../services/globals';
import '../../services/errors';
import '../../services/validation';
import '../../services/third-party-subscription-info';
import '../../services/queue';
import '../../services/area';
import '../../services/access-control';
import { countriesWithCodes } from '../../utils/countries-and-codes';
import { isEmpty, get, toUpper } from 'lodash';
import { CreatedFromEnum } from '../../utils/createdFromEnum';

const module = angular.module('whyline.modals.enqueue', [
  'whyline.services.now-serving',
  'whyline.services.subscription',
  'whyline.services.globals',
  'whyline.services.errors',
  'whyline.services.validation',
  'whyline.services.third-party-subscription-info',
  'whyline.services.access-control',
  'whyline.factories.socket',
  'whyline.services.queue',
  'whyline.services.area',
]);

const EnqueueController = (
  $scope,
  $uibModalInstance,
  $translate,
  AreaService,
  NotificationService,
  SubscriptionService,
  ErrorService,
  ValidationService,
  PlaceService,
  ThirdPartySubscriptionInfoService,
  enqueueIn,
  blockUI,
  currentPlace,
  currentOrganization,
  AccessControlService,
) => {
  'ngInject';
  const place = currentPlace;
  $scope.enterpriseType = place.enterpriseType;

  if (place.configuration.authorizedAndCourtesyDefault) {
    $scope.courtesyAndAuthorizedEnabled = true;
  } else {
    AccessControlService.CanPerformAction(['priority.courtesy'], place._id).then((canCourtesy) => {
      if (canCourtesy) {
        AccessControlService.CanPerformAction(['priority.authorized'], place._id).then(
          (canAuthorized) => {
            if (canAuthorized) {
              $scope.courtesyAndAuthorizedEnabled = true;
            } else {
              $scope.courtesyAndAuthorizedEnabled = false;
            }
          },
        );
      } else {
        $scope.courtesyAndAuthorizedEnabled = false;
      }
    });
  }

  $scope.currentArea = {
    withoutAppointment: {
      withPriority: true,
      withoutPriority: true,
    },
  };

  const currentAreaId = get(enqueueIn, 'areaId', null);
  if (currentAreaId) {
    $scope.currentArea = AreaService.GetOne(currentAreaId);
  }

  $scope.countries = countriesWithCodes;

  let enqueueBlock = blockUI.instances.get('enqueueBlock');
  $scope.blockEnqueueButton = false;

  $scope.thirdPartyApi = get(
    currentOrganization.configuration,
    'thirdPartyInfo.getSubscriptionInfo',
    false,
  );

  $scope.validatePerson = () => {
    if (!ValidationService.validatePerson($scope.person, 'personId')) {
      return;
    }

    ThirdPartySubscriptionInfoService.ValidatePerson(
      $scope.person.idType,
      $scope.person.id,
      currentOrganization._id,
    )
      .then((re) => {
        if (!isEmpty(re)) {
          $scope.person.id = re.id.toString();
          $scope.person.firstName = re.firstName.toString();
          $scope.person.lastName = re.lastName.toString();
          $scope.person.email = re.email.toString();
        } else {
          const idType = $scope.person.idType;
          const id = $scope.person.id;

          $scope.person = $scope.emptyPerson();
          $scope.person.idType = idType;
          $scope.person.id = id;
        }
      })
      .catch(() => {
        NotificationService.Error($translate.instant('error_search_person'));
      });
    $scope.apiSearch = true;
  };

  const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

  const selected =
    identifications.find((id) => id.default) || identifications.find((id) => isEmpty(id.key));

  $scope.idTypesList = identifications;

  const reasons = get($scope, 'currentArea.configuration.reasons', []);
  $scope.reasonsEnabled = reasons.filter((r) => r.enabled);

  $scope.requestReasonAndMotive =
    get($scope, 'currentArea.configuration.requestReasonAndMotive', false) &&
    (enqueueIn.where !== 'line' || $scope.enterpriseType === 'whyline') &&
    $scope.reasonsEnabled.length > 0;

  $scope.reasonRequired =
    get($scope, 'currentArea.configuration.reasonRequired', false) &&
    $scope.reasonsEnabled.length > 0;

  $scope.selectedReason = { value: '' };

  $scope.motive = undefined;

  $scope.selectedIdType = {
    value: selected,
  };

  $scope.showEmail = !(
    !place.requirements.virtualine.email &&
    enqueueIn.where == 'process' &&
    !$scope.currentArea.appointmentsConfiguration.personalData.email
  );
  $scope.showPhone = !(
    !place.requirements.virtualine.phone &&
    enqueueIn.where == 'process' &&
    !$scope.currentArea.appointmentsConfiguration.personalData.phone
  );

  $scope.emptyPerson = () => ({
    id: '',
    idType: selected ? selected.key : undefined,
    phone: {
      countryCode: toUpper(place.location.countryCode),
      full: '',
    },
    email: '',
    priority: null,
  });

  $scope.person = $scope.emptyPerson();

  if (
    $scope.currentArea.withoutAppointment.withPriority &&
    !$scope.currentArea.withoutAppointment.withoutPriority
  ) {
    $scope.priority = {
      show: true,
      selectedCategory: 'disabled',
      canBeChanged: false,
    };
    $scope.person.priority = {
      category: $scope.priority.selectedCategory,
    };
  } else {
    $scope.priority = {
      show: false,
      selectedCategory: 'disabled',
      canBeChanged: true,
    };
  }

  // Id Type
  $scope.updateIdType = () => {
    $scope.person.idType = get($scope.selectedIdType, 'value.key', undefined);
  };

  const setPersonPriority = () => {
    if ($scope.priority.show) {
      $scope.person.priority = {
        category: $scope.priority.selectedCategory,
      };
    } else {
      $scope.person.priority = null;
    }
  };

  $scope.setPriority = () => {
    $scope.priority.show = !$scope.priority.show;
    setPersonPriority();
  };

  $scope.setPriorityCategory = (priorityCategory) => {
    $scope.priority.selectedCategory = priorityCategory;
    $scope.person.priority.category = priorityCategory;
  };

  $scope.enqueue = (person) => {
    const createdFrom = CreatedFromEnum.operator;
    const requestReasonAndMotive =
      get($scope, 'currentArea.configuration.requestReasonAndMotive', false) &&
      (enqueueIn.where !== 'line' || $scope.enterpriseType === 'whyline') &&
      $scope.reasonsEnabled.length > 0;

    const requestPriority = currentAreaId && !$scope.currentArea.withoutAppointment.withoutPriority;

    if (!$scope.person.priority) {
      Reflect.deleteProperty($scope.person, 'priority');
    }

    if (requestReasonAndMotive) {
      if ($scope.selectedReason.value === '' || !$scope.selectedReason.value) {
        if (
          get($scope, 'currentArea.configuration.reasonRequired', false) &&
          $scope.reasonsEnabled.length > 0
        ) {
          NotificationService.Warning($translate.instant('select_a_reason_err'));
          return;
        } else {
          enqueueIn = Object.assign(enqueueIn, { motive: $scope.motive });
        }
      } else {
        enqueueIn = Object.assign(enqueueIn, {
          reason: $scope.selectedReason.value.name,
          motive: $scope.motive,
        });
      }
    }

    if (requestPriority && !$scope.person.priority) {
      NotificationService.Warning($translate.instant('select_a_priority_err'));
      return;
    }

    if (ValidationService.validatePerson(person) && !$scope.blockEnqueueButton) {
      $scope.blockEnqueueButton = true;
      enqueueBlock.start('Creando suscripción...');

      SubscriptionService.Create(person, enqueueIn, createdFrom)
        .then(() => {
          delete enqueueIn.reason;
          delete enqueueIn.motive;
          NotificationService.Success($translate.instant('user_add_fila_succ'));
          enqueueBlock.stop();
          $scope.blockEnqueueButton = false;
          $scope.cancel();
        })
        .catch((err) => {
          ErrorService.handler(err);
          enqueueBlock.stop();
          $scope.blockEnqueueButton = false;
          $scope.cancel();
        });
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller('EnqueueController', EnqueueController);
