import angular from 'angular';
import '../../services/place';
import '../../services/area';
import '../../services/notifications';
import '../../services/organization';
import '../../services/globals';
import '../../services/incident-report';
import { find } from 'lodash';

const module = angular.module('whyline.modals.incident-controller', [
  'whyline.services.organization',
  'whyline.services.area',
  'whyline.services.place',
  'whyline.services.notifications',
  'whyline.services.globals',
  'whyline.services.incident-report'
]);

const IncidentReportModalController = (
  $scope,
  $uibModalInstance,
  $translate,
  OrganizationService,
  AreaService,
  PlaceService,
  NotificationService,
  GlobalsService,
  IncidentReportService,
  $timeout,
) => {
  'ngInject';

  $scope.organizations = [];
  $scope.places = [];
  $scope.areas = [];
  $scope.areaSelected = null;
  $scope.organizationSelected = null;
  $scope.placeSelected = null;
  $scope.branchIdSelected = null;

  OrganizationService.GetAllAsPromise().then(orgs => {
    $scope.organizations = orgs;
  });

  $scope.getPlaceByOrganization = async () => {

    const organization = find($scope.organizations, org => org._id === $scope.organizationSelected);
    OrganizationService.SetCurrent(organization);
    $timeout(() => {
      $scope.placeSelected = null;
      $scope.areaSelected = null;
      PlaceService.GetAllEnterpriseAsPromise().then(places => {
        $scope.places = places;
      });
    });
  };

  $scope.getAreasByPlace = async () => {
    const place = find($scope.places, pl => pl._id === $scope.placeSelected);
    if (!place) {
      return;
    }
    $scope.branchIdSelected = place.branchId;
    PlaceService.SetCurrent(place);
    $timeout(() => {
      $scope.areaSelected = null;
      AreaService.GetMyAreasAsPromiseFromServer().then(areas => {
        $scope.areas = areas;
      });
    });
  };

  $scope.export = () => {
    let message = $translate.instant('validation_custom_incident_report');;
    if (!$scope.organizationSelected) {
      NotificationService.Error(message.replace('{key}', 'una organización.'));
      return;
    }

    if (!$scope.placeSelected || !$scope.branchIdSelected) {
      NotificationService.Error(message.replace('{key}', 'un lugar.'));
      return;
    }

    const data = {
      organizationId: $scope.organizationSelected,
      branchId: $scope.branchIdSelected
    };

    if ($scope.areaSelected) {
      data.areaId = $scope.areaSelected;
    }

   IncidentReportService.GenerateReport(data).then(res => {
    const { message } = res.data;
    if (!message || typeof message !== 'string') {
      NotificationService.Error($translate.instant('success'));
      return;
    }

    IncidentReportService.DownloadReport(data).then(() => {
      NotificationService.Success(message);
    }).catch(err => {
      const { message } = err;

      if (!message || typeof message !== 'string') {
        NotificationService.Error($translate.instant('err_operation'));
        return;
      }

      NotificationService.Error(message);
      return;

    });
   }).catch(err =>  {
    const { message } = err;

    if (!message || typeof message !== 'string') {
      NotificationService.Error($translate.instant('err_operation'));
      return;
    }

    NotificationService.Error(message);
    return;

   });
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };
};

module.exports = module.controller('IncidentReportModalController', IncidentReportModalController);
