import angular from 'angular';
import './modals/resource-groups';
import '../services/resource-group';
import '../services/errors';

const module = angular.module('whyline.controllers.resource-groups', [
  'whyline.modals.resource-groups',
  'whyline.services.resource-group',
  'whyline.services.errors',
]);

const ResourceGroupsController = ($scope, $uibModal, ResourceGroupService, NotificationService, $translate, ErrorService, dialog) => {
  /*@ngInject*/
  $scope.title = 'resourceGroups';
  $scope.quantityOfItems = 8;

  $scope.resourceGroups = ResourceGroupService.GetAll();

  $scope.remove = (resourceGroupId, name) => {
    dialog.confirm(`¿Seguro deseas eliminar el resourceGroup ${name || ''}?`)
      .then(() => {
        ResourceGroupService.Remove(resourceGroupId)
        .then(() => {
          NotificationService.Success(`El resourceGroup ${name || ''} fue removido exitosamente.`);
        })
        .catch(ErrorService.handler);
      });
  };

  $scope.switch = ResourceGroupService.Switch;

  // Modal
  $scope.open = resourceGroupId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/resource-groups-modal.html',
      size: 'lg',
      controller: 'ResourceGroupModalController',
      resolve: {
        resourceGroupId: () => resourceGroupId
      }
    });
  };
};

module.exports = module.controller('ResourceGroupsController', ResourceGroupsController);
