import angular from 'angular';
import '../../services/user';
import '../../services/errors';

const module = angular.module('whyline.controllers.modals.account', [
  'whyline.services.user',
  'whyline.services.errors',
]);

const AccountModalController = ($scope, $uibModalInstance, UserService, ErrorService, NotificationService, $translate) => {
  'ngInject';

  $scope.passwordTypeInput = {
    'current': {
      'showPassword': false,
      'type': 'password',
    },
    'new': {
      'showPassword': false,
      'type': 'password',
    },
    'confirm': {
      'showPassword': false,
      'type': 'password',
    },
  };

  const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])\S{8,}$/);

  $scope.newPasswordHelpTooltip = $translate.instant('new_password_help');
  $scope.me = UserService.CopyCurrent();

  const validate = () => {
    const currentPassword = $scope.me.currentPassword;
    const newPassword = $scope.me.newPassword;
    const confirmPassword = $scope.me.confirmPassword;
    if(!currentPassword) {
      NotificationService.Warning($translate.instant('mandatory_actual_pw'));
      return false;
    }
    if(!newPassword) {
      NotificationService.Warning($translate.instant('mandatory_new_pw'));
      return false;
    }
    if(!confirmPassword) {
      NotificationService.Warning($translate.instant('mandatory_repeat_pw'));
      return false;
    }
    if(!regexPassword.test(newPassword) ){
      NotificationService.Warning($translate.instant('new_password_rules'));
      return false;
    }
    if(newPassword !== confirmPassword) {
      NotificationService.Error($translate.instant('new_password_error_equalto'), 'Error');
      return false;
    }
    return true;
  };

  $scope.save = () => {
    if(validate()) {
      UserService.UpdateCurrent($scope.me)
        .then(() => $scope.cancel())
        .catch(ErrorService.handler);
      // En el then no esta funcionando
      $uibModalInstance.close('cancel');
    }
  };

  $scope.cancel = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.togglePassword = (input) => {
    $scope.passwordTypeInput[input].showPassword = !$scope.passwordTypeInput[input].showPassword;
    $scope.passwordTypeInput[input].type = $scope.passwordTypeInput[input].type === 'password' ? 'text' : 'password';
  }
};

module.exports = module.controller('AccountModalController', AccountModalController);
