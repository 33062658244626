import angular from 'angular';
import moment from 'moment-timezone';
import {
  isEmpty,
  forEach,
  find,
  uniqBy,
  values,
  first,
  eq,
  keys,
  map,
  reduce,
  chain,
  flatMap,
  filter
} from 'lodash';

import '../services/subscription';
import '../services/globals';
import '../services/line';
import '../services/exportTo';
import '../services/area';
import '../services/notifications';
import '../services/user';
import '../services/place';
import '../services/schema';
import '../components/stats';


const module = angular.module('whyline.controllers.stats', [
  'whyline.services.subscription',
  'whyline.services.globals',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.exportTo',
  'whyline.services.notifications',
  'whyline.services.user',
  'whyline.services.place',
  'whyline.services.schema',
  'whyline.components.stats'
]);

const StatsController = ($scope, $translate, NotificationService, GlobalsService, LineService, SubscriptionService, AreaService, ExportToService, UserService, PlaceService, $timeout, SchemaService, $q, $state) => {
  'ngInject';
  $scope.disableSearchFilterButton = false;
  $scope.disableButtonCSV = false;
  $scope.showLoaderForStats = false;
  $scope.placesList = [];
  $scope.selectedPlace = [];

  $scope.from = moment().startOf('day').toDate();
  $scope.to = moment().endOf('day').toDate();
  $scope.linesArr = [];
  $scope.processesArr = [];
  $scope.selectedLines = [];
  $scope.selectedStatus = [];
  $scope.selectedProcessModel = [];
  $scope.selectedProcess = null;
  $scope.schemaList = [];
  $scope.selectedSchema = [];
  $scope.subscriptions = [];
  $scope.statusData = [{
      _id: 1,
      label: 'waiting',
      status: 'waiting',
    },
    {
      _id: 2,
      label: 'processing',
      status: 'processing',
    },
    {
      _id: 3,
      label: 'not-approved',
      reason: 'not-approved',
      status: 'canceled',
    },
    {
      _id: 5,
      label: 'not-checked-in',
      status: 'canceled',
      reason: 'not-checked-in'
    },
    {
      _id: 6,
      label: 'not-present',
      reason: 'not-present',
      status: 'canceled',
    },
    {
      _id: 7,
      label: 'forwarded',
      reason: 'forwarded',
      status: 'canceled',
    },
    {
      _id: 8,
      label: 'unsubscribe',
      reason: 'unsubscribe',
      status: 'canceled',
    },
    {
      _id: 9,
      label: 'queue-clear',
      reason: 'queue-clear',
      status: 'canceled',
    },
    {
      _id: 12,
      label: 'requeued',
      reason: 'requeued',
      status: 'canceled',
    },
    {
      _id: 13,
      label: 'retained',
      reason: 'retained',
      status: 'canceled',
    },
    {
      _id: 11,
      label: 'daily-reset',
      reason: 'daily-reset',
      status: 'canceled',
    },
    {
      _id: 10,
      label: 'completed',
      status: 'completed',
    },
  ];

  $scope.priorityOnly = false;

  $scope.statusOrReasonName = [
    'waiting',
    'processing',
    'not-present',
    'unsubscribe',
    'queue-clear',
    'daily-reset',
    'canceled',
    'requeued',
    'retained',
    'not-checked-in',
    'not-approved',
    'completed',
    'incomplete'];


  $scope.$watchCollection('selectedStatus', (val, oldVal)=> {
    $scope.selectedStatusNames = map($scope.selectedStatus, status => status.reason || status.status);
  });

  $q.when(PlaceService
  .GetByIds(UserService.GetPlacesForStats()))
  .then(places => $timeout(() => {
    $scope.placesList = values(places);
    $scope.placesList.map(place => {
      if(place.plan && place.plan.type === 'free') {
        place.disabled = true;
      }
    });
    if($state.params.placeId) {
      $scope.selectedPlace = filter($scope.placesList, { _id: $state.params.placeId });
    }
  }));

  $scope.hasLines = () => !!$scope.selectedLines.length || !!$scope.selectedStatus.length;
  $scope.hasLinesAndStatus = () => !!$scope.selectedLines.length && !!$scope.selectedStatus.length;
  $scope.hasProcess = () => !!$scope.selectedProcessModel.length;
  $scope.hasSchema = () => !!$scope.selectedSchema.length;
  $scope.hasPlace = () => !!$scope.selectedPlace.length;

  function clearAll() {
    $scope.linesArr = [];
    $scope.processesArr = [];
    $scope.selectedLines = [];
    $scope.selectedStatus = [];
    $scope.selectedProcessModel = [];
    $scope.schemaStats = null;
    $scope.processStats = null;
    $scope.lineStats = null;
    $scope.subscriptions = [];
    $scope.schemaList = [];
    $scope.selectedSchema = [];
  }

  function clearResults () {
    $scope.schemaStats = null;
    $scope.processStats = null;
    $scope.lineStats = null;
  }

  $scope.clearAll = () => {
    $scope.selectedLines = [];
    $scope.selectedStatus = [];
    $scope.selectedProcessModel = [];
    $scope.schemaStats = null;
    $scope.subscriptions = [];
    $scope.selectedSchema = [];
    $scope.priorityOnly = false;
  };

  function reFetchFiltersForSelectedPlace() {
     clearAll();
     LineService.GetAllAsPromiseFromServer().then(linesObjs => $scope.linesArr = values(linesObjs));
     AreaService.GetAllAsPromiseFromServer().then(processObjs => $scope.processesArr = values(processObjs));
    SchemaService.ClearCacheAndGetAllTopLevelAsPromiseFromServer().then(schemas => $scope.schemaList = values(schemas));
  }
  function initializeFilters(linesObjs, processObjs, schemas) {
    clearAll();
    $scope.linesArr = values(linesObjs);
    $scope.processesArr = values(processObjs);
    $scope.schemaList = values(schemas);
  }

  $scope.$watchCollection('selectedPlace', (newPlace, old) => {
    if(eq(first(newPlace), first(old)) || !newPlace.length) return;
    GlobalsService.SetCurrentPlace(first(newPlace));
    $scope.placeId = GlobalsService.CurrentPlace._id.toString();
    $state.params.placeId = newPlace._id;
    reFetchFiltersForSelectedPlace();
  });


  $scope.multiselectTranslations = {
    checkAll: $translate.instant('multiselect_todos'),
    uncheckAll: $translate.instant('multiselect_ninguno'),
    dynamicButtonTextSuffix:  $translate.instant('multiselect_seleccionados'),
    buttonDefaultText: $translate.instant('multiselect_seleccionar'),
    searchPlaceholder: $translate.instant('multiselect_buscar'),
  };

  $scope.linesSettings = {
    template: '<i ng-if="option.supervisorLine" class="icon icon-supervisor"></i><b translate>{{option.label}}</b>',
    externalIdProp: '',
    displayProp: 'label',
    idProp: '_id',
    searchField: 'label',
    enableSearch: true,
    keyboardControls: true,
   // smartButtonMaxItems: 3,
   // smartButtonTextConverter: (itemText, item) => item.label
  };

   $scope.processesSettings = {
    template: '<b ng-if="option.ordered" translate>{{option.label}}</b>',
    externalIdProp: '',
    idProp: '_id',
    searchField: 'label',
    displayProp: 'label',
    //smartButtonMaxItems: 3,
   
    enableSearch: true,
    keyboardControls: true,
   // smartButtonTextConverter: (itemText, item) => item.label
  };

  $scope.schemaSettings = {
    template: '<b translate>{{option.name}}</b>',
    displayProp: 'name',
    idProp: '_id',
    searchField: 'name',
    smartButtonMaxItems: 1,
    enableSearch: true,
    keyboardControls: true,
    selectionLimit: 1,
    showCheckAll: false,
    closeOnSelect: true,
    smartButtonTextConverter: (itemText, item) => item.name
  };

   $scope.placesSettings = {
     template: '<b translate>{{option.name}}</b>',
     externalIdProp: '',
     idProp: '_id',
     searchField: 'name',
     displayProp: 'name',
     smartButtonMaxItems: 3,
     enableSearch: true,
     keyboardControls: true,
     selectionLimit: 1,
     showCheckAll: false,
     showUncheckAll: false,
     closeOnSelect: true,
     smartButtonTextConverter: (itemText, item) => item.name
   };

  $scope.statusSettings = {
    template: '<b translate>{{option.label}}</b>',
    externalIdProp: '',
    idProp: '_id',
    displayProp: 'type',
    //smartButtonMaxItems: 3,
    //smartButtonTextConverter: (itemText, item) => $translate.instant(item.label)
  };


  const getStatusesAndReasons = () => {
    let selectedStatuses = [];
    forEach($scope.selectedStatus, item => {
        selectedStatuses.push({status: item.status, reasons: []});
    });
    selectedStatuses = uniqBy(selectedStatuses, 'status');
    forEach($scope.selectedStatus, item => {
      const founded = find(selectedStatuses, {status: item.status});
      if(founded) {
        if(item.hasOwnProperty('reason') && item.reason) {
          founded.reasons.push(item.reason);
        }
      }
    });
    return selectedStatuses;
  };

  $scope.search = () => {
    clearResults();
    const from = moment($scope.from).startOf('day').utc().toDate();
    const to = moment($scope.to).endOf('day').utc().toDate();
    if($scope.selectedLines.length === 0 && isEmpty($scope.selectedProcessModel) && isEmpty($scope.selectedSchema)) {
      NotificationService.Warning($translate.instant('min_line_prcess_service_select'), $translate.instant('warning'));
      return;
    }
    if($scope.selectedLines.length > 0 && $scope.selectedStatus <= 0) {
      NotificationService.Warning($translate.instant('min_state_select'), $translate.instant('warning'));
      return;
    }

    $scope.disableSearchFilterButton = true;

    $scope.showLoaderForStats = true;

    if($scope.selectedLines.length > 0) {
      getLinesStats(from, to);
    } else if(!isEmpty($scope.selectedProcessModel)) {
      getProcessStats(from, to);
    } else{
      getSchemaStats(from, to);
    }
  };

  const getLinesStats = (from, to) => {
    const status = getStatusesAndReasons();
    let linesIds = [];

    $scope.selectedLines.forEach(lineOrArea => {
      lineOrArea.selectedStatus = {};
      status.forEach(s => {
        lineOrArea.selectedStatus[s] = 0;
      });
      setSelectedLines(lineOrArea, linesIds);
    });
    SubscriptionService.Stats($scope.placeId, linesIds, from, to, status, $scope.priorityOnly)
      .then(response => {

        $scope.lineStats = mapLineStats(response.data.result);
        $scope.disableSearchFilterButton = false;

        $scope.showLoaderForStats = false;
      })
      .catch(() => {
        $scope.disableSearchFilterButton = false;
        $scope.showLoaderForStats = false;
        NotificationService.Warning($translate.instant('err_line_stats'), 'Error');
      });
  };

  function mapLineStats(stats) {
    let lineIds = keys(stats);
    forEach(lineIds, lineId => stats[lineId].lineId = lineId);
    let lines = values(stats);
    forEach(lines, mapLine());
    return lines;
  }

  const mapSubscriptionStatsData = subscriptionsDict => {
    $scope.selectedLines.forEach(lineOrArea => {
      for(let lineOrAreaId in subscriptionsDict) {
        if(lineOrArea._id === lineOrAreaId) {
          for(let key in subscriptionsDict[lineOrAreaId]) {
            lineOrArea[key] = subscriptionsDict[lineOrAreaId][key];
          }
        }
      }
    });
  };

  const setSelectedLines = (line, linesIds) => {
    linesIds.push(line._id);
  };

  const getProcessStats = (from, to) => {
    let areas = map($scope.selectedProcessModel, area => area._id);
    SubscriptionService.StatsProcess(areas, from, to, $scope.priorityOnly)
      .then(response => {
        $scope.processStats = response.data;
        $scope.processStats.type = 'area';
        forEach($scope.processStats, mapLineLabel());
        $scope.disableSearchFilterButton = false;
        $scope.showLoaderForStats = false;

      })
      .catch(() => {
        $scope.disableSearchFilterButton = false;
        $scope.showLoaderForStats = false;
        NotificationService.Warning($translate.instant('err_line_stats'), 'Error');
      });
  };

  $scope.statusOrReasonName = [
    'waiting',
    'processing',
    'not-present',
    'unsubscribe',
    'queue-clear',
    'daily-reset',
    'canceled',
    'requeued',
    'retained',
    'not-checked-in',
    'not-approved',
    'completed',
    'incomplete'];

  const getSchemaStats = (from, to) => {
    let schemaId = $scope.selectedSchema[0]._id;
    SubscriptionService.SchemaStats(schemaId, from, to, $scope.priorityOnly)
      .then(response => {

         $scope.schemaStats = response.data;
         $scope.schemaStats.label = $scope.schemaStats.name;
         $scope.schemaStats.type = 'sector';
         forEach($scope.schemaStats.areas, mapLineLabel($scope.schemaStats));
         forEach($scope.schemaStats.lines, mapLine($scope.schemaStats));
         forEach($scope.schemaStats.sectors, mapSchemaRecursively($scope.schemaStats));
         $scope.disableSearchFilterButton = false;
         $scope.showLoaderForStats = false;
      })
      .catch(() => {
        $scope.disableSearchFilterButton = false;
        $scope.showLoaderForStats = false;
        NotificationService.Warning($translate.instant('err_line_stats'), 'Error');
      });
  };

   const mapSchemaRecursively = schemaParent => schema => {

     schema.parent = schemaParent;
     schema.label = schema.name;
      forEach(schema.areas, mapLineLabel(schema));
      forEach(schema.lines, mapLine(schema));
      forEach(schema.sectors, mapSchemaRecursively(schema));
  }

  const mapLineLabel = schema => process => {
    process.processLines = [];
    process.parent = schema;
    process.type = 'area';
    $scope.linesArr.forEach(line => {
      if (process.result[line._id]) {
        let result = {
          label: line.label,
          csvlabel: line.supervisorLine ? `${line.label} (s)` : line.label,
          _id: line._id,
          type: 'line',
          parent: process,
          stats: process.result[line._id]
        };
        process.processLines.push(result);
      }
    });
  };

  const mapLine = schema => lineStats => {
    let line = find($scope.linesArr, li => li._id == lineStats.lineId);
    if(!line){
      
      lineStats.type = 'line';
      lineStats.parent = schema;
    } else {
    lineStats.label = line.label;
    lineStats.csvlabel = line.supervisorLine ? `${line.label} (s)` : line.label;
    lineStats.type = 'line';
    lineStats.parent = schema;
    }
  };

  // DatePicker configuration
  $scope.inlineOptions = {
    customClass: getDayClass,
    minDate: new Date(),
    showWeeks: true
  };

  let today = new Date();
  //today.setMonth(today.getMonth()+6);
  //$scope.maxDate = new Date(today.getFullYear(),today.getMonth() , today.getDate());

  $scope.dateOptionsFrom = {
    // dateDisabled: disabledWeekend,
    formatYear: 'yy',
    minDate: new Date(2016, 1, 1),
    //maxDate: moment($scope.to),
    maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    startingDay: 1,
  };

  $scope.dateOptionsTo = {
    // dateDisabled: disabledWeekend,
    formatYear: 'yy',
    //maxDate: new Date(2020, 5, 22),
    maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    minDate: moment($scope.from),
    startingDay: 1,
  };

  $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
  $scope.format = $scope.formats[0];
  $scope.altInputFormats = ['M!/d!/yyyy'];

  $scope.datesIsOpen = {
    from: false,
    to: false,
  };

  // DatePicker functions
  $scope.today = function() {
    $scope.to = Date.now();
  };
  $scope.today();

  $scope.clear = function() {
    $scope.dt = null;
  };

  $scope.toggleMin = () => {
    $scope.inlineOptions.minDate = $scope.inlineOptions.minDate ? null : new Date();
    $scope.dateOptionsTo.minDate = moment($scope.from);
  };

  $scope.toggleMin();

  $scope.toggleDateOpen = item => {
    $scope.datesIsOpen[item] = !$scope.datesIsOpen[item];
  };

  $scope.setDate = (year, month, day) => {
    $scope.dt = new Date(year, month, day);
  };

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  var afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  $scope.events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

  const getDayClass = data => {
    let date = data.date;
    data.date.notice = undefined;
    let mode = data.mode;
    if(mode === 'day') {
      var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
      for(var i = 0; i < $scope.events.length; i++) {
        let currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

        if(dayToCheck === currentDay) {
          return $scope.events[i].status;
        }
      }
    }
    return '';
  };

  $scope.$watch('from', newVal => {
    $scope.dateOptionsTo = {
      // dateDisabled: disabledWeekend,
      formatYear: 'yy',
      //maxDate: new Date(2020, 5, 22),
      maxDate: new Date(today.getFullYear(),today.getMonth() , today.getDate()),
      minDate: moment(newVal),
      startingDay: 1,
    };
  });

  $scope.$watch('to', newVal => {
    $scope.dateOptionsFrom = {
      // dateDisabled: disabledWeekend,
      formatYear: 'yy',
      maxDate: moment(newVal),
      startingDay: 1,
    };
  });

  const aliases = {
    'line': 'Linea',
    'sector': 'Servicio',
    'area': 'Proceso'
  };

  const fields = [
    {
      label: $translate.instant('stats_label_Lugar'),
      value: () => first($scope.selectedPlace).name
    },
    {
      label: $translate.instant('stats_label_FechaDesde'),
      value: () => moment($scope.from).format('YYYY-MM-DD')
    },
    {
      label: $translate.instant('stats_label_FechaHasta'),
      value: () => moment($scope.to).format('YYYY-MM-DD')
    },
    {
      label: $translate.instant('stats_label_Servicio'),
      value: function (row) {
        if (row.type === 'sector' && row.parent) {
          return row.parent.name
        } else if (row.type === 'line' && row.parent && row.parent.parent && row.parent.parent.type === 'sector') {

          return row.parent.parent.name;

        } else if (row.parent && row.parent.type === 'sector') {
          return row.parent.name;
        }  else{
          return row.type === 'sector' ? row.label : '';
        }
      }
    }, 
    {
      label: $translate.instant('stats_label_SubServicio'),
      value: function (row) {
        if(row.type === 'sector' && row.parent) {
          return row.name;
        } else if(row.parent && row.parent.type === 'sector' && row.parent.parent && row.type === 'line') {
          return row.parent.name;
        }
        return '';
      }
    },
    {
      label: $translate.instant('stats_label_Proceso'),
      value: function (row) {
        if (row.type === 'area') {
          return row.label;
        } else if(row.parent && row.parent.type === 'area') {
          return row.parent.label;
        } else {
          return '';
        }
      }
    },
    {
      label: $translate.instant('stats_label_Linea'),
      value: function(row) {
        return row.type === 'line' ? row.csvlabel : '';
      }
    },
    {
      label: $translate.instant('stats_label_Proximos'),
      value: 'waiting'
    }, {
      label: $translate.instant('stats_label_atencion'),
      value: 'processing'
    }, {
      label: $translate.instant('stats_label_Ausente'),
      value: 'not-present'
    }, {
      label: $translate.instant('stats_label_Eliminado'),
      value: 'unsubscribe'
    }, {
      label: $translate.instant('stats_label_Anulado_m'),
      value: 'queue-clear'
    }, {
      label: $translate.instant('stats_label_Anulado_a'),
      value: 'daily-reset'
    }, {
      label: $translate.instant('stats_label_Vencido'),
      value: 'not-checked-in'
    }, {
      label: $translate.instant('stats_label_Incompleto'),
      value: 'not-approved'
    },{
      label: $translate.instant('stats_label_Reencolado'),
      value: 'requeued'
    },{
      label: $translate.instant('stats_label_Retenido'),
      value: 'retained'
    }, {
      label: $translate.instant('stats_label_Total_nocompleto'),
      value: 'incomplete'
    }, {
      label: $translate.instant('stats_label_Completo'),
      value: 'completed'
    }

  ];

  const getAreasForCsv = schema => processStats =>  {
   return reduce(processStats, (arr, process) => {
      let stats = process.processStatus;
      let areaAndLines = [{ ...stats, type: 'area', parent: schema , label: process.label }];
      let lines = map(process.processLines, line => { return { ...line.stats, parent: line.parent, label:line.label, csvlabel: line.csvlabel, type: line.type}});
      return arr.concat(areaAndLines).concat(lines);
    }, []);
  }

  function getAreasForCsvOrNull(){
    let st = getAreasForCsv()($scope.processStats);
    return st.length && st ||null;
  }

  function getSchemaforCsv () {
    return $scope.schemaStats && flattenSchema([])($scope.schemaStats);
  }

  const flattenSchema = arr => schema => {
    let flat = chain(arr).concat([schema]).concat(getAreasForCsv(schema)(schema.areas)).concat(schema.lines).value();
    if(!schema.sectors.length) {
      return flat;
    }
    return flatMap(schema.sectors, flattenSchema(flat));
  };

  $scope.exportAsCSV = () => {
    let data = $scope.lineStats || getSchemaforCsv() || getAreasForCsvOrNull();
    
    if (data) { ExportToService.jsonToCSV({ data, fields }, 'whylineStatistics'); }

  };

  $scope.$on('$destroy',()=> {
    //clear current place to force read from url
    if ($state.params.placeId) {
      PlaceService.SetCurrent(find($scope.placesList, { _id: $state.params.placeId }));    
    }
        
  })
}


module.exports = module.controller('StatsController', StatsController);
