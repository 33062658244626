import angular from 'angular';
import '../../services/organization-scenario';
import '../../services/organization-area';
import '../../services/organization-condition';
import '../../services/errors';
import { cloneDeep, get } from 'lodash';

const module = angular.module('whyline.modals.organization-scenario', [
  'whyline.services.organization-scenario',
  'whyline.services.organization-area',
  'whyline.services.organization-condition',
  'whyline.services.errors',
]);

const OrganizationScenarioModalController = (
  $scope,
  $timeout,
  $uibModalInstance,
  $translate,
  OrganizationScenarioService,
  OrganizationAreaService,
  OrganizationConditionService,
  NotificationService,
  ErrorService,
  organizationScenarioId,
  currentOrganization,
) => {
  'ngInject';

  $scope.blockSaveButton = false;

  $scope.conditions = [];

  $scope.areas = [];

  $scope.scenario = {
    name: '',
    areaId: '',
    conditionId: '',
  };

  const areasPromise = OrganizationAreaService.GetAllAsPromise();
  const conditionsPromise = OrganizationConditionService.GetAllAsPromise();

  const promises = [areasPromise, conditionsPromise];

  Promise.all(promises).then(([areas, conditions]) => {
    $scope.areas = Object.values(areas);
    $scope.setAreaSelected($scope.areas[0]);

    $scope.conditions = Object.values(conditions);
    $scope.setConditionSelected($scope.conditions[0]);
    if (organizationScenarioId) {
      getOrganizationScenariosById(organizationScenarioId);
    }
  });


  $scope.setAreaSelected = area => {
    $scope.areaSelected = area;
    $scope.scenario.areaId = area._id;
  };

  $scope.setConditionSelected = condition => {
    $scope.conditionSelected = condition;
    $scope.scenario.conditionId = condition._id;
  };

  const getOrganizationScenariosById = id => {
    OrganizationScenarioService.GetOneAsPromise(id).then(scenario => {
      $timeout(() => {
        $scope.scenario = cloneDeep(scenario);
        const area = $scope.areas.find(a => a._id === scenario.areaId);
        $scope.setAreaSelected(area);
        $scope.setConditionSelected($scope.conditions.find(c => c._id === scenario.conditionId));
      });
    });
  }

  const validate = () => {
    const { name, areaId, conditionId } = $scope.scenario;
    const nameMaxLength = 56;
    const validCharactersRegex = new RegExp(/^[a-zA-ZáéíóúñÁÉÍÓÚÑ ]+$/g);

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_name'));
      return false;
    }

    if (name.length > nameMaxLength) {
      NotificationService.Warning(
        $translate.instant('max_{maxNumber}_length_name').replace('{maxNumber}', nameMaxLength),
      );
      return false;
    }

    if (!validCharactersRegex.test(name)) {
      NotificationService.Warning($translate.instant('name_only_letters'));
      return false;
    }

    if (!areaId) {
      NotificationService.Warning($translate.instant('scenario_area_required'));
      return false;
    }

    if (!conditionId) {
      NotificationService.Warning($translate.instant('scenario_condition_required'));
      return false;
    }

    return true;
  };

  $scope.close = () => {
    $uibModalInstance.close('cancel');
  };

  $scope.save = () => {
    if (validate()) {
      $scope.blockSaveButton = true;
      let message = '';
      let promise = null;
      if (organizationScenarioId) {
        message = $translate.instant('scenario_update_succ');
        promise = OrganizationScenarioService.Update($scope.scenario);
      } else {
        message = $translate.instant('scenario_create_succ');
        promise = OrganizationScenarioService.Create($scope.scenario);
      }

      promise
        .then(() => {
          NotificationService.Success(message);
          $uibModalInstance.close('cancel');
        })
        .catch(err => {
          ErrorService.handler(err);
          $uibModalInstance.close('cancel');
        });
    }
  };
};

module.exports = module.controller(
  'OrganizationScenarioModalController',
  OrganizationScenarioModalController,
);
