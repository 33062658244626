import angular from 'angular';
import '../factories/socket.io';
import '../resources/appointment';
import '../services/globals';
import '../services/subscription';
import '../services/area';
import '../services/line';
import '../services/errors';
import '../services/appointment';
import '../services/socket';

const module = angular.module('whyline.sockets.appointment', [
  'whyline.factories.socket',
  'whyline.resources.appointment',
  'whyline.services.globals',
  'whyline.services.subscription',
  'whyline.services.area',
  'whyline.services.line',
  'whyline.services.errors',
  'whyline.services.appointment',
  'whyline.services.socket',
]);

const AppointmentSocket = (Socket, $timeout, Appointment, SubscriptionService, AreaService, API_URL, LineService, ErrorService, AppointmentService, SocketService, $rootScope) => {
  $rootScope.$on('socketReady', (event, socket) => {

    const commonHandler = appointment => {
      Appointment.UpdateCache(appointment._id, appointment);
    };

    // CREATED
    socket.on('appointment:created', commonHandler);

    // CHECKED IN
    socket.on('appointment:checkin', data => {
      const {
        appointment,
        subscription
      } = data;

      commonHandler(appointment);

      AreaService.GetOneAsPromise(subscription.areaId).then(queue => {
        SubscriptionService.UpdateSubscriptionsCacheSearch(queue, subscription, false, true);
      });
    });

    // UPDATED
    socket.on('appointment:updated', commonHandler);

    // forwarded
    socket.on('appointment:forwarded', commonHandler);
  });
};

module.exports = module.factory('AppointmentSocket', (Socket, $timeout, Appointment, SubscriptionService, AreaService, API_URL, LineService, ErrorService, AppointmentService, SocketService, $rootScope) => new AppointmentSocket(Socket, $timeout, Appointment, SubscriptionService, AreaService, API_URL, LineService, ErrorService, AppointmentService, SocketService, $rootScope));
