import angular from 'angular';
import '../services/banner';
import '../services/errors';
import '../services/notifications';
import './modals/banner';
import '../services/globals';

const module = angular.module('whyline.controllers.banners', [
  'whyline.services.banner',
  'whyline.services.errors',
  'whyline.services.notifications',
  'whyline.modals.banner',
  'whyline.services.globals',
]);

const BannersController = function (
  $scope,
  $uibModal,
  $translate,
  dialog,
  BannerService,
  NotificationService,
  ErrorService,
  currentPlace,
  GlobalsService
) {
  /*@ngInject*/

  $scope.banners = BannerService.GetAll();
  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';
  $scope.open = bannerId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/banners-modal.html',
      size: 'lg',
      controller: 'BannerModalController',
      resolve: {
        bannerId: () => bannerId,
        currentPlace,
      },
    });
  };

  $scope.delete = (bannerId, bannerName) => {
    const name = bannerName || '';
    dialog
      .confirm(
        $translate.instant('banner_{name}_remove_sure').replace('{name}', name)
      )
      .then(() => {
        BannerService.Remove(bannerId)
          .then(() => {
            NotificationService.Success(
              $translate.instant('banner_{name}_remove_succ')
            );
            $scope.banners = BannerService.GetAll();
          })
          .catch(err => {
            ErrorService.handler(err);
          });
      });
  };
};

module.exports = module.controller('BannersController', BannersController);
