const CreatedFromEnum = {
  agenda: 'agenda',
  mobileApp: 'mobile-app',
  operator: 'operator',
  sigeci: 'sigeci',
  thirdParty: 'third-party',
  totemOperator: 'totem-operator',
  virtualineAgenda: 'virtualine-agenda',
}

module.exports = {
  CreatedFromEnum,
}
