import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/organization-scenario';

const module = angular.module('whyline.services.organization-scenario', [
  'whyline.resources.organization-scenario'
]);

let OrganizationScenario;

class OrganizationScenarioService {

  static $inject = ['OrganizationScenario'];

  constructor(injectedOrganizationScenario) {
    OrganizationScenario = injectedOrganizationScenario;
  }

  Create(data) {
    const newOrganizationScenario = new OrganizationScenario(data);
    return OrganizationScenario.Save(newOrganizationScenario);
  }

  Copy(organizationScenario) {
    const copy = cloneDeep(organizationScenario);
    return new OrganizationScenario(copy);
  }

  Update(scenario) {
    return OrganizationScenario.Save(scenario);
  }

  Remove(scenarioId) {
    const scenarioToRemove = OrganizationScenario.FindById(scenarioId);
    return OrganizationScenario.Remove(scenarioToRemove);
  }

  GetAll() {
    return OrganizationScenario.FetchAll();
  }

  GetAllByOrganizationId(organizationId) {
    return OrganizationScenario.GetAll(organizationId);
  }

  GetAllFromServer() {
    return OrganizationScenario.FetchAll({force: true});
  }

  GetAllAsPromise() {
    return OrganizationScenario.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return OrganizationScenario.FetchAll({ promise: true, force: true});
  }

  GetOne(scenarioId) {
    return OrganizationScenario.FindById(scenarioId);
  }

  GetOneFromServer(scenarioId) {
    return OrganizationScenario.FindById(scenarioId, {force: true});
  }

  GetOneAsPromiseFromServer(scenarioId) {
    return OrganizationScenario.FindById(scenarioId, {promise: true, force: true});
  }

  GetOneAsPromise(scenarioId) {
    return OrganizationScenario.FindById(scenarioId, {promise: true});
  }

  FindByIds(ids, options) {
    return OrganizationScenario.FindByIds(ids, options);
  }

  Clear(scenario) {
    return scenario.clear();
  }

}

module.exports = module.service('OrganizationScenarioService', OrganizationScenarioService);
