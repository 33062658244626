import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/organization-area';
import '../resources/organization-line';
import '../resources/user';
import '../resources/subscription';

const module = angular.module('whyline.services.organization-area', [
  'whyline.resources.organization-area',
  'whyline.resources.organization-line',
  'whyline.resources.user',
]);

class OrganizationAreaService {
  static $inject = ['OrganizationArea', 'OrganizationLine', 'User', '$timeout'];

  constructor(OrganizationArea, OrganizationLine, User, $timeout) {
    this.OrganizationArea = OrganizationArea;
    this.OrganizationLine = OrganizationLine;
    this.User = User;
    this.$timeout = $timeout;
  }

  GetAll() {
    return this.OrganizationArea.FetchAll();
  }

  GetAllFromServer() {
    return this.OrganizationArea.FetchAll({force: true});
  }

  GetAllAsPromise() {
    return this.OrganizationArea.FetchAll({ promise: true });
  }

  GetAllPopulated() {
    return this.OrganizationArea.FetchAll({populate: ['organizationLines']});
  }

  Copy(organizationArea) {
    const copy = cloneDeep(organizationArea);
    return new this.OrganizationArea(copy);
  }

  Update(organizationArea) {
    return this.OrganizationArea.Save(organizationArea);
  }

  /**
   * Update partial fields of Organization Area
   *
   * @param {Object} options
   * @param {Object} options.data
   * @param {String} options.organizationId
   * @param {String} options.organizationAreaId
   * @return {Object}
   * @public
   * @static
   */
  UpdatePartial(options) {
    return this.OrganizationArea.SavePartial(options);
  }

  Create(data) {
    const newOrganizationArea = new this.OrganizationArea(data);
    return this.OrganizationArea.Save(newOrganizationArea);
  }

  Remove(organizationAreaId) {
    const organizationArea = this.OrganizationArea.FindById(organizationAreaId);
    return this.OrganizationArea.Remove(organizationArea);
  }

  GetMyAreasAsPromise() {
    return this.OrganizationArea.GetMyAreas({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return this.OrganizationArea.FetchAll({ promise: true, force: true });
  }

  GetWithLinesAsPromise(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId, {
        promise: true,
        populate: ['organizationLines']
      })
      .then(organizationArea => {
        return this.OrganizationLine.FindByIds(organizationArea.organizationLines, { promise: true })
        .then(lines => {
          organizationArea.organizationLinesPopulated = lines;
          return organizationArea;
        });
      });
  }

  GetWithLinesAsPromiseFromServer(organizationAreaId) {
    return this.OrganizationLine.FetchAll({ promise: true })
      .then(() => this.OrganizationArea.FindById(organizationAreaId, {
        populate: ['organizationLines'],
        promise: true,
        force: true
      })
    );
  }

  FindByIds(ids, options) {
    return this.OrganizationArea.FindByIds(ids, options);
  }

  Switch(area, enabled, enableInPlaces) {
    return area.enable(enabled, enableInPlaces);
  }

  GetOne(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId);
  }

  GetOneAsPromise(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId, {promise: true});
  }

  GetOneFromServer(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId, {force: true});
  }

  GetOneWithLines(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId, {populate: ['organizationLines'] });
  }

  GetOneWithLinesAsPromise(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId, {populate: ['organizationLines'], promise: true });
  }

  GetOneWithLinesAsPromiseFromServer(organizationAreaId) {
    return this.OrganizationArea.FindById(organizationAreaId, {populate: ['organizationLines'], promise: true, force: true });
  }
}

module.exports = module.service('OrganizationAreaService', OrganizationAreaService);
