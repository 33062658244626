import angular from 'angular';
import '../resources/display';
import '../app.constants';

const module = angular.module('whyline.services.displaytv', [
  'whyline.constants',
  'whyline.resources.display',
]);

let Display;

class DisplayTvService {

  static $inject = ['Display'];

  constructor(injectedDisplay) {
    Display = injectedDisplay;
  }

  GetDisplayTvData(displayId, isCallNext) {
    return Display.GetDisplayTvData(displayId, isCallNext)
      .then(displayTvData => Promise.resolve(displayTvData));
  }

}

module.exports = module.service('DisplayTvService', DisplayTvService);
