import angular from 'angular';
import './../app.constants';
import './../services/line';
import './../services/area';

const module = angular.module('whyline.services.queue', [
  'whyline.constants',
  'whyline.services.line',
  'whyline.services.area',
]);

let API_URL;
let SAFE_STATES;
let $state;
let $timeout;
let ErrorService;
let LineService;
let AreaService;
let NotificationService;
let $translate;

class QueueService {

  static $inject = ['API_URL', 'SAFE_STATES', '$state', '$timeout', 'ErrorService', 'LineService', 'AreaService', 'NotificationService', '$translate'];

  constructor(injectedAPI, injectedSAFE_STATES, injected$state, injected$timeout, injectedErrorService, injectedLineService, injectedAreaService, injectedNotificationService, injected$Translate) {
    this.currentQueue = null;
    API_URL = injectedAPI;
    $state = injected$state;
    $timeout = injected$timeout;
    SAFE_STATES = injectedSAFE_STATES;
    ErrorService = injectedErrorService;
    LineService = injectedLineService;
    AreaService = injectedAreaService;
    NotificationService = injectedNotificationService;
    $translate = injected$Translate;
  }

  SetCurrent(queue) {
    this.currentQueue = queue;
  }

  GetQueueById(ids) {
    if(ids.lineId) {
      return LineService.GetOneAsPromise(ids.lineId);
    } else if(ids.areaId) {
      return AreaService.GetOneAsPromise(ids.areaId);
    }
  }

  CallNext(queue) {
    if(queue.type === 'line') {
      return LineService.CallNext(queue._id);
    } else if(queue.type === 'area' && !queue.ordered) {
      return AreaService.CallNext(queue._id);
    } else {
      NotificationService.Error($translate.instant('err_queue_subscriptions_all'), 'Error');
    }
  }

  CallTo(subscriptionId, queue) {
    if(queue.type === 'line') {
      return LineService.CallTo(subscriptionId, queue._id);
    } else if(queue.type === 'area' && !queue.ordered) {
      return AreaService.CallTo(subscriptionId, queue._id);
    } else {
      NotificationService.Error($translate.instant('err_queue_subscriptions_all'), 'Error');
    }
  }

}

module.exports = module.service('QueueService', QueueService);
