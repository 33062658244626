import angular from 'angular';
import '../app.constants';
import '../factories/socket.io';
import '../resources/line';
import '../resources/subscription';
import '../services/notifications';
import '../services/socket';
import '../services/user';

const module = angular.module('whyline.sockets.line', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.line',
  'whyline.resources.subscription',
  'whyline.services.notifications',
  'whyline.services.socket',
  'whyline.services.user',
]);

const LineSocket = (Socket, Line, Subscription, NotificationService, API_URL, SocketService, $rootScope, $translate, UserService) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('line:created', line => {
        UserService.SyncCurrent()
          .then(user => {
            const currentRole = UserService.GetCurrentRoleFor(user);
            if(currentRole.name === 'Coordinator' || currentRole.name === 'Admin General') {
              Line.UpdateCache(line._id, line);
              // Update cache for carousel
              const cacheUrl = `${API_URL}line/allowed`;
              const list = Line.GetCacheSearch(cacheUrl);
              list[line._id] = line;
              Line.UpdateCacheSearch(cacheUrl, list, {});
            }
          });
      });
      socket.on('line:updated', line => {
        Line.UpdateCache(line._id, line);
      });
      socket.on('line:cleared', line => {
        let url = `${API_URL}subscription?enqueueIn=line&lineId=${line._id}`;
        Subscription.ClearCacheData(url);
        Line.UpdateCache(line._id, line);
        let n = line.label;
        let text = $translate.instant('line_{name}_was_clean').replace('{name}', n);
        NotificationService.Information(text);
      });
      socket.on('line:deleted', line => {
        Line.RemoveFromCache(line._id, line._type);
        const cacheUrl = `${API_URL}line/allowed`;
        const list = Line.GetCacheSearch(cacheUrl);
        if(list.hasOwnProperty(line._id)) {
          Reflect.deleteProperty(list, line._id);
        }
        Line.UpdateCacheSearch(cacheUrl, list, {});
      });
    });
  });
};

module.exports = module.factory('LineSocket', (Socket, Line, Subscription, NotificationService, API_URL, SocketService, $rootScope, $translate, UserService) => new LineSocket(Socket, Line, Subscription, NotificationService, API_URL, SocketService, $rootScope, $translate, UserService));

