import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/role';

const module = angular.module('whyline.services.role', ['whyline.resources.role']);

let Role;

const handleError = err => {
  console.error(err);
  throw err;
};

class RoleService {

  static $inject = ['Role'];

  constructor(injectedRole) {
    Role = injectedRole;
  }

  Create(data) {
    const newRole = new Role(data);
    newRole.type = 'role';
    return Role.Save(newRole);
  }

  Copy(role) {
    const copy = cloneDeep(role);
    return new Role(copy);
  }

  Update(role) {
    return Role.Save(role);
  }

  Remove(roleId) {
    const role = Role.FindById(roleId);
    return Role.Remove(role);
  }

  GetAll() {
    return Role.FetchAll();
  }

  GetAllAsPromise() {
    return Role.FetchAll({ promise: true });
  }

  GetOne(roleId) {
    return Role.FindById(roleId);
  }

  GetOneAsPromise(roleId) {
    return Role.FindById(roleId, { promise: true });
  }

  GetOneAsPromiseFromServer(roleId) {
    return Role.FindById(roleId, { promise: true, force: true });
  }

}

module.exports = module.service('RoleService', RoleService);
