import angular from 'angular';
import '../resources/ticket-template';


const module = angular.module('whyline.services.ticket-template', [
  'whyline.resources.ticket-template',
]);

let TicketTemplate;
let $timeout;

class TicketTemplateService {

  static $inject = ['TicketTemplate', '$timeout'];

  constructor(injectedTicketTemplate, injected$timeout) {
    TicketTemplate = injectedTicketTemplate;
    $timeout = injected$timeout;
  }

  GetAllByOrganizationId(organizationId) {
    return TicketTemplate.GetAllByOrganizationId(organizationId);
  }
  
}

module.exports = module.service('TicketTemplateService', TicketTemplateService);