import angular from 'angular';

const module = angular.module('whyline.directives.mustequals', []);

const mustEquals = () => {
  return {
      require: "ngModel",
      scope: {
          otherValue: "=mustEquals"
      },
      link: function(scope, element, attributes, ngModel) {

          ngModel.$validators.mustEquals = function(modelValue) {
              return modelValue == scope.otherValue;
          };

          scope.$watch("otherValue", function() {
              ngModel.$validate();
          });
      }
  };
};

module.exports = module.directive("mustEquals", mustEquals);
