import angular from 'angular';
import './resource';
import '../app.constants';
import {
  schedule
} from '../utils';
import {
  isEmpty,
  values,
} from 'lodash';
import { Certificate } from 'crypto';

const module = angular.module('whyline.resources.organization-area', [
  'whyline.resources.resource',
  'whyline.resources.organization-line',
  'whyline.constants',
]);

const createWithoutAppointment = () => {
  let withoutAppointmentObject = {
    withPriority: {
      type: Boolean,
      required: true,
      default: false,
    },
    withoutPriority: {
      type: Boolean,
      required: true,
      default: false,
    },
    requestSource: {
      mobileApp: {
        type: Boolean,
        required: true,
        default: false,
      },
      reception: {
        type: Boolean,
        required: true,
        default: false,
      },
      totem: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
  };
  return withoutAppointmentObject;
};

const OrganizationAreaFactory = (API_URL, $http, Resource, OrganizationLine, $timeout) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'organization/:organizationId/process';

  const _schema = {
    _id: {
      type: String
    },
    organizationId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    ordered: {
      type: Boolean,
      required: true
    },
    organizationLines: {
      type: [OrganizationLine],
      required: true,
      default: []
    },
    createdAt: {
      type: Date,
      required: true
    },
    modifiedAt: {
      type: Date,
      required: true
    },
    type: {
      type: String,
      required: false,
    },
    schedule: {
      type: Object,
      required: true,
      default: schedule.create()
    },
    maxNumbers: {
      type: Number,
      required: true,
      default: 0
    },
    open: {
      type: Boolean,
      required: true,
    },
    serviceId: {
      type: String,
      required: false,
      default: '',
    },
    configuration: {
      postMessage: String,
      reasonRequired: Boolean,
      reasons: [],
      requestReasonAndMotive: Boolean,
    },
    organizationId: {
      type: String,
      required: true,
    },
    isDeleted: {
      type: Boolean,
    },
    isWithAppointment: {
      type: Boolean,
      required: true,
      default: false,
    },
    isWithoutAppointment: {
      type: Boolean,
      required: true,
      default: false,
    },
    withoutAppointment: createWithoutAppointment(),
  };

  const OrganizationArea = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.enable = (enabled, enableInPlaces) => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
        enableInPlaces
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            // Cuando halla respuesta, modificar el cache
            OrganizationArea.UpdateCache(item._id, item, null);
          });
          // Devuelve la respuesta desde el cache.
          return OrganizationArea.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  OrganizationArea.SavePartial = options => {
    const url = `${API_URL}organization/${options.organizationId}/process/${options.organizationAreaId}`;
    return $http({
      method: 'PATCH',
      url: url,
      data: options.data,
    })
      .then(response => {
        if (response.data) {
          const item = response.data;

          OrganizationArea.UpdateCache(item._id, item, null);

          return response;
        }
      }).catch(e => {
        throw e;
      });
  };

  OrganizationArea.prototype = new Resource(_type, _schema, OrganizationArea);

  Resource.Extend(_type, OrganizationArea);

  //one minute
  const validityTime = 5 * 60 * 1000;
  OrganizationArea.InitCache(validityTime);

  return OrganizationArea;
};

module.exports = module.factory('OrganizationArea', OrganizationAreaFactory);
