import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.country', ['whyline.resources.resource']);

const CountryFactory = Resource => {
  /*@ngInject*/

  const _type = 'country';

  const _schema = {
    _id: String,
    code: String,
    createdAt: Date,
    identifications: [
      {
        default: Boolean,
        inputType: String,
        key: String,
        name: String,
        selected: Boolean,
        validator: String,
      }
    ],
    name: String,
    updatedAt: Date,
  };

  const Country = function(data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  Country.prototype = new Resource(_type, _schema, Country);

  Resource.Extend(_type, Country);

  //one minute
  const validityTime = 5 * 60 * 1000;
  Country.InitCache(validityTime);

  return Country;
};

export default module.factory('Country', CountryFactory);
