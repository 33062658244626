import { forEach, isArray, cloneDeep } from 'lodash';
import Cache from './cache';

let API_URL;
let $http;
let $timeout;

const $inject = (name, value) => {
  switch (name) {
    case 'API_URL':
      API_URL = value;
      break;
    case '$http':
      $http = value;
      break;
    case '$timeout':
      $timeout = value;
      break;
  }
};

/**
 * Each instance has frontend values that we don't
 * want to save in backend, so this method returns
 * a copy of the instance with only the porperties
 * that matters for backend.
 *
 * @param {Resource} item
 * @return {Object}
 * @public
 * @static
 */
const Clean = item => {
  // makes a copy of the current item
  const itemCopy = cloneDeep(item);
  // Gets all properties from the schema
  const schemaKeys = Object.keys(item._schema);
  // Only iterates over not client properties
  const validProps = schemaKeys.filter(key => !item._schema[key].client);
  // Using lodash forEach because it's an object
  forEach(item, (value, key) => {
    // Removes all invalid properties
    if(validProps.indexOf(key) < 0) {
      itemCopy[key] = undefined;
      Reflect.deleteProperty(itemCopy, key);
    }
  });
  // Returns the copy
  return itemCopy;
};

/**
 * Saves the instance data on the server
 *
 * @param {Resource} item
 * @param {Object} options
 * @return {Promise} Returns the updated instance
 * @public
 * @static
 */
const Save = (item, options = {}) => {
  let method = 'post';
  // Creates endpoint url based in the instance type
  let url = API_URL + item._type;
  // makes a clean copy of the instance to send to the server without client side fields
  const obj = Clean(item);
  // If the instance has _id then it's an update
  if(item._id) {
    method = 'put';
    url += `/${item._id}`;
  }
  // Adds custom query options (must be a string)
  if(options.query) {
    url += `?${options.query}`;
  }
  return $http[method](url, obj).then(
    response => {
      const newItem = response.data;
      return $timeout(() => {
        //Updates cache in a $timeout to avoid angular digest conflicts
        Cache.Update(newItem._id, newItem, options, item._type, item._ChildClass);
        // Return the cache reference in the promise
        return Cache.GetByIdFrom(newItem._id, item._type);
      });
    }
  );
};

/**
 * Removes the instance on the database
 *
 * @param {Resource} item
 * @return {Promise} Returns the true if success
 * @public
 * @static
 */
const Remove = item => {
  const method = 'delete';
  const url = `${API_URL}${item._type}/${item._id}`;
  return $http[method](url).then(() => {
    //@TODO: verify this has been properly removed from server before clean cache
    const promise = $timeout(() => Cache.RemoveFrom(item._id, item._type));
    return promise;
  });
};

/**
 * Populates properties when posible
 *
 * @param {Resource} item
 * @param {Array <string>} fields
 * @example Populate(item, ['user', 'places', 'lines'])
 * @public
 * @static
 */
const Populate = (item, fields) => {
  if(isArray(fields) && fields.length) {
    forEach(fields, field => {
      const fieldValue = item[field];
      const fieldSchema = item._schema[field];
      item[`${field}Populated`] = {};
      // makes sure the field to populate is in the schema and has values
      if(fieldValue && fieldValue.length && fieldSchema && typeof fieldSchema.type === 'object') {
        const FieldClass = fieldSchema.type;
        //cheks if field is a single object or an array
        if(isArray(FieldClass) && FieldClass.length) {
          //Using FindByIds for populating child class when field is array
          item[`${field}Populated`] = FieldClass[0].FindByIds(fieldValue);
        } else {
          //Using FindById for populating child class when field is a single object
          item[`${field}Populated`] = FieldClass.FindById(fieldValue);
        }
        //Keep track of populated fields
        item._populated.push(field);
      }
    });
  }
};


/**
 * Removes a populated field (useful for avoid circular referencies)
 *
 * @param {Resource} item
 * @param {Array <string>} fields
 * @example Depopulate(item, ['user', 'places', 'lines'])
 * @public
 * @static
 */
const Depopulate = (item, fields) => {
  if(isArray(fields) && fields.length) {
    forEach(fields, field => {
      // makes sure the field to populate is in the schema
      if(item._schema[field] && typeof item._schema[field].type === 'object') {
        //Checks if the field is populated by looking at the populated fields list
        const index = item._populated.indexOf(field);
        if(index >= 0) {
          //Remove of populated fields list
          item._populated.splice(index, 1);
        }
        //Checks if the field is populated by looking at the property in the current item
        if(item[`${field}Populated`]) {
          //Removes the populated field from the item
          Reflect.deleteProperty(item, `${field}Populated`);
        }
      }
    });
  }
};

export default {
  $inject,
  Clean,
  Save,
  Remove,
  Populate,
  Depopulate,
};
