import angular from 'angular';
import '../services/place';
import 'angular-qr';

const module = angular.module('whyline.controllers.qr-generator', [
  'ja.qr',
  'whyline.services.place',
]);

const QRController = (PlaceService, $scope, $window) => {
  /*@ngInject*/
  $scope.qrCode = PlaceService.GetCurrentCheckInCode() || '';
  $scope.qrSize = 350;

  $scope.printQR = () => {
    $window.print();
  };
};

module.exports = module.controller('QRController', QRController);
