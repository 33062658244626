import angular from 'angular';
import 'angular-socket-io';
import { localStorage } from '../utils';
import '../services/socket';

const module = angular.module('whyline.factories.socket', [
  'btford.socket-io',
  'whyline.services.socket'
]);

const SocketFactory = (socketFactory, $rootScope, $q, $timeout, SocketService, SOCKET_URL) => {
  'ngInject';
  const defer = $q.defer();

  const connect = (token, opts) => {
    opts = Object.assign({
      forceNew: true,
      query: `token=${token}`,
      reconnection: true,
      reconnectionAttempts: 5,
      transports: ['websocket']
    }, opts || {});

    const newSocket = new socketFactory({ ioSocket: io.connect(SOCKET_URL, opts) });

    newSocket._id = Math.random();

    return newSocket;
  };

  $rootScope.$on('authenticated', () => {
    $timeout(() => {
      SocketService.GetInstance().then(currSocket => {
        if (currSocket) {
          return defer.resolve(currSocket)
        }

        const token = localStorage.get('token')

        const newSocket = connect(token)

        SocketService.SetInstance(newSocket)

        defer.resolve(newSocket)
      })
    })
  })

  $rootScope.$on('connect-display-socket', () => {
    $timeout(() => {
      const token = 'token_display';

      const newSocket = connect(token, { reconnectionAttempts: Infinity });

      SocketService.SetDisplayInstance(newSocket);

      newSocket.on('connect', () => {
        $rootScope.$emit('socket:display-connected', newSocket);
      });

      newSocket.on('disconnect', () => {
        $rootScope.$emit('display:disconnected');
      });

      newSocket.on('reconnect', () => {
        $rootScope.$emit('display:reconnected');
      });

      defer.resolve(newSocket);
    });
  });

  $rootScope.$on('connect-totem-socket', () => {
    $timeout(() => {
      const token = 'token_totem';

      const newSocket = connect(token, { reconnectionAttempts: Infinity });

      SocketService.SetTotemInstance(newSocket);

      newSocket.on('connect', () => {
        $rootScope.$emit('socket:totem-connected', newSocket);
      });

      newSocket.on('disconnect', () => {
        $rootScope.$emit('totem:disconnected');
      });

      newSocket.on('reconnect', () => {
        $rootScope.$emit('totem:reconnected');
      });

      defer.resolve(newSocket);
    });
  });

  return defer.promise;
};

module.exports = module.factory('Socket', SocketFactory);
