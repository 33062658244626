import angular from 'angular';
import '../resources/box';
import { cloneDeep } from 'lodash';
import { localStorage } from '../utils';

const module = angular.module('whyline.services.box', [
  'whyline.resources.box'
]);

let Box;
class BoxService {

  static $inject = ['Box'];

  constructor(injectedBox) {
    Box = injectedBox;
  }

  Create(data) {
    const newBox = new Box(data);
    return Box.Save(newBox);
  }

  Copy(box) {
    const copy = cloneDeep(box);
    return new Box(copy);
  }

  Enable(boxId) {
    return Box.Enable(boxId);
  }

  GetAll() {
    return Box.FetchAll();
  }

  GetAllFromServer() {
    return Box.FetchAll({force: true});
  }

  GetAllAsPromise() {
    return Box.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return Box.FetchAll({ promise: true, force: true});
  }

  GetOne(boxId) {
    return Box.FindById(boxId);
  }

  GetOneFromServer(boxId) {
    return Box.FindById(boxId, {force: true});
  }

  GetOneAsPromiseFromServer(boxId) {
    return Box.FindById(boxId, {promise: true, force: true});
  }

  GetOneAsPromise(boxId) {
    return Box.FindById(boxId, {promise: true});
  }

  GetAllEnabledByPlaceId(userId, emitSocket) {
    return Box.GetAllEnabledByPlaceId(userId, emitSocket);
  }

  FindByIds(ids, options) {
    return Box.FindByIds(ids, options);
  }

  Update(box) {
    return Box.Save(box);
  }

  Remove(boxId) {
    const boxToRemove = Box.FindById(boxId);
    return Box.Remove(boxToRemove);
  }

  SetBox(selectedBox) {
    localStorage.set('selectedBox', selectedBox);
  }

}

module.exports = module.service('BoxService', BoxService);
