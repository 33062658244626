import angular from 'angular';
import './resource';

const module = angular.module('whyline.resources.organization', [
  'whyline.resources.resource',
  'whyline.resources.place',
  'whyline.resources.area',
  'whyline.resources.line',
  'whyline.resources.display',
  'whyline.resources.schema',
  'whyline.resources.user',
  'whyline.resources.subscription',
]);

const OrganizationFactory = ($timeout, $http, API_URL, Resource, Place, Area, Line, Display, Schema, User, Subscription) => {
  /*@ngInject*/
  // API REST Resource Name
  const _type = 'organization';
  const apiEndPoint = `${API_URL}${_type}`;

  const _schema = {
    _id: {
      type: String
    },
    catchphrase: String,
    categories: [String],
    configuration: {
      checkIn: {
        min: {
          type: Number,
        },
        max: {
          type: Number,
        }
      },
      mobileApp: {
        canSendAppointmentDelayAlert: Boolean,
        showPeopleAheadAfterCheckIn: Boolean,
        showWaitingTime: Boolean,
        showQRCode: Boolean,
      },
      requirements: {
        id: {
          default: false,
          type: Boolean,
        },
        virtualine: {
          email: {
            default: false,
            type: Boolean,
          },
        }
      },
      plan: {
        id: String,
        maxAreas: Number,
        maxDisplays: Number,
        maxLines: Number,
        maxPlaces: Number,
        maxSectors: Number,
        maxTotems: Number,
        maxUsers: Number,
        maxVideos: Number,
        name: String,
      },
      queuer: {
        aliasType: {
          type: String,
        },
        boxLabelRequired: {
          default: false,
          type: Boolean,
        },
        canCloneAndForward: {
          default: false,
          type: Boolean,
        },
        canConfirmAppointments: {
          default: false,
          type: Boolean,
        },
        canProcessAppointment: {
          default: false,
          type: Boolean,
        },
        canEditAppointments: {
          default: true,
          type: Boolean,
        },
        canGiveAppointments: {
          default: false,
          type: Boolean,
        },
        canSkipProcessOrder: {
          default: false,
          type: Boolean,
        },
        checkInTolerance: {
          type: Number,
        },
        closedMessage: {
          type: String,
        },
        enterpriseType: String,
        hasEnterprise: {
          default: true,
          type: Boolean,
        },
        qr: String,
        requeue: {
          enabled: {
            default: false,
            type: Boolean,
          },
          limit: {
            in: {
              minutes: {
                default: 60,
                type: Number,
              },
            },
          },
        },
        retained: {
          enabled: {
            default: false,
            type: Boolean,
          },
          days: {
            default: 90,
            type: Number,
          }
        },
        showWaitingInLineInPlace: {
          default: true,
          type: Boolean,
        },
        showWorkingDay: {
          default: false,
          type: Boolean,
        },
        useScenarios: {
          default: false,
          type: Boolean,
        },
      },
      thirdPartyInfo: {
        getSubscriptionInfo: {
          type: Boolean,
          default: false,
        },
        modulename: String,
        password: String,
        urlSubscriptionInfo: String,
        username: String,
      },
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    enabledForMobileApp: Boolean,
    externalId: String,
    hiddenInMobileApp: Boolean,
    images: {
      banner: String,
      logo: String,
    },
    language: String,
    messages: {
      appoinment: {
        en: {
          type: String,
          default: '',
        },
        es: {
          type: String,
          default: '',
        },
        pt: {
          type: String,
          default: '',
        },
      },
      checkIn: {
        en: {
          type: String,
          default: '',
        },
        es: {
          type: String,
          default: '',
        },
        pt: {
          type: String,
          default: '',
        },
      },
      checkInEarly: {
        en: {
          type: String,
          default: '',
        },
        es: {
          type: String,
          default: '',
        },
        pt: {
          type: String,
          default: '',
        },
      },
      numberPeopleAhead: {
        en: {
          type: String,
          default: '',
        },
        es: {
          type: String,
          default: '',
        },
        pt: {
          type: String,
          default: '',
        },
      },
      youAreNext: {
        en: {
          type: String,
          default: '',
        },
        es: {
          type: String,
          default: '',
        },
        pt: {
          type: String,
          default: '',
        },
      },
    },
    name: String,
    phoneNumber: String,
    slug: String,
    timezone: String,
    userAdmin: {
      local: {
        email: String,
        password: String
      },
      profile: {
        firstName: String,
        lastName: String
      },
    },
    users: [{
      userId: String,
      rolId: String,
    }],
    website: String,
  };

  const Organization = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });

    this.getGeneralPartial = () => {
      let { categories, externalId, name, slug, timezone, website, images, language, phoneNumber } = this;
      return { categories, externalId, name, slug, timezone, website, images, language, phoneNumber };
    };

    this.getConfigurationPartial = () => {
      let { configuration } = this;
      return configuration;
    };

    this.getMessagesPartial = () => {
      let { messages } = this;
      return messages;
    };

    this.enableOrganization = enabled => {
      const promise = $http.put(`${API_URL}${_type}/${this._id}/enabled`, {
        enabled,
      });
      promise.then(
        response => {
          const item = response.data;
          $timeout(() => {
            Organization.UpdateCache(item._id, item);
          });
          return Organization.GetOneFromCache('_id', this._id);
        }
      );
      return promise;
    };
  };

  Organization.UpdateGeneral = (organization, organizationId) => {
    const url = `${API_URL}organization/${organizationId}/general`;
    return $http.put(url, organization)
      .then(response => {
        const organization = response.data;
        Organization.UpdateCache(organization._id, organization);
        return Promise.resolve(organization);
      });
  };

  Organization.UpdateConfiguration = (organization, organizationId, applyIn) => {
    const url = `${API_URL}organization/${organizationId}/configuration?applyIn=${applyIn}`;
    return $http.put(url, organization)
      .then(response => {
        const organization = response.data;
        Organization.UpdateCache(organization._id, organization);
        return Promise.resolve(organization);
      });
  };

  Organization.GetAliasTypes = () => {
    const url = `${API_URL}${_type}/get-alias-types`;
    return $http.get(url)
      .then(response => {
        return response.data;
      });
  };

  Organization.prototype = new Resource(_type, _schema, Organization);

  Resource.Extend(_type, Organization);

  //one hour
  const validityTime = 60 * 60 * 1000;
  Organization.InitCache(validityTime);

  Organization.TruncateAll = () => {
    Place.TruncateCache();
    Area.TruncateCache();
    Line.TruncateCache();
    Display.TruncateCache();
    Schema.TruncateCache();
    User.TruncateCache();
    Subscription.TruncateCache();
  };

  return Organization;
};

export default module.factory('Organization', OrganizationFactory);
