/* eslint-disable arrow-parens */
import angular from 'angular';
import { countBy, forEach, some, find, get, sortBy, concat, map, assign, merge } from 'lodash';
import moment from 'moment-timezone';
import '../app.constants';
import '../resources/subscription';
import '../services/globals';
import '../services/errors';
import '../services/subscription';
import '../services/line';
import '../services/area';
import '../services/queue';
import '../services/user';
import '../services/notifications';
import './modals/enqueue';
import './modals/preference';
import './modals/info';
import './modals/forward';
import './modals/load-subscriptions';
import '../components/carousel';
import { appointmentStatusEnum } from '../utils/appointmentStatusEnum';

const module = angular.module('whyline.controllers.queue', [
  'whyline.constants',
  'whyline.resources.subscription',
  'whyline.services.globals',
  'whyline.services.errors',
  'whyline.services.subscription',
  'whyline.services.line',
  'whyline.services.area',
  'whyline.services.queue',
  'whyline.services.user',
  'whyline.services.notifications',
  'whyline.modals.enqueue',
  'whyline.modals.info',
  'whyline.modals.preference',
  'whyline.modals.forward',
  'whyline.modals.load-subscriptions',
  'whyline.components.carousel',
]);

const QueueController = (
  QueueService,
  UserService,
  SubscriptionService,
  ErrorService,
  GlobalsService,
  NotificationService,
  PlaceService,
  OrganizationService,
  $scope,
  $uibModal,
  $state,
  $timeout,
  $translate,
  dialog,
  $rootScope,
  SAFE_STATES,
  AreaService,
  LineService,
) => {
  /*@ngInject*/

  const lineId = $state.params.lineId;
  const areaId = $state.params.areaId;
  const placeId = $state.params.placeId;
  const user = UserService.GetCurrent();
  const userId = user._id;
  let enqueueIn = {};

  $scope.orderByPreference = [
    {
      filter: 'arrival-order',
      label: $translate.instant('arrival-order'),
    },
    {
      filter: 'preference-order',
      label: $translate.instant('preference-order'),
    },
  ];
  $scope.orderByItems = 'score';
  const orderStatus = sortBy(
    appointmentStatusEnum.map((key) => ({
      key,
      label: $translate.instant(key),
    })),
    [(state) => state.label],
  );
  $scope.typeStatus = concat(
    {
      key: 'state',
      label: $translate.instant('state'),
    },
    orderStatus,
  );
  $scope.preferenceSelected = 'arrival-order';
  $scope.statusSelected = 'state';
  $scope.quantityOfItems = 8;
  $scope.choosingCompletionState = {};
  $scope.subscriptions = {};
  $scope.processing = {};
  $scope.showComplete = false;
  $scope.canCall = false;
  $scope.canDone = true;
  $scope.subscriptionsAmount = {
    waiting: 0,
    done: 0,
    inPlace: 0,
  };

  $scope.showPreference = false;
  $scope.user = user;
  $scope.showLoadSubscriptions = false;

  if (lineId) {
    const getAreas = LineService.GetAreaPreference(lineId, placeId);
    Promise.all([getAreas])
      .then((areaPreferenceEnabled) => {
        $timeout(() => {
          $scope.showPreference = get(areaPreferenceEnabled, '[0].data.enabled', false);
        });
      })
      .catch(ErrorService.handler);

    LineService.GetOneAsPromiseFromServer(lineId).then(line => {
      $scope.showLoadSubscriptions = line.checkAppointments;
    })
  }

  if (areaId) {
    if ($scope.areas) {
      map($scope.areas, (item, key) => {
        if (key === areaId) {
          $scope.areaConfiguration = item.configuration;
          $scope.showPreference = item.configuration.preference.enabled;
        }
      });
    }
  }

  $scope.filters = [
    { key: 'total', label: 'total in line' },
    { key: 'inPlace', label: 'in place' },
  ];
  PlaceService.GetCurrentAsPromise().then((place) => {
    $scope.filterData = {
      showFilters: place.configuration.showWaitingInLineInPlace,
      selectedFilter: 'total',
    };
  });
  $scope.applyFilter = (filter) => {
    $scope.filterData.selectedFilter = filter.key;
  };
  $scope.filterSubscriptions = (item) => {
    if (item) {
      switch ($scope.filterData.selectedFilter) {
        case 'total':
          return true;
        case 'inPlace':
          return item.statusInfo.reason == 'checked-in';
      }
    }
  };

  $scope.preferenceOrder = (preferenceSelected) => {
    if (preferenceSelected === 'preference-order') {
      let order = {
        low: {},
        medium: {},
        high: {},
        default: {},
      };
      map($scope.subscriptions, (subscription) => {
        if (subscription.preference) {
          if (subscription.preference === 'low') {
            order.low[subscription._id] = subscription;
          } else if (subscription.preference === 'medium') {
            order.medium[subscription._id] = subscription;
          } else if (subscription.preference === 'high') {
            order.high[subscription._id] = subscription;
          } else {
            order.default[subscription._id] = subscription;
          }
        } else {
          order.default[subscription._id] = subscription;
        }
      });
      $scope.subscriptions = {
        ...order.high,
        ...order.medium,
        ...order.low,
        ...order.default,
      };
      $scope.orderByItems = '';
    } else {
      $scope.subscriptions = SubscriptionService.GetAllByQueue(QueueService.currentQueue, {
        force: true,
      });
      $scope.orderByItems = 'score';
    }
  };

  $scope.toggleShowComplete = () => {
    $scope.showComplete = !$scope.showComplete;
  };

  // Get the processing subscription
  $scope.$watch(
    'subscriptions',
    (newVal) => {
      // Update if someone is processing
      $scope.processing = find(newVal, {
        status: 'processing',
        calledBy: userId,
      });

      // Enable the call next button
      $scope.canCall = some(newVal, (sub) => sub.status === 'waiting' && sub.checkIn.checkedIn);

      // Get amount of subscriptions acording with type (done, waiting...)
      getSubscriptionsAmount(newVal);
    },
    true,
  );

  // Set the current queue according with the params.
  if (!lineId && !areaId) {
    // If there are not params, then set the first object of the linesAndAreas object as the current.
    QueueService.SetCurrent($scope.lines[0] || $scope.areas[0]);
  } else {
    QueueService.GetQueueById({
      lineId,
      areaId,
    })
      .then((queue) => {
        if (queue.realType === 'process') {
          queue.disabledAddPersonFromProcess = !queue.isWithoutAppointment;
        }

        if (queue.realType !== 'supervisor') {
          enqueueIn = {
            where: queue.realType,
            lineId: queue.realType === 'line' ? queue._id : undefined,
            areaId: queue.realType === 'process' ? queue._id : undefined,
          };
          if (queue.realType === 'process') {
            $scope.filterData.showFilters = false;
          }
        }
        if (queue.realType === 'supervisor') {
          enqueueIn = {
            where: 'line',
            lineId: queue._id,
          };
        }
        // Set the current queue that I got by id
        QueueService.SetCurrent(queue);
        // Added the current queue to the scope
        $scope.currentQueue = QueueService.currentQueue;

        // Get permissions
        if (QueueService.currentQueue && QueueService.currentQueue._id) {
          $scope.permissions = UserService.GetPermissions(
            GlobalsService.CurrentPlace._id,
            QueueService.currentQueue._id,
          );
        }

        // Get subscriptions for the current queue.
        $scope.subscriptions = SubscriptionService.GetAllByQueue(QueueService.currentQueue, {
          force: true,
        });
      })
      .catch(ErrorService.handler);
  }

  $scope.filterSubscriptionsByStatus = (subscription, search) =>
    get(subscription, 'statusInfo.displayName', '') === search || search === 'state';

  /**
   * Modals
   */
  // Modal to show user (subscription) info
  $scope.info = (subscriptionId) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/subscription-modal.html',
      size: 'md',
      controller: 'InfoController',
      resolve: {
        // don't replace with 'short-hand'!
        subscriptionId: () => subscriptionId,
        currentPlaceId: () => GlobalsService.CurrentPlace._id,
        areaConfiguration: () => $scope.areaConfiguration,
      },
    });
  };

  $scope.preference = (subscriptionId, preference) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/subscription-preference.html',
      size: 'md',
      controller: 'PreferenceController',
      resolve: {
        subscriptionId: () => subscriptionId,
        currentPlaceId: () => GlobalsService.CurrentPlace._id,
        areaId: () => areaId,
        preference: () => preference,
      },
    });
  };

  // Modal to enqueue/add user to line
  $scope.openEnqueueModal = () => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/subscription-enqueue.html',
      size: 'md',
      controller: 'EnqueueController',
      resolve: {
        enqueueIn: () => enqueueIn,
        currentPlace: () => PlaceService.GetOneAsPromise(GlobalsService.CurrentPlace._id),
        currentOrganization: () => OrganizationService.GetCurrent(),
      },
    });
  };

  // Modal to enqueue/add user to line
  $scope.openForwardModal = (subscription) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/subscription-forward.html',
      size: 'md',
      controller: 'ForwardController',
      resolve: {
        subscription: () => subscription,
        line: () => QueueService.currentQueue,
        preference: () => $scope.showPreference,
      },
    });
  };

  $scope.openRetainModal = (subscription, expirationDays) => {
    let daysLeft = expirationDays;

    if (get(subscription, "retained.expirationDate", null)) {
      const today = moment().startOf('day');
      const lastDay = moment(subscription.retained.expirationDate).startOf('day');
      daysLeft = lastDay.diff(today, 'days');
    }

    const daysText = daysLeft < 2 ? $translate.instant('day') : $translate.instant('days');
    const infoText = `${$translate
      .instant('retain_for_{days}')
      .replace('{days}', daysLeft)} ${daysText}`;

    dialog.reason(infoText).then((reason) => {
      SubscriptionService.Retain(subscription._id, QueueService.currentQueue._id, reason).catch(
        ErrorService.handler,
      );
    });
  };

  $scope.rejoin = (subscription) => {
    dialog
      .confirm(
        $translate
          .instant('rejoin_{name}_sure')
          .replace('{name}', subscription.person.firstName || ''),
      )
      .then(() => {
        // @TODO add translation to this notification
        NotificationService.Information(`Re-enviando a ${subscription.person.firstName}.`);
        SubscriptionService.Rejoin(subscription);
      });
  };

  /**
   * Refactored
   */
  // Forward subscription to supervisor
  $scope.forwardSubscription = (subscriptionId, name) => {
    // First, ask the user if really wants to forward a subscription
    dialog
      .confirm($translate.instant('derive_{name}_supervisor_sure').replace('{name}', name || ''))
      .then(() => {
        // Use the SubscriptionService to forward a subscription
        SubscriptionService.Forward(subscriptionId, QueueService.currentQueue)
          .then(() => {
            $timeout(() => {
              const text = $translate
                .instant('user_{name}_derived_succ')
                .replace('{name}', name || '');
              NotificationService.Success(text, $translate.instant('success'));
            });
          })
          .catch(ErrorService.handler);
      });
  };

  // Remove subscription
  $scope.removeSubscription = (subscriptionId, name) => {
    dialog
      .confirm($translate.instant('list_remove_{name}_sure').replace('{name}', name || ''))
      .then(() => {
        SubscriptionService.Remove(subscriptionId, QueueService.currentQueue)
          .then(() => {
            $timeout(() => {
              const text = $translate
                .instant('user_{name}_remove_succ')
                .replace('{name}', name || '');
              NotificationService.Success(text, $translate.instant('success'));
            });
          })
          .catch(ErrorService.handler);
      })
      .catch((res) => res);
  };

  // Call next subscription in the list
  $scope.callNext = () => {
    $scope.canDone = false;
    QueueService.CallNext(QueueService.currentQueue)
      .then(() => {
        $scope.showComplete = false;
        NotificationService.Success(
          $translate.instant('person_call_succ'),
          $translate.instant('success'),
        );
        $timeout(() => {
          $scope.canDone = true;
        }, 3000);
      })
      .catch(ErrorService.handler);
  };

  // Call a specific subscription by id
  $scope.callTo = (subscriptionId) => {
    QueueService.CallTo(subscriptionId, QueueService.currentQueue)
      .then(() => {
        $scope.showComplete = false;
        NotificationService.Success(
          $translate.instant('person_call_succ'),
          $translate.instant('success'),
        );
        $timeout(() => {
          $scope.canDone = true;
        }, 3000);
      })
      .catch(ErrorService.handler);
  };

  // Done a processing subscription
  // (options: complete, rejected or absent)
  $scope.endSubscription = (subscription, status, reason) => {
    SubscriptionService.EndSubscription(subscription, status, reason)
      .then(() => {
        $timeout(() => {
          $scope.toggleShowComplete();
        });
      })
      .catch(ErrorService.handler);
  };

  $scope.requeue = (subscription) => {
    dialog
      .confirm(
        $translate
          .instant('confirm_requeue_{name}')
          .replace('{name}', subscription.person.firstName || ''),
      )
      .then(() => {
        SubscriptionService.Requeue(subscription)
          .then(() => {
            NotificationService.Success(
              $translate.instant('requeue_success'),
              $translate.instant('success'),
            );
          })
          .catch(ErrorService.handler);
      });
  };

  const getSubscriptionsAmount = (subscriptions) => {
    forEach(subscriptions, (value, key) => {
      if (value.status === 'canceled' && value.reason === 'queue-clear') {
        Reflect.deleteProperty(subscriptions, key);
      }
    });
    const amount = countBy(subscriptions, 'status');
    const amountReason = countBy(subscriptions, 'statusInfo.reason');
    $scope.subscriptionsAmount.waiting = amount.waiting || 0;
    $scope.subscriptionsAmount.done = (amount.completed || 0) + (amount.canceled || 0);
    $scope.subscriptionsAmount.inPlace = amountReason['checked-in'] || 0;
    if ($scope.currentQueue) {
      const current = {
        _id: $scope.currentQueue._id,
        waiting: $scope.subscriptionsAmount.waiting,
        type: $scope.currentQueue.type,
      };
      $rootScope.$broadcast('update-active-queue', { currentQueue: current });
    }
  };

  $scope.continueSubscription = (subscription) => {
    const forwardedReason = "forwarded";
    SubscriptionService.ForwardTo(
      subscription._id,
      subscription.assignedUser.serviceId,
      subscription.assignedUser.serviceType,
      subscription.status,
      forwardedReason,
      subscription.preference,
    )
    .then(() =>
      $timeout(() => {
        NotificationService.Success($translate.instant('user_derived_correct'));
        $scope.toggleShowComplete();
      }),
    )
    .catch((err) => {
      ErrorService.handler(err);
    });
  }

  $scope.loadSubscriptions = (subscription) => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/load-subscriptions.html',
      size: 'md',
      controller: 'LoadSubscriptionsController',
      resolve: {
        subscription: () => subscription,
        currentPlace: () => PlaceService.GetOneAsPromise(GlobalsService.CurrentPlace._id),
        currentOrganization: () => OrganizationService.GetCurrent(),
      },
    });
  }

  $scope.undoRedirect = (subscription) => {
    dialog
      .confirm($translate.instant('subscription_undo_redirect_confirmation'))
      .then(() => undoRedirectConfirmation(subscription))
      .catch(() => 'canceled');
  }; 

  const undoRedirectConfirmation = (subscription) => {
    let newSubscriptionId = '';
    const movementsHistory = subscription.movementsHistory.filter(movement => movement.action === 'forward-to');
    
    if (movementsHistory.length > 0) {
      const lastInTheList = movementsHistory.length - 1;
      newSubscriptionId = movementsHistory[lastInTheList].idNewSubscription;
      SubscriptionService.UndoForwardTo(
        subscription._id,
        newSubscriptionId
      )
      .then(() =>
        $timeout(() => {
          NotificationService.Success($translate.instant('subscription_undo_redirect_correct'));
        }),
      )
      .catch((err) => {
        ErrorService.handler(err);
      });
    } else {
      NotificationService.Warning($translate.instant('subscription_undo_redirect_error'));
    }
  }
};

export default module.controller('QueueController', QueueController);
