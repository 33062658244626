// IMPORTS
import angular from 'angular';

import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'angular-animate';
import 'angular-jwt';
import 'angular-toastr';
import 'angulartics';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-vs-repeat';
import 'ng-file-upload';
import 'angular-messages';
import 'angular-animate-change';
import 'angular-dialog';
import 'angular-filter';
import 'angularjs-dropdown-multiselect';
import 'angular-ui-bootstrap-datetimepicker';
import 'angular-block-ui';
import 'angular-hotkeys';
import 'angular-slugify';
import '@meanie/angular-focus';
import 'ui-select';
import 'angular-sticky-plugin';
import '@kariudo/angular-fullscreen';
import 'angular-ui-carousel';
import 'angular-breadcrumb';
import 'ng-focus-if';
import 'ui-cropper';
import 'angular-libphonenumber/dist/libphonenumber.js';
import 'angular-libphonenumber';
import './services/box';
import './services/globals';
import './services/device';
import './services/location';
import './services/language';
import './services/timezone';
import './services/auth';
import './services/cache';
import './services/connection';
import './services/validation';
import './services/notifications';
import './services/appointment';
import './services/appointment-reservation';
import './services/country';
import './services/date-handler';
import './services/holiday';
import './services/socket';
import './services/resource-group';
import './services/place-messages';
import './services/organization-messages';
import './services/organization';
import './services/plan';
import './services/sector';
import './services/ticket-template';
import './services/line-flow-type-organization';
import './services/organization-sector';
import './services/organization-line';
import './services/organization-custom-roles';
import './services/organization-area';
import './services/banner';
import './services/organization-condition';
import './services/organization-scenario';
import './controllers/box';
import './controllers/modals/box-select';
import './controllers/main';
import './controllers/sign-in';
import './controllers/sign-up';
import './controllers/places/index';
import './controllers/places/list';
import './controllers/places/edit';
import './controllers/organizations/index';
import './controllers/organizations/list';
import './controllers/organizations/create';
import './controllers/now-serving';
import './controllers/queue';
import './controllers/reception';
import './controllers/reception/status';
import './controllers/reception/path';
import './controllers/reception/welcome';
import './controllers/lines';
import './controllers/organization-lines';
import './controllers/organization-custom-role';
import './controllers/line-flow-type-organization';
import './controllers/areas';
import './controllers/organization-areas';
import './controllers/schemas';
import './controllers/totems';
import './controllers/devices';
import './controllers/resource-groups';
import './controllers/roles';
import './controllers/totem-tv';
import './controllers/totem-tv/user';
import './controllers/totem-tv/status';
import './controllers/totem-tv/path';
import './controllers/totem-tv/welcome-and-thanks';
import './controllers/displays';
import './controllers/banners';
import './controllers/users';
import './controllers/organization-conditions';
import './controllers/organization-scenarios';
import './controllers/verify-account';
import './controllers/reset-password';
import './controllers/recover-password';
import './controllers/display-tv';
import './controllers/stats';
import './controllers/qr-generator';
import './controllers/appointment/reception/search';
import './controllers/appointment/reception/results';
import './controllers/appointment/reception/confirm';
import './controllers/appointment/totem/search';
import './controllers/appointment/totem/results';
import './controllers/appointment/totem/confirm';
import './controllers/uploader';
import './controllers/plans';
import './controllers/sectors';
import './controllers/organization-users';
import './controllers/schedule/schedule';
import './controllers/schedule/holidays';
import './controllers/scenarios';
import './controllers/v1tov2';
import './filters/to-array';
import './filters/in-array';
import './filters/average-wait-time';
import './filters/estimated-wait-time';
import './filters/get-subscriptions';
import './filters/waited-time';
import './filters/format-date';
import './filters/display-hour';
import './directives/numbers-only';
import './directives/must-equals';
import './directives/prevent-scroll';
import './directives/focus-me';
import './directives/access-control';
import './directives/plan-control';
import './directives/plan-click';
import './directives/time-mask';
import './factories/socket.io';
import './sockets/area';
import './sockets/box';
import './sockets/line';
import './sockets/user';
import './sockets/organization-user';
import './sockets/display';
import './sockets/device';
import './sockets/totem';
import './sockets/subscription';
import './sockets/connection';
import './sockets/appointment';
import './sockets/resource-group';
import './sockets/place';
import './components/no-data';
import './components/partial-change';
import './components/image-uploader';
import './sockets/application';
import './sockets/holiday';
import 'angular-recaptcha';

import config from './config';
import run from './app.run';

angular.lowercase = text => text.toLowerCase();

// REQUIRES
require('angular-moment');
require('angulartics-google-analytics');

const app = angular.module('whyline', [
  'ui.router',
  'ui.bootstrap',
  'ngAnimate',
  'ngFileUpload',
  'angular-jwt',
  'toastr',
  'ngMessages',
  'animate-change',
  'angular.filter',
  'pascalprecht.translate',
  'vs-repeat',
  'simple-angular-dialog',
  'angularjs-dropdown-multiselect',
  'ui.bootstrap.datetimepicker',
  'blockUI',
  'angulartics',
  'cfp.hotkeys',
  'Focus.Service',
  'angularMoment',
  'ui.select',
  'slugifier',
  'hl.sticky',
  'FBAngular',
  'ui.carousel',
  'focus-if',
  'uiCropper',
  'cwill747.phonenumber',
  'ncy-angular-breadcrumb',
  'whyline.services.box',
  'whyline.services.globals',
  'whyline.services.location',
  'whyline.services.timezone',
  'whyline.services.auth',
  'whyline.services.cache',
  'whyline.services.connection',
  'whyline.services.notifications',
  'whyline.services.appointment',
  'whyline.services.appointment-reservation',
  'whyline.services.country',
  'whyline.services.socket',
  'whyline.services.resource-group',
  'whyline.services.place-messages',
  'whyline.services.organization-messages',
  'whyline.services.organization',
  'whyline.services.plan',
  'whyline.services.sector',
  'whyline.services.line-flow-type-organization',
  'whyline.services.organization-sector',
  'whyline.services.organization-line',
  'whyline.services.organization-custom-role',
  'whyline.services.organization-area',
  'whyline.services.date-handler',
  'whyline.services.holiday',
  'whyline.services.device',
  'whyline.services.ticket-template',
  'whyline.services.banner',
  'whyline.services.organization-condition',
  'whyline.services.organization-scenario',
  'whyline.controllers.box',
  'whyline.controllers.box-select',
  'whyline.controllers.main',
  'whyline.controllers.sign-in',
  'whyline.controllers.sign-up',
  'whyline.controllers.places',
  'whyline.controllers.places.list',
  'whyline.controllers.places.edit',
  'whyline.controllers.organizations',
  'whyline.controllers.organizations.list',
  'whyline.controllers.organizations.create',
  'whyline.controllers.now-serving',
  'whyline.controllers.queue',
  'whyline.controllers.reception',
  'whyline.controllers.reception-status',
  'whyline.controllers.reception-path',
  'whyline.controllers.reception-welcome',
  'whyline.controllers.lines',
  'whyline.controllers.organization-lines',
  'whyline.controllers.organization-custom-role',
  'whyline.controllers.line-flow-type-organization',
  'whyline.controllers.areas',
  'whyline.controllers.organization-areas',
  'whyline.controllers.organization-users',
  'whyline.controllers.schemas',
  'whyline.controllers.roles',
  'whyline.controllers.resource-groups',
  'whyline.controllers.totems',
  'whyline.controllers.devices',
  'whyline.controllers.totem-tv',
  'whyline.controllers.totem-tv-user',
  'whyline.controllers.totem-tv-status',
  'whyline.controllers.totem-tv-path',
  'whyline.controllers.totem-tv-welcome-and-thanks',
  'whyline.controllers.displays',
  'whyline.controllers.banners',
  'whyline.controllers.users',
  'whyline.controllers.organization-conditions',
  'whyline.controllers.organization-scenarios',
  'whyline.controllers.verify-account',
  'whyline.controllers.reset-password',
  'whyline.controllers.recover-password',
  'whyline.controllers.display-tv',
  'whyline.controllers.stats',
  'whyline.controllers.qr-generator',
  'whyline.controllers.scenarios',
  'whyline.controllers.sectors',
  'whyline.controllers.reception.appointment-search',
  'whyline.controllers.reception.appointment-results',
  'whyline.controllers.reception.appointment-confirm',
  'whyline.controllers.totem-tv.appointment-search',
  'whyline.controllers.totem-tv.appointment-results',
  'whyline.controllers.totem-tv.appointment-confirm',
  'whyline.controllers.uploader',
  'whyline.controllers.plans',
  'whyline.controllers.schedule',
  'whyline.controllers.schedule-holidays',
  'whyline.controllers.v1tov2',
  'whyline.filters.toArray',
  'whyline.filters.inArray',
  'whyline.filters.averageWaitTime',
  'whyline.filters.estimatedWaitTime',
  'whyline.filters.getSubscriptions',
  'whyline.filters.waitedTime',
  'whyline.filters.formatDate',
  'whyline.filters.displayHour',
  'whyline.directives.numbersOnly',
  'whyline.directives.mustequals',
  'whyline.directives.preventScroll',
  'whyline.directives.access-control',
  'whyline.directives.plan-control',
  'whyline.directives.plan-click',
  'whyline.directives.time-mask',
  'whyline.factories.socket',
  'whyline.sockets.area',
  'whyline.sockets.box',
  'whyline.sockets.line',
  'whyline.sockets.user',
  'whyline.sockets.organizationuser',
  'whyline.sockets.display',
  'whyline.sockets.totem',
  'whyline.sockets.device',
  'whyline.sockets.subscription',
  'whyline.sockets.connection',
  'whyline.sockets.appointment',
  'whyline.sockets.resource-group',
  'whyline.sockets.place',
  'whyline.components.no-data',
  'whyline.components.partial-change',
  'whyline.components.image-uploader',
  'whyline.sockets.application',
  'whyline.sockets.holiday',
  'vcRecaptcha',
]);

app.config(config);
app.run(run);
