import angular from 'angular';
import 'angular-toastr';

const module = angular.module('whyline.services.notifications', [
  'toastr',
]);

let toastr;
let $translate;
const defaultDesktopNotification = {
  title: 'Whyline Inc.',
  icon: '/img/favicons/logo.png',
  requireInteraction: false,
  showOnPageHidden: true,
};
class NotificationService {

  static $inject = ['toastr', '$translate'];

  constructor(injectedToastr, injected$translate) {
    /*@ngInject*/
    toastr = injectedToastr;
    $translate = injected$translate;
  }

  Clear(toast) {
    if(!toast) {
      toastr.clear();
    } else {
      toastr.clear(toast);
    }
  }

  Error(message, options = {}) {
    if(message) {
      toastr.error($translate.instant(message), $translate.instant('error-title'), options);
    } else {
      toastr.error($translate.instant('error-message'), $translate.instant('error-title'), options);
    }
  }

  Success(message, options = {}) {
    if(message) {
      toastr.success($translate.instant(message), $translate.instant('success-title'), options);
    } else {
      toastr.success($translate.instant('success-message'), $translate.instant('success-title'), options);
    }
  }

  Warning(message, options = {}) {
    if(message) {
      toastr.warning($translate.instant(message), $translate.instant('warning-title'), options);
    }
  }

  Information(message, options = {}) {
    if(message) {
      toastr.info($translate.instant(message), $translate.instant('information-title'), options);
    }
  }

  Alert(message, options = { toastClass: 'toast-white', positionClass: 'toast-top-right', tapToDismiss: false, timeOut: 0, closeButton: true }) {
    if(message) {
      toastr.warning($translate.instant(message), $translate.instant('information-title'), options);
    }
  }

  ShowDesktop(data) {
    const options = Object.assign({}, defaultDesktopNotification, data);

    if(!('Notification' in window)) {
      return console.info('Este navegador no soporta notificaciones nativas. Debe actualizarlo al menos a la version 32.');
    }

    if(options.body) {
      Notification.requestPermission()
        .then(() => {
          const notificationInstance = new Notification(`${options.title}`, options);
          if(options.onclick) {
            notificationInstance.onclick = options.onclick;
          }
        })
        .catch(console.log);
    }
  }

}

module.exports = module.service('NotificationService', NotificationService);
