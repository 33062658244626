import angular from 'angular';
import './resource';
import '../app.constants';
import '../resources/subscription';

const module = angular.module('whyline.resources.organization-scenario', [
  'whyline.constants',
  'whyline.resources.resource',
  'whyline.resources.subscription',
]);

const OrganizationScenarioFactory = (API_URL, $http, Resource, $timeout, Subscription, Socket) => {
  /*@ngInject*/
  //API REST Resource Name
  const _type = 'organization/:organizationId/scenarios';

  const _schema = {
    _id: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    areaId: {
      type: String,
      required: true,
    },
    conditionId: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    isDefault: {
      type: Boolean,
      required: true,
      default: false,
    },
    isIntermediate: {
      type: Boolean,
      required: true,
      default: false,
    },
  };

  const OrganizationScenario = function (data) {
    angular.forEach(_schema, (prop, key) => {
      this[key] = prop.default;
    });

    angular.forEach(data, (prop, key) => {
      this[key] = prop;
    });
  };

  OrganizationScenario.prototype = new Resource(_type, _schema, OrganizationScenario);

  Resource.Extend(_type, OrganizationScenario);

  //five minutes
  const validityTime = 5 * 60 * 1000;
  OrganizationScenario.InitCache(validityTime);

  return OrganizationScenario;
};

module.exports = module.factory('OrganizationScenario', OrganizationScenarioFactory);
