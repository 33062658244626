import angular from 'angular';

const module = angular.module('whyline.filters.inArray', []);

/*const filter = () => {
  return function(array, value) {
    return array.indexOf(value) !== -1;
  };
};*/
const filter = () => (array, value) => array.indexOf(value) !== -1;

module.exports = module.filter('inArray', filter);
