import angular from 'angular';
import { cloneDeep } from 'lodash';
import '../resources/organization-custom-role';

const module = angular.module('whyline.services.organization-custom-role', [
  'whyline.resources.organization-custom-role'
]);

let OrganizationCustomRole;

class OrganizationCustomRoleService {
  static $inject = ['OrganizationCustomRole'];

  constructor(injectedOrganizationCustomRole) {
    OrganizationCustomRole = injectedOrganizationCustomRole;
  }

  Create(data) {
    const newOrganizationRole = new OrganizationCustomRole(data);
    return OrganizationCustomRole.Save(newOrganizationRole);
  }

  Copy(organizationCustomRole) {
    const copy = cloneDeep(organizationCustomRole);
    return new OrganizationCustomRole(copy);
  }


  Update(customRole) {
    return OrganizationCustomRole.Save(customRole);
  }

  Remove(customRoleId) {
    const customRoleToRemove = OrganizationCustomRole.FindById(customRoleId);
    return OrganizationCustomRole.Remove(customRoleToRemove);
  }

  GetAll() {
    return OrganizationCustomRole.FetchAll();
  }

  GetAllFromServer() {
    return OrganizationCustomRole.FetchAll({force: true});
  }

  GetAllAsPromise() {
    return OrganizationCustomRole.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return OrganizationCustomRole.FetchAll({ promise: true, force: true});
  }

  GetOne(customRoleId) {
    return OrganizationCustomRole.FindById(customRoleId);
  }

  GetOneFromServer(customRoleId) {
    return OrganizationCustomRole.FindById(customRoleId, {force: true});
  }

  GetOneAsPromiseFromServer(customRoleId) {
    return OrganizationCustomRole.FindById(customRoleId, {promise: true, force: true});
  }

  GetOneAsPromise(customRoleId) {
    return OrganizationCustomRole.FindById(customRoleId, {promise: true});
  }

  FindByIds(ids, options) {
    return OrganizationCustomRole.FindByIds(ids, options);
  }


}

module.exports = module.service('OrganizationCustomRoleService', OrganizationCustomRoleService);
