import angular from 'angular';
import { cloneDeep, concat, get } from 'lodash';
import '../resources/totem';
import '../resources/area';
import '../resources/line';

const module = angular.module('whyline.services.totem', [
  'whyline.resources.totem',
  'whyline.resources.area',
  'whyline.resources.line',
]);

let Totem;
let Area;
let Line;
let $timeout;

const handleError = err => {
  console.error(err);
  throw err;
};

class TotemService {

  static $inject = ['Totem', 'Area', 'Line', '$timeout'];

  constructor(injectedTotem, injectedArea, injectedLine, injected$timeout) {
    Totem = injectedTotem;
    Area = injectedArea;
    Line = injectedLine;
    $timeout = injected$timeout;
  }

  Create(data) {
    const newTotem = new Totem(data);
    newTotem.type = 'totem';
    return Totem.Save(newTotem);
  }

  Copy(totem) {
    const copy = cloneDeep(totem);
    return new Totem(copy);
  }

  Update(totem) {
    return Totem.Save(totem);
  }

  Remove(totemId) {
    const totem = Totem.FindById(totemId);
    return Totem.Remove(totem);
  }

  GetAll() {
    return Totem.FetchAll();
  }

  GetAllAsPromise() {
    return Totem.FetchAll({ promise: true});
  }

  GetOne(totemId) {
    return Totem.FindById(totemId);
  }

  GetOneAsPromise(totemId) {
    return Totem.FindById(totemId, {promise: true});
  }

  GetOneAsPromiseFromServer(totemId) {
    return Totem.FindById(totemId, {promise: true, force: true});
  }

  Switch(totem) {
    const currentValue = get(totem, 'enabled', false);
    return totem.enable(!currentValue);
  }

  GetOnePopulatedAsPromiseFromServer(totemId) {
    return Area.FetchAll({ promise: true })
    .then(() => Line.FetchAll({ promise: true }))
    .then(() => Totem.FindById(totemId, {
      populate: ['lines', 'areas', 'schemas'],
      promise: true,
      force: true
    }))
    .then(newData => newData)
    .catch(handleError);
  }

  GetOnePopulatedAsPromiseFromServerWithPropAllPopulated(totemId) {
    return Totem.GetTotemPopulated(totemId, {promise: true, force: true});
  }

  GetTotemBAHash(totemId) {
    return Totem.GetTotemBAHash(totemId);
  }
}

module.exports = module.service('TotemService', TotemService);
