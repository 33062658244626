import angular from 'angular';
import { localStorage } from '../utils';
import '../app.constants';
import '../services/place';

const module = angular.module('whyline.controllers.now-serving', [
  'whyline.constants',
  'whyline.services.place',
]);

const NowServingController = ($scope, PlaceService, ATTENTION_URL) => {
  $scope.redirectToNewAttentionModule = () => {
    const place = PlaceService.GetCurrent();
    const token = localStorage.get('token');
    const params = JSON.stringify({ placeId: place._id });
    const url = new URL(`${ATTENTION_URL}external`);
    url.searchParams.append('token', token);
    url.searchParams.append('url', '/');
    url.searchParams.append('params', params);
    console.log(url.toString());

    window.open(url, '_blank');
  };
};

module.exports = module.controller('NowServingController', NowServingController);
