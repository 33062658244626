import angular from 'angular';
import { get, isEmpty } from 'lodash';
import { validatePersonId } from '../utils';
import * as libPhoneNumber from 'libphonenumber-js';
import './notifications';
import './place';
import { CreatedFromEnum } from '../utils/createdFromEnum';

const module = angular.module('whyline.services.validation', [
  'whyline.services.notifications',
  'pascalprecht.translate',
  'whyline.services.place'
]);

const ValidationService = function ($translate, NotificationService, PlaceService, $window) {
  'ngInject';

  const validCharacters = '[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]';
  const namePattern = new RegExp(`^${validCharacters}+(${validCharacters}{1,} {0,1}${validCharacters}{1,}){0,}${validCharacters}+$`, 'u');
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  function validatePerson(person, property = null, scope = null, toasterOptions = undefined, sender = undefined) {
    let currentPlace = PlaceService.GetCurrent();
    const identifications = PlaceService.getIdentificationsForCurrentPlace() || [];

    const getIdTypeName = key => get(identifications.find(id => id.key.toLowerCase() === key.toLowerCase()), 'name', '');

    const firstName = get(person, 'firstName', null);
    const lastName = get(person, 'lastName', null);
    const idType = get(person, 'idType');
    const idNumber = get(person, 'id');

    const email = get(person, 'email', null);
    const phone = {
      countryCode: get(person, 'phone.countryCode', ''),
      full: get(person, 'phone.full', ''),
    };

    switch (property) {
      case 'name':
        // Person name
        if (!firstName) {
          NotificationService.Warning($translate.instant('mandatory_person_name'), toasterOptions);
          if (scope) {
            scope.userData.firstName.$error.badName = true;
            scope.userData.firstName.$invalid = true;
          }
          return false;
        }
        if (firstName.length < 2) {
          NotificationService.Warning($translate.instant('min_lenght_person_name'), toasterOptions);
          if (scope) {
            scope.userData.firstName.$error.badCharName = true;
            scope.userData.firstName.$invalid = true;
          }
          return false;
        }
        if (!namePattern.test(firstName)) {
          NotificationService.Warning($translate.instant('person_name_only_letters'), toasterOptions);
          return false;
        }

        if (!lastName) {
          NotificationService.Warning($translate.instant('mandatory_person_lastname'), toasterOptions);
          return false;
        }
        if (lastName.length < 2) {
          NotificationService.Warning($translate.instant('min_lenght_lastname'), toasterOptions);
          return false;
        }
        if (!namePattern.test(lastName)) {
          NotificationService.Warning($translate.instant('person_lastname_letters_only'), toasterOptions);
          return false;
        }

        break;

      case 'phone':

        if (currentPlace.requirements && currentPlace.requirements.phoneNumber && !phone.full) {
          NotificationService.Warning($translate.instant('phone_required'), toasterOptions);
          return false;
        }
        if (currentPlace.requirements && currentPlace.requirements.phoneNumber && phone.full || phone.full) {
          // Validation fails if: some part is empty OR some part doesn't match the given pattern OR areaCode+number isn't 10 chars long
          if (!libPhoneNumber.isValidNumber(phone.full, phone.countryCode)) {
            NotificationService.Warning($translate.instant('invalid_tel'), toasterOptions);
            return false;
          }
        }
        break;

      case 'personId':
        // Person ID
        if (!idNumber && currentPlace.requirements && (currentPlace.requirements.id || sender === 'appointments')) {
          let i = getIdTypeName(idType);
          let text = $translate.instant('mandatory_person_{id}').replace('{id}', i);
          NotificationService.Warning(text, toasterOptions);
          return false;
        }
        if (!idType && currentPlace.requirements && currentPlace.requirements.id) {
          NotificationService.Warning($translate.instant('mandatory_number_type'), toasterOptions);
          return false;
        }

        if (currentPlace.requirements && currentPlace.requirements.id || idNumber) {
          const identification = identifications.find(id => id.key === idType || id.key === idType.toLowerCase());

          if (idNumber && !validatePersonId(idNumber, idType, $window, identification)) {
            if (isEmpty(identification.key)) {
              NotificationService.Warning('mandatory_id_type', toasterOptions);
              return false;
            }

            let i = getIdTypeName(idType);
            let text = $translate.instant('invalid_person_{id}').replace('{id}', i);
            NotificationService.Warning(text, toasterOptions);
            return false;
          }
        }
        break;

      case 'email':
        if (get(scope, 'appointment.createdFrom', false) !== CreatedFromEnum.sigeci) {
          if (currentPlace.requirements && currentPlace.requirements.virtualine && currentPlace.requirements.virtualine.email && !email) {
            NotificationService.Warning($translate.instant('mandatory_email'), toasterOptions);
            return false;
          }
          if (email && !emailPattern.test(email)) {
            NotificationService.Warning($translate.instant('invalid_email_format'), toasterOptions);
            return false;
          }
        }
        break;

      default:
        if (!validatePerson(person, 'name') || !validatePerson(person, 'phone') || !validatePerson(person, 'email', scope) || !validatePerson(person, 'personId')) {
          return false;
        }
        break;
    }
    return true;
  }

  return {
    validatePerson
  };
};

module.exports = module.service('ValidationService', ValidationService);
