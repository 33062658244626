import angular from 'angular';
import { localStorage } from '../../utils';
import { get } from 'lodash';
import '../../app.constants';
import '../../services/globals';
import '../../services/organization';
import '../../services/errors';
import '../modals/organizations';

const module = angular.module('whyline.controllers.organizations.list', [
  'whyline.constants',
  'whyline.services.globals',
  'whyline.services.organization',
  'whyline.services.errors',
  'whyline.modals.organization',
]);

const OrganizationsListController = (
  SAFE_STATES,
  $translate,
  $rootScope,
  $scope,
  $state,
  $timeout,
  OrganizationService,
  GlobalsService,
  ErrorService,
  $uibModal
) => {
  /*@ngInject*/

  GlobalsService.SetCurrentPlace(null);
  GlobalsService.SetCurrentOrganization(null);
  $scope.placeholderSearch = GlobalsService.IsMobile()
    ? $translate.instant('search')
    : '';

  let roleName;
  let showOrganizations;
  localStorage.get('user').then(user => {
    roleName = get(user, 'permissions[0].role.name', '');
    $scope.superAdmin = user && user.sudo;
    showOrganizations = $scope.superAdmin || roleName === 'Organization Admin';

    // TODO: This is bug VV1-1606, it needs to be fixed in order to allow the organization admin
    // to access the organization list even if there's only 1 (so he can edit his own)
    // Bug description says this needs to be reviewed when plans gets implemented

    OrganizationService.GetAllAsPromise()
    .then(organizations => {
      $timeout(() => {
        if (
          Object.keys(organizations).length === 1
          && !showOrganizations
          && organizations[Object.keys(organizations)[0]].enabled
        ) {
          GlobalsService.SetSingleOrganization(true);
          $scope.selectOrganization([Object.values(organizations)[0]][0]);
        } else {
          GlobalsService.SetSingleOrganization(false);
        }

        $scope.organizations = organizations;

        for(const idKey in organizations) {
          organizations[idKey].users.forEach(u => {
            if (u === user._id) {
              organizations[idKey].userCanEdit = true;
            }
          });

          organizations[idKey].canUseOrganization
            = organizations[idKey].enabled || $scope.superAdmin;
          organizations[idKey].canEditOrganization
            = organizations[idKey].enabled
              && organizations[idKey].userCanEdit
            || $scope.superAdmin;

          if (!organizations[idKey].enabled) {
            organizations[idKey].editTooltip = 'organization_disabled';
          } else if (!organizations[idKey].userCanEdit) {
            organizations[idKey].editTooltip = 'do_not_have_permission';
          }
        }
      });
    })
    .catch(ErrorService.handler);
  });

  $scope.selectOrganization = organization => {
    OrganizationService.SetCurrent(organization);
    $state.go(SAFE_STATES.placesList, { organizationId: organization._id });

    $rootScope.$emit('organization-join', organization._id);
  };

  $scope.edit = organizationId => {
    $uibModal.open({
      templateUrl:
        '/templates/components/modals/organizations-modal/index.html',
      size: 'lg',
      controller: 'OrganizationModalController',
      resolve: {
        organizationId: () => organizationId,
        superAdmin: () => $scope.superAdmin,
      },
    });
  };

  $scope.switch = organization => {
    OrganizationService.SwitchOrganizationEnable(organization);
  };
};

export default module.controller(
  'OrganizationsListController',
  OrganizationsListController
);
