import angular from 'angular';
import '../../services/globals';
import '../../services/reception';
import '../../services/errors';
import '../../components/operator';
import '../../components/status';

const module = angular.module('whyline.controllers.reception-path', [
  'whyline.services.globals',
  'whyline.services.reception',
  'whyline.services.errors',
  'whyline.components.operator',
  'whyline.components.status',
]);

const ReceptionPathController = ($scope, $state, $timeout, SchemaService, GlobalsService, ReceptionService, ErrorService) => {
  /*@ngInject*/

  $scope.currentPage = 1;

  ReceptionService.SetButtonStatus('back', true);
  ReceptionService.SetButtonStatus('forward', false);
  ReceptionService.SetButtonStatus('status-text', false);

  if(GlobalsService.CurrentPlace.placeId) {
    $scope.currentPlaceId = GlobalsService.CurrentPlace.placeId;
  } else {
    $scope.currentPlaceId = $state.params.placeId;
  }

  if($state.params.n == 0) {
    SchemaService.GetAllPopulated()
      .then(items => {
        // newData.items = Object.assign({}, newData.schemasPopulated, newData.areasPopulated, newData.linesPopulated);
        $timeout(() => {
          $scope.items = items;
        });
      })
      .catch(ErrorService.handler);
  }

  // Get current item in the actual path
  if($state.params.n != 0) {
    $timeout(() => {
      const step = ReceptionService.GetCurrent();
      if(step && step.items) {
        $scope.items = step.items;
      }
    });
  }
};

module.exports = module.controller('ReceptionPathController', ReceptionPathController);
