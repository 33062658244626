import angular from 'angular';
import '../services/organization-area';
import '../services/errors';
import './modals/organization-areas';
import '../components/status';
import '../services/globals';

const module = angular.module('whyline.controllers.organization-areas', [
  'whyline.components.status',
  'whyline.modals.organization-area',
  'whyline.services.organization-area',
  'whyline.services.errors',
  'whyline.services.globals',
]);

const OrganizationAreasController = ($scope, $uibModal, $translate, OrganizationAreaService, NotificationService, ErrorService, dialog, GlobalsService) => {
  /*@ngInject*/

  // View init
  $scope.organizationAreas = OrganizationAreaService.GetAllFromServer();
  $scope.quantityOfItems = 8;

  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';


  // Toggle area enabled
  $scope.switch = organizationArea => {
    let enableInPlaces = organizationArea.open;
    let enabled = !organizationArea.open;
    OrganizationAreaService.Switch(organizationArea, enabled, enableInPlaces).then(response => {
      if (response.data) {
        enableInPlaces = true;
        enabled = response.data.open;
        const confirmMessage = enabled ? 'organization_area_enable_all_places' : 'organization_area_disable_all_places';
        dialog
          .confirm($translate.instant(confirmMessage))
          .then(() => {
            OrganizationAreaService.Switch(organizationArea, enabled, enableInPlaces);
          }).catch(() => {
            OrganizationAreaService.Switch(organizationArea, !enabled, enableInPlaces);
          });
      }
    });
  };

  // Remove
  $scope.remove = (organizationAreaId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('area_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        OrganizationAreaService.Remove(organizationAreaId)
          .then(() => {
            NotificationService.Success($translate.instant('area_remove_succ'));
            $scope.organizationAreas = OrganizationAreaService.GetAll();
          })
          .catch(err => {
            $scope.organizationAreas = OrganizationAreaService.GetAllFromServer();
            ErrorService.handler(err);
          });
      });
  };

  // Modal
  $scope.open = organizationAreaId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-areas-modal.html',
      size: 'lg',
      controller: 'OrganizationAreaModalController',
      resolve: {
        organizationAreaId: () => organizationAreaId
      }
    });
  };
};

module.exports = module.controller('OrganizationAreasController', OrganizationAreasController);
