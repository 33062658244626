import angular from 'angular';
import '../app.constants';
import '../services/auth';
import '../services/globals';
import 'angular-recaptcha';

const module = angular.module('whyline.controllers.reset-password', [
  'whyline.constants',
  'whyline.services.auth',
  'whyline.services.globals',
  'vcRecaptcha',
]);

const ResetPasswordController = function (SAFE_STATES, $scope, $state, AuthService, NotificationService, GlobalsService, $translate, PUBLIC_KEY) {
  'ngInject';

  $scope.enterpriseType = GlobalsService.EnterpriseType;
  $scope.currentYear = new Date().getFullYear();

  const showSignInError = () => {
    NotificationService.Error($translate.instant('err_reset_pw'));
  };

  $scope.reseted = false;

  const validatePassword = () => {
    const regexPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])\S{8,}$/);
    if (!regexPassword.test(this.password)) {
      NotificationService.Error($translate.instant('invalid_current_pw'));
      return false;
    }
  };

  this.save = () => {
    if (this.password !== this.passwordRepeated) {
      NotificationService.Error($translate.instant('unmatch_pw'));
    } else {
      validatePassword(this.password);
      grecaptcha.ready(() => {
        grecaptcha.execute(PUBLIC_KEY, { action: 'submit' }).then(captchaResponse => {
          return AuthService.ResetPassword(this.password, $state.params.token, captchaResponse)
          .then(res => {
            if (res === true) {
              $scope.reseted = true;
            } else if (res && res !== true) {
              $state.go(SAFE_STATES.organizationsList);
            } else {
              showSignInError();
            }
          })
          .catch(showSignInError);
        });
      });
    }
  };
};

module.exports = module.controller('ResetPasswordController', ResetPasswordController);
