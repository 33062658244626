import angular from 'angular';
import './modals/organization-lines';
import '../components/status';
import '../services/organization-line';
import '../services/errors';
import '../services/notifications';
import '../services/globals';

const module = angular.module('whyline.controllers.organization-lines', [
  'whyline.components.status',
  'whyline.services.organization-line',
  'whyline.services.errors',
  'whyline.services.notifications',
  'whyline.modals.organization-line',
  'whyline.services.globals',
]);

const OrganizationLinesController = ($scope, $window, $timeout, $translate, GlobalsService, $uibModal, NotificationService, OrganizationLineService, ErrorService, dialog, currentOrganization) => {
  /*@ngInject*/

  // View init
  $scope.organizationLines = OrganizationLineService.GetAll();
  $scope.quantityOfItems = 8;

  // Plan to limit lines creation
  $scope.plan = {};
  $scope.plan.maxLines = currentOrganization.plan && currentOrganization.plan.maxLines ? currentOrganization.plan.maxLines : 5;
  $scope.placeholderSearch = GlobalsService.IsMobile() ? $translate.instant('search') : '';

  // Toggle line enabled
  $scope.switch = organizationLine => {
    let enableInPlaces = organizationLine.open;
    let enabled = !organizationLine.open;
    OrganizationLineService.Switch(organizationLine, enabled, enableInPlaces).then(response => {
      if (response.data) {
        enableInPlaces = true;
        enabled = response.data.open;
        const confirmMessage = enabled ? 'organization_line_enable_all_places' : 'organization_line_disable_all_places';
        dialog
          .confirm($translate.instant(confirmMessage))
          .then(() => {
            OrganizationLineService.Switch(organizationLine, enabled, enableInPlaces);
          }).catch(() => {
            OrganizationLineService.Switch(organizationLine, !enabled, enableInPlaces);
          });
      }
    });
  };

  // Remove
  $scope.remove = (organizationLineId, name) => {
    let n = name || '';
    dialog.confirm($translate.instant('line_{name}_remove_sure').replace('{name}', n))
      .then(() => {
        OrganizationLineService.Remove(organizationLineId)
          .then(() => {
            NotificationService.Success($translate.instant('line_remove_succ'));
            $scope.organizationLines = OrganizationLineService.GetAll();
          })
          .catch(err => {
            $scope.organizationLines = OrganizationLineService.GetAllFromServer();
            ErrorService.handler(err);
          });
      });
  };

  // Modal
  $scope.open = organizationLineId => {
    $uibModal.open({
      templateUrl: '/templates/components/modals/organization-lines-modal.html',
      size: 'lg',
      controller: 'OrganizationLineModalController',
      resolve: {
        organizationLineId: () => organizationLineId,
        currentOrganization,
      }
    });
  };
};

module.exports = module.controller('OrganizationLinesController', OrganizationLinesController);
