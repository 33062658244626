import angular from 'angular';
import '../app.constants';
import { pickBy, indexOf, keys, values } from 'lodash';
import '../resources/area';
import '../services/socket';
import '../services/area';
import '../services/user';
import '../factories/socket.io';

const module = angular.module('whyline.sockets.area', [
  'whyline.constants',
  'whyline.factories.socket',
  'whyline.resources.area',
  'whyline.services.socket',
  'whyline.services.area',
  'whyline.services.user',
]);

const AreaSocket = (Socket, Area, Subscription, API_URL, $rootScope, SocketService, AreaService, UserService) => {
  $rootScope.$on('socketReady', () => {
    SocketService.GetInstance().then(socket => {
      socket.on('area:created', area => {
        UserService.SyncCurrent()
          .then(user => {
            const currentRole = UserService.GetCurrentRoleFor(user);
            if(currentRole.name === 'Coordinator' || currentRole.name === 'Admin General') {
              Area.UpdateCache(area._id, area);
              // Update cache for carousel
              const cacheUrl = `${API_URL}area/allowed`;
              const list = Area.GetCacheSearch(cacheUrl);
              list[area._id] = area;
              Area.UpdateCacheSearch(cacheUrl, list, {});
            }
          });
      });
      socket.on('area:updated', area => {
        if(!area.hasOwnProperty('serviceId')) {
          AreaService.GetOneAsPromise(area._id).then(area => {
            Reflect.deleteProperty(area, 'serviceId');
          });
        }
        Area.UpdateCache(area._id, area);
      });
      socket.on('area:deleted', area => {
        Area.RemoveFromCache(area._id, area._type);
        const cacheUrl = `${API_URL}area/allowed`;
        const list = Area.GetCacheSearch(cacheUrl);
        if(list.hasOwnProperty(area._id)) {
          Reflect.deleteProperty(list, area._id);
        }
        Area.UpdateCacheSearch(cacheUrl, list, {});
      });

      // Refresh subscriptions in cache for Areas containing the Line cleared
      socket.on('line:cleared', line => {
        // Get cleared Line id
        let lineClearedId = line._id;
        // Get all Areas from cache
        Area.FetchAll({ promise: true })
        .then(areas => {
          // Get only those Areas that contain the cleared Line
          let affectedAreas = pickBy(areas, area => indexOf(area.lines, lineClearedId) > -1);
          let affectedAreasKeys = keys(affectedAreas);
          // Clear each Cache entry
          for(const a of values(areas)) {
            const areaFromServer = AreaService.GetOneFromServer(a._id);
            Area.UpdateCache(a._id, areaFromServer);
          }
          affectedAreasKeys.forEach(areaId => {
            let url = `${API_URL}subscription?enqueueIn=process&areaId=${areaId}`;
            Subscription.ClearCacheData(url);
          });
        });
      });
    });
  });
};

module.exports = module.factory('AreaSocket', (Socket, Area, Subscription, API_URL, $rootScope, SocketService, AreaService, UserService) => new AreaSocket(Socket, Area, Subscription, API_URL, $rootScope, SocketService, AreaService, UserService));
